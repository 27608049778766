import { useMemo } from 'react'
import { create } from 'axios'

import { intercept } from './helpers'

export default () => {
  const client = useMemo(() => {
    const instance = create()

    instance.interceptors.request.use(intercept)

    return instance
  }, [])

  return client
}
