import { propTypes, defaultProps } from 'apps/scorecard/prop-types/placeholder'
import { usePlaceholder } from 'apps/scorecard/hooks'

export const Header = (props) => usePlaceholder(props)

Header.propTypes = propTypes

Header.defaultProps = defaultProps

export default Header
