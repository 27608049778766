import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'
import { map, get, keys } from 'lodash'
import {
  string,
  bool,
  number,
  func,
  any,
  object,
  array,
  oneOfType,
} from 'prop-types'
import React from 'react'

import { PLEASE_CHOOSE } from 'common/constants'

class DropdownSelect extends React.Component {
  static defaultProps = {
    isMultiSelect: false,
    isConnect: false,
    renderOption: null,
    selectProps: {},
  }

  static propTypes = {
    formControlVariant: string,
    formControlFullWidth: bool,
    formControlClassName: string,
    inputLabel: string,
    selectOnChange: func,
    selectValue: any,
    selectOptions: oneOfType([object, array]),
    isMultiSelect: bool,
    // TODO: refactor: remove this coupled prop
    isConnect: bool,
    renderOption: func,
    selectProps: object,
  }

  renderMenuItems = () => {
    const { selectOptions, isConnect, renderOption } = this.props

    return map(selectOptions, (value, key) => {
      if (renderOption) {
        return renderOption(value)
      }
      const val = key === PLEASE_CHOOSE ? '' : key
      return (
        <MenuItem value={isConnect ? val : key} key={value}>
          {value}
        </MenuItem>
      )
    })
  }

  // this method requires that selectOptions to be an array of strings
  renderSelectMenuItems = () => {
    const { selectOptions, selectValue, renderOption } = this.props
    const selectedValues =
      typeof selectValue === 'object'
        ? Object.values(selectValue).map((item) => item.id)
        : selectValue
    return map(selectOptions, (value, key) => {
      if (renderOption) {
        return renderOption(value, key)
      }
      return (
        <MenuItem
          key={typeof value === 'object' ? JSON.stringify(key) : key}
          value={value}
          style={{ textTransform: `capitalize` }}
        >
          <Checkbox checked={selectedValues?.includes(value?.id || value)} />
          {key}
        </MenuItem>
      )
    })
  }

  stringifySelectValues = (selectValues) =>
    map(selectValues, (value, id) =>
      value.showColor && value.color ? (
        <span>
          <span
            style={{
              height: '6px',
              width: '6px',
              borderRadius: '50%',
              display: 'inline-block',
              margin: '3px',
              backgroundColor: value.color,
            }}
          />
          <span>{value.name}</span>
          <span>{selectValues.length > id + 1 ? ', ' : ''}</span>
        </span>
      ) : (
        <span>
          {value.name}
          <span>{selectValues.length > id + 1 ? ', ' : ''}</span>
        </span>
      )
    )

  render() {
    const {
      isMultiSelect,
      formControlVariant,
      formControlClassName,
      formControlFullWidth,
      inputLabel,
      selectOnChange,
      selectValue,
      selectOptions,
      selectProps,
      isOutlined = false,
      handleDeselect,
      name,
    } = this.props

    const value = selectValue || get(keys(selectOptions), '[0]', '')

    const handleOnChange = (e) => {
      if (handleDeselect) handleDeselect(e, name)
      selectOnChange(e)
    }

    return (
      <FormControl
        variant={formControlVariant}
        className={formControlClassName}
        fullWidth={formControlFullWidth}
      >
        <InputLabel htmlFor={inputLabel}>{inputLabel}</InputLabel>

        <Select
          label={inputLabel}
          onChange={handleOnChange}
          defaultValue={value}
          value={value}
          multiple={isMultiSelect}
          {...(isOutlined && {
            input: () => <OutlinedInput label={inputLabel} id={inputLabel} />,
          })}
          {...(isMultiSelect && {
            renderValue: (selected) => this.stringifySelectValues(selected),
          })}
          {...selectProps}
        >
          {isMultiSelect
            ? this.renderSelectMenuItems()
            : this.renderMenuItems()}
        </Select>
      </FormControl>
    )
  }
}

export { DropdownSelect }
