import React, { Suspense as OnDemand } from 'react'
import { Switch as Routes } from 'react-router-dom'

import { useRoutes } from 'apps/scorecard/hooks'
import { Route, Style } from 'apps/scorecard/components'
import {
  Interview,
  Interviews,
  NotFound,
  Properties,
  Questions,
} from 'apps/scorecard/components/pages'
import { Loader } from 'apps/scorecard/components/widgets'
import Modals from 'apps/scorecard-v2/components/modals'

export const App = () => {
  const routes = useRoutes()

  return (
    <>
      <Style />
      <Modals />
      <OnDemand fallback={<Loader />}>
        <Routes>
          <Route path={routes.questions.suggest} component={Questions} exact />
          <Route path={routes.interview.root} component={Interview} />
          <Route path={routes.interviews} component={Interviews} />
          <Route path={routes.properties} component={Properties} exact />
          <Route path='*' component={NotFound} />
        </Routes>
      </OnDemand>
    </>
  )
}

App.propTypes = {}

App.defaultProps = {}

export default App
