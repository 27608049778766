import { Factory } from '../client/Factory'

const FeedsAPIServiceNamespace = 'feedsapi'

const FeedsAPIServiceConfigs = {
  QueryFeeds: Factory.createRequestConfig(Factory.MethodGet, '/sabo/feed/list'),
  ChangeFeedStatus: Factory.createRequestConfig(
    Factory.MethodPatch,
    '/sabo/feed/status'
  ),
  ChangeFeedClass: Factory.createRequestConfig(
    Factory.MethodPatch,
    '/sabo/feed/classification'
  ),
  ChangeFeedAssignee: Factory.createRequestConfig(
    Factory.MethodPatch,
    '/sabo/feed/assignee'
  ),
  BulkUpdate: Factory.createRequestConfig(
    Factory.MethodPatch,
    '/sabo/feed/bulk-update'
  ),
  ChangeFeedAgency: Factory.createRequestConfig(
    Factory.MethodPatch,
    '/sabo/feed/agency'
  ),
}

const FeedsAPIService = {
  QueryFeeds: Factory.makeAuthenticatedClientCall(
    FeedsAPIServiceConfigs.QueryFeeds
  ),
  ChangeFeedStatus: Factory.makeAuthenticatedClientCall(
    FeedsAPIServiceConfigs.ChangeFeedStatus
  ),
  ChangeFeedAssignee: Factory.makeAuthenticatedClientCall(
    FeedsAPIServiceConfigs.ChangeFeedAssignee
  ),
  ChangeFeedClass: Factory.makeAuthenticatedClientCall(
    FeedsAPIServiceConfigs.ChangeFeedClass
  ),
  BulkUpdate: Factory.makeAuthenticatedClientCall(
    FeedsAPIServiceConfigs.BulkUpdate
  ),
  ChangeFeedAgency: Factory.makeAuthenticatedClientCall(
    FeedsAPIServiceConfigs.ChangeFeedAgency
  ),
}

export { FeedsAPIServiceNamespace, FeedsAPIServiceConfigs, FeedsAPIService }
