/* eslint-disable import/no-cycle */
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import flow from 'lodash/flow'
import React from 'react'

import { TESTED_SKILLS_LIMIT } from 'common/constants'

import { Autosuggest } from '../Autosuggest'

const styles = (theme) => ({
  skillChip: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
})

const SkillsBase = ({
  skills,
  addSkill,
  removeSkill,
  skillIds,
  skillsLabel,
  skillsAutosuggestProps,
  classes,
}) => {
  const renderItem = (profileSkill, index) => (
    <Chip
      className={classes.skillChip}
      key={`${JSON.stringify(profileSkill)}`}
      label={profileSkill.name}
      onDelete={removeSkill(index)}
      id={`testedSkill-${index}`}
      data-automator={`tested-skills-chips-${index}`}
    />
  )

  const { buildSkillsLabel } = skillsAutosuggestProps
  const getOptionDisabled = ({ value: { id } }) => skillIds.includes(id)

  return (
    <>
      <Typography variant='h6'>{skillsLabel}</Typography>
      <Grid item xs={10} sm={6}>
        <Autosuggest
          {...skillsAutosuggestProps}
          label={buildSkillsLabel(TESTED_SKILLS_LIMIT)}
          onChange={addSkill(skills.length)}
          skillIds={skillIds}
          getOptionDisabled={getOptionDisabled}
          clearOnBlur
          data-automator='tested-skills-autosuggest'
        />
      </Grid>

      <Box m={1}>
        <Grid container direction='row'>
          {skills.map(renderItem)}
        </Grid>
      </Box>
    </>
  )
}

export const Skills = flow(withStyles(styles))(SkillsBase)
