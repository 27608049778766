import styled from 'styled-components'
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
} from '@mui/material'

import { Highlighted } from 'apps/scorecard/components/widgets/buttons'

export const StyledDialog = styled(Dialog)`
  padding: 15px;
`

export const StyledDialogTitle = styled(DialogTitle)`
  padding: 8px 24px 0;

  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  align-items: center;
`

export const StyledDialogActions = styled(DialogActions)`
  margin: 10px 20px 10px;
  padding: 8px 3px;
`

export const StyledDialogContent = styled(DialogContent)`
  padding: 0 24px;
`

export const StyledTextField = styled(TextField)`
  margin-top: 12px;
`

export const StyledButton = styled(Highlighted)`
  ${({ color }) =>
    color === 'default' &&
    `
    background-color: #F9D223;
    &:hover {
      background-color: #e9c72e;
    }
  `}
`
