import property from 'lodash/property'
import styled from 'styled-components'

import { Button } from 'apps/scorecard/components/widgets'

export default (component) => styled(component)`
  ${Button} {
    color: ${property('theme.palette.error.light')};
  }
`
