import { capitalize } from 'lodash'

import { ProfileService } from 'sdk/profile/Service'

const splitAndcapitalize = (text) => text.split('_').map(capitalize).join(' ')

const getItem = ({ isFormat2, text }) => {
  const formattedText = splitAndcapitalize(text)
  return {
    [isFormat2 ? text : formattedText]: {
      name: isFormat2 ? text : formattedText,
      id: isFormat2 ? formattedText : text,
    },
  }
}

export const transformTravelPreferenceOptions = ({ options, isFormat2 }) => {
  const result = options.reduce(
    (acc, cur) => ({
      workPreference: {
        ...acc.workPreference,
        ...getItem({ text: cur.workPreference, isFormat2 }),
      },
      workSetups: {
        ...acc.workSetups,
        ...getItem({ text: cur.workSetup, isFormat2 }),
      },
    }),
    { workPreference: {}, workSetups: {} }
  )
  return result
}

// eslint-disable-next-line consistent-return
const getTravelPreferenceOptions = async ({ isFormat2 } = {}) => {
  try {
    const response = await ProfileService.getTravelPreferences()
    return transformTravelPreferenceOptions({
      options: response?.data?.data || [],
      isFormat2,
    })
  } catch (error) {
    console.error(error)
    return {}
  }
}

export default getTravelPreferenceOptions
