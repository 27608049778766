import noop from 'lodash/noop'
import { useMemo } from 'react'
import { create } from 'axios'
import settle from 'axios/lib/core/settle'
import { adapter as adapt } from 'axios/lib/defaults'

import { useAuth, useGlobal } from 'apps/scorecard/hooks'

import { STATUS } from './constants'
import { intercept } from './helpers'

export default () => {
  const { data: global } = useGlobal()
  const { accessToken } = useAuth()
  const client = useMemo(() => {
    const adapter = function ({ global: getFrom = noop, offline, ...config }) {
      const data = getFrom(global) || (!accessToken && offline)
      const inject = (resolve, reject) =>
        settle(resolve, reject, { data, config, ...STATUS })

      return data ? new Promise(inject) : adapt.call(this, config)
    }
    const instance = create({
      headers: {
        accept: 'application/json, text/plain, */*',
        ...(!!accessToken && { Authorization: accessToken }),
      },
      baseURL: process.env.REACT_APP_API_HOST,
      adapter,
    })

    instance.interceptors.response.use(intercept)

    return instance
  }, [accessToken, global])

  return client
}
