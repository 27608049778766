import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import React, { useState } from 'react'

const PasswordField = ({ error, onChange, isSubmitting, label, ...props }) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false)

  const togglePasswordVisibility = () =>
    setPasswordVisible((visible) => !visible)

  return (
    <FormControl
      error={!!error}
      onChange={(e) => onChange(e.target.value)}
      disabled={isSubmitting}
      {...props}
    >
      <InputLabel htmlFor='password'>{label}</InputLabel>
      <OutlinedInput
        id='password'
        autoComplete='new-password'
        type={isPasswordVisible ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={() => togglePasswordVisibility()}
              size='large'
            >
              {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={label}
      />
    </FormControl>
  )
}

PasswordField.defaultProps = {
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
}

export { PasswordField }
