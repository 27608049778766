import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import FindReplaceIcon from '@mui/icons-material/FindReplace'
import { IconButton, Typography } from '@mui/material'
import debounce from 'lodash.debounce'
import { useEffect, useState, useCallback } from 'react'

import StyledTooltip from 'apps/scorecard-v2/components/StyledTooltip'
import { STATUS } from 'apps/scorecard-v2/constants'
import { useKeyPress, useTracking } from 'apps/scorecard/hooks'

import { StyledTextField } from '../../live-coding/style'

import DetailsFooter from './details-footer'
import DetailsTab from './details-tab'
import QuestionSlider from './question-slider'
import {
  StyledWrapper,
  StyledTitle,
  StyledIconButton,
  StyledCircle,
} from './style'

const QuestionDetail = ({
  handleSave,
  question,
  setCurrentQuestionIndex,
  questionsLength,
  handleSaveFeedback,
  onChangeQuestion,
  toggleTabKeyDisable,
}) => {
  const { text, difficulty, hint, id, score, note, index, parentTitle } =
    question

  const [comment, setComment] = useState(note || '')
  const [isCommentInFocus, setIsCommentFocus] = useState(false)
  const [itemScore, setItemScore] = useState(0)
  const [typingStatus, setTypingStatus] = useState(STATUS.idle)

  const tracking = useTracking()
  const eventTracking = (label, action = 'Click') => {
    tracking.event({
      category: 'Scorecard-v2',
      action,
      label: `${action}_${label}`,
    })
  }

  toggleTabKeyDisable(true)

  useEffect(
    () => () => {
      toggleTabKeyDisable(false)
    },
    []
  )

  useEffect(() => {
    setItemScore(score || 0)
  }, [id])

  useEffect(() => {
    setComment(note || '')
  }, [id])

  useEffect(() => {
    toggleTabKeyDisable(isCommentInFocus)
  }, [isCommentInFocus])

  useKeyPress('ArrowLeft', () => !isCommentInFocus && onNavigateBackward())
  useKeyPress('ArrowRight', () => !isCommentInFocus && onNavigateForward())
  useKeyPress('x', () => !isCommentInFocus && handleKeyPress(0))
  useKeyPress('1', () => !isCommentInFocus && handleKeyPress(1))
  useKeyPress('2', () => !isCommentInFocus && handleKeyPress(2))
  useKeyPress('3', () => !isCommentInFocus && handleKeyPress(3))
  useKeyPress('4', () => !isCommentInFocus && handleKeyPress(4))
  useKeyPress('5', () => !isCommentInFocus && handleKeyPress(5))
  useKeyPress('6', () => !isCommentInFocus && handleKeyPress(6))
  useKeyPress('7', () => !isCommentInFocus && handleKeyPress(7))
  useKeyPress('8', () => !isCommentInFocus && handleKeyPress(8))
  useKeyPress('9', () => !isCommentInFocus && handleKeyPress(9))
  useKeyPress('0', () => !isCommentInFocus && handleKeyPress(10))
  useKeyPress('Backspace', () => {
    !isCommentInFocus && setCurrentQuestionIndex(null)
  })

  const handleItemSave = (_, inputScore) => {
    if (inputScore === score) {
      return
    }

    const payload = {
      questionAnswer: {
        answer: { score: inputScore, note: comment },
      },
    }
    handleSave(id, payload)
  }

  const handleKeyPress = (inputScore) => {
    setItemScore(inputScore)
    handleItemSave(null, inputScore)
  }

  const handleCommentChange = ({ target }) => {
    setComment(() => target.value)
    setTypingStatus(STATUS.pending)
    debouncedSaveComment(target.value, itemScore, id)
  }

  const onNavigateForward = () => {
    if (index < questionsLength - 1) {
      setCurrentQuestionIndex(() => index + 1)
    }
  }

  const onNavigateBackward = () => {
    if (index > 0) {
      setCurrentQuestionIndex(() => index - 1)
    }
  }

  const handleFocus = () => {
    setIsCommentFocus(true)
  }

  const handleBlur = () => {
    setIsCommentFocus(false)
  }
  const handleBackClick = () => {
    setCurrentQuestionIndex(() => null)
  }

  const handleChangeQuestion = () => {
    onChangeQuestion()
  }

  const debouncedSaveComment = useCallback(
    debounce((userComment, score, id) => {
      if (userComment !== note) {
        const payload = {
          questionAnswer: {
            answer: { score, note: userComment },
          },
        }
        handleSave(id, payload, true)
        setTypingStatus(STATUS.fulfilled)
      }
    }, 700),
    []
  )

  const getHelperText = () => {
    if (typingStatus === STATUS.pending) {
      return 'Typing...'
    }
    if (typingStatus === STATUS.fulfilled) {
      return 'Saved'
    }
    return ' '
  }

  return (
    <StyledWrapper>
      <Typography color='#2B254F' mt={1} fontWeight='600'>
        {parentTitle}
      </Typography>
      <StyledTitle>
        <StyledIconButton
          onClick={() => {
            handleBackClick()
            eventTracking('Back')
          }}
        >
          <ArrowBackIosIcon />
        </StyledIconButton>
        <StyledCircle
          difficulty={difficulty}
          onMouseEnter={() => eventTracking('Dot', 'Hover')}
        />
        <Typography fontWeight={700}>{text}</Typography>

        <IconButton onClick={handleChangeQuestion} sx={{ marginLeft: 'auto' }}>
          <StyledTooltip arrow title='Add/replace questions' placement='top'>
            <FindReplaceIcon htmlColor='#2b254f' fontSize='1.85rem' />
          </StyledTooltip>
        </IconButton>
      </StyledTitle>

      <QuestionSlider
        handleItemSave={handleItemSave}
        setItemScore={setItemScore}
        itemScore={itemScore}
      />

      <StyledTextField
        fullWidth
        variant='outlined'
        placeholder='Add a comment'
        value={comment}
        onChange={handleCommentChange}
        multiline
        onFocus={handleFocus}
        onBlur={handleBlur}
        show='show'
        helperText={getHelperText()}
        onClick={() => eventTracking('Text_Area')}
      />

      <DetailsTab
        hint={hint}
        handleSaveFeedback={handleSaveFeedback}
        questionId={id}
        setIsCommentFocus={setIsCommentFocus}
      />
      <DetailsFooter
        onNavigateForward={onNavigateForward}
        onNavigateBackward={onNavigateBackward}
        canGoBack={index > 0}
        canGoForward={index < questionsLength - 1}
        answeredRatio={`${index + 1}/${questionsLength}`}
      />
    </StyledWrapper>
  )
}

export default QuestionDetail
