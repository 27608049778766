import * as Yup from 'yup'

Yup.addMethod(Yup.array, 'unique', function (message, mapper) {
  return this.test('unique', message, function (list) {
    const { path } = this
    const mappedList = list.map(mapper)
    return (
      mappedList.length === new Set(mappedList).size ||
      this.createError({ path, message })
    )
  })
})
