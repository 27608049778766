import property from 'lodash/property'
import styled from 'styled-components'

import { Button } from 'apps/scorecard/components/widgets'

export default (component) => styled(component)`
  padding-bottom: ${({ theme: { spacing } }) => spacing(1)};

  & > {
    div[aria-roledescription='markup'] {
      font-family: ${property('theme.typography.fontFamily')};

      & > {
        * {
          margin-top: ${({ theme: { spacing } }) => spacing(2)};

          & > {
            a {
              color: inherit;

              &:after {
                content: '🔗';
                display: inline-block;
                margin-left: ${({ theme: { spacing } }) => spacing(1)};
              }
            }
          }
        }
      }

      em {
        font-style: italic;
      }

      strong {
        font-weight: bold;
      }

      ol,
      ul {
        list-style-position: inside;
        margin-left: ${({ theme: { spacing } }) => spacing(2)};
      }

      ol {
        list-style-type: decimal;
      }

      ul {
        list-style-type: disc;
      }

      blockquote,
      pre {
        ${property('theme.typography.body1')};
        background-color: ${property('theme.palette.grey.50')};
        box-shadow: ${property('theme.shadows.1')};
        margin-left: ${({ theme: { spacing } }) => spacing(2)};
        margin-right: ${({ theme: { spacing } }) => spacing(2)};
        padding: ${({ theme: { spacing } }) => spacing(2)};
      }

      blockquote {
        ${property('theme.typography.body1')};
        border-left: solid 10px ${property('theme.palette.grey.500')};
        color: rgba(0, 0, 0, 0.5);

        &:before,
        &:after {
          content: '"';
          display: inline-block;
        }
      }

      pre {
        ${property('theme.typography.body2')};
        color: rgba(0, 0, 0, 0.75);
        overflow: auto;
      }
    }

    nav {
      margin-top: ${({ theme: { spacing } }) => spacing(1)};

      ${Button} {
        text-transform: none;
      }
    }
  }
`
