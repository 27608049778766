/**
 *
 * For more details:
 *
 * https://react-dnd.github.io/react-dnd/docs/overview#drag-sources-and-drop-targets
 *
 * https://react-dnd.github.io/react-dnd/docs/api/drag-source
 *
 * */

import constant from 'lodash/constant'
import isEqual from 'lodash/isEqual'
import { cloneElement, useCallback, useMemo } from 'react'
import { useDrag, useDrop } from 'react-dnd'

const collect = (monitor) => ({ isDragging: monitor.isDragging() })

const canDrop = constant(false)

const Source = ({ type, source, find, sort, children }) => {
  const item = useMemo(
    () => ({ item: find(source), type }),
    [type, find, source]
  )
  const end = useCallback(
    (_, monitor) => {
      const {
        item: { index: to },
      } = item
      const {
        item: { details: from },
      } = monitor.getItem()
      const didDrop = monitor.didDrop()

      return !didDrop && sort({ from, to })
    },
    [sort, item]
  )
  const hover = useCallback(
    ({ item: { details: dragged } }) => {
      const {
        item: { index: to, details: hovered },
      } = item

      if (!isEqual(dragged, hovered)) {
        sort({ from: dragged, to })
      }
    },
    [sort, item]
  )
  const [{ isDragging }, drag] = useDrag({ item, collect, end })
  const [, drop] = useDrop({ accept: type, canDrop, hover })
  const opacity = useMemo(() => (isDragging ? 0 : 1), [isDragging])
  const ref = useCallback((node) => drag(drop(node)), [drag, drop])

  return cloneElement(children, { style: { opacity }, ref })
}

export { Source }
