import property from 'lodash/property'
import styled from 'styled-components'

export const getColorBy =
  () =>
  ({ value, range = Math.ceil(value / 2) }) =>
    ({
      0: '#eeeeee',
      1: '#f0a9a0',
      2: '#f9ba7f',
      3: '#ffd569',
      4: '#a3d3b3',
      5: '#a3d0d3',
    }[range])

export default (component) => styled(component)`
  align-items: center;
  background-color: ${getColorBy()};
  flex: 1 1 0px;
  flex-grow: 1;
  position: relative;

  &:after {
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }

  &,
  label {
    display: flex;
    justify-content: center;
  }

  &:first-of-type {
    strong {
      padding: 0 ${({ theme: { spacing } }) => spacing(1)};
    }
  }

  &:not(:first-of-type) {
    strong {
      width: 24px;
    }
  }

  label {
    align-items: flex-end;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  strong {
    ${property('theme.typography.button')};
    background-color: ${property('theme.palette.common.white')};
    border-radius: 100px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    color: ${property('theme.palette.common.black')};
    cursor: pointer;
    display: inline-block;
    height: 24px;
    line-height: 24px;
    margin-bottom: ${({ theme: { spacing } }) => spacing(1)};
    position: relative;
    text-align: center;
    text-transform: uppercase;
    transition: ${({
      theme: {
        transitions: {
          duration: { shortest },
          easing: { easeInOut },
        },
      },
    }) =>
      `background-color ${shortest}ms ${easeInOut}, color ${shortest}ms ${easeInOut}`};
    z-index: 3;

    &:hover {
      background-color: ${property('theme.palette.common.black')};
      color: ${property('theme.palette.common.white')};
    }
  }

  span {
    display: none;
  }

  em {
    ${property('theme.typography.body2')};
    left: 0;
    position: absolute;
    width: 200%;
    text-align: center;
    top: ${({ theme: { spacing } }) => spacing(1)};
    z-index: 1;
  }

  input {
    position: absolute;
    z-index: -1;

    &:checked,
    &:focus {
      & + label {
        strong {
          background-color: ${property('theme.palette.common.black')};
          color: ${property('theme.palette.common.white')};
        }
      }
    }
  }
`
