import { useFormik } from 'formik'
import debounce from 'lodash.debounce'
import { useEffect, useCallback, useState } from 'react'
import * as Yup from 'yup'

import {
  InterviewConfirmation,
  Popup,
} from 'apps/scorecard-v2/components/modals'
import { Archive } from 'apps/scorecard-v2/components/modals/archive'
import { LIVE_CODING, STATUS } from 'apps/scorecard-v2/constants'
import { getIsNonTechnical } from 'apps/scorecard-v2/helpers'
import { useScorecardDispatch } from 'apps/scorecard-v2/hooks'
import {
  archiveInterview,
  updateInterviewDetails,
} from 'apps/scorecard-v2/slices/interview-slice'

import ScoringRequired from '../../../scoring-required'

import FeedbackFooter from './feedback-footer'
import FeedbackSection from './feedback-section'
import OverallScore from './overall-score'
import { Wrapper } from './style'

const validationSchema = Yup.object().shape({
  feedback1: Yup.string().min(150, 'Min 150 characters').required('Required'),
  feedback2: Yup.string().min(150, 'Min 150 characters').required('Required'),
  feedback3: Yup.string().min(150, 'Min 150 characters').required('Required'),
})

const Feedback = ({
  interview,
  questions,
  accessToken,
  setCurrentTab,
  toggleTabKeyDisable,
}) => {
  const {
    multiFeedback = {},
    dateTime,
    id,
    feedbackStatus,
    endingTime,
    vertical,
  } = interview
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [showScoringRequired, setShowScoringRequired] = useState(false)
  const [showArchive, setShowArchive] = useState(false)
  const [typing, setTyping] = useState(STATUS.idle)

  const scores = {}
  questions.nodes.forEach(({ title, nodes }) => {
    let totalAnswered = 0
    let totalQuestions = 0
    let accumulatedScores = 0
    let sectionWithScores = 0

    nodes.forEach((subSection) => {
      let subSectionScore = 0
      let subTotalAnswered = 0
      subSection.nodes.forEach((question) => {
        if (question.score) {
          subSectionScore += question.score
          subTotalAnswered += 1
        }
        totalQuestions += 1
      })
      totalAnswered += subTotalAnswered
      const score = subTotalAnswered ? subSectionScore / subTotalAnswered : 0

      if (score) {
        sectionWithScores += 1

        accumulatedScores += score
      }
    })

    scores[title] = {
      label: `${totalAnswered}/${totalQuestions}`,
      computedScore: sectionWithScores
        ? (accumulatedScores / sectionWithScores).toFixed(1)
        : 0,
      hasError: totalAnswered < totalQuestions && title === LIVE_CODING,
    }
  })

  const dispatch = useScorecardDispatch()

  const formik = useFormik({
    initialValues: {
      feedback1: multiFeedback.feedback1 || '',
      feedback2: multiFeedback.feedback2 || '',
      feedback3: multiFeedback.feedback3 || '',
      feedback4: multiFeedback.feedback4 || '',
      feedback5: multiFeedback.feedback5 || '',
    },
    validationSchema,
    onSubmit: (values) => {
      if (scores[LIVE_CODING]?.hasError) {
        setShowScoringRequired(true)
        return null
      }

      const payload = {
        endingTime: Math.floor(Date.now() / 1000),
        multiFeedback: values,
      }

      dispatch(
        updateInterviewDetails({
          accessToken,
          interviewId: id,
          payload,
        })
      )
    },
  })

  useEffect(() => {
    formik.setFieldTouched(true)
  }, [])

  const handleSaveFeedback = useCallback(
    debounce((values) => {
      dispatch(
        updateInterviewDetails({
          accessToken,
          interviewId: id,
          payload: { multiFeedback: values },
        })
      )
      setTyping(STATUS.idle)
    }, 750),
    []
  )

  const handleConfirmSubmit = () => {
    setShowConfirmation(true)
  }

  const onConfirm = () => {
    if (Object.keys(formik.errors).length) {
      setShowPopup(true)
    }

    setShowConfirmation(false)
    formik.handleSubmit()
  }

  const onArchive = (archivingReason) => {
    const payload = {
      interviewId: id,
      accessToken,
      archivingReason,
    }

    dispatch(archiveInterview(payload))
  }

  const onArchiveConfirm = () => {
    setShowArchive(true)
  }

  const onScoreConfirm = () => {
    setCurrentTab(LIVE_CODING)
  }

  const isNonTechnical = getIsNonTechnical(vertical.name)

  return (
    <>
      <Wrapper>
        <OverallScore scores={scores} isNonTechnical={isNonTechnical} />
        <FeedbackSection
          formik={formik}
          multiFeedback={multiFeedback}
          handleSaveFeedback={handleSaveFeedback}
          status={typing || feedbackStatus}
          setTyping={setTyping}
          toggleTabKeyDisable={toggleTabKeyDisable}
        />
        <FeedbackFooter
          endingTime={endingTime}
          dateTime={dateTime}
          onSubmit={handleConfirmSubmit}
        />
      </Wrapper>
      <InterviewConfirmation
        open={showConfirmation}
        onConfirm={onConfirm}
        onReject={() => setShowConfirmation(false)}
        onArchiveConfirm={onArchiveConfirm}
      />
      <Archive
        setOpen={setShowArchive}
        open={showArchive}
        onArchive={onArchive}
      />
      <Popup
        message='Please fill out the required fields.'
        title='Some required feedback fields are missing'
        open={showPopup}
        onConfirm={() => {
          setShowPopup(false)
        }}
      />
      <ScoringRequired
        open={showScoringRequired}
        onClose={() => setShowScoringRequired(false)}
        onConfirm={onScoreConfirm}
      />
    </>
  )
}

export default Feedback
