import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import withStyles from '@mui/styles/withStyles'
import flow from 'lodash/flow'
import get from 'lodash/get'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter, NavLink } from 'react-router-dom'

import Layout from 'components/Layout'
import { Loading } from 'components/Loading'
import { withProfile } from 'hocs/withProfile'
import { ProfileService } from 'sdk/profile/Service'

import { PAGES } from '../../common/constants'
import { AuthenticatedNavBar } from '../../components'

import { FaqSections } from './FaqSections'

const styles = (theme) => ({
  menuLink: {
    color: theme.palette.secondary.dark,
    fontSize: theme.typography.fontSize,
    textDecoration: 'none',
  },
  activeLink: {
    textDecoration: 'none',
    borderColor: theme.palette.primary.dark,
    color: theme.palette.black,
    '& span': {
      fontWeight: theme.typography.fontWeight.bold,
    },
  },
})

class FaqComponent extends React.Component {
  state = {
    loading: false,
  }

  constructor(props) {
    super(props)

    this.sections = [
      {
        title: this.props.t('faq_personal'),
        link: `/${PAGES.FAQ_PERSONAL}`,
        routeParam: PAGES.FAQ_PERSONAL,
      },
      {
        title: this.props.t('faq_project'),
        link: `/${PAGES.FAQ_PROJECT}`,
        routeParam: PAGES.FAQ_PROJECT,
      },
      {
        title: this.props.t('faq_community'),
        link: `/${PAGES.FAQ_COMMUNITY}`,
        routeParam: PAGES.FAQ_COMMUNITY,
      },
    ]

    this.sectionPages = this.sections.map((section) => section.routeParam)
  }

  async componentDidMount() {
    const { data } = await ProfileService.getProfile()
    this.props.dispatchers.loadProfile(data)
  }

  getCurrentTitle(currentSection) {
    const sections = this.sections.filter(
      (section) => section.routeParam === currentSection
    )
    return get(sections, '[0].title', '')
  }

  render() {
    const { classes } = this.props
    const currentSection = this.props.location.pathname.slice(1)
    const currentTitle = this.getCurrentTitle(currentSection)

    return (
      <Loading loading={this.state.loading}>
        <Layout navbarComponent={AuthenticatedNavBar} currentPage={PAGES.FAQ}>
          <Grid container direction='row' alignItems='flex-start'>
            <Grid item xs={12} sm={3} md={3}>
              <List component='nav' aria-label='Profile sections'>
                {this.sections.map(({ routeParam, title, link }) => (
                  <ListItem key={JSON.stringify({ title, link })}>
                    <NavLink
                      to={link}
                      className={classes.menuLink}
                      activeClassName={classes.activeLink}
                    >
                      <ListItemText primary={title} />
                    </NavLink>
                  </ListItem>
                ))}
              </List>
            </Grid>

            <Grid item xs={12} sm={9} md={9}>
              {' '}
              {/* Second row, profile sections */}
              <FaqSections title={currentTitle} />
            </Grid>
          </Grid>
        </Layout>
      </Loading>
    )
  }
}

export const Faq = flow(
  withTranslation('faq'),
  withRouter,
  withProfile,
  withStyles(styles)
)(FaqComponent)
