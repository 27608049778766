import { Button } from '@mui/material'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'

import { TextField } from 'components'

class ReferralFormFC extends React.Component {
  render() {
    return (
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
        }}
        validate={(values) => {
          const err = {}
          if (values.firstName.length < 2) {
            err.firstName = true
          }
          if (values.lastName.length < 2) {
            err.lastName = true
          }
          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            err.email = true
          }

          return err
        }}
        onSubmit={this.props.onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form action='' method='post' name='' onSubmit={handleSubmit}>
            <TextField
              name='firstName'
              label={this.props.t('firstName')}
              onBlur={handleBlur}
              onChange={handleChange}
              error={errors.firstName}
              value={values.firstName}
              disabled={isSubmitting}
            />
            <TextField
              name='lastName'
              label={this.props.t('lastName')}
              onBlur={handleBlur}
              onChange={handleChange}
              error={errors.lastName}
              value={values.lastName}
              disabled={isSubmitting}
            />
            <TextField
              name='email'
              type='email'
              label={this.props.t('email')}
              onBlur={handleBlur}
              onChange={handleChange}
              error={errors.email}
              value={values.email}
              disabled={isSubmitting}
            />
            <Button
              type='submit'
              variant='contained'
              color='primary'
              size='large'
              className={this.props.classes.inviteBtn}
              disabled={isSubmitting}
            >
              {this.props.t('submit')}
            </Button>
          </form>
        )}
      </Formik>
    )
  }
}

ReferralFormFC.propTypes = {
  t: PropTypes.func,
  classes: PropTypes.object,
  onSubmit: PropTypes.func,
}

const ReferralForm = withTranslation('referral')(ReferralFormFC)
export { ReferralForm }
