import HelpIcon from '@mui/icons-material/Help'
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import moment from 'moment'
import React from 'react'

import { DEFAULT_DATE_FORMAT } from 'common/constants'

const DatePicker = (props) => {
  const {
    onChange,
    hasTooltip,
    toolTipText,
    toolTipClass,
    error,
    helperText,
    inputClassName,
    minDate,
  } = props

  const convertDateToUTCDate = (date) =>
    new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))

  const handleDateChange = (date) => {
    if (!moment(date, DEFAULT_DATE_FORMAT, true).isValid()) {
      onChange(null)
      return
    }
    const formattedDate = convertDateToUTCDate(date)
    onChange(formattedDate)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        {...props}
        autoOk
        clearable
        minDate={minDate ? new Date(minDate) : null}
        inputFormat={props.format || DEFAULT_DATE_FORMAT}
        onChange={handleDateChange}
        onAccept={handleDateChange}
        renderInput={(params) => (
          <TextField
            variant='outlined'
            margin='normal'
            helperText={helperText}
            className={inputClassName}
            {...params}
            error={error}
            data-automator={props['data-automator']}
          />
        )}
      />
      {!!hasTooltip && (
        <Tooltip title={toolTipText} className={toolTipClass}>
          <HelpIcon />
        </Tooltip>
      )}
    </LocalizationProvider>
  )
}

DatePicker.defaultProps = {
  value: null,
}

export default DatePicker
