import { Factory } from '../client/Factory'

const InvitationServiceNamespace = 'invitation'

const InvitationServiceConfigs = {
  getInvitation: Factory.createRequestConfig(
    Factory.MethodGet,
    '/invitation/:token'
  ),
  getLatestInvitation: Factory.createRequestConfig(
    Factory.MethodGet,
    '/invitation/latest'
  ),
  sendInvitation: Factory.createRequestConfig(
    Factory.MethodPost,
    '/invitation'
  ),
}

const InvitationService = {
  getInvitation: Factory.makeClientCall(InvitationServiceConfigs.getInvitation),
  getLatestInvitation: Factory.makeAuthenticatedClientCall(
    InvitationServiceConfigs.getLatestInvitation
  ),
  sendInvitation: Factory.makeAuthenticatedClientCall(
    InvitationServiceConfigs.sendInvitation
  ),
}

export {
  InvitationServiceNamespace,
  InvitationServiceConfigs,
  InvitationService,
}
