import { Factory } from '../client/Factory'

const InterviewerServiceNamespace = 'interviewer'

/**
 * @type {{getProfile: (*|{method: string, uri: string, params: string[]})}}
 * @type {{updateProfile: (*|{method: string, uri: string, params: string[]})}}
 */
const InterviewerServiceConfigs = {
  addInterviewer: Factory.createRequestConfig(
    Factory.MethodPost,
    '/interviewers'
  ),

  getInterviewer: Factory.createRequestConfig(
    Factory.MethodGet,
    '/interviewer/:user_id'
  ),

  setInterviewerStatus: Factory.createRequestConfig(
    Factory.MethodPost,
    '/interviewer/:user_id/p2p-status'
  ),

  removeInterviewer: Factory.createRequestConfig(
    Factory.MethodDelete,
    '/interviewer/:user_id'
  ),

  checkInterviewerRole: Factory.createRequestConfig(
    Factory.MethodGet,
    '/interviewer/:user_id/check-role'
  ),
}

const InterviewerService = {
  addInterviewer: Factory.makeAuthenticatedClientCall(
    InterviewerServiceConfigs.addInterviewer
  ),

  getInterviewer: Factory.makeAuthenticatedClientCall(
    InterviewerServiceConfigs.getInterviewer
  ),

  setInterviewerStatus: Factory.makeAuthenticatedClientCall(
    InterviewerServiceConfigs.setInterviewerStatus
  ),

  removeInterviewer: Factory.makeAuthenticatedClientCall(
    InterviewerServiceConfigs.removeInterviewer
  ),

  checkInterviewerRole: Factory.makeAuthenticatedClientCall(
    InterviewerServiceConfigs.checkInterviewerRole
  ),
}

export {
  InterviewerServiceNamespace,
  InterviewerServiceConfigs,
  InterviewerService,
}
