import MenuIcon from '@mui/icons-material/Menu'
import AppBar from '@mui/material/AppBar'
import Grid from '@mui/material/Grid'
import Hidden from '@mui/material/Hidden'
import IconButton from '@mui/material/IconButton'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Toolbar from '@mui/material/Toolbar'
import withStyles from '@mui/styles/withStyles'
import { object, bool, shape, any, string } from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import { PAGES } from 'apps/portal/common/constants'
import defaultLogo from 'assets/img/logo.png'

const styles = (theme) => {
  const borderStyle = `${theme.spacing(0.1)} solid ${theme.palette.grey[300]}`

  return {
    gridRoot: {
      margin: 0,
      justifyContent: 'center',
    },
    logoContainerGridItem: {
      // borderRight: borderStyle,
      paddingRight: 0,
      height: theme.spacing(9),
      width: theme.spacing(9),
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        borderRight: 0,
      },
    },
    logoLink: {
      lineHeight: 4.48,
    },
    logo: {
      width: theme.spacing(3.9),
    },
    appBar: {
      // boxShadow: 'none',
      borderBottom: borderStyle,
      backgroundColor: theme.palette.background.light,
      height: theme.spacing(9),
    },
    toolbar: {
      backgroundColor: theme.palette.background.light,
      height: theme.spacing(9),
      margin: 0,
    },
    menuButtonContainer: {
      marginLeft: theme.spacing(2),
    },
    drawer: {
      width: theme.spacing(30),
    },
  }
}

class NavBarClass extends React.Component {
  static propTypes = {
    classes: object,
    shouldRenderDrawer: bool,
    logo: shape({
      image: any,
      altText: string,
    }),
  }

  static defaultProps = {
    shouldRenderDrawer: true,
    logo: {
      image: null,
      altText: null,
    },
  }

  constructor(props) {
    super(props)
    /*eslint-disable */

    this.state = {
      drawerVisible: false,
      logo: null,
    }
    /* eslint-enable */
    this.toggleDrawer = this.toggleDrawer.bind(this)
  }

  toggleDrawer(visible) {
    return (event) => {
      if (
        event &&
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return
      }

      this.setState({
        drawerVisible: visible,
      })
    }
  }

  sideList() {
    const { classes } = this.props

    return (
      <div
        className={classes.drawer}
        role='presentation'
        onClick={this.toggleDrawer(false)}
        onKeyDown={this.toggleDrawer(false)}
      >
        {this.props.children}
      </div>
    )
  }

  render() {
    const {
      classes,
      shouldRenderDrawer,
      logo: { image, altText },
    } = this.props

    return (
      <>
        <AppBar position='fixed' className={classes.appBar}>
          <Toolbar disableGutters className={classes.toolbar}>
            <Grid
              spacing={2}
              alignItems='center'
              container
              className={classes.gridRoot}
            >
              {/* hide on medium screens up */}
              {!!shouldRenderDrawer && (
                <Hidden mdUp>
                  <Grid item sm={1} className={classes.menuButtonContainer}>
                    <IconButton
                      edge='start'
                      color='primary'
                      aria-label='menu'
                      onClick={this.toggleDrawer(true)}
                      size='large'
                    >
                      <MenuIcon />
                    </IconButton>
                  </Grid>
                </Hidden>
              )}

              <Grid item className={classes.logoContainerGridItem}>
                <Link
                  id='org-logo'
                  to={`/${PAGES.DASHBOARD}`}
                  className={classes.logoLink}
                  data-locator='header-logo'
                >
                  <img
                    data-locator='header-logo'
                    src={image || defaultLogo}
                    className={classes.logo}
                    alt={altText || ''}
                  />
                </Link>
              </Grid>

              <Hidden mdDown>{this.props.children}</Hidden>
            </Grid>
          </Toolbar>
        </AppBar>

        {!!shouldRenderDrawer && (
          <SwipeableDrawer
            open={this.state.drawerVisible}
            onClose={this.toggleDrawer(false)}
            onOpen={this.toggleDrawer(true)}
          >
            {this.sideList()}
          </SwipeableDrawer>
        )}
      </>
    )
  }
}

const NavBarWithStyles = withStyles(styles)(NavBarClass)
export { NavBarWithStyles as NavBar }
