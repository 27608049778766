import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const StepProgress = ({ currentStep, totalSteps, caption }) => {
  const { t } = useTranslation('step_progress')

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box>
          <Typography
            variant='body2'
            color='primary.blue'
            fontWeight='bold'
            textTransform='uppercase'
          >
            {t('count', { currentStep, totalSteps })}
          </Typography>
        </Box>
        <Box sx={{ width: 200, ml: 1.5 }}>
          <LinearProgress
            variant='determinate'
            value={(currentStep / totalSteps) * 100}
            sx={{
              height: 12,
              borderRadius: '4px',
              '& .MuiLinearProgress-bar': {
                backgroundColor: 'blue',
              },
            }}
          />
        </Box>
      </Box>
      <Box mt={0.5}>
        <Typography variant='body2' color='primary.blue'>
          {caption}
        </Typography>
      </Box>
    </Box>
  )
}

StepProgress.propTypes = {
  currentStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
  caption: PropTypes.string.isRequired,
}

export default StepProgress
