/* eslint-disable */
import flow from 'lodash/flow'
import isPlainObject from 'lodash/isPlainObject'
import intersection from 'lodash/intersection'
import xor from 'lodash/xor'
import { object } from 'prop-types'
import React, { Component } from 'react'
import { withProfile } from 'hocs/withProfile'
import { withTranslation } from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import { ProfileService } from 'sdk/profile/Service'
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material'

import { Layout } from 'components'
import { PAGES } from '../../common/constants'
import { AuthenticatedNavBar } from '../../components'

import contents from './content.json'
const { categories, items } = contents

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  cards: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(5),
    maxWidth: `calc(33% - ${theme.spacing(2)})`,
    '&:nth-child(3n)': {
      marginRight: '0',
    },
  },
  header: {
    height: '144px',
    objectFit: 'Fill',
  },
  logo: {
    objectFit: 'contain',
    height: '40px',
    maxWidth: '100%',
    width: 'auto',
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
  },
  field: {
    '&:not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
})

class ServicesClass extends Component {
  static propTypes = {
    classes: object,
  }

  constructor(props) {
    super(props)

    this.state = {
      selection: [...categories],
    }

    this.isSelected = this.isSelected.bind(this)
    this.toggleSelection = this.toggleSelection.bind(this)
    this.selectAll = this.selectAll.bind(this)
    this.renderField = this.renderField.bind(this)
    this.renderFilter = this.renderFilter.bind(this)
    this.renderItem = this.renderItem.bind(this)
    this.renderItems = this.renderItems.bind(this)
    this.renderParagraph = this.renderParagraph.bind(this)
    this.renderDescription = this.renderDescription.bind(this)
  }

  async componentDidMount() {
    const { data } = await ProfileService.getProfile()
    this.props.dispatchers.loadProfile(data)
  }

  renderItem({
    categories: category,
    id,
    name,
    description,
    logo,
    photo,
    url,
  }) {
    const {
      props: { classes, t },
    } = this
    const onClick = () => typeof window !== 'undefined' && window.open(url)

    return (
      <Card key={id} className={classes.card}>
        <CardActionArea onClick={onClick}>
          <CardMedia
            component='img'
            alt={name}
            image={photo}
            title={name}
            className={classes.header}
          />
          <CardContent>
            <CardMedia
              component='img'
              alt={name}
              image={logo}
              title={name}
              className={classes.logo}
            />
            <Typography variant='subtitle2' color='textSecondary' gutterBottom>
              {category}
            </Typography>
            <Typography variant='body2' color='textSecondary' component='p'>
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions className={classes.actions}>
          <Button size='small' color='primary' onClick={onClick}>
            {t('find_more')}
          </Button>
        </CardActions>
      </Card>
    )
  }

  renderField(props) {
    const {
      props: { classes },
      state: { selection },
      toggleSelection,
    } = this
    const { value, onChange, checked } = isPlainObject(props)
      ? props
      : {
          value: props,
          onChange: toggleSelection,
          checked: selection.includes(props),
        }

    return (
      <FormControlLabel
        key={value}
        control={
          <Checkbox
            checked={checked}
            onChange={onChange}
            value={value}
            color='primary'
          />
        }
        label={value}
        className={classes.field}
      />
    )
  }

  renderFilter() {
    const {
      props: { classes, t },
      state: { selection },
      renderField,
      selectAll,
    } = this
    const all = {
      checked: selection.length === categories.length,
      value: t('select_all'),
      onChange: selectAll,
    }

    return (
      <form className={classes.filter}>
        <FormGroup>
          {renderField(all)}
          {categories.map(renderField)}
        </FormGroup>
      </form>
    )
  }

  selectAll() {
    this.setState({ selection: categories })
  }

  toggleSelection({ target: { value, checked } }) {
    this.setState(({ selection, ...state }) => ({
      ...state,
      selection: xor(selection, [value]),
    }))
  }

  isSelected({ categories }) {
    const {
      state: { selection },
    } = this

    return !selection.length || !!intersection(categories, selection).length
  }

  renderItems() {
    const {
      props: { classes },
      isSelected,
      renderItem,
    } = this

    return (
      <div className={classes.cards}>
        {items.filter(isSelected).map(renderItem)}
      </div>
    )
  }

  renderParagraph(paragraph, key) {
    return <Typography key={key}>{paragraph}</Typography>
  }

  renderDescription() {
    const {
      props: { t },
      renderParagraph,
    } = this

    return (
      <Typography
        sx={{ pr: 2 }}
        dangerouslySetInnerHTML={{ __html: t('description') }}
      />
    )
  }

  render() {
    const {
      props: { classes, t },
      renderFilter,
      renderItems,
      renderDescription,
    } = this

    return (
      <Layout
        navbarComponent={AuthenticatedNavBar}
        currentPage={PAGES.SERVICES}
        className={classes.root}
      >
        <Grid container component='main'>
          <Grid item xs={12} sm={8} md={8} lg={10}>
            <Typography variant='h5' className={classes.title}>
              <strong>{t('title')}</strong>
            </Typography>
            {renderDescription()}
            {renderItems()}
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={2}>
            {renderFilter()}
          </Grid>
        </Grid>
      </Layout>
    )
  }
}

const Services = flow(
  withTranslation('services'),
  withStyles(styles),
  withProfile
)(ServicesClass)

export { Services }
