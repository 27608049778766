import axios from 'axios'

axios.defaults.baseURL = process.env.REACT_APP_API_HOST

const getConfig = (token, rest = {}) => {
  const config = {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    ...rest,
  }
  return config
}

export const getInterview = ({ interviewId, accessToken }) => {
  const config = getConfig(accessToken)
  return axios.get(`/interviews/${interviewId}/questions/v2`, config)
}

export const putQuestionScore = ({
  interviewId,
  accessToken,
  questionId,
  payload,
}) => {
  const config = getConfig(accessToken)
  return axios.put(
    `/interviews/${interviewId}/questions/${questionId}`,
    payload,
    config
  )
}

export const updateInterview = ({ interviewId, accessToken, payload }) => {
  const config = getConfig(accessToken)
  return axios.patch(`/interviews/${interviewId}`, payload, config)
}

export const putQuestionFeedback = ({ accessToken, questionId, payload }) => {
  const config = getConfig(accessToken)

  return axios.post(`/question/${questionId}/feedback`, payload, config)
}

export const getDimensionQuestions = ({ accessToken, ...rest }) => {
  const config = getConfig(accessToken, { params: rest })

  return axios.get(`/question`, config)
}

export const deleteQuestions = ({ accessToken, interviewId, questions }) => {
  const config = getConfig(accessToken)

  const payload = { interviewId, questions }

  return axios.post(
    `/interviews/${interviewId}/questions/delete`,
    payload,
    config
  )
}

export const addQuestions = ({ accessToken, interviewId, questions }) => {
  const config = getConfig(accessToken)

  const payload = { interviewId, questions }

  return axios.post(`/interviews/${interviewId}/questions`, payload, config)
}

export const postArchiveFeedback = ({
  accessToken,
  interviewId,
  archivingReason,
}) => {
  const config = getConfig(accessToken)

  return axios.post(
    `/interviews/${interviewId}/archive`,
    { archivingReason },
    config
  )
}

export const getReport = ({ interviewId, accessToken }) => {
  const config = getConfig(accessToken)
  return axios.get(`/interviews/${interviewId}/report/v3`, config)
}

export const getPublicReport = ({ interviewId, password }) =>
  axios.post(`/interviews/${interviewId}/report-aggregated/v3`, {
    password,
  })

export const setReport = ({ interviewId, accessToken, payload }) => {
  const config = getConfig(accessToken)
  return axios.patch(`/interviews/${interviewId}/report/v3`, payload, config)
}

export const setFeedback = ({ interviewId, accessToken, payload }) => {
  const config = getConfig(accessToken)
  return axios.patch(`/admin/interviews/${interviewId}`, payload, config)
}

export const postPublishReport = ({ interviewId, accessToken }) => {
  const config = getConfig(accessToken)
  return axios.post(`/interviews/${interviewId}/report/snapshot`, {}, config)
}
