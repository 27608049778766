import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoIcon from '@mui/icons-material/Info'
import {
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Accordion,
} from '@mui/material'

import { useTracking } from 'apps/scorecard/hooks'

import {
  StyledTaskWrapper,
  StyledTaskTitle,
  StyledTaskHint,
  StyledHintTitle,
} from './style'

const TaskInfo = ({ task, skill }) => {
  if (!task) {
    return null
  }

  const tracking = useTracking()
  const eventTracking = (label, action = 'Click') => {
    tracking.event({
      category: 'Scorecard-v2',
      action,
      label: `${action}_${label}`,
    })
  }

  return (
    <StyledTaskWrapper>
      <Accordion>
        <AccordionSummary
          onClick={() => eventTracking('Task_Info_Expand_More')}
          expandIcon={<ExpandMoreIcon />}
        >
          <StyledTaskTitle
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            width='100%'
          >
            <Box display='flex'>
              <InfoIcon />
              {skill && (
                <Typography marginLeft={1}>Task Info ({skill.name})</Typography>
              )}
            </Box>

            <a
              href={task.link}
              target='_blank'
              rel='noreferrer'
              onClick={() => eventTracking('Link_to_doc')}
            >
              Link to doc
            </a>
          </StyledTaskTitle>
        </AccordionSummary>
        <AccordionDetails>
          <StyledHintTitle component='h2'>{task.name}</StyledHintTitle>
          <StyledTaskHint
            mb={2}
            dangerouslySetInnerHTML={{ __html: task.description }}
          />
          <StyledTaskHint dangerouslySetInnerHTML={{ __html: task.hint }} />
        </AccordionDetails>
      </Accordion>
    </StyledTaskWrapper>
  )
}

export default TaskInfo
