/* eslint-disable*/
import React, { memo } from 'react'
import { Link, Typography, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { LicensesOverlay } from 'components/licenses'

const useStyles = makeStyles((theme) => ({
  licensesWrapper: {
    '& > div': {
      position: 'relative',
      border: 0,
      padding: 0,

      '& span': {
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeight.normal,
      },
    },
  },
}))

function Footer(props) {
  const classes = useStyles()
  const currentYear = new Date().getFullYear()
  const {
    config: {
      footer: {
        companyName,
        links: { imprint, privacyPolicy },
      },
    },
    translate,
  } = props

  return (
    <Grid
      item
      container
      direction='row'
      justifyContent='flex-start'
      alignItems='flex-start'
      xs={11}
      md={11}
      lg={11}
      spacing={1}
    >
      <Grid item xs={12} md={2.8} lg={2.8}>
        <Typography variant='body2' id='footPrint'>
          &copy;
          {`${currentYear} ${companyName}. ${translate('allRightsReserved')} `}
        </Typography>
      </Grid>
      <Grid item>
        <span>|</span>
      </Grid>
      <Grid item>
        <Link underline='always' target='_blank' href={privacyPolicy}>
          <Typography variant='body2'>{translate('privacyPolicy')}</Typography>
        </Link>
      </Grid>
      <Grid item>
        <span>|</span>
      </Grid>
      <Grid item>
        <Link underline='always' target='_blank' href={imprint}>
          <Typography variant='body2'> {translate('imprint')}</Typography>
        </Link>
      </Grid>
      <Grid item>
        <span>|</span>
      </Grid>
      <Grid item>
        <div className={classes.licensesWrapper}>
          <LicensesOverlay />
        </div>
      </Grid>
    </Grid>
  )
}

export default memo(Footer)
