import { CircularProgress, Box, Typography } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'

import loadingImg from 'apps/portal/assets/img/coding.png'

const styles = (theme) => ({
  container: {
    textAlign: 'center',
    minHeight: '100vh',
  },
  loading: {
    marginTop: theme.spacing(5),
  },
  loadingImage: {
    width: '25vw',
    height: '30vw',
    marginTop: '5%',
    textAlign: 'center',

    '& img': {
      width: '100%',
    },
  },
  subtitle: {
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
  },
})

class Loading extends React.Component {
  static defaultProps = {
    loading: false,
  }

  static propTypes = {
    loading: PropTypes.bool,
  }

  render() {
    const { loading, classes } = this.props

    return (
      (loading && (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          flexDirection='column'
          className={classes.container}
        >
          <Box className={classes.loadingImage}>
            <img src={loadingImg} alt='Loading...' />
          </Box>

          <Typography variant='h5' gutterBottom className={classes.subtitle}>
            {this.props.t('loading_subtitle')}
          </Typography>

          <Box className={classes.loading}>
            <CircularProgress />
          </Box>
        </Box>
      )) ||
      (!this.props.loading && this.props.children)
    )
  }
}

const tloading = withTranslation('loading')(Loading)
const loading = withStyles(styles)(tloading)

export { loading as Loading }
