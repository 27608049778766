/* eslint-disable*/
import React, { useMemo, memo } from 'react'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { placeStatusApproved } from 'apps/portal/common/place-status'
import defaultImage from 'assets/img/certification.svg'
import { buildImageURL } from 'common/image'
import { formatDateISO } from 'common/dates'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { PUBLIC_PROFILE_CERTIFICATION_FORMAT } from 'common/constants'

const useStyles = makeStyles((theme) => ({
  certifications: {
    marginTop: theme.spacing(2),

    '& > h6': {
      color: theme.palette.grey[600],
      fontWeight: theme.typography.fontWeight.bold,
      textAlign: 'left',
      textTransform: 'uppercase',
      lineHeight: 3,
    },
  },

  certification: {
    border: `1px solid ${theme.palette.lightGray5}`,
    borderRadius: theme.spacing(1.4),
    padding: theme.spacing(1.4),
    marginBottom: theme.spacing(2),

    '& h6:nth-child(1)': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& svg': {
        color: theme.palette.lightGray6,
      },
    },

    '& p': {
      fontWeight: 'normal',
      textAlign: 'start',
      fontSize: theme.typography.header.fontSize,
      lineHeight: '2',
    },

    '& > div': {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.lightGray2,

      '& svg': {
        marginRight: theme.spacing(0.5),
        color: theme.palette.green,
        width: theme.spacing(1.8),
      },
    },
  },

  authorityLogo: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'normal',
    color: 'black',
    textTransform: 'capitalize',
    fontWeight: theme.typography.fontWeight.strong,

    '& img': {
      marginRight: theme.spacing(1),
      width: theme.spacing(3.1),
      borderRadius: '50%',
    },
  },
}))

function Certifications(props) {
  const classes = useStyles()
  const { translate, certifications } = props
  const getAuthorityName = useMemo(
    () => (status, name) =>
      placeStatusApproved(status)
        ? name
        : `${name} (${translate('place_under_review')})`
  )

  const addDefaultImage = (e) => {
    e.target.src = defaultImage
  }

  const activeCertifications = useMemo(
    () =>
      certifications &&
      certifications.filter((certification) =>
        placeStatusApproved(certification.authority.status)
      )
  )

  const Certification = ({ certification }) =>
    useMemo(() => {
      const { authority, name, issueDate } = certification
      return (
        <div className={classes.certification}>
          <Typography variant='h6'>
            <div className={classes.authorityLogo}>
              {authority?.logo?.bucket ? (
                <img
                  src={buildImageURL(authority.logo)}
                  alt={authority.name}
                  onError={addDefaultImage}
                />
              ) : (
                <img src={`${defaultImage}`} alt={authority.name} />
              )}

              {getAuthorityName(authority.status, authority.name)}
            </div>
          </Typography>

          <Typography variant='body2'>{name}</Typography>
          <Typography variant='body2' component='div'>
            <CheckCircleIcon />
            {`${formatDateISO(issueDate, PUBLIC_PROFILE_CERTIFICATION_FORMAT)}`}
          </Typography>
        </div>
      )
    })
  return (
    <div className={classes.certifications}>
      {activeCertifications && activeCertifications.length > 0 && (
        <>
          <Typography variant='subtitle2'>
            {translate('certifications')}
          </Typography>

          {activeCertifications.map((certification) => (
            <Certification
              certification={certification}
              key={certification.name}
            />
          ))}
        </>
      )}
    </div>
  )
}

export default memo(Certifications)
