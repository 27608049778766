import React, { forwardRef, useMemo, useCallback } from 'react'
import { string, shape, node, bool, arrayOf, objectOf } from 'prop-types'
import { List, ListSubheader } from '@mui/material'
import Item from './item'
import withStyle from './style'

const SideBar = forwardRef(
  (
    {
      className,
      groupedItems,
      isInterviewTypeEM,
      interviewId,
      requiredLC,
      isNonTechnical,
    },
    ref
  ) => {
    const classes = useMemo(() => ({ root: className }), [className])
    const renderItems = useCallback(
      (item) => <Item key={item.url} {...item} />,
      []
    )
    const interviewType = useCallback(
      (id) => (isInterviewTypeEM ? id : 'Requested'),
      [isInterviewTypeEM]
    )

    return (
      <List
        subheader={<li />}
        ref={ref}
        aria-roledescription='sidebar'
        classes={classes}
      >
        {requiredLC && (
          <li>
            <ul className='section-list'>
              <ListSubheader>
                {isNonTechnical ? 'Case Study' : 'Live Coding'}
              </ListSubheader>
              <Item
                url={`/scorecard/interview/${interviewId}#lc-tasks`}
                label={isNonTechnical ? 'Case Studies' : 'Live Coding Tasks'}
                onClick={() => {}}
              />
            </ul>
          </li>
        )}
        {Object.keys(groupedItems).map((sectionId) => (
          <li key={`section-${sectionId}`}>
            <ul className='section-list'>
              <ListSubheader>
                {sectionId === 'Recommended'
                  ? interviewType(sectionId)
                  : sectionId}
              </ListSubheader>
              {groupedItems[sectionId].map(renderItems)}
            </ul>
          </li>
        ))}
      </List>
    )
  }
)

SideBar.propTypes = {
  groupedItems: objectOf(
    arrayOf(
      shape({
        label: node.isRequired,
        url: string.isRequired,
        external: bool,
      })
    ).isRequired
  ).isRequired,
  className: string.isRequired,
}

export default withStyle(SideBar)
