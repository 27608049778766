/* eslint-disable */
import React from 'react'
import { matchPath, Redirect, Route, Switch } from 'react-router-dom'
import { AuthGuard } from 'components/AuthGuard'
import {
  Account,
  ChangePassword,
  Dashboard,
  Faq,
  Interviewer,
  Login,
  Logout,
  Profile,
  PublicProfile,
  Referral,
  Registration,
  Services,
  Events,
  ViewCV,
  JobPage,
} from './containers'
import { Licenses } from 'components/licenses'
import { PAGES } from './common/constants'
import { MESSAGE_TOPIC_NAMES } from 'common/constants'
import './localization/index'
import { store } from 'common/store'
import { setPubSubInstance } from 'sdk/client/Factory'
import { SimplePubSub } from 'common/SimplePubSub'
import { Actions } from './containers/logout/Actions'
import flow from 'lodash/flow'
import { connect } from 'react-redux'
import { loadProfile } from 'apps/portal/containers/profile/Actions'
import { withTracker } from '../../components/GoogleAnalytics'
import Config from 'contexts/config'
import { Helmet } from 'react-helmet'
import { withConfigInjection } from 'hocs/whitelabel'
import { getConfigFromHostname } from 'apps/portal/config'
import { AccountsService } from 'sdk/account/Service'
import {
  AccountsServiceConfigs,
  AccountsServiceNamespace,
  CategoryServiceConfigs,
  CategoryServiceNamespace,
  createEndpointMethodsMap,
  getObjectFromMap,
  InvitationServiceConfigs,
  InvitationServiceNamespace,
  ProfileServiceConfigs,
  ProfileServiceNamespace,
  ReferralServiceConfigs,
  ReferralServiceNamespace,
  JobsServiceNamespace,
  JobsServiceConfigs,
} from 'sdk'
import { withTranslation } from 'react-i18next'
import { withNotifications } from 'hocs'

export * from 'apps/portal/common/yupInitialization'

class Application extends React.Component {
  componentDidMount() {
    this.initializeNotification()
  }

  // notification for API call results
  initializeNotification() {
    const { t: translate, warn } = this.props

    const serviceConfigs = {
      [AccountsServiceNamespace]: AccountsServiceConfigs,
      [CategoryServiceNamespace]: CategoryServiceConfigs,
      [InvitationServiceNamespace]: InvitationServiceConfigs,
      [ProfileServiceNamespace]: ProfileServiceConfigs,
      [ReferralServiceNamespace]: ReferralServiceConfigs,
      [JobsServiceNamespace]: JobsServiceConfigs,
    }

    try {
      // initialize Pub-Sub instance
      const simplePubSub = new SimplePubSub()

      // TODO: refactor SDK factory - create factory generator to accept PubSub
      // instance as dependency
      setPubSubInstance(simplePubSub)

      simplePubSub.subscribe(MESSAGE_TOPIC_NAMES.UNAUTHORIZED, () => {
        store.dispatch({ type: Actions.LOGOUT_USER })
      })
      simplePubSub.subscribe(MESSAGE_TOPIC_NAMES.ERROR, ({ url, method }) => {
        const endpointMethodsMap = createEndpointMethodsMap(serviceConfigs)
        const translationKey = getObjectFromMap({
          url,
          method,
          map: endpointMethodsMap,
          matcher: matchPath,
        }).error

        // make sure translationKey is provided and i18n file has an entry for it
        if (translate && translate(translationKey) !== translationKey) {
          warn(translate(translationKey))
        }
      })
    } catch (err) {
      console.error(err)
    }
  }

  render() {
    const { isAuthenticated, config } = this.props
    const {
      pages: { services, events, referrals, faq, interviewer },
      meta: { pageTitle, favicon },
    } = config
    const params = window && new URLSearchParams(window.location.search)

    return (
      <Config.Provider value={config}>
        <Helmet>
          <title>{pageTitle}</title>
          {!!favicon && (
            <link rel='icon' type='image/png' href='/favicon.png' />
          )}
        </Helmet>

        <Switch>
          <Route exact path={`/${PAGES.JOB_PAGE}/:jobId/:profileId`}>
            <JobPage />
          </Route>
          <Route exact path={`/${PAGES.REGISTER}/:token`}>
            <Registration />
          </Route>

          <Route path={`/${PAGES.LOGIN}`}>
            <Login />
          </Route>

          <Route exact path={`/${PAGES.THIRD_PARTY}`}>
            <Licenses />
          </Route>

          {!!faq && (
            <AuthGuard path={`/${PAGES.FAQ}`} authToken={isAuthenticated}>
              <Faq />
            </AuthGuard>
          )}

          {!!services && (
            <AuthGuard
              exact
              path={`/${PAGES.SERVICES}`}
              authToken={isAuthenticated}
            >
              <Services />
            </AuthGuard>
          )}

          {!!events && (
            <AuthGuard
              exact
              path={`/${PAGES.EVENTS}`}
              authToken={isAuthenticated}
            >
              <Events />
            </AuthGuard>
          )}

          <Route exact path={`/${PAGES.EXPERT_PROFILE}/:slug`}>
            <PublicProfile />
          </Route>

          <AuthGuard exact path={`/${PAGES.ROOT}`} authToken={isAuthenticated}>
            <Redirect to={`/${PAGES.DASHBOARD}?${params}`} />
          </AuthGuard>

          <AuthGuard
            exact
            path={`/${PAGES.PROFILE}`}
            authToken={isAuthenticated}
          >
            <Profile />
          </AuthGuard>

          <AuthGuard
            exact
            path={`/${PAGES.PROFILE}/:section`}
            authToken={isAuthenticated}
          >
            <Profile />
          </AuthGuard>

          <AuthGuard
            exact
            path={`/${PAGES.PROFILE}/:section/edit`}
            authToken={isAuthenticated}
          >
            <Profile />
          </AuthGuard>

          <AuthGuard
            exact
            path={`/${PAGES.DASHBOARD}`}
            authToken={isAuthenticated}
          >
            <Dashboard />
          </AuthGuard>

          {!!referrals && (
            <AuthGuard
              exact
              path={`/${PAGES.REFERRALS}`}
              authToken={isAuthenticated}
            >
              <Referral />
            </AuthGuard>
          )}

          <AuthGuard
            exact
            path={`/${PAGES.ACCOUNT_CHANGE_PASSWORD}`}
            authToken={isAuthenticated}
          >
            <ChangePassword />
          </AuthGuard>

          <AuthGuard path={`/${PAGES.ACCOUNT}`} authToken={isAuthenticated}>
            <Account />
          </AuthGuard>

          <AuthGuard
            exact
            path={`/${PAGES.LOGOUT}`}
            authToken={isAuthenticated}
          >
            <Logout />
          </AuthGuard>

          {!!interviewer && (
            <AuthGuard
              isCloneRequired={true}
              path={`/${PAGES.INTERVIEWER}`}
              authToken={isAuthenticated}
            >
              <Interviewer />
            </AuthGuard>
          )}

          {!!interviewer && (
            <AuthGuard
              path={`/${PAGES.VIEW_CV}/:profileId`}
              authToken={isAuthenticated}
            >
              <ViewCV />
            </AuthGuard>
          )}
        </Switch>
      </Config.Provider>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isAuthenticated: state.auth && !!state.auth.accessToken,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadProfile: (profile) => dispatch(loadProfile(profile)),
  }
}

const app = flow(
  withConfigInjection(getConfigFromHostname(window.location.hostname)),
  connect(mapStateToProps, mapDispatchToProps),
  withTracker,
  withNotifications,
  withTranslation('application')
)(Application)

export { app as Application }
