import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import withStyles from '@mui/styles/withStyles'
import { flow } from 'lodash'
import React from 'react'

import { SENIORITY_POOLS } from 'common/constants'

const styles = (theme) => ({
  container: {
    display: 'inline-flex',
    width: '100%',
  },
  extra: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: `0 ${theme.spacing(2)}`,
  },
  error: {
    '& .MuiFormControlLabel-label, & .MuiRadio-root': {
      color: theme.palette.error.main,
    },
  },
})

const SeniorityPoolRadioFieldBase = ({
  value,
  onChange,
  classes,
  disabled,
  labelEmerging,
  labelSenior,
  labelPrincipal,
  error,
}) => (
  <FormControl className={classes.formControl}>
    <RadioGroup
      aria-label='seniority'
      name='pool'
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className={error ? classes.error : ''}
      row
    >
      <FormControlLabel
        value={SENIORITY_POOLS.EMERGING}
        control={<Radio color='primary' />}
        label={labelEmerging}
        labelPlacement='end'
        disabled={disabled}
        data-automator='seniority-pool-emerging'
      />

      <FormControlLabel
        value={SENIORITY_POOLS.SENIOR}
        control={<Radio color='primary' />}
        label={labelSenior}
        labelPlacement='end'
        disabled={disabled}
        data-automator='seniority-pool-senior'
      />

      <FormControlLabel
        value={SENIORITY_POOLS.PRINCIPAL}
        control={<Radio color='primary' />}
        label={labelPrincipal}
        labelPlacement='end'
        disabled={disabled}
        data-automator='seniority-pool-principal'
      />
    </RadioGroup>
  </FormControl>
)

const SeniorityPoolRadioField = flow(withStyles(styles))(
  SeniorityPoolRadioFieldBase
)

export { SeniorityPoolRadioField }
