import isEqual from 'lodash/isEqual'
import { useCallback, useMemo, useContext } from 'react'
import { reverse } from 'apps/scorecard/helpers/boolean'
import { useSetFeedback } from 'apps/scorecard/hooks/services/questions'
import { Interview as InterviewContext } from 'apps/scorecard/contexts'
import { useNotifications } from 'apps/scorecard/hooks'
import { parser } from './helpers'

export const useMarkup = ({
  source: full,
  limit,
  expanded,
  setExpanded,
  ...props
}) => {
  const scan = useCallback(
    (node, input = { parent: node, reviewed: false, text: '' }) => {
      const parse = (stack, child) => {
        const { nodeType, nodeValue } = child
        const remaining = Math.max(limit - stack.text.length, 0)
        const clear = () => {
          child.remove()

          return stack
        }
        const review = () => {
          const revision = nodeValue.substring(0, remaining)
          const reviewed = !isEqual(nodeValue.length, revision.length)
          const text = reviewed ? `${revision.trim()}...` : nodeValue

          child.replaceWith(document.createTextNode(text))

          return Object.assign(stack, {
            reviewed: stack.reviewed || reviewed,
            text: stack.text.concat(text),
          })
        }

        switch (true) {
          case !remaining:
            return clear()
          case isEqual(nodeType, 1):
            return scan(child, stack)
          case isEqual(nodeType, 3):
            return review()
          default:
            return stack
        }
      }

      return Array.from(node.childNodes).reduce(parse, input)
    },
    [limit]
  )
  const setFeedbackApi = useSetFeedback()
  const interview = useContext(InterviewContext)
  const { show } = useNotifications()

  const { innerHTML: short, expandable } = useMemo(() => {
    const html = parser.parseFromString(full, 'text/html')
    const {
      parent: { innerHTML },
      reviewed,
    } = scan(html.body)

    return { expandable: props.expandable || (reviewed && !!limit), innerHTML }
  }, [props.expandable, full, limit, scan])
  const dangerouslySetInnerHTML = useMemo(
    () => ({ __html: !expandable || expanded ? full : short }),
    [expandable, expanded, full, short]
  )
  const toggle = useCallback(
    (event) => {
      setExpanded(reverse)
      return event.preventDefault()
    },
    [setExpanded]
  )
  const setFeedback = useCallback(
    (payload) => {
      const interviewerId = interview.interview.interviewer.id
      const apiPayload = { ...payload, interviewerId }
      setFeedbackApi(apiPayload)
        .then(() => {
          show('Your feedback was submitted. Thank you!', {
            variant: 'success',
          })
        })
        .catch(() => {
          show('There was an error completing your request.', {
            variant: 'error',
          })
        })
    },
    [setFeedbackApi, interview.interview.interviewer.id, show]
  )

  return { dangerouslySetInnerHTML, expandable, expanded, toggle, setFeedback }
}
