import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import withStyles from '@mui/styles/withStyles'
import { Formik, Form } from 'formik'
import update from 'immutability-helper'
import flow from 'lodash/flow'
import get from 'lodash/get'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { object } from 'yup'

import { TIME_TO_SCROLL_MS } from 'apps/portal/common/constants'
import { SaveTab } from 'apps/portal/components/SaveTab'
import { FormWarnOnLeave } from 'components/FormWarnOnLeave'
import { withNotifications } from 'hocs'
import { withProfile } from 'hocs/withProfile'
import { getEducationsValidation } from 'validation'

import { onCancel, onSubmit } from './common'
import { EducationForm } from './EducationForm'

const styles = () => ({
  add: {
    textAlign: 'center',
  },
})

class EducationsFormComponent extends React.Component {
  constructor(props) {
    super(props)
    this.onSubmit = onSubmit.bind(this)
    this.state = {
      clickCancel: false,
    }
    this.onCancel = onCancel.bind(this)

    const { t } = props

    this.validationSchema = object({
      educations: getEducationsValidation({
        schoolErrorMessage: t('profile_education_invalid_school_error_message'),
        locationErrorMessage: t(
          'profile_education_invalid_location_error_message'
        ),
        degreeErrorMessage: t('profile_education_invalid_degree_error_message'),
        startDateInvalidErrorMessage: t(
          'profile_education_invalid_start_date_label_error_message'
        ),
        startDateAfterEndDateErrorMessage: t(
          'profile_education_start_date_after_end_date_error_message'
        ),
        endDateInvalidErrorMessage: t(
          'profile_education_invalid_end_date_label_error_message'
        ),
        endDateBeforeStartDateErrorMessage: t(
          'profile_education_end_date_before_start_date_error_message'
        ),
      }),
    })
  }

  editEducation = (setter, educations, index) => (field, value) => {
    const editedEducations = update(educations, {
      [index]: { $merge: { [field]: value } },
    })

    setter('educations', editedEducations)
  }

  addEducation = (setter, educations) => (e) => {
    setter('educations', [...educations, { id: '', degree: '' }])
    setTimeout(
      () =>
        typeof window !== 'undefined' &&
        window.scrollTo(0, document.body.clientHeight),
      TIME_TO_SCROLL_MS
    )
  }

  removeEducation = (setter, educations, index) => (exp) => (e) => {
    const educationsArray = [...educations]
    educationsArray.splice(index, 1)
    setter('educations', educationsArray)
  }

  render() {
    const { classes, fail, t } = this.props
    const formikProps = {
      initialValues: { educations: this.props.profile.educations || [] },
      validationSchema: this.validationSchema,
      onSubmit: (formData, { resetForm }) =>
        this.onSubmit(formData, { resetForm }, fail, t),
      validateOnChange: false,
      validateOnBlur: false,
    }

    return (
      <Grid
        container
        direction='column'
        justifyContent='flex-start'
        alignItems='stretch'
      >
        <Formik {...formikProps}>
          {({ values, errors, dirty, handleSubmit, setFieldValue }) => (
            <Form>
              <FormWarnOnLeave shouldWarn={dirty && !this.state.clickCancel} />
              {values.educations.map((edu, index) => (
                <React.Fragment key={edu.id || index}>
                  <Grid item>
                    <EducationForm
                      education={edu}
                      onChange={this.editEducation(
                        setFieldValue,
                        values.educations,
                        index
                      )}
                      index={index}
                      outerIndex={index}
                      errors={get(errors, `educations[${index}]`, {})}
                      removeEducation={this.removeEducation(
                        setFieldValue,
                        values.educations,
                        index
                      )}
                    />
                  </Grid>
                  {index < values.educations.length - 1 && (
                    <Box p={3}>
                      <Divider />
                    </Box>
                  )}
                </React.Fragment>
              ))}
              <Box component='div' className={classes.add}>
                <IconButton
                  id='add-education'
                  onClick={this.addEducation(setFieldValue, values.educations)}
                  size='large'
                >
                  <AddIcon />
                </IconButton>
              </Box>
              <SaveTab disableSave={!dirty} onCancel={this.onCancel} />
            </Form>
          )}
        </Formik>
      </Grid>
    )
  }
}

const EducationsForm = flow(
  withStyles(styles),
  withTranslation('profileEditEducation'),
  withProfile,
  withNotifications
)(EducationsFormComponent)

export { EducationsForm }
