/* eslint-disable */
import flow from 'lodash/flow'
import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@mui/styles'
import changePasswordImg from 'apps/portal/assets/log_in.png'
import Divider from '@mui/material/Divider'
import { FormHelperText, Typography } from '@mui/material'
import { Formik } from 'formik'
import FormLabel from '@mui/material/FormLabel'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import Tooltip from '@mui/material/Tooltip'
import HelpIcon from '@mui/icons-material/Help'
import Button from '@mui/material/Button'
import { AuthenticatedNavBar } from '../../../components'
import Redirect from 'components/Redirect'
import { PAGES } from './../../../common/constants'
import { Layout, Loading } from 'components'
import { AccountsService } from 'sdk/account/Service'
import { withNotifications } from '../../../../../hocs'
import * as Yup from 'yup'

const styles = (theme) => ({
  fullHeight: {
    height: '100%',
  },
  avatar: {
    width: '300px',
  },
  formContainer: {
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2),
    },
  },
  toolTip: {
    display: 'absolute',
    float: 'right',
    marginRight: theme.spacing(-7),
    cursor: 'pointer',
    paddingLeft: theme.spacing(4),
  },
})

class ChangePasswordComponent extends React.Component {
  constructor(props) {
    super(props)
    this.onCancel = this.onCancel.bind(this)
    this.onSubmit = this.onSubmit.bind(this)

    this.state = {
      showCurrentPassword: false,
      showNewPassword: false,
      showNewPasswordConfirmation: false,
      redirectTo: '',
      loading: false,
    }
  }

  onPasswordVisibilityClick(part) {
    const key = `show${part}`
    this.setState({ [key]: !this.state[key] })
  }

  onCancel() {
    this.setState({ redirectTo: `/${PAGES.ACCOUNT_PREFERENCES}` })
  }

  async onSubmit(formData) {
    const {
      props: { notify, t },
    } = this
    let message

    this.setState({ loading: true })

    try {
      await AccountsService.changePassword({
        current: formData.currentPassword,
        new: formData.newPassword,
      })

      message = 'valid'
      this.setState(
        { loading: false, redirectTo: `/${PAGES.ACCOUNT_PREFERENCES}` },
        () => {
          notify(t(`change-password[${message}]`))
        }
      )
    } catch ({
      response: {
        data: { message: error },
      },
    }) {
      message = error.replace(/\./gi, '|')
      this.setState({ loading: false, redirectTo: `` }, () => {
        notify(t(`change-password[${message}]`))
      })
    }
  }

  validationSchema() {
    const { t } = this.props
    return Yup.object().shape({
      currentPassword: Yup.string().required(t('current_password_validation')),
      newPassword: Yup.string()
        .min(6, t('new_password_validation_char'))
        .required(t('new_password_validation')),
      newPasswordConfirmation: Yup.string()
        .required(t('new_password_confirmation_validation_provide'))
        .oneOf(
          [Yup.ref('newPassword'), null],
          t('new_password_confirmation_validation')
        ),
    })
  }

  render() {
    const {
      state: { redirectTo, loading },
      props: { classes, t },
    } = this

    return (
      <Redirect to={redirectTo}>
        <Loading loading={loading}>
          <Layout
            navbarComponent={AuthenticatedNavBar}
            currentPage={PAGES.ACCOUNT_CHANGE_PASSWORD}
          >
            <Grid container spacing={0}>
              <Grid item lg={5}>
                <Box
                  display={{
                    sm: 'none',
                    xs: 'none',
                    xl: 'block',
                    md: 'block',
                    lg: 'block',
                  }}
                  align={'center'}
                >
                  <img
                    src={changePasswordImg}
                    className={this.props.classes.avatar}
                    alt={this.props.t('login_sub_title')}
                  />
                </Box>
              </Grid>
              <Grid item lg={1}>
                <Box
                  align={'center'}
                  className={this.props.classes.fullHeight}
                  display={{
                    sm: 'none',
                    xs: 'none',
                    xl: 'block',
                    lg: 'block',
                    md: 'block',
                  }}
                >
                  <Divider variant={'middle'} orientation={'vertical'} />
                </Box>
              </Grid>
              <Grid item lg={6} sm={12} md={7} xs={12}>
                <Grid
                  container
                  className={this.props.classes.fullHeight}
                  direction={'column'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  spacing={0}
                >
                  <Grid
                    item
                    lg={9}
                    sm={12}
                    xs={12}
                    className={this.props.classes.formContainer}
                  >
                    <Typography
                      component={'h1'}
                      variant={'h5'}
                      gutterBottom={true}
                    >
                      {t('password_change_title')}
                    </Typography>
                    <Typography
                      variant={'body1'}
                      className={classes.body}
                      gutterBottom
                    >
                      {t('password_change_sub_title')}
                    </Typography>
                    <Formik
                      initialValues={{
                        currentPassword: '',
                        newPassword: '',
                        newPasswordConfirmation: '',
                      }}
                      validationSchema={this.validationSchema()}
                      onSubmit={this.onSubmit}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        status,
                      }) => (
                        <form className={classes.form} onSubmit={handleSubmit}>
                          {!!status && (
                            <FormLabel error={true}>{status}</FormLabel>
                          )}
                          <FormControl
                            variant='outlined'
                            fullWidth
                            margin={'normal'}
                            value={values.currentPassword}
                            error={
                              !!(
                                touched.currentPassword &&
                                errors.currentPassword
                              )
                            }
                            onChange={handleChange}
                            id={'currentPassword'}
                            name={'currentPassword'}
                            disabled={isSubmitting}
                          >
                            <InputLabel htmlFor='currentPassword'>
                              {t('current_password')}
                            </InputLabel>
                            <OutlinedInput
                              id='currentPassword'
                              type={
                                this.state.showCurrentPassword
                                  ? 'text'
                                  : 'password'
                              }
                              endAdornment={
                                <InputAdornment position='end'>
                                  <IconButton
                                    aria-label={t('toggle_password_visibility')}
                                    onClick={this.onPasswordVisibilityClick.bind(
                                      this,
                                      'CurrentPassword'
                                    )}
                                    size='large'
                                  >
                                    {this.state.showCurrentPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label={t('current_password')}
                            />
                            {touched.currentPassword &&
                            errors.currentPassword ? (
                              <FormHelperText error>
                                {' '}
                                {errors.currentPassword}
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                          <FormControl
                            variant='outlined'
                            fullWidth
                            margin={'normal'}
                            value={values.newPassword}
                            onChange={handleChange}
                            error={
                              !!(touched.newPassword && errors.newPassword)
                            }
                            id={'newPassword'}
                            name={'newPassword'}
                            disabled={isSubmitting}
                          >
                            <InputLabel htmlFor='newPassword'>
                              {t('new_password')}
                            </InputLabel>
                            <OutlinedInput
                              id='newPassword'
                              type={
                                this.state.showNewPassword ? 'text' : 'password'
                              }
                              endAdornment={
                                <InputAdornment position='end'>
                                  <IconButton
                                    aria-label={t('toggle_password_visibility')}
                                    onClick={this.onPasswordVisibilityClick.bind(
                                      this,
                                      'NewPassword'
                                    )}
                                    size='large'
                                  >
                                    {this.state.showNewPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                  <Tooltip
                                    className={classes.toolTip}
                                    title={this.props.t(
                                      'new_password_validation_char'
                                    )}
                                  >
                                    <HelpIcon />
                                  </Tooltip>
                                </InputAdornment>
                              }
                              label={t('new_password')}
                            />
                            {touched.newPassword && errors.newPassword ? (
                              <FormHelperText error>
                                {' '}
                                {errors.newPassword}
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                          <FormControl
                            variant='outlined'
                            fullWidth
                            margin={'normal'}
                            value={values.newPasswordConfirmation}
                            onChange={handleChange}
                            error={
                              !!(
                                touched.newPasswordConfirmation &&
                                errors.newPasswordConfirmation
                              )
                            }
                            id={'newPasswordConfirmation'}
                            name={'newPasswordConfirmation'}
                            disabled={isSubmitting}
                          >
                            <InputLabel htmlFor='newPasswordConfirmation'>
                              {t('new_password_confirmation')}
                            </InputLabel>
                            <OutlinedInput
                              id='newPasswordConfirmation'
                              type={
                                this.state.showNewPasswordConfirmation
                                  ? 'text'
                                  : 'password'
                              }
                              endAdornment={
                                <InputAdornment position='end'>
                                  <IconButton
                                    aria-label={t('toggle_password_visibility')}
                                    onClick={this.onPasswordVisibilityClick.bind(
                                      this,
                                      'NewPasswordConfirmation'
                                    )}
                                    size='large'
                                  >
                                    {this.state.showNewPasswordConfirmation ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label={t('new_password_confirmation')}
                            />
                            {touched.newPasswordConfirmation &&
                            errors.newPasswordConfirmation ? (
                              <FormHelperText error>
                                {' '}
                                {errors.newPasswordConfirmation}
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                          <Box className={classes.actionArea} textAlign='right'>
                            <Button
                              variant='contained'
                              type='button'
                              disabled={isSubmitting}
                              onClick={this.onCancel}
                            >
                              {t('cancel_button')}
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                              type={'submit'}
                              disabled={isSubmitting}
                              color={'primary'}
                              variant='contained'
                            >
                              {t('submit_button')}
                            </Button>
                          </Box>
                        </form>
                      )}
                    </Formik>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Layout>
        </Loading>
      </Redirect>
    )
  }
}

const ChangePassword = flow(
  withTranslation('account_form'),
  withStyles(styles),
  withNotifications
)(ChangePasswordComponent)

export { ChangePassword }
