import Grid from '@mui/material/Grid'
import withStyles from '@mui/styles/withStyles'
import PropTypes from 'prop-types'
import React from 'react'

const styles = (theme) => ({})

class GridView extends React.Component {
  static defaultProps = {
    spacing: 6,
    justify: 'center',
    xsPerRow: 1,
    smPerRow: 1,
    mdPerRow: 2,
    lgPerRow: 4,
    xlPerRow: 3,
  }

  static propTypes = {
    spacing: PropTypes.number,
    justify: PropTypes.string,
    xsPerRow: PropTypes.number,
    smPerRow: PropTypes.number,
    mdPerRow: PropTypes.number,
    lgPerRow: PropTypes.number,
    xlPerRow: PropTypes.number,
  }

  render() {
    return (
      <Grid
        container
        spacing={this.props.spacing}
        justifyContent={this.props.justify}
      >
        {React.Children.map(this.props.children, (val, idx) => (
          <Grid
            key={idx}
            item
            xs={12 / this.props.xsPerRow}
            sm={12 / this.props.smPerRow}
            md={12 / this.props.mdPerRow}
            lg={12 / this.props.lgPerRow}
            xl={12 / this.props.xlPerRow}
          >
            {val}
          </Grid>
        ))}
      </Grid>
    )
  }
}

export default withStyles(styles)(GridView)
