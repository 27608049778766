import { Typography } from '@mui/material'

import { STATUS } from 'apps/scorecard-v2/constants'

const FeedbackStatus = ({ status, error, typing }) => {
  const getStatusText = () => {
    if (status === STATUS.fulfilled) {
      return 'Saved'
    }

    if (status === STATUS.pending) {
      return 'Typing...'
    }

    return ' '
  }

  return (
    <>
      {error ? (
        <Typography component='span' fontSize='0.8rem'>
          {error}
        </Typography>
      ) : (
        <span>&nbsp;</span>
      )}
      {typing && (
        <Typography component='span' fontSize='0.8rem' color='#2b254f'>
          {getStatusText()}
        </Typography>
      )}
    </>
  )
}

export default FeedbackStatus
