import AddBoxIcon from '@mui/icons-material/AddBox'
import { IconButton, Typography } from '@mui/material'
import { useMemo, useState } from 'react'

import { QuestionsModal } from 'apps/scorecard-v2/components/modals/questions-modal'
import StyledTooltip from 'apps/scorecard-v2/components/StyledTooltip'
import { getScoreColor } from 'apps/scorecard-v2/helpers'
import { useKeyPress, useTracking } from 'apps/scorecard/hooks'

import { Question } from '../question'
import { QuestionDetail } from '../question-detail'

import KeyboardHint from './keyboard-hint'
import { StyledTitle, StyledWrapper } from './style'

const QuestionList = ({
  title,
  handleSave,
  handleShowHeader,
  id,
  handleSaveFeedback,
  interviewId,
  tabTitle,
  allQuestions = [],
  sectionScores,
  subDimQuestionCount,
  toggleTabKeyDisable,
  sectionIndex,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null)
  const [focusIndex, setFocusIndex] = useState(null)

  useKeyPress(
    'ArrowDown',
    () => {
      const nextIndex = focusIndex + 1

      if (focusIndex === null) {
        setFocusIndex(0)
        return
      }

      if (nextIndex <= allQuestions.length) {
        setFocusIndex(nextIndex)
        return
      }

      if (nextIndex > allQuestions.length) {
        setFocusIndex(0)
      }
    },
    { prevent: true }
  )

  useKeyPress(
    'ArrowUp',
    () => {
      const nextIndex = focusIndex - 1
      const lastIndex = allQuestions.length - 1

      if (nextIndex >= 0) {
        setFocusIndex(nextIndex)
      } else {
        setFocusIndex(lastIndex)
      }
    },
    { prevent: true }
  )

  useKeyPress('q', () => {
    if (currentQuestionIndex) {
      return
    }

    if (focusIndex === null) {
      setFocusIndex(0)
      return
    }

    let sum = 0
    let subDimIndex = 0

    for (const countIndex in subDimQuestionCount) {
      sum += subDimQuestionCount[countIndex]
      if (focusIndex < sum) {
        subDimIndex = +countIndex
        break
      }
    }

    const nextSubDimIndex = subDimIndex + 1
    if (nextSubDimIndex === Object.keys(subDimQuestionCount).length) {
      setFocusIndex(0)
    } else {
      setFocusIndex(sum)
    }
  })

  const questions = allQuestions.filter(({ parentId }) => parentId === id)

  const questionsMap = useMemo(
    () =>
      questions.reduce((acc, curr) => {
        acc[curr.id] = { hasScore: curr.score && curr.score > 0 }
        return acc
      }, {}),
    [questions]
  )

  const onQuestionAddClick = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  let sectionAnsweredCount = 0
  const totalScore = Object.values(sectionScores).reduce((acc, curr) => {
    acc += curr

    if (curr > 0) {
      sectionAnsweredCount += 1
    }

    return acc
  }, 0)

  const sectionTotalScore = totalScore
    ? (totalScore / sectionAnsweredCount).toFixed(1)
    : totalScore

  const tracking = useTracking()
  const eventTracking = (label, action = 'Click') => {
    tracking.event({
      category: 'Scorecard-v2',
      action,
      label: `${action}_${label}`,
    })
  }

  return (
    <>
      <StyledWrapper paddingX={3}>
        {currentQuestionIndex === null && (
          <>
            <StyledTitle>
              <Typography>
                {title}{' '}
                <IconButton
                  onClick={() => {
                    onQuestionAddClick()
                    eventTracking('Message_Icon')
                  }}
                >
                  <StyledTooltip
                    arrow
                    title='Add/replace questions'
                    placement='top'
                  >
                    <AddBoxIcon />
                  </StyledTooltip>
                </IconButton>
                {sectionIndex === 0 && <KeyboardHint />}
              </Typography>
              <Typography color={getScoreColor(sectionTotalScore)}>
                {sectionTotalScore || 'n/a'}
              </Typography>
            </StyledTitle>
            {questions.map((question, index) => (
              <Question
                handleSave={handleSave}
                key={question.id}
                question={question}
                index={index}
                setCurrentQuestionIndex={setCurrentQuestionIndex}
                focusIndex={focusIndex}
                setFocusIndex={setFocusIndex}
                subDimQuestionCount={subDimQuestionCount}
              />
            ))}
          </>
        )}

        {currentQuestionIndex !== null && (
          <QuestionDetail
            handleSave={handleSave}
            setCurrentQuestionIndex={setCurrentQuestionIndex}
            question={allQuestions[currentQuestionIndex]}
            questionsLength={allQuestions.length}
            handleShowHeader={handleShowHeader}
            handleSaveFeedback={handleSaveFeedback}
            onChangeQuestion={onQuestionAddClick}
            toggleTabKeyDisable={toggleTabKeyDisable}
          />
        )}
      </StyledWrapper>
      <QuestionsModal
        isOpen={isOpen}
        handleClose={handleClose}
        interviewId={interviewId}
        itemId={id}
        title={title}
        questionsMap={questionsMap}
        tabTitle={tabTitle}
        toggleTabKeyDisable={toggleTabKeyDisable}
      />
    </>
  )
}

export default QuestionList
