import sortBy from 'lodash/sortBy'

export const ProfileIncoming = (profile) => {
  const skills = sortBy(profile.skills || [], ['order', 'startedAt']).map(
    (skill, index) => ({ ...skill, order: skill.order || index + 1 })
  )

  return {
    ...profile,
    skills,
  }
}
