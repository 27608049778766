import { DialogActions, Button, Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { renderTextField } from 'apps/portal/containers/jobPage/utilities/formFields'

const ReferralForm = ({
  setOpenSecond,
  setOpenFirst,
  profileId,
  jobId,
  JobsService,
  showFailedNotification,
}) => {
  const { t } = useTranslation('jobPage')
  const theme = useTheme()
  const methods = useForm()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods

  const transformData = (data) => {
    const {
      firstName,
      lastName,
      email,
      comments,
      github,
      linkedIn,
      website,
      xing,
    } = data

    const communications = [
      { type: 'TYPE_EMAIL', value: email },
      { type: 'TYPE_GITHUB', value: github },
      { type: 'TYPE_LINKEDIN', value: linkedIn },
      { type: 'TYPE_WEBSITE', value: website },
      { type: 'TYPE_xing', value: xing },
    ]

    return {
      firstName,
      lastName,
      comments,
      communications: communications.filter((item) => !!item.value),
    }
  }

  const submitReferAFriendRequest = useCallback(
    async (data) => {
      const transformedData = transformData(data)

      try {
        await JobsService.referAFriend(
          {
            ...transformedData,
          },
          { path: { id: profileId } }
        )
        setOpenFirst(false)
        setOpenSecond(true)
      } catch (err) {
        setOpenFirst(false)
        showFailedNotification()
        console.error(err)
      }
    },
    [profileId]
  )

  const onSubmit = (data) => {
    submitReferAFriendRequest(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display='flex'>
        {renderTextField({
          name: 'firstName',
          label: t('firstName'),
          control,
          defaultValue: '',
          rules: { required: 'First Name is required' },
          style: { flexBasis: '50%' },
          errors,
        })}
        {renderTextField({
          errors,
          name: 'lastName',
          label: t('lastName'),
          control,
          defaultValue: '',
          rules: { required: 'Last Name is required' },
          style: { ml: 2, flexBasis: '50%' },
        })}
      </Box>
      {renderTextField({
        errors,
        name: 'email',
        label: t('theirEmail'),
        control,
        defaultValue: '',
        rules: {
          required: 'Their E-mail is required',
          pattern: { value: /^\S+@\S+$/i, message: 'Invalid email' },
        },
      })}
      {renderTextField({
        name: 'comments',
        label: t('whatMakesFit'),
        multiline: true,
        rows: 4,
        control,
        defaultValue: '',
      })}
      <Typography
        variant='body1'
        sx={{ mt: 0.5, mb: 0.5, color: theme.palette.secondary.blue }}
      >
        {t('socialMediaLabel')}
        <Box component='span' sx={{ opacity: 0.6 }}>
          ({t('optional')}
)
</Box>
      </Typography>
      {renderTextField({
        errors,
        name: 'github',
        label: 'Github',
        control,
        defaultValue: '',
        rules: {
          pattern: { value: /^https?:\/\/.+/, message: 'Invalid URL' },
        },
      })}
      {renderTextField({
        errors,
        name: 'linkedIn',
        label: 'LinkedIn',
        control,
        defaultValue: '',
        rules: {
          pattern: { value: /^https?:\/\/.+/, message: 'Invalid URL' },
        },
      })}
      {renderTextField({
        errors,
        name: 'website',
        label: 'Website',
        control,
        defaultValue: '',
        rules: {
          pattern: { value: /^https?:\/\/.+/, message: 'Invalid URL' },
        },
      })}
      {renderTextField({
        errors,
        name: 'xing',
        label: 'Xing',
        control,
        defaultValue: '',
        rules: {
          pattern: { value: /^https?:\/\/.+/, message: 'Invalid URL' },
        },
      })}
      <DialogActions sx={{ pl: 0, pr: 0 }}>
        <Box
          display='flex'
          sx={{ mt: 4, justifyContent: 'space-between', width: '100%' }}
        >
          <Button variant='outlined' onClick={() => setOpenFirst(false)}>
            {t('cancel')}
          </Button>
          <Button variant='contained' type='submit' color='primary'>
            {t('refer')}
          </Button>
        </Box>
      </DialogActions>
    </form>
  )
}

export default ReferralForm
