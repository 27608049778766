import { TextField as Input, FormControl, FormHelperText } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import flow from 'lodash/flow'
import React from 'react'

const styles = (theme) => ({
  container: {
    display: 'inline-flex',
    width: '100%',
  },
  extra: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: `0 ${theme.spacing(2)}`,
  },
})

const TextFieldBase = ({
  value: defaultValue,
  classes,
  helperText,
  maxLength,
  error,
  ...props
}) => {
  const rawValue = props.field ? props.field.value : defaultValue
  const value = maxLength ? String(rawValue).substring(0, maxLength) : rawValue
  const extra = !!helperText || !!maxLength
  const helperTextVal = `${value.length} / ${maxLength}`

  return (
    <FormControl className={classes.container} error={error}>
      <Input {...props} {...props.field} error={error} value={value} />
      {extra && (
        <div className={classes.extra}>
          <FormHelperText>{helperText}</FormHelperText>
          {maxLength && <FormHelperText>{helperTextVal}</FormHelperText>}
        </div>
      )}
    </FormControl>
  )
}

TextFieldBase.defaultProps = {
  type: 'text',
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
  value: '',
}

const TextField = flow(withStyles(styles))(TextFieldBase)

export { TextField }
