import get from 'lodash/get'
import { shape, string } from 'prop-types'
import React, { useMemo } from 'react'
import { Chip } from '@mui/material'

export const Skill = ({ skill, name }) => {
  const label = useMemo(() => get(skill, 'name', name), [skill, name])

  return <Chip label={label} />
}

Skill.propTypes = {
  skill: shape({ name: string }),
  name: string,
}

Skill.defaultProps = {}

export default Skill
