import logo from 'assets/img/logo.png'
import { EXPERTLEAD_HOMEPAGE } from 'common/constants'

const config = {
  meta: {
    favicon: true,
    pageTitle: 'expertlead Portal',
  },
  navigation: {
    logo: {
      image: logo,
      altText: 'Expertlead',
    },
    links: {
      login: true,
      aboutUs: true,
      whyExpertlead: true,
      services: true,
      referrals: true,
      faq: true,
      interviewer: true,
      events: true,
    },
    contactNumber: true,
    homePageUrl: EXPERTLEAD_HOMEPAGE,
  },
  pages: {
    services: true,
    referrals: true,
    faq: true,
    interviewer: true,
    events: true,
  },
  content: {
    home: {
      welcome: 'Meet your personal manager!',
    },
  },
  footer: {
    companyName: 'Expertlead GmbH',
    links: {
      privacyPolicy: 'https://www.expertlead.com/privacy-policy',
      imprint: 'https://www.expertlead.com/imprint',
    },
  },
  badges: true,
  profileType: false,
  isDefaultBg: true,
}

export { config }
