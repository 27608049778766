import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import FormLabel from '@mui/material/FormLabel'
import Grid from '@mui/material/Grid'
import withStyles from '@mui/styles/withStyles'
import flow from 'lodash/flow'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { PAGES } from 'apps/portal/common/constants'

import { useForm } from './hooks'

const styles = (theme) => ({
  actionArea: {
    marginTop: theme.spacing(1),
  },
})

const LoginFormBase = ({ t: translate, classes, onSuccessCallback }) => {
  const {
    fields: { email, password, rememberMe },
    handleSubmit,
    status,
    isSubmitting,
  } = useForm({
    translate,
    onSuccessCallback,
  })

  return (
    <>
      <Typography component='h1' variant='h5' gutterBottom>
        {translate('login_title')}
      </Typography>

      <Typography variant='body1' gutterBottom>
        {translate('login_sub_title')}
      </Typography>

      <form onSubmit={handleSubmit}>
        {!!status && <FormLabel error>{status}</FormLabel>}
        {email}
        {password}
        {rememberMe}
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
          className={classes.actionArea}
        >
          <Typography>
            <Link to={`/${PAGES.LOGIN_FORGOT_PASSWORD}`} color='primary'>
              {translate('forgot_password')}
            </Link>
          </Typography>
          <Grid item>
            <Button
              type='submit'
              disabled={isSubmitting}
              color='primary'
              size='large'
              variant='contained'
            >
              {translate('submit_button')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

LoginFormBase.propTypes = {
  t: PropTypes.func,
  classes: PropTypes.object,
  onSuccessCallback: PropTypes.func,
}

export const LoginForm = flow(
  withStyles(styles),
  withTranslation('login_form')
)(LoginFormBase)
