import { number } from 'yup'

const validScore = (label) =>
  number()
    .min(0)
    .max(10)
    .required(`${label} is a required field (0-10)`)
    .typeError(`${label} is a required field (0-10)`)

export { validScore }
