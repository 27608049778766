import React, { useCallback, useRef, useState } from 'react'
import Container from '@mui/material/Portal'

export default () => {
  const [opened, setOpened] = useState(false)
  const ref = useRef(opened)
  const Portal = useCallback(
    ({ children }) => !!ref.current && <Container>{children}</Container>,
    []
  )
  const open = useCallback(() => {
    ref.current = true

    setOpened(true)
  }, [ref, setOpened])
  const close = useCallback(() => {
    ref.current = false

    setOpened(false)
  }, [ref, setOpened])

  return { Portal, opened, open, close }
}
