import { useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'
import GA from 'react-ga'

// import { isProduction } from 'apps/scorecard/helpers/lang'

export const useTracking = () => {
  const history = useHistory()

  useLayoutEffect(() => {
    const { pathname, search } = window.location

    GA.pageview(pathname + search)
  }, [])

  useLayoutEffect(() => {
    const listen = ({ pathname, search }) => GA.pageview(pathname + search)

    return history.listen(listen)
  }, [history])

  return GA
}

export default GA.initialize(process.env.REACT_APP_TRACKING_ID, {
  debug: false,
})
