import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import flow from 'lodash/flow'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter, Route, Switch, Redirect } from 'react-router-dom'

import { withProfile } from 'hocs/withProfile'

import { PAGES } from '../../../common/constants'
import { AboutMe } from '../components/AboutMe'
import { Certifications } from '../components/Certifications'
import { Educations } from '../components/Educations'
import { Experiences } from '../components/Experiences'
import { Skills } from '../components/Skills'
import { AboutMeForm } from '../forms/AboutMeForm'
import { CertificationsForm } from '../forms/CertificationsForm'
import { EducationsForm } from '../forms/EducationsForm'
import { ExperiencesForm } from '../forms/ExperiencesForm'
import { SkillsForm } from '../forms/SkillsForm'

const styles = (theme) => ({
  externalLink: {
    textDecoration: 'none',
  },
  editButton: {
    marginRight: theme.spacing(2),
  },
})

const profileURL = (profile) =>
  typeof window !== 'undefined'
    ? `${window.location.origin}/expert/${profile.slug}`
    : ''

class ProfileSectionsComponent extends React.Component {
  /**
   * Available props
   */
  static propTypes = {
    profile: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    isEditMode: PropTypes.bool,
    currentTitle: PropTypes.string,
  }

  toggleEditMode = () => {
    const { pathname } = this.props.location
    const editPath = '/edit'
    let newPath
    if (this.props.isEditMode) {
      newPath = pathname.slice(0, -editPath.length)
    } else {
      newPath = `${pathname}${editPath}`
    }

    this.props.history.push(newPath)
  }

  onSuccess = (response) => {
    this.props.dispatchers.loadProfile(response.data)
    this.toggleEditMode()
  }

  render() {
    const { classes, t, isEditMode, currentTitle, profile } = this.props

    return (
      <Box p={3}>
        <Grid
          container
          direction='column'
          justifyContent='space-between'
          alignItems='stretch'
        >
          <Grid item>
            {' '}
            {/* Upper most row */}
            <Grid
              container
              direction='row'
              justifyContent='flex-start'
              alignItems='flex-start'
            >
              <Grid item xs={6}>
                <Grid
                  container
                  direction='row'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                >
                  <Grid item>
                    <Typography variant='h5'>
                      <strong> {t('profile_my_cv')} / </strong> {currentTitle}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <Grid
                  container
                  direction='row'
                  justifyContent='flex-end'
                  alignItems='flex-start'
                >
                  <Grid item>
                    {!isEditMode && (
                      <>
                        <Button
                          color='primary'
                          size='large'
                          className={classes.editButton}
                          startIcon={<EditIcon />}
                          onClick={this.toggleEditMode}
                        >
                          {t('profile_edit_button')}
                        </Button>
                        <a
                          target='_blank'
                          href={profileURL(this.props.profile)}
                          className={classes.externalLink}
                          rel='noopener noreferrer'
                        >
                          <Button
                            color='primary'
                            size='large'
                            startIcon={<VisibilityIcon />}
                          >
                            {t('profile_view_button')}
                          </Button>
                        </a>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {' '}
            {/* Second row, profile sections */}
            <Grid container>
              <Grid item xs={7}>
                <Route exact path={`/${PAGES.PROFILE_SKILLS_SECTION}`}>
                  <Skills />
                </Route>
                <Route exact path={`/${PAGES.PROFILE_SKILLS_SECTION}/edit`}>
                  <SkillsForm
                    profile={profile}
                    onSuccess={this.onSuccess}
                    onCancel={this.toggleEditMode}
                  />
                </Route>
              </Grid>
              <Grid item xs={12}>
                <Switch>
                  <Redirect
                    from={`/${PAGES.PROFILE}`}
                    exact
                    to={`/${PAGES.PROFILE_ABOUT_ME_SECTION}`}
                  />
                  <Route exact path={`/${PAGES.PROFILE_ABOUT_ME_SECTION}`}>
                    <AboutMe {...profile} />
                  </Route>
                  <Route exact path={`/${PAGES.PROFILE_ABOUT_ME_SECTION}/edit`}>
                    <AboutMeForm
                      profile={profile}
                      onSuccess={this.onSuccess}
                      onCancel={this.toggleEditMode}
                    />
                  </Route>

                  <Route exact path={`/${PAGES.PROFILE_EXPERIENCE_SECTION}`}>
                    <Experiences {...profile} />
                  </Route>
                  <Route
                    exact
                    path={`/${PAGES.PROFILE_EXPERIENCE_SECTION}/edit`}
                  >
                    <ExperiencesForm
                      onSuccess={this.onSuccess}
                      onCancel={this.toggleEditMode}
                    />
                  </Route>

                  <Route exact path={`/${PAGES.PROFILE_EDUCATION_SECTION}`}>
                    <Educations {...profile} />
                  </Route>
                  <Route
                    exact
                    path={`/${PAGES.PROFILE_EDUCATION_SECTION}/edit`}
                  >
                    <EducationsForm
                      onSuccess={this.onSuccess}
                      onCancel={this.toggleEditMode}
                    />
                  </Route>
                  <Route exact path={`/${PAGES.PROFILE_CERTIFICATION_SECTION}`}>
                    <Certifications {...profile} />
                  </Route>
                  <Route
                    exact
                    path={`/${PAGES.PROFILE_CERTIFICATION_SECTION}/edit`}
                  >
                    <CertificationsForm
                      onSuccess={this.onSuccess}
                      onCancel={this.toggleEditMode}
                    />
                  </Route>
                </Switch>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    )
  }
}

export const ProfileSections = flow(
  withTranslation('profile_view'),
  withStyles(styles),
  withRouter,
  withProfile
)(ProfileSectionsComponent)
