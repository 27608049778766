import { Dialog, Accordion } from '@mui/material'
import styled from 'styled-components'

export const StyledDialog = styled(Dialog)`
  & * {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }

  & .MuiBackdrop-root {
    background-color: transparent;
  }

  & .MuiDialog-container {
    justify-content: flex-end;

    & > .MuiPaper-root {
      height: 700px;
      max-width: max(750px, 30vw);
      width: max(750px, 30vw);
      border-radius: 8px;
      margin: 0;
      flex-direction: column;
      overflow-y: scroll;
    }
  }
`

export const StyledAccordion = styled(Accordion)`
  margin-block: 5px;
  align-items: center;
  box-shadow: none;
  color: #414141;

  & .MuiAccordionDetails-root {
    padding: 0 22px;
    line-height: 1.4rem;
    color: #888;
  }

  & .Mui-expanded {
    margin: 5px 0;
  }

  & .MuiCheckbox-root {
    margin-top: 0;
  }

  &::before {
    display: none;
  }
`
