/* eslint-disable */
import React, { memo, useMemo } from 'react'
import { Typography, Divider, Grid, Chip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { TOP_SKILLS_LIMIT } from 'apps/portal/common/constants'
import { pluralize } from 'common/utils'
import defaultImage from 'assets/img/skills_default_icon.svg'
import { durationInYears } from 'common/dates'
import { useViewport } from 'common/view-port-hook'

const useStyles = makeStyles((theme) => ({
  skillsContainer: {
    padding: theme.spacing(2),
  },
  skillsWrapper: {
    backgroundColor: theme.palette.white,
    position: 'relative',
    borderRadius: theme.spacing(1),
    boxShadow: '0px 6.28771px 6.28771px rgba(0, 0, 0, 0.1)',
    '& img': {
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      marginRight: theme.spacing(1),
    },
    '& > h6': {
      color: theme.palette.lightGray,
    },
    '& .MuiTypography-body2': {
      color: theme.palette.lightGray2,
      fontWeight: theme.typography.fontWeight.strong,
    },
  },
  topSkills: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing(0.5, 0),

    '& div': {
      display: 'flex',
      alignItems: 'center',
    },
    '& > h6': {
      maxWidth: '76%',
    },
    '& > p': {
      color: theme.palette.lightGray2,
    },
  },
  additionalSkills: {
    margin: theme.spacing(0.5, 0),
    display: 'flex',
    alignItems: 'center',
  },
  additionalSkillsLabel: {
    margin: theme.spacing(4, 0, 1, 0),
  },
  otherSkills: {
    margin: '3px',
  },
}))

function Skills(props) {
  const { skills, translate } = props
  const classes = useStyles()
  const { isDeviceMobile } = useViewport()

  const topSkills =
    skills && skills.filter((skill) => skill.order <= TOP_SKILLS_LIMIT)
  const otherSkills =
    skills && skills.filter((skill) => skill.order > TOP_SKILLS_LIMIT)

  const calculateYearsOfXP = useMemo(
    () => (skill) => {
      const { months, years } = durationInYears(skill.startedAt)
      return years === 0
        ? `${months} ${pluralize('Month')(months)}`
        : `${years} ${pluralize('Year')(years)}`
    },
    []
  )
  const addDefaultImage = (e) => {
    e.target.onerror = null
    e.target.src = defaultImage
  }

  const SkillsHeader = () =>
    isDeviceMobile ? <SkillsHeaderForMobile /> : <SkillsHeaderForDesktop />
  const SkillsHeaderForMobile = () => (
    <Typography variant='body2'>{translate('skills')}</Typography>
  )
  const SkillsHeaderForDesktop = () => (
    <Typography variant='h6'>{translate('skills')}</Typography>
  )
  const TopSkills = memo(({ skill }) => {
    return (
      <div className={classes.topSkills}>
        <div>
          <img
            src={`${skill?.skill?.logo}`}
            alt={skill.skill.name}
            onError={addDefaultImage}
            loading='lazy'
          />
          <Typography variant='subtitle1'>{skill.skill.name}</Typography>
        </div>
        <Typography variant='body1'>{calculateYearsOfXP(skill)}</Typography>
      </div>
    )
  })

  const OtherSkills = memo(({ skill }) =>
    isDeviceMobile ? (
      <OtherSkillsForMobile skill={skill} />
    ) : (
      <OtherSkillsForDesktop skill={skill} />
    )
  )
  const OtherSkillsForMobile = memo(({ skill }) => (
    <Chip
      label={skill.skill.name}
      variant='outlined'
      className={classes.otherSkills}
    />
  ))
  const OtherSkillsForDesktop = memo(({ skill }) => (
    <div className={classes.additionalSkills}>
      <img
        src={`${skill?.skill?.logo}`}
        alt={skill.skill.name}
        onError={addDefaultImage}
      />
      <Typography variant='subtitle1'>{skill.skill.name}</Typography>
    </div>
  ))

  return (
    <>
      {skills && skills.length > 0 && (
        <Grid
          item
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          xs={11}
          md={12}
          lg={12}
          className={classes.skillsWrapper}
        >
          <Grid item xs={11} className={classes.skillsContainer}>
            <SkillsHeader />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} className={classes.skillsContainer}>
            {topSkills && topSkills.length > 0 && (
              <>
                <Typography variant='body2'>{translate('Main')}</Typography>
                {topSkills.map((skill, idx) => (
                  <TopSkills skill={skill} key={`${skill.skill.name}_${idx}`} />
                ))}
              </>
            )}

            {otherSkills && otherSkills.length > 0 && (
              <>
                <Typography
                  variant='body2'
                  className={classes.additionalSkillsLabel}
                  m={1}
                >
                  {translate('Additional')}
                </Typography>
                {otherSkills.map((skill, idx) => (
                  <OtherSkills
                    skill={skill}
                    key={`${skill.skill.name}_${idx}`}
                  />
                ))}
              </>
            )}
          </Grid>
        </Grid>
      )}
    </>
  )
}
export default Skills
