import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import withStyles from '@mui/styles/withStyles'
import { flow } from 'lodash'
import React from 'react'

import { GENDERS } from 'common/constants'

const styles = (theme) => ({
  container: {
    display: 'inline-flex',
    width: '100%',
  },
  extra: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: `0 ${theme.spacing(2)}`,
  },
  error: {
    '& .MuiFormControlLabel-label, & .MuiRadio-root': {
      color: theme.palette.error.main,
    },
  },
})

const GenderRadioFieldBase = ({
  value,
  onChange,
  classes,
  disabled,
  labelMale,
  labelFemale,
  error,
}) => (
  <FormControl className={classes.formControl}>
    <RadioGroup
      aria-label='gender'
      name='gender'
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className={error ? classes.error : ''}
      row
    >
      <FormControlLabel
        value={GENDERS.MALE}
        control={<Radio color='primary' />}
        label={labelMale}
        labelPlacement='end'
        disabled={disabled}
        data-automator='gender-male'
      />

      <FormControlLabel
        value={GENDERS.FEMALE}
        control={<Radio color='primary' />}
        label={labelFemale}
        labelPlacement='end'
        disabled={disabled}
        data-automator='gender-female'
      />
    </RadioGroup>
  </FormControl>
)

const GenderRadioField = flow(withStyles(styles))(GenderRadioFieldBase)

export { GenderRadioField }
