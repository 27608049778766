import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import withStyles from '@mui/styles/withStyles'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'

import { TextField } from 'components'

import { PAGES } from '../../../common/constants'

const styles = (theme) => ({
  body: {
    width: '60%',
  },
  cancelButton: {
    textDecoration: 'none',
  },
  actionArea: {
    marginTop: theme.spacing(4),
  },
  form: {
    width: '65%',
    marginTop: theme.spacing(2),
  },
})

class ForgotPasswordForm extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  }

  validationSchema() {
    return Yup.object().shape({
      email: Yup.string().email(),
    })
  }

  render() {
    const { t, classes } = this.props
    return (
      <>
        <Typography variant='h5' gutterBottom>
          {t('title')}
        </Typography>
        <Typography variant='body1' gutterBottom className={classes.body}>
          {t('sub_title')}
        </Typography>
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={this.validationSchema()}
          onSubmit={this.props.onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            status,
          }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <TextField
                name='email'
                id='email'
                label={t('email')}
                error={!!(touched.email && errors.email)}
                value={values.email}
                onChange={handleChange}
                disabled={isSubmitting}
              />
              <Box className={classes.actionArea} textAlign='right'>
                <Link to={`/${PAGES.LOGIN}`} className={classes.cancelButton}>
                  <Button color='primary'>{t('cancel')}</Button>
                </Link>
                &nbsp;&nbsp;
                <Button
                  type='submit'
                  disabled={isSubmitting}
                  color='primary'
                  variant='contained'
                >
                  {t('send')}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </>
    )
  }
}

const tForgotPassword = withTranslation('forgot_password')(ForgotPasswordForm)
const sForgotPasswordForm = withStyles(styles)(tForgotPassword)
export { sForgotPasswordForm as ForgotPasswordForm }
