import isEmpty from 'lodash/isEmpty'
import React, { createElement, useEffect, useState } from 'react'

import { DOMAINS, ORGANIZATION_IDS } from 'common/constants'
import { Loading } from 'components'

const DIR_NAMES = {
  EXPERTLEAD: 'expertlead',
  JV: 'futurepath',
}

const getConfigDirectoryNameFromHostname = (hostname) =>
  ({
    [DOMAINS.PORTAL.JV]: DIR_NAMES.JV,
  }[hostname] || DIR_NAMES.EXPERTLEAD)

const getConfigDirectoryNameFromOrganizationId = (orgId) =>
  ({
    [ORGANIZATION_IDS.JV]: DIR_NAMES.JV,
  }[orgId] || DIR_NAMES.EXPERTLEAD)

const withConfigInjection = (getConfig) => (component) => (props) => {
  const Configured = () => {
    const [loading, setLoading] = useState(true)
    const [config, setConfig] = useState(null)

    useEffect(() => {
      if (config) return

      const executeConfigExtraction = async () => {
        try {
          setConfig(await getConfig())
        } catch (e) {
          console.error(e)
        }
      }

      executeConfigExtraction()
    }, [config])

    useEffect(() => {
      if (!isEmpty(config)) {
        setLoading(false)
      }
    }, [config])

    return (
      <Loading loading={loading}>
        {createElement(component, { ...props, config })}
      </Loading>
    )
  }

  return <Configured />
}

export {
  getConfigDirectoryNameFromHostname,
  getConfigDirectoryNameFromOrganizationId,
  withConfigInjection,
}
