import CheckIcon from '@mui/icons-material/Check'
import { Typography, Box } from '@mui/material'

import StyledTooltip from 'apps/scorecard-v2/components/StyledTooltip'
import { CASE_STUDY, LIVE_CODING } from 'apps/scorecard-v2/constants'

import { StyledTab, StyledTabs } from './style'

const TabNames = ({
  sortedTabNames,
  tabExtraLabel,
  currentTab,
  handleTabChange,
  eventTracking,
  isNonTechnical,
}) => (
  <StyledTabs value={currentTab} onChange={handleTabChange} position='relative'>
    {sortedTabNames.map((tabTitle, index) => {
      const updatedTabName =
        isNonTechnical && tabTitle === LIVE_CODING ? CASE_STUDY : tabTitle

      return (
        <StyledTab
          key={tabTitle}
          label={
            <StyledTooltip arrow title={index === 0 ? <TooltipTitle /> : ''}>
              <Typography fontSize='0.89rem' display='flex' alignItems='center'>
                {tabExtraLabel[tabTitle]?.done && (
                  <CheckIcon
                    sx={{
                      fontSize: '1rem',
                      stroke: '#2B254F',
                      strokeWidth: 1,
                    }}
                  />
                )}
                {updatedTabName}
                &nbsp;
                <span style={{ opacity: 0.7 }}>
                  {tabExtraLabel[tabTitle]?.label}
                </span>
              </Typography>
            </StyledTooltip>
          }
          value={tabTitle}
          onClick={() => eventTracking(`${tabTitle.replace(' ', '_')}_Tab`)}
        />
      )
    })}
    <StyledTab
      label='Feedback'
      value='Feedback'
      onClick={() => eventTracking('Feedback_Tab')}
    />
  </StyledTabs>
)

const TooltipTitle = () => (
  <Box display='flex' alignItems='center' gap={0.5}>
    <Typography
      fontSize='0.8rem'
      border='1px solid #fff'
      padding='0 4px'
      borderRadius='8px'
    >
      T
    </Typography>
    <Typography fontSize='0.8rem'>
      Press T to switch between the tabs
    </Typography>
  </Box>
)
export default TabNames
