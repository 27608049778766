/* eslint-disable */
import React from 'react'
import { withTranslation } from 'react-i18next'
import { buildImageURL } from 'common/image'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { humanizeDuration } from 'common/dates'
import defaultImage from 'assets/img/Education.svg'
import { placeStatusApproved } from '../../../common/place-status'

class EducationComponent extends React.Component {
  getSchoolName = (status, name) =>
    placeStatusApproved(status)
      ? name
      : `${name} (${this.props.t('place_under_review')})`

  addDefaultImage = (e) => {
    e.target.src = defaultImage
  }

  render() {
    const { classes, degree, school, startDate, endDate } = this.props
    const { year, duration } = humanizeDuration(startDate, endDate)

    return (
      <Grid
        container
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
      >
        <Grid item>
          <Grid item>
            {school.logo.bucket ? (
              <img
                className={classes.companyLogo}
                src={buildImageURL(school.logo)}
                alt={school.name}
                onError={this.addDefaultImage}
              />
            ) : (
              <img
                className={classes.companyLogo}
                src={`${defaultImage}`}
                alt={school.name}
              />
            )}
          </Grid>

          <Grid item>
            <Typography variant='h6'>{degree}</Typography>
          </Grid>

          <Grid item>
            <Typography variant='body1'>
              {this.getSchoolName(school.status, school.name)}
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant='subtitle2'>
              <Box fontWeight='fontWeightLight' className={classes.duration}>
                {year} | {duration}
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const Education = withTranslation('profileEducation')(EducationComponent)

export { Education }
