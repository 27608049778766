import {
  FormHelperText,
  ListSubheader,
  MenuItem,
  Checkbox,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'
import { map } from 'lodash'
import React, { useCallback, useRef } from 'react'

import { capitalize } from 'common/utils'

const Dropdown = ({
  value,
  onChange,
  classes,
  helperText,
  maxLength,
  error,
  disabled,
  label,
  name,
  id,
  isGroping,
  isNative,
  selectOptions,
  inputVariant,
  isMultiSelect = false,
  selectValue = [],
  ...props
}) => {
  const dataAutomator = props['data-automator'] || 'dropdown'
  const dropdownEl = useRef(null)

  const renderSelectGroup = ({ options, label }) => {
    const items =
      isGroping &&
      map(options, (option) => (
        <MenuItem
          key={
            typeof option.value === 'object'
              ? JSON.stringify(option.value)
              : option.label
          }
          value={option.value.id}
          style={{ textTransform: 'capitalize' }}
        >
          {option.label}
        </MenuItem>
      ))

    return [
      <ListSubheader style={{ backgroundColor: 'white' }}>
        {label}
      </ListSubheader>,
      items,
    ]
  }

  const renderOptions = ({ value, label }) => (
    <option
      value={value}
      key={typeof value === 'object' ? JSON.stringify(value) : value}
    >
      {label}
    </option>
  )

  const stringifyObject = useCallback((obj) => {
    if (typeof obj === 'object') {
      return Object.values(obj).join('\n')
    }
    return obj
  }, [])

  const stringifySelectValues = (selectValues) =>
    map(selectValues, (value, id) => (
      <span>
        {capitalize(value.split('_').join(' '))}
        <span>{selectValues.length > id + 1 ? ', ' : ''}</span>
      </span>
    ))

  const renderSelectMenuItems = () =>
    map(selectOptions, (option, key) => (
      <MenuItem key={key} value={option.name}>
        <ListItemIcon>
          <Checkbox checked={(value || []).includes(option?.name)} />
        </ListItemIcon>
        <ListItemText primary={option?.id} />
      </MenuItem>
    ))

  return (
    <FormControl
      fullWidth
      disabled={disabled}
      variant={inputVariant || 'outlined'}
      error={!!error}
      data-automator={dataAutomator}
    >
      <InputLabel ref={dropdownEl} htmlFor={label}>
        {label}
      </InputLabel>

      <Select
        label={label}
        inputProps={{
          name,
          id,
        }}
        value={value}
        input={<OutlinedInput label={label} id={label} />}
        native={isNative}
        multiple={isMultiSelect}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
        {...(isMultiSelect && {
          renderValue: (options) => stringifySelectValues(options),
        })}
      >
        {isMultiSelect
          ? renderSelectMenuItems()
          : selectOptions.map(isGroping ? renderSelectGroup : renderOptions)}
        {}
      </Select>
      <FormHelperText>{stringifyObject(error)}</FormHelperText>
    </FormControl>
  )
}

export { Dropdown }
