import { Card, CardContent, Typography, Container } from '@mui/material'
import CardActionArea from '@mui/material/CardActionArea'
import CardActions from '@mui/material/CardActions'
import withStyles from '@mui/styles/withStyles'
import flow from 'lodash/flow'
import PropTypes from 'prop-types'
import React, { useEffect, useCallback } from 'react'
import { GoogleLogin } from 'react-google-login'
import { withTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'

import logo from 'assets/img/login_logo.png'
import {
  GOOGLE_AUTH_CLIENT_ID,
  GOOGLE_AUTH_HOSTED_DOMAIN,
  GOOGLE_AUTH_SCOPE,
} from 'common/constants'
import { AccountsService } from 'sdk/account/Service'

import { authenticateUser } from './Actions'

const styles = (theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(6, 2),
    maxWidth: 500,
  },
  card: {
    width: '100%',
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  media: {
    backgroundColor: theme.palette.dark,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.background.light,
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  actions: {
    height: 100,
    display: 'flex',
    justifyContent: 'center',
  },
  yellow: {
    color: theme.palette.background.topSkills,
    textAlign: 'center',
  },
  image: {
    marginBottom: 10,
  },
  wrapper: {
    textAlign: 'center',
  },
})

const LoginClass = ({
  onSuccessRedirectUri,
  history,
  classes,
  t,
  homeUri,
  location,
}) => {
  const dispatch = useDispatch()
  const { authToken, onAuthenticate } = useSelector((state) => ({
    authToken: state.auth && state.auth.accessToken,
    onAuthenticate: (auth) => dispatch(authenticateUser(auth)),
  }))

  const pathname = location.state?.from?.pathname || `/${onSuccessRedirectUri}`

  const redirect = useCallback(
    (param) => {
      history.push(param)
    },
    [history]
  )

  useEffect(() => {
    if (authToken) {
      redirect(pathname)
    }
  }, [authToken, onSuccessRedirectUri, redirect, pathname])

  const onGoogleFailure = (error) => {
    // TODO (Dio): Decide what to do here
    console.error('google failed', error)
  }

  const onGoogleSuccess = async (properties) => {
    const {
      tokenId,
      accessToken: googleAccessToken,
      profileObj: {
        imageUrl: googleImageUrl,
        email: googleEmail,
        givenName: googleFirstName,
        familyName: googleLastName,
      },
    } = properties

    let user

    try {
      const { data } = await AccountsService.authenticate({
        email: googleEmail,
        accessToken: tokenId,
      })

      user = data
    } catch (e) {
      const { data } = await AccountsService.addAccount({
        firstName: googleFirstName,
        lastName: googleLastName,
        email: googleEmail,
        accessToken: tokenId,
      })

      user = data

      const { data: authData } = await AccountsService.authenticate({
        email: googleEmail,
        accessToken: tokenId,
      })

      user = {
        ...user,
        ...authData,
      }
    } finally {
      onAuthenticate({
        accessToken: user.accessToken,
        imageUrl: googleImageUrl,
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        organizationId: user.organizationId,
        googleAccessToken,
        id: user.id,
      })

      redirect(pathname)
    }
  }

  return (
    <Container className={classes.root}>
      <Card className={classes.card}>
        <CardActionArea className={classes.media}>
          <CardContent className={classes.wrapper}>
            <img src={logo} className={classes.image} alt='logo' />
            <Typography gutterBottom variant='h5' component='h2'>
              {t('welcome')}
            </Typography>
            <Typography
              gutterBottom
              variant='h5'
              component='h2'
              className={classes.yellow}
            >
              {t('internal_tools')}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions className={classes.actions}>
          <GoogleLogin
            clientId={GOOGLE_AUTH_CLIENT_ID}
            onSuccess={onGoogleSuccess}
            onFailure={onGoogleFailure}
            hostedDomain={GOOGLE_AUTH_HOSTED_DOMAIN}
            scope={GOOGLE_AUTH_SCOPE}
            buttonText={t('signin')}
            cookiePolicy='single_host_origin'
            redirectUri={homeUri}
          />
        </CardActions>
      </Card>
    </Container>
  )
}

LoginClass.propTypes = {
  authToken: PropTypes.string,
}

const Login = flow(
  withTranslation('login'),
  withRouter,
  withStyles(styles)
)(LoginClass)

export { Login }
