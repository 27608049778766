import { makeEnumFromArray } from 'common/enum'

const PUBLIC_WEBSITE_URL = 'https://expertlead.com'
const AUTH_TOKEN_KEY = process.env.REACT_APP_AUTH_TOKEN_KEY

const IMAGE_HOST = process.env.REACT_APP_IMAGE_HOST

/**
 * Used as default debounce delay in async operations
 */
const DEBOUNCE_DELAY = 300

const { JV_HOMEPAGE } = process.env
const { EXPERTLEAD_HOMEPAGE } = process.env
const NullID = '00000000-0000-0000-0000-000000000000'

const FORM_FIELDS = {
  AUTOSUGGEST_CITIES: 'city',
  AUTOSUGGEST_COUNTRIES: 'country',
  AUTOSUGGEST_FREELANCE_MANAGER: 'owner',
  CHECKBOX_AVAILABLE_FOR_TRAVEL: 'availableForTravel',
  CHECKBOX_OPEN_TO_RELOCATION: 'openToRelocation',
  CUSTOM_AVATAR: 'avatar',
  CV_UPLOAD: 'cvUpload',
  CUSTOM_SPOKEN_LANGUAGES: 'spokenLanguages',
  CUSTOM_SKILLS: 'skills',
  TESTED_SKILLS: 'testedSkills',
  CUSTOM_EDUCATIONS: 'educations',
  CUSTOM_CERTIFICATIONS: 'certifications',
  CUSTOM_EXPERIENCES: 'experiences',
  DATEPICKER_AVAILABILITY: 'availabilityDate',
  RADIO_AVAILABILITY: 'availabilityFulltime',
  DROPDOWN_EMAIL: 'emailDropdown',
  DROPDOWN_FOCUS_ROLE: 'focusRole',
  DROPDOWN_WORK_PREFERENCES: 'workPreferences',
  DROPDOWN_WORK_SETUP: 'workSetups',
  TEXTFIELD_EMAIL: 'email',
  TEXTFIELD_PASSWORD: 'password',
  TEXTFIELD_FIRST_NAME: 'firstName',
  TEXTFIELD_LAST_NAME: 'lastName',
  TEXTFIELD_HEADLINE: 'headline',
  TEXTFIELD_NOTICE_PERIOD: 'noticePeriod',
  TEXTFIELD_ADDRESS: 'address',
  TEXTFIELD_POSTAL_CODE: 'postalCode',
  TEXTFIELD_NOTE: 'note',
  TEXTFIELD_WEBSITE: 'website',
  TEXTFIELD_MOBILE: 'mobile',
  RADIO_GENDER: 'gender',
  RADIO_SENIORITY_POOL: 'pool',
  SLIDER_AVAILABILITY_HOURS: 'availabilityHours',
  SWITCH_AVAILABILITY: 'availability',
  SWITCH_REMEMBER_ME: 'rememberMe',
  TEXTFIELD_SCORE: 'score',
  TEXTFIELD_GITHUB: 'github',
  TEXTFIELD_LINKEDIN: 'linkedin',
  TEXTFIELD_HUBSPOT: 'hubspotDealId',
  TEXTFIELD_BIO: 'bio',
  RICH_TEXTFIELD_RECRUITERS_INSIGHTS: 'recruitersInsights',
  DATEPICKER_DOB: 'dob',
  TEXTFIELD_TAX_ID: 'vat',
  TEXTFIELD_HOURLY_RATE: 'hourlyRateMin',
  TEXTFIELD_ANNUAL_SALARY: 'annualSalaryMin',
  TEXTFIELD_REFERRAL_CODE: 'referralCode',
  AUTOSUGGEST_LOCATION_VAT: 'locationVat',
  LOCATION: 'location',
  VAT: 'vat',
  TEXTFIELD_LOCATION: 'locations',
  DROPDOWN_EMPLOYMENT: 'employmentType',
  DROPDOWN_ASSIGNEE: 'assigneeFilter',
  TEXTFIELD_NAME: 'name',
  ONLY_VALID_WEBSITE: 'hasWebsiteUrl',
  DROPDOWN_HIREABLE: 'isHireable',
  TEXTFIELD_BIO_OR: 'bioOr',
  TEXTFIELD_BIO_AND: 'bioAnd',
  TEXTFIELD_NEW_EMAIL: 'newEmail',
  TEXTFIELD_CURRENT_EMAIL: 'currentEmail',
  HAS_EMAIL_ADDRESS: 'hasEmailAddress',
  HAS_LINKEDIN_URL: 'hasLinkedinUrl',
  HAS_NO_REFERENCE: 'hasNoReference',
  ASSIGNEES: 'assignees',
  KEYWORDS_SEARCH: 'keywords',
  TEXTFIELD_PREFERRED_RATE: 'hourlyRatePreferred',
  TEXTFIELD_PREFERRED_SALARY: 'annualSalaryPreferred',
}

const GENDERS = makeEnumFromArray(['MALE', 'FEMALE'])

const SENIORITY_POOLS = makeEnumFromArray(['EMERGING', 'SENIOR', 'PRINCIPAL'])

const INTERVIEWER_STATUSES = {
  NONE: 'P2PStatusUndefined',
  EM: 'P2PStatusExpertMatch',
  EV: 'P2PStatusExpertView',
  BOTH: 'P2PStatusBoth',
}

// TODO (Jirffey): consolidate similar values found in other modules
const AVAILABILITY_OPTIONS = makeEnumFromArray([
  'AVAILABILITY_UNDEFINED',
  'AVAILABILITY_AVAILABLE',
  'AVAILABILITY_NOT_AVAILABLE',
])

// TODO (Jirffey): remove duplicate in src/apps/portal/common/constants.js
const AVAILABILITY_HOURS_OPTIONS = {
  HOURS_STEP: 5,
  HOURS_LENGTH: 8,
  HOURS_MAX: 40,
}

// TODO (Jirffey): consolidate similar values found in other modules
const DEFAULT_DATE_FORMAT = 'dd-MM-yyyy'
const PLEASE_CHOOSE = 'Please choose'
const ANY = 'Any'

const SPOKEN_LANGUAGE_PROFICIENCIES = [
  'BEGINNER',
  'INTERMEDIATE',
  'ADVANCED',
  'NATIVE',
]

const PUBLIC_PROFILE_CERTIFICATION_FORMAT = 'MMM DD, yyyy'
const PROFILE_TYPES = {
  FREELANCER: 'TYPE_EXPERT',
  FULL_TIME_EMPLOYEE: 'TYPE_FULL_TIME_EMPLOYEE',
}

// TODO (Jirffey): consolidate similar values found in other modules
/**
 * Used as timeout ms to scroll elements when adding
 */
const TIME_TO_SCROLL_MS = 100

// TODO (Jirffey): consolidate similar values found in other modules
const TOP_SKILLS_LIMIT = 5
const SKILLS_LIMIT = 45
const TESTED_SKILLS_LIMIT = 3
const INDUSTRIES_LIMIT = 2

const ORGANIZATION_IDS = {
  EXPERTLEAD: process.env.REACT_APP_EXPERTLEAD_ORG_ID,
  JV: process.env.REACT_APP_JV_ORG_ID,
}

const DOMAINS = {
  PORTAL: {
    EXPERTLEAD: process.env.REACT_APP_PORTAL_EXPERTLEAD_DOMAIN,
    JV: process.env.REACT_APP_PORTAL_JV_DOMAIN,
  },
}

const GOOGLE_AUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID
const GOOGLE_AUTH_HOSTED_DOMAIN =
  process.env.REACT_APP_GOOGLE_AUTH_HOSTED_DOMAIN
const GOOGLE_AUTH_SCOPE = process.env.REACT_APP_GOOGLE_AUTH_SCOPE

const EMPTY_UUID = '00000000-0000-0000-0000-000000000000'

const COMMON_PAGES = {
  LOGIN: 'login',
}

const SERVICE_DESK_URL =
  'https://expertlead.atlassian.net/servicedesk/customer/portals'

const SERVICE_DESK_URL_INTERNAL =
  'https://expertlead.atlassian.net/servicedesk/customer/portal/3'

const SERVICE_DESK_URL_INTERVIEWER =
  'https://expertlead.atlassian.net/servicedesk/customer/portal/4'

const EMPLOYMENT_TYPE = {
  CONTRIBUTOR_TYPE_UNKNOWN: {
    label: 'Unknown',
    initials: '?',
  },
  CONTRIBUTOR_TYPE_FULL_TIME_EMPLOYEE: {
    label: 'Full-Timer',
    initials: 'FT',
  },
  CONTRIBUTOR_TYPE_FREELANCER: {
    label: 'Freelancer',
    initials: 'FL',
  },
}

const ASSIGNEE_TYPE = {
  FILTER_ALL: 'Filter All',
  FILTER_UNASSIGNED: 'Unassigned',
  FILTER_ASSIGNED_ME: 'Assigned to me',
}

const HIREABLE_TYPE = {
  HIREABLE_ALL: 'Any',
  HIREABLE_YES: 'Hireable',
  HIREABLE_NO: 'Not Hireable',
}

const EMPLOYMENT_TYPE_FILTER = {
  CONTRIBUTOR_TYPE_ALL: 'All',
  CONTRIBUTOR_TYPE_UNKNOWN: 'Unknown',
  CONTRIBUTOR_TYPE_FULL_TIME_EMPLOYEE: 'Full-time',
  CONTRIBUTOR_TYPE_FREELANCER: 'Freelancer',
}

const GITHUB_URL = 'https://github.com'
const HUBSPOT_URL = 'https://app.hubspot.com/contacts/5492847/deal'

// used for publishing/subscribing to event topics
const MESSAGE_TOPIC_NAMES = {
  UNAUTHORIZED: 'UNAUTHORIZED',
  ERROR: 'ERROR',
}

const HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID
const IS_HEATMAP_ACTIVE = process.env.REACT_APP_IS_HEATMAP_ACTIVE === 'true'

const PRIVILEGED_USERS = ['superAdmin', 'orgAdmin']
const PUBLIC_PROFILE_CONTACT_US = Object.freeze({
  emailToExpertlead: 'community@expertlead.de',
  emailToFuturepath: 'freelancers@futurepath.io',
  emailToExpertleadForPerm: 'sales@expertlead.de',
})
const PUBLIC_PROFILE_MINIMUM_RADAR_CHART_PLOT_COORDINATES = 5
const PUBLIC_PROFILE_MINIMUM_RADAR_CHART_THRESHOLD_VALUE = 6
const PUBLIC_PROFILE_MAXIMUM_RADAR_CHART_AXIS_VALUE = 10

const OPEN_TO_RELOCATION_TYPES = {
  NOT_OPEN_TO_RELOCATION: 'NOT_OPEN_TO_RELOCATION',
  OPEN_TO_RELOCATION: 'OPEN_TO_RELOCATION',
  NDEFINED_RELOCATION: 'NDEFINED_RELOCATION',
}

export {
  DEBOUNCE_DELAY,
  AUTH_TOKEN_KEY,
  PUBLIC_WEBSITE_URL,
  IMAGE_HOST,
  FORM_FIELDS,
  GENDERS,
  SENIORITY_POOLS,
  INTERVIEWER_STATUSES,
  AVAILABILITY_OPTIONS,
  AVAILABILITY_HOURS_OPTIONS,
  DEFAULT_DATE_FORMAT,
  SPOKEN_LANGUAGE_PROFICIENCIES,
  TIME_TO_SCROLL_MS,
  PLEASE_CHOOSE,
  TOP_SKILLS_LIMIT,
  SKILLS_LIMIT,
  TESTED_SKILLS_LIMIT,
  INDUSTRIES_LIMIT,
  ORGANIZATION_IDS,
  DOMAINS,
  PROFILE_TYPES,
  GOOGLE_AUTH_CLIENT_ID,
  GOOGLE_AUTH_HOSTED_DOMAIN,
  GOOGLE_AUTH_SCOPE,
  EMPTY_UUID,
  COMMON_PAGES,
  ANY,
  SERVICE_DESK_URL,
  SERVICE_DESK_URL_INTERNAL,
  SERVICE_DESK_URL_INTERVIEWER,
  EMPLOYMENT_TYPE,
  ASSIGNEE_TYPE,
  GITHUB_URL,
  HUBSPOT_URL,
  HIREABLE_TYPE,
  EMPLOYMENT_TYPE_FILTER,
  EXPERTLEAD_HOMEPAGE,
  JV_HOMEPAGE,
  NullID,
  MESSAGE_TOPIC_NAMES,
  HOTJAR_ID,
  IS_HEATMAP_ACTIVE,
  PRIVILEGED_USERS,
  PUBLIC_PROFILE_CERTIFICATION_FORMAT,
  PUBLIC_PROFILE_CONTACT_US,
  PUBLIC_PROFILE_MINIMUM_RADAR_CHART_PLOT_COORDINATES,
  PUBLIC_PROFILE_MINIMUM_RADAR_CHART_THRESHOLD_VALUE,
  PUBLIC_PROFILE_MAXIMUM_RADAR_CHART_AXIS_VALUE,
  OPEN_TO_RELOCATION_TYPES,
}
