import { shape, string } from 'prop-types'
import React, { useMemo } from 'react'

import property from 'lodash/property'
import format from 'date-fns/format'
import fromUnixTime from 'date-fns/fromUnixTime'
import isToday from 'date-fns/isToday'

import {
  Box,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material'

import { join } from 'apps/scorecard/helpers/string'
import { Card, InterviewPrompt } from 'apps/scorecard/components/widgets'

import { DATE_TIME_FORMAT } from './constants'
import withStyle from './style'

export const Interview = ({
  elements: { header, footer },
  candidate: { firstName, lastName, headLine, ...candidate },
  className,
  vertical,
  dateTime,
  dateTimeFormat,
  skill,
  endingTime,
  status,
}) => {
  const formattedDateTime = useMemo(
    () => format(fromUnixTime(dateTime), dateTimeFormat),
    [dateTime, dateTimeFormat]
  )

  const skills = useMemo(
    () => join(candidate.skills, property('name')),
    [candidate.skills]
  )

  const startsToday = isToday(new Date(formattedDateTime).getTime())

  return (
    <>
      <Card
        className={`${startsToday ? `${className} startsToday ` : className}`}
      >
        <CardHeader
          action={header}
          subheader={<SubHeader formattedDateTime={formattedDateTime} />}
        />
        <CardContent>
          <dl aria-roledescription='name'>
            <dt>Name</dt>
            <dd>
              {firstName} {lastName}
            </dd>
          </dl>
          {!!headLine && (
            <dl aria-roledescription='headline'>
              <dt>Headline</dt>
              <dd>{headLine}</dd>
            </dl>
          )}
          {!!vertical && (
            <dl aria-roledescription='position'>
              <dt>Position</dt>
              <dd>{vertical.name}</dd>
            </dl>
          )}
          {!!skills && (
            <dl aria-roledescription='stack'>
              <dt>Stack</dt>
              <dd>{skills}</dd>
            </dl>
          )}
          {!!skill && (
            <dl aria-roledescription='stack'>
              <dt>LC Stack</dt>
              <dd>{skill.name}</dd>
            </dl>
          )}
        </CardContent>
        <CardActions>{footer}</CardActions>
      </Card>
      <InterviewPrompt
        dateTime={dateTime}
        endingTime={endingTime}
        status={status}
      />
    </>
  )
}

const SubHeader = ({ formattedDateTime }) => (
  <Box display='flex' justifyContent='space-between' alignItems='center'>
    <Typography pt='2px' fontSize='0.7rem'>
      {formattedDateTime}
    </Typography>
  </Box>
)

Interview.propTypes = {
  className: string.isRequired,
  dateTimeFormat: string,
  candidate: shape({
    firstName: string.isRequired,
    lastName: string.isRequired,
  }).isRequired,
}

Interview.defaultProps = {
  dateTimeFormat: DATE_TIME_FORMAT,
}

export default withStyle(Interview)
