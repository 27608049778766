import React, { useState } from 'react'
import { Tooltip, IconButton, Divider } from '@mui/material'
import {
  AnnouncementOutlined,
  Announcement,
  ThumbUpAltOutlined,
  LiveHelpOutlined,
  FileCopyOutlined,
  BlockOutlined,
  EventNoteOutlined,
} from '@mui/icons-material'
import { Wrapper, FeedbackIcons } from './style'
import { FEEDBACK_MAP } from './constant'

const QuestionFeedback = ({ setFeedback, questionId }) => {
  const [isActive, setIsActive] = useState(false)

  const handleActiveClick = (event) => {
    event.preventDefault()
    setIsActive(!isActive)
  }

  const handleIconClick = (event, value) => {
    event.preventDefault()
    setIsActive(false)
    setFeedback({ feedback: value, questionId })
  }

  return (
    <Wrapper>
      <IconButton onClick={handleActiveClick} size='large'>
        <Tooltip title='Leave feedback'>
          {isActive ? <Announcement /> : <AnnouncementOutlined />}
        </Tooltip>
      </IconButton>
      {isActive && (
        <>
          <Divider orientation='vertical' flexItem />

          <FeedbackIcons>
            <IconButton
              onClick={(event) => {
                handleIconClick(event, FEEDBACK_MAP.notRelevant)
              }}
              size='large'
            >
              <Tooltip title='Not relevant'>
                <BlockOutlined />
              </Tooltip>
            </IconButton>
            <IconButton
              onClick={(event) => {
                handleIconClick(event, FEEDBACK_MAP.duplicated)
              }}
              size='large'
            >
              <Tooltip title='Duplicate'>
                <FileCopyOutlined />
              </Tooltip>
            </IconButton>
            <IconButton
              onClick={(event) => {
                handleIconClick(event, FEEDBACK_MAP.outdated)
              }}
              size='large'
            >
              <Tooltip title='Outdated'>
                <EventNoteOutlined />
              </Tooltip>
            </IconButton>
            <IconButton
              onClick={(event) => {
                handleIconClick(event, FEEDBACK_MAP.hintImprovement)
              }}
              size='large'
            >
              <Tooltip title='Improve hint'>
                <LiveHelpOutlined />
              </Tooltip>
            </IconButton>
            <IconButton
              onClick={(event) => {
                handleIconClick(event, FEEDBACK_MAP.good)
              }}
              size='large'
            >
              <Tooltip title='Good'>
                <ThumbUpAltOutlined />
              </Tooltip>
            </IconButton>
          </FeedbackIcons>
        </>
      )}
    </Wrapper>
  )
}

export default QuestionFeedback
