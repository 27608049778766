import isEqual from 'lodash/isEqual'
import React, { useCallback } from 'react'
import { useDrop } from 'react-dnd'

import { insertAndShift } from 'common/arrays'
import { Source } from 'components/DnD'

export default ({ type }) => {
  const [, ref] = useDrop({ accept: type })

  const use = useCallback(({ onFind, onSort }) => {
    const find = (target) =>
      onFind((collection) =>
        collection.reduce(
          (stack, details, index) =>
            !stack && isEqual(details, target) ? { details, index } : stack,
          null
        )
      )

    const sort = ({ from, to }) => {
      const { index: fromIndex } = find(from)

      return onSort((collection) =>
        insertAndShift(collection, { from: fromIndex, to })
      )
    }

    return { find, sort }
  }, [])
  const connect = useCallback(
    (settings) =>
      (render) =>
      (source, ...params) => {
        const { key, ...children } = render(source, ...params)
        const methods = use(settings)

        return (
          <Source key={key} type={type} source={source} {...methods}>
            {children}
          </Source>
        )
      },
    [use, type]
  )

  return useCallback(
    (settings) => ({ connect: connect(settings), ref }),
    [connect, ref]
  )
}
