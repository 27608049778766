import { useEffect, useState, useMemo } from 'react'

import { ONE_SEC, STATUS } from 'apps/scorecard-v2/constants'
import useScorecardDispatch from 'apps/scorecard-v2/hooks/useDispatch'
import useScorecardSelector from 'apps/scorecard-v2/hooks/useScorecardSelector'
import {
  getInterviewDetails,
  setIdleStatus,
  updateInterviewDetails,
} from 'apps/scorecard-v2/slices/interview-slice'
import { setModal } from 'apps/scorecard-v2/slices/modal-slice'
import { useAuth } from 'apps/scorecard/hooks'

import { INTERVIEW_GRADING } from '../modal-types'

import ModalDialog from './modal-dialog'
import { StyledSnackbar } from './style'

const InterviewModal = () => {
  const [tabExtraLabel, setTabExtraLabel] = useState({})
  const [currentTab, setCurrentTab] = useState('')
  const [snackbarMessage, setSnackbarMessage] = useState('')

  const dispatch = useScorecardDispatch()
  const { accessToken } = useAuth()

  const useSelector = useScorecardSelector()
  const { modalValue, interview } = useSelector((state) => ({
    modalValue: state.modal.value,
    interview: state.interview,
  }))

  const isOpen = modalValue.type === INTERVIEW_GRADING

  const tabNamesMap = useMemo(() => {
    const namesMap =
      interview?.value?.questions?.nodes?.reduce((acc, curr) => {
        acc[curr.title] = true
        return acc
      }, {}) || {}

    return namesMap
  }, [interview?.value?.questions?.nodes?.length, isOpen])

  useEffect(() => {
    if (interview?.value?.questions?.nodes) {
      const extraLabel = {}
      interview.value.questions.nodes.forEach(({ nodes, title }) => {
        const questionsLength = nodes.reduce((acc, curr) => {
          acc += curr.nodes?.length
          return acc
        }, 0)

        const answeredQuestionsCount = nodes.reduce((acc, curr) => {
          const count = curr.nodes.reduce((accumulator, currentValue) => {
            if (currentValue.score) {
              acc += 1
            }
            return accumulator
          }, 0)

          acc += count
          return acc
        }, 0)

        extraLabel[title] = {
          done: questionsLength && answeredQuestionsCount === questionsLength,
          label: `(${answeredQuestionsCount}/${questionsLength})`,
        }
      })

      setTabExtraLabel(extraLabel)
    }
  }, [isOpen, interview.value?.questions?.nodes])

  useEffect(() => {
    if (isOpen) {
      dispatch(
        getInterviewDetails({
          interviewId: modalValue.interviewId,
          accessToken,
        })
      )
    }
  }, [isOpen, modalValue.interviewId])

  useEffect(() => {
    if (interview?.value?.archiveStatus === STATUS.fulfilled) {
      window.dispatchEvent(new CustomEvent('interview:archived'))
      handleClose()
    }
  }, [interview?.value.archiveStatus])

  useEffect(() => {
    if (!isOpen || !interview?.value?.interview) {
      return
    }

    if (!interview.value.interview.startingTime) {
      const payload = { startingTime: Math.floor(Date.now() / 1000) }
      dispatch(
        updateInterviewDetails({
          accessToken,
          interviewId: modalValue.interviewId,
          payload,
        })
      )
    }
  }, [isOpen, modalValue.interviewId, interview.value])

  useEffect(() => {
    if (interview.value?.scoreStatus === STATUS.fulfilled) {
      setSnackbarMessage('Score saved')
    }

    if (interview.value?.questionFeedbackStatus === STATUS.fulfilled) {
      setSnackbarMessage('Feedback sent')
    }

    if (interview.value?.interviewEndingStatus === STATUS.fulfilled) {
      const interviewInfo = {
        endingTime: Math.floor(Date.now() / 1000),
        type: interview.value.interview.type,
      }

      window.dispatchEvent(
        new CustomEvent('interview:end', {
          detail: { status: 'success', interview: interviewInfo, valid: true },
        })
      )
      dispatch(setModal(''))
    }

    if (interview.value?.interviewEndingStatus === STATUS.rejected) {
      setSnackbarMessage('There was an error completing the interview')
    }
  }, [
    interview.value.scoreStatus,
    interview.value?.questionFeedbackStatus,
    interview.value?.interviewEndingStatus,
  ])

  const handleClose = () => {
    dispatch(setModal({ type: '' }))
  }

  const handleTabChange = (_, value) => {
    setCurrentTab(value)
  }

  const handleSnackbarClose = () => {
    setSnackbarMessage('')
    dispatch(
      setIdleStatus([
        'scoreStatus',
        'questionFeedbackStatus',
        'interviewEndingStatus',
      ])
    )
  }

  return (
    <>
      <ModalDialog
        handleClose={handleClose}
        isOpen={isOpen}
        interview={interview}
        currentTab={currentTab}
        handleTabChange={handleTabChange}
        tabExtraLabel={tabExtraLabel}
        accessToken={accessToken}
        tabNamesMap={tabNamesMap}
        setCurrentTab={setCurrentTab}
        dispatch={dispatch}
      />
      <StyledSnackbar
        open={!!snackbarMessage}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={ONE_SEC}
        onClose={handleSnackbarClose}
      />
    </>
  )
}

export default InterviewModal
