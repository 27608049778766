/* eslint-disable*/
import React, { useMemo, useCallback } from 'react'
import { Avatar, Button, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { buildImageURL } from 'common/image'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { ParagraphByWord } from 'components/ParagraphByWord'
import Certifications from './Certifications'
import { PROFILE_TYPES } from 'common/constants'
import { getTimeZoneByLatLong } from 'common/dates'
import momentTz from 'moment-timezone'
import moment from 'moment'
import timeZoneNames from 'common/timeZoneName.json'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import { ContactUsAsPerOrg } from './ContactUsAsPerOrg'
import { ProfileService } from 'sdk'

const useStyles = makeStyles((theme) => {
  const aboutWrapper = {
    width: '100%',
  }
  const profileImg = {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: `${theme.spacing(0)} ${theme.spacing(0.79)} ${theme.spacing(
      0.79
    )} rgba(0, 0, 0, 0.1)`,
    border: `${theme.spacing(0.25)} solid ${theme.palette.white}`,
  }
  const background = {
    backgroundColor: theme.palette.white,
    position: 'relative',
    borderRadius: theme.spacing(1),
  }
  return {
    [theme.breakpoints.down('md')]: {
      aboutWrapper: {
        ...aboutWrapper,
      },
      profileImg: {
        ...profileImg,
        width: theme.spacing(15),
        height: theme.spacing(15),
      },
      background: {
        ...background,
        boxShadow: `${theme.spacing(0)} ${theme.spacing(0.58)} ${theme.spacing(
          0.58
        )} rgba(0, 0, 0, 0.1)`,
      },
    },
    [theme.breakpoints.up('md')]: {
      aboutWrapper: {
        ...aboutWrapper,
        height: theme.spacing(25),
        position: 'absolute',
        top: theme.spacing(-14.375),
      },
      profileImg: {
        ...profileImg,
        width: theme.spacing(20),
        height: theme.spacing(20),
      },
      background: {
        ...background,
        boxShadow: `${theme.spacing(0)} ${theme.spacing(
          0.78596375
        )} ${theme.spacing(0.78596375)} rgba(0, 0, 0, 0.1)`,
      },
    },
    content: {
      marginTop: theme.spacing(-10),
      textAlign: 'center',
      padding: theme.spacing(3),

      '& > h4': {
        fontWeight: theme.typography.fontWeight.bold,
        color: theme.palette.black1,
      },
      '& > h6': {
        color: theme.palette.grey[600],
        fontWeight: theme.typography.fontWeight.strong,
        lineHeight: 1.6,
      },
    },
    headline: {
      marginBottom: theme.spacing(1),
    },

    alignCenter: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '& svg': {
        width: theme.spacing(2),
        height: theme.spacing(2),
        marginRight: theme.spacing(0.5),
      },
    },
    bio: {
      position: 'relative',
      '& h6': {
        color: theme.palette.grey[600],
        fontWeight: theme.typography.fontWeight.bold,
        textAlign: 'left',
        textTransform: 'uppercase',
        lineHeight: 2.5,
        marginTop: theme.spacing(2),
      },

      '& div': {
        textAlign: 'start',
        '& p': {
          textAlign: 'left',
          margin: 0,
        },
        '& a': {
          marginLeft: 0,
          position: 'absolute',
          bottom: theme.spacing(-3),
          left: 0,
          textDecoration: 'none',
        },
      },
      '& span': {
        textIndent: 0,
        display: 'inline-block',
        textAlign: 'initial',
        '&:after': {
          content: "'' !important",
        },
      },
    },
    languages: {
      fontFamily: theme.typography.fontFamily,
      marginTop: theme.spacing(5),
      '& > h6': {
        color: theme.palette.grey[600],
        fontWeight: theme.typography.fontWeight.bold,
        textAlign: 'left',
        textTransform: 'uppercase',
        lineHeight: 2.5,
      },
      '& span': {
        textIndent: 0,
        display: 'inline-block',
        textAlign: 'initial',
      },
      '& strong': {
        textTransform: 'capitalize',
        fontWeight: theme.typography.fontWeight.bold,
        marginRight: theme.spacing(0.4),
      },
      '& div': {
        textAlign: 'left',
        textTransform: 'lowercase',
        marginBottom: theme.spacing(0.5),
      },
    },
    nameWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      '& h4': {
        fontWeight: theme.typography.fontWeight.bolder,
      },

      '& h6': {
        background: theme.palette.lightGray,
        borderRadius: theme.spacing(0.5),
        margin: theme.spacing(1),
        padding: theme.spacing(0.3, 1),
        color: theme.palette.white,
      },
    },

    nameWrapperWithoutFlex: {
      display: 'block',
      '& h4': {
        fontWeight: theme.typography.fontWeight.bolder,
      },

      '& h6': {
        background: theme.palette.lightGray,
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(0.3, 1),
        color: theme.palette.white,
        width: 'fit-content',
        margin: theme.spacing(1, 'auto', 2),
      },
    },
    buttonForContactUs: {
      marginTop: theme.spacing(2),
      borderRadius: theme.spacing(4),
      color: 'white',
      textTransform: 'capitalize',
      background: theme.palette.primary.blue,
      '&:hover': {
        backgroundColor: theme.palette.primary.blue,
      },
      width: '100%',
    },
  }
})

function About(props) {
  const classes = useStyles()
  const {
    translate,
    photo,
    type,
    firstName,
    headline,
    spokenLanguages,
    location,
    bio,
    certifications,
    config,
    organizationId,
    profileId,
    gEvent,
    slugId,
    isPermProfile,
    match,
    notify,
  } = props
  const isFreelancer = type === PROFILE_TYPES.FREELANCER

  const getTimeZoneName = () => {
    const timeZone =
      location &&
      getTimeZoneByLatLong(location.coordinates.lat, location.coordinates.lon)

    const offset = useMemo(() =>
      moment.tz(moment().format('YYYY-MM-DD'), timeZone).format('Z')
    )
    const timeZoneAbbr = useMemo(() =>
      moment.tz(moment().format('YYYY-MM-DD'), timeZone).format('z')
    )

    const timeZoneName = useMemo(() =>
      timeZoneNames.filter(
        (timeZone) =>
          timeZone.abbreviation === timeZoneAbbr && timeZone.offset === offset
      )
    )
    return (timeZoneName.length >= 1 && timeZoneName[0].name) || ''
  }
  const timeZoneName = getTimeZoneName()
  const isLongName =
    (config.profileType && !!isFreelancer) || firstName?.length > 18

  const handleContactExpertlead = useCallback(async () => {
    const id = match.params && match.params.id
    try {
      ProfileService.contactExpertLead({}, { path: { id } })
      notify(translate('contact_success'))
    } catch (error) {
      console.error(error)
      notify(translate('contact_fail'))
    }
  }, [])

  return (
    <div className={classes.aboutWrapper}>
      <div className={classes.background}>
        <Avatar
          src={buildImageURL(photo)}
          className={classes.profileImg}
          alt={firstName}
          id='avatar'
        />
        <div className={classes.content}>
          <div
            className={
              isLongName ? classes.nameWrapperWithoutFlex : classes.nameWrapper
            }
          >
            <Typography variant='h4'>{firstName}</Typography>
            {(config.profileType || isPermProfile) && (
              <Typography variant='subtitle2'>
                {isFreelancer ? translate('freelancer') : translate('fullTime')}
              </Typography>
            )}
          </div>
          <Typography
            variant='subtitle1'
            id='headline'
            className={classes.headline}
          >
            {headline}
          </Typography>
          <Typography
            variant='subtitle1'
            className={classes.alignCenter}
            id='location'
          >
            <LocationOnIcon />

            {location && `${location.accent}, ${location.country.name}`}
          </Typography>
          {timeZoneName && (
            <Typography
              variant='subtitle1'
              className={classes.alignCenter}
              id='timeZoneName'
            >
              <WatchLaterIcon />
              {timeZoneName}
            </Typography>
          )}
          {!profileId && (
            <ContactUsAsPerOrg
              organizationId={organizationId}
              gEvent={gEvent}
              slugId={slugId}
              profileType={type}
            ></ContactUsAsPerOrg>
          )}
          {!!profileId && (
            <Button
              variant='contained'
              className={classes.buttonForContactUs}
              onClick={handleContactExpertlead}
            >
              {translate('contact_expertlead')}
            </Button>
          )}
          <div className={classes.bio}>
            <Typography variant='subtitle2'>{translate('about')}</Typography>
            <ParagraphByWord
              text={bio}
              limit={30}
              variant='body1'
              fontWeight='fontWeightRegular'
              component='div'
              slugId={slugId}
              type='bio'
              gEvent={gEvent}
            />
          </div>
          {spokenLanguages && spokenLanguages.length > 0 && (
            <div className={classes.languages}>
              <Typography variant='subtitle2'>
                {translate('languages')}
              </Typography>
              <div fontWeight='fontWeightLight' id='languagesWrapper'>
                {spokenLanguages &&
                  spokenLanguages.map((lang, idx) => (
                    <div key={lang + idx}>
                      <strong id='languages'>{lang.language.name}</strong>
                      <span> {` — ${lang.proficiency}`}</span>
                    </div>
                  ))}
              </div>
            </div>
          )}
          <Certifications
            translate={translate}
            certifications={certifications}
          />
        </div>
      </div>
    </div>
  )
}

export default About
