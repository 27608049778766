import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import { omit } from 'lodash'
import flow from 'lodash/flow'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import logo2 from 'apps/portal/assets/login_2.png'
import { Loading } from 'components'
import Layout from 'components/Layout'
import Redirect from 'components/Redirect'
import { AccountsService } from 'sdk/account/Service'
import { InvitationService } from 'sdk/invitation/Service'

import { PAGES } from '../../common/constants'
import { NonAuthenticatedNavBar } from '../../components'
import { authenticateUser } from '../login/Actions'

import { RegistrationForm } from './components/RegistrationForm'

const styles = (theme) => ({
  fullHeight: {
    height: '100%',
  },
  actionArea: {
    marginTop: theme.spacing(2),
  },
  container: {
    textAlign: 'center',
    alignItems: 'center',
    alignContent: 'center',
    paddingBottom: theme.spacing(2),
  },
  avatar: {
    width: '330px',
    marginBottom: theme.spacing(2),
  },
})

class Registration extends React.Component {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.componentDidMount = this.componentDidMount.bind(this)
    this.state = {
      redirectTo: '',
      showPassword: false,
      loading: true,
      invitation: {},
    }
  }

  async componentDidMount() {
    try {
      const res = await InvitationService.getInvitation(
        {},
        {
          path: {
            token: this.props.match.params.token,
          },
        }
      )
      this.setState({
        invitation: res.data,
        loading: false,
      })
    } catch (err) {
      // TODO: handle error, server and invalid token
      this.setState({
        loading: false,
      })
    }
  }

  async onSubmit(formData, { setStatus, setSubmitting }) {
    try {
      await AccountsService.addAccount({
        email: this.state.invitation.email,
        password: formData.password,
        invitationToken: this.state.invitation.token,
      })
      formData = omit(formData, 'name', 'passwordConfirmation')
      const res = await AccountsService.authenticate({
        ...formData,
        rememberMe: false,
      })
      this.setState({ loading: false, redirectTo: `/${PAGES.DASHBOARD}` })
      this.props.onAuthenticate(res.data)
    } catch (err) {
      setStatus(this.props.t(err.response.data.error))
    }
    setSubmitting(false)
  }

  render() {
    return (
      <Redirect to={this.state.redirectTo}>
        <Loading loading={this.state.loading}>
          <Layout
            navbarComponent={NonAuthenticatedNavBar}
            currentPage='Register'
          >
            <Grid container spacing={0}>
              <Grid item lg={5} md={4}>
                <Box
                  display={{
                    sm: 'none',
                    xs: 'none',
                    xl: 'block',
                    md: 'block',
                    lg: 'block',
                  }}
                  align='center'
                >
                  <img
                    src={logo2}
                    className={this.props.classes.avatar}
                    alt={this.props.t('registration_form_sub_title')}
                  />
                  <Typography component='h1' variant='h5'>
                    {this.props.t('registration_title')}
                  </Typography>
                  <Typography component='h5'>
                    {this.props.t('registration_sub_title')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={1}>
                <Box
                  align='center'
                  className={this.props.classes.fullHeight}
                  display={{
                    sm: 'none',
                    xs: 'none',
                    xl: 'block',
                    lg: 'block',
                    md: 'block',
                  }}
                >
                  <Divider variant='middle' orientation='vertical' />
                </Box>
              </Grid>
              <Grid item lg={6} sm={12} md={7} xs={12}>
                <Grid
                  container
                  className={this.props.classes.fullHeight}
                  direction='column'
                  alignItems='center'
                  justifyContent='center'
                  spacing={0}
                >
                  <Grid
                    item
                    lg={9}
                    sm={12}
                    xs={12}
                    className={this.props.classes.formContainer}
                  >
                    <Typography component='h1' variant='h5' gutterBottom>
                      {this.props.t('registration_form_title')}
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                      {this.props.t('registration_form_sub_title')}
                    </Typography>
                    <RegistrationForm
                      name={`${this.state.invitation.firstName} ${this.state.invitation.lastName}`}
                      email={this.state.invitation.email}
                      onSubmit={this.onSubmit}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Layout>
        </Loading>
      </Redirect>
    )
  }
}

const mapDispatchToProps = (dispatch, _) => ({
  onAuthenticate: (auth) => dispatch(authenticateUser(auth)),
})

const registration = flow(
  withTranslation('registration'),
  withRouter,
  withStyles(styles),
  connect(null, mapDispatchToProps)
)(Registration)

export { registration as Registration }
