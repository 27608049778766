import { func, string } from 'prop-types'
import React from 'react'

import { Button } from 'apps/scorecard/components/widgets'

import withStyle from './style'

export const Error = ({ className, message, retry }) => (
  <div className={className}>
    {!!message && <p>{message}</p>}
    {!!retry && <Button onClick={retry}>Retry</Button>}
  </div>
)

Error.propTypes = {
  className: string.isRequired,
  message: string.isRequired,
  retry: func,
}

Error.defaultProps = {}

export default withStyle(Error)
