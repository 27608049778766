import property from 'lodash/property'
import styled from 'styled-components'

import icon from 'apps/scorecard/assets/img/link-icon.png'

export default (component) => styled(component)`
  a {
    ${property('theme.typography.body2')};
    align-items: center;
    color: inherit;
    display: flex;
    text-decoration: inherit;

    &.active {
      font-weight: ${property('theme.typography.fontWeightBold')};
    }

    &:not([aria-current]):after {
      background: center url(${icon}) no-repeat;
      background-size: cover;
      content: '';
      display: ${({ external }) => (external ? 'inline-block' : 'none')};
      height: 18px;
      margin-left: ${({ theme: { spacing } }) => spacing(1)};
      width: 18px;
    }
  }
`
