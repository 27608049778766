import makeStyles from '@mui/styles/makeStyles'

const styles = (theme) => {
  const cancelBtn = {
    background: '#FFFFFF',
    opacity: '0.4',
    fontWeight: 700,
    color: '#2B254F',
    boxShadow: `0px 5px 5px rgba(0, 0, 0, 0.02)`,
    border: `1px solid rgba(43, 37, 79, 0.16)`,

    '&:hover': {
      border: `1px solid ${theme.palette.secondary.blue}`,
    },
  }
  const submitBtn = {
    background: '#3C26DE',
  }

  const titleStyle = {
    fontSize: theme.spacing(3),
    lineHeight: '28px',
    fontWeight: 700,
    paddingTop: 0,
  }
  return {
    '@global': {
      body: {
        backgroundColor: theme.palette.lightGray7,
        color: theme.palette.secondary.blue,
        boxSizing: 'border-box',

        '& div div.MuiChip-root': {
          margin: theme.spacing(0.5),
        },

        '& #root > a:first-child*': {
          display: 'none',
        },
      },
      button: {
        textTransform: 'none',
      },
    },
    subTitle: {
      fontSize: theme.typography.header.fontSize,
      fontWeight: theme.typography.fontWeight.bolder,
      color: theme.palette.secondary.blue,
      '& + p': {
        marginTop: theme.spacing(1),
      },
    },
    header: {
      padding: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3, 9),
      },

      '& h6': {
        color: theme.palette.primary.blue,
        marginTop: theme.spacing(3),
      },
    },
    title: {
      display: 'inline-block',
      width: '80%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        border: '1px solid rgba(43, 37, 79, 0.16)',
        borderRight: 0,
        borderLeft: 0,
      },
    },

    main: {
      border: '1px solid rgba(43, 37, 79, 0.16)',
      boxShadow: `0px 5px 5px ${theme.palette.secondary.gray}`,
      [theme.breakpoints.up('md')]: {
        borderRadius: theme.spacing(2),
      },
    },

    wrapper: {
      display: 'flex',
      flexFlow: 'row wrap',
      backgroundColor: theme.palette.white,
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(8),
        border: '1px solid #fff',
        borderRadius: theme.spacing(2, 2, 0, 0),
      },
    },

    container: {
      position: 'relative',
      display: 'flex',
      flexFlow: 'column',
      flex: '3 0',
    },

    sidebar: {
      flex: 1,
    },
    sideSection: {
      backgroundColor: '#F1F3F6',
      padding: theme.spacing(1),
      display: 'inline-block',

      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2, 2, 2, 0),
        border: '1px solid #2B254F29',
        borderRadius: theme.spacing(2),
        boxShadow: `0px 5px 5px 0px #00000005`,
      },

      '& li': {
        alignItems: 'flex-start',

        '& > div': {
          minWidth: theme.spacing(3),
        },

        [theme.breakpoints.down('md')]: {
          padding: 0,
          '& > div': {
            margin: theme.spacing(1.2, 0.3),
          },
        },

        '& .MuiTypography-body1': {
          color: '#A2A1B4',
          fontWeight: 500,
        },

        '& .MuiTypography-body2': {
          color: 'black',
          fontWeight: 500,
        },
      },
      '& img': {
        objectFit: 'contain',
        width: theme.spacing(3),
        height: theme.spacing(3),
      },
    },
    details: {
      display: 'inline-block',
      width: '80%',
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2),
      },
    },
    footerAction: {
      background: theme.palette.backgroundColor,
      borderRadius: theme.spacing(0, 0, 2, 2),
      border: '1px solid rgba(43, 37, 79, 0.16)',
      display: 'flex',
      padding: theme.spacing(3, 8),
      borderRight: 0,
      borderLeft: 0,
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(3),
        justifyContent: 'space-around',
        textAlign: 'center',
      },
    },
    sideButtons: {
      display: 'flex',
      gap: theme.spacing(2),

      [theme.breakpoints.down('md')]: {
        '& > div:first-child': {
          width: '100%',

          '& > button:first-child': {
            width: '100%',
          },
        },
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    sideButtonsDown: {
      display: 'flex',
      gap: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        position: 'fixed',
        bottom: 0,
        background: 'red',
        width: '100%',
        justifyContent: 'space-evenly',
        padding: theme.spacing(2),
        border: '1px solid rgba(43, 37, 79, 0.16)',
        borderRight: 0,
        borderLeft: 0,
        background: theme.palette.backgroundColor,
      },
    },
    notInterestedBtn: {
      fontWeight: 700,
      color: '#2B254F',
      opacity: 0.4,
      border: '1px solid #2b254f29',
      borderRadius: '4px',
      textTransform: 'none',
      boxShadow: 'none',
      background: `#fff`,
    },

    referAFriend: {
      background: '#FFFFFF',
      border: '1px solid #2b254f29',
      borderRadius: '4px',
      textTransform: 'none',
    },
    elLogo: {
      height: theme.spacing(4),
    },

    dialogWrapperReferAFriend: {
      '& .MuiDialog-container > div': {
        display: 'flex',
        padding: theme.spacing(4, 1, 1),
        position: 'relative',
        borderRadius: theme.spacing(2),
        width: '416px',
        [theme.breakpoints.down('md')]: {
          width: '100%',
          margin: theme.spacing(1.5),
        },
        '& > div:first-of-type': {
          paddingTop: theme.spacing(1),
          [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0, 1),
          },
        },

        '& h2 > button': {
          position: 'absolute',
          top: 0,
          right: 0,
        },
        '& button:last-child, button:nth-last-child(2)': {
          padding: theme.spacing(1.5),
        },
        '&  button:first-child': { ...cancelBtn, textTransform: 'none' },

        '&  button:last-child': { ...submitBtn, textTransform: 'none' },
      },
    },

    dialogWrapperNotInterested: {
      '& .MuiDialog-container > div': {
        display: 'flex',
        padding: theme.spacing(4, 2),
        position: 'relative',
        width: '416px',
        borderRadius: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
          width: '100%',
        },
        '& > div:first-of-type': {
          padding: theme.spacing(1, 2, 0, 2),

          '& form > div:last-child': {
            '&  button:first-child': {
              ...cancelBtn,
              textTransform: 'none',
              padding: theme.spacing(2),
            },

            '&  button:last-child': {
              ...submitBtn,
              textTransform: 'none',
              padding: theme.spacing(2),
            },
          },
        },
        '& h2 > button': {
          position: 'absolute',
          top: 0,
          right: 0,
        },
      },
    },
    dialogActionNotInterested: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 2),

      '& button': {
        padding: theme.spacing(2),
        textTransform: 'none',
        borderRadius: theme.spacing(0.5),
      },
      '&  button:first-child': { ...cancelBtn },

      '&  button:last-child': { ...submitBtn },
    },

    dialogTitleStyle: {
      ...titleStyle,
    },
    secondDialogTitleStyle: {
      ...titleStyle,
      marginTop: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
    dialogWrapper: {
      '& .MuiDialog-container > div': {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(5, 2),
        position: 'relative',
        borderRadius: theme.spacing(2),
        width: '465px',
        [theme.breakpoints.down('md')]: {
          width: '100%',
        },
        '& > :nth-child(4)': {
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            gap: theme.spacing(2),
          },
          '& button': {
            padding: theme.spacing(2, 5),
            textTransform: 'none',
            borderRadius: theme.spacing(0.5),
          },
          '&  button:first-child': { ...submitBtn },
          '&  button:last-child': {
            ...cancelBtn,
            color: '#3C26DE',
            opacity: 1,
            '&:hover': {
              border: `1px solid #3C26DE`,
            },
            [theme.breakpoints.down('md')]: {
              width: '100%',
              margin: 0,
            },
          },
        },
        '& h2 > button': {
          position: 'absolute',
          top: 0,
          right: 0,
        },
        '& p': {
          textAlign: 'center',
          color: '#2B254F',
        },
      },
    },
    dialogAction: {
      display: 'flex',
      justifyContent: 'space-evenly',
      width: '100%',
    },
  }
}

const useStyles = makeStyles(styles)

export default useStyles
