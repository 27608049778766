import flow from 'lodash/flow'
import { func, node } from 'prop-types'
import React, { useCallback } from 'react'
import { MenuItem } from '@mui/material'

export const Item = ({ onClick: click, onClose: close, children }) => {
  const onClick = useCallback(flow(click, close), [click, close])

  return <MenuItem onClick={onClick}>{children}</MenuItem>
}

Item.propTypes = {
  onClick: func.isRequired,
  onClose: func.isRequired,
  children: node,
}

Item.defaultProps = {}

export default Item
