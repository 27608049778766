import HelpIcon from '@mui/icons-material/Help'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormHelperText from '@mui/material/FormHelperText'
import FormLabel from '@mui/material/FormLabel'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'

import { TextField } from 'components'

import { PasswordInput } from './PasswordInput'

const privacyPolicyURL = process.env.REACT_APP_PRIVACY_POLICY_URL

const styles = (theme) => ({
  actionArea: {
    marginTop: theme.spacing(2),
  },
  toc: {
    margin: '0 3px',
    color: '#666',
    textDecoration: 'underline',
  },
  toolTip: {
    display: 'absolute',
    float: 'right',
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(-4),
    cursor: 'pointer',
    paddingLeft: theme.spacing(1),
  },
  passwordContainer: {
    display: 'flex',
  },
})

class RegistrationForm extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  render() {
    const { classes } = this.props
    return (
      <Formik
        initialValues={{
          name: this.props.name,
          email: this.props.email,
          password: '',
          passwordConfirmation: '',
        }}
        validate={(values) => {
          const err = {}
          if (values.name.length < 2) {
            err.name = true
          }
          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            err.email = true
          }
          if (values.password.length < 6) {
            err.password = true
          }
          if (values.password !== values.passwordConfirmation) {
            err.passwordConfirmation = this.props.t('password_need_to_match')
          }

          return err
        }}
        onSubmit={this.props.onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          status,
          isSubmitting,
        }) => (
          <form
            action='/auth/register'
            method='post'
            name=''
            onSubmit={handleSubmit}
          >
            {!!status && <FormLabel error>{status}</FormLabel>}
            <TextField
              name='name'
              label={this.props.t('name')}
              onBlur={handleBlur}
              onChange={handleChange}
              error={errors.name && touched.name}
              disabled={isSubmitting}
              value={values.name}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              name='email'
              type='email'
              label={this.props.t('email')}
              onBlur={handleBlur}
              onChange={handleChange}
              error={errors.email && touched.email}
              value={values.email}
              disabled={isSubmitting}
              InputProps={{
                readOnly: true,
              }}
            />
            <React.Fragment className={classes.passwordContainer}>
              <PasswordInput
                id='password'
                name='password'
                error={errors.password}
                value={values.password}
                touched={touched.password}
                handleChange={handleChange}
              />
              <Tooltip
                className={classes.toolTip}
                title={this.props.t('password_tooltip')}
              >
                <HelpIcon />
              </Tooltip>
            </React.Fragment>
            <PasswordInput
              id='passwordConfirmation'
              name='password_confirmation'
              error={errors.passwordConfirmation}
              value={values.passwordConfirmation}
              touched={touched.passwordConfirmation}
              handleChange={handleChange}
            >
              <FormHelperText error>
                {errors.passwordConfirmation}
              </FormHelperText>
            </PasswordInput>
            <Box>
              <Typography variant='caption' color='inherit' display='inline'>
                By creating an account, you agree to expertlead's
                <Link
                  href={privacyPolicyURL}
                  target='_blank'
                  className={classes.toc}
                  rel='noopener'
                  underline='hover'
                >
                  User Agreement
                </Link>
                and
                <Link
                  className={classes.toc}
                  href='https://www.expertlead.com/privacy-policy'
                  target='_blank'
                  rel='noopener'
                  underline='hover'
                >
                  Privacy Policy
                </Link>
              </Typography>
            </Box>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              size='large'
              disabled={isSubmitting}
              className={classes.actionArea}
            >
              {this.props.t('submit_button')}
            </Button>
          </form>
        )}
      </Formik>
    )
  }
}

const tregistration = withTranslation('registration_form')(RegistrationForm)
const sregistration = withStyles(styles)(tregistration)

export { sregistration as RegistrationForm }
