import PropTypes from 'prop-types'
import React from 'react'
import { Redirect as ReactRedirect } from 'react-router-dom'

export default class Redirect extends React.Component {
  static propTypes = {
    to: PropTypes.string,
  }

  render() {
    const prefix = this.props.to && this.props.to.substring(0, 7)
    return (
      (this.props.to &&
        prefix === 'http://' &&
        typeof window !== 'undefined' &&
        window.location.assign(this.props.to)) ||
      (this.props.to && prefix !== 'http://' && (
        <ReactRedirect to={this.props.to} />
      )) ||
      this.props.children
    )
  }
}
