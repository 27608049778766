import { GENERIC_QUESTIONS } from 'apps/scorecard-v2/constants'

import CommonTab from '../common/tab'

const GenericQuestions = ({
  items,
  accessToken,
  interview,
  toggleTabKeyDisable,
}) => (
  <CommonTab
    items={items}
    accessToken={accessToken}
    interview={interview}
    tabTitle={GENERIC_QUESTIONS}
    toggleTabKeyDisable={toggleTabKeyDisable}
  />
)

export default GenericQuestions
