import { sum } from 'apps/scorecard/helpers/collection'

export const align = ({ calculate, elements, reference }) => {
  const scroll = ({ element, parent = getParentNode(element) }) =>
    parent.scrollTo({
      top: calculate({
        elements: { element, parent, reference },
        helpers: { getScrollTop, getTop, sum },
      }),
      behavior: 'smooth',
    })

  return elements.map(scroll)
}

export const getNode = ({ current: node }) => node

export const getParentNode = (element) => getNode(element).parentNode

export const getScrollTop = (element) =>
  element.scrollY || element.scrollTop || 0

export const getTop = (element) => getNode(element).getBoundingClientRect().top
