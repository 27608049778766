import noop from 'lodash/noop'
import { bool, func, node, string } from 'prop-types'
import React, { Fragment, useMemo } from 'react'
import { Tooltip } from '@mui/material'

import withStyle from './style'

export const Score = ({ className, done, label, value, tooltip, ...props }) => {
  const id = useMemo(() => JSON.stringify({ score: value }), [value])

  return (
    <div className={className}>
      <input id={id} name='level' type='radio' {...props} />
      <Tooltip title={tooltip} placement='top' arrow>
        <label htmlFor={id}>
          {!value ? (
            <strong>{label}</strong>
          ) : (
            <>
              <strong>{value}</strong>
              <span> (</span>
              <em>{label}</em>
              <span>)</span>
            </>
          )}
        </label>
      </Tooltip>
    </div>
  )
}

Score.propTypes = {
  className: string.isRequired,
  label: node,
  checked: bool,
  done: bool,
  onChange: func,
  disabled: bool,
  tooltip: string,
}

Score.defaultProps = {
  checked: false,
  disabled: false,
  done: false,
  onChange: noop,
  tooltip: '',
}

export default withStyle(Score)
