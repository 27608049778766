import moment from 'moment'
import { array, object, string, date, ref, lazy } from 'yup'

const getEducationsValidation = ({
  schoolErrorMessage,
  locationErrorMessage,
  degreeErrorMessage,
  startDateInvalidErrorMessage,
  startDateAfterEndDateErrorMessage,
  endDateInvalidErrorMessage,
  endDateBeforeStartDateErrorMessage,
}) =>
  array().of(
    object({
      school: lazy((value) => {
        switch (typeof value) {
          case 'object':
            return object()
              .shape({
                id: string().required(schoolErrorMessage),
              })
              .required(schoolErrorMessage)
          default:
            return string().required(schoolErrorMessage)
        }
      }),

      location: object()
        .shape({
          id: string().required(locationErrorMessage),
        })
        .required(locationErrorMessage),
      degree: string().required(degreeErrorMessage),
      startDate: date()
        .required(startDateInvalidErrorMessage)
        .typeError(startDateInvalidErrorMessage)
        .when('endDate', (endDate, schema) => {
          if (endDate) {
            const endDateParsed = moment(endDate)

            /**
             * this custom date parsing is implemented because:
             * 1. start date should be before the end date (if end date exists and is valid)
             * 2. start date should always be before the current date
             *
             * as far as i know, directly calling the param endDate's `isValid()`
             * method (as part of `Yup` schema) doesn't work since it's nullable. therefore it will always
             * evaluate to true regardless of the input. that's why we had to use
             * `moment` to add custom date parsing
             */
            if (endDateParsed.isValid() && endDateParsed.isBefore(new Date())) {
              return schema.max(
                ref('endDate'),
                startDateAfterEndDateErrorMessage
              )
            }
          }

          return schema.max(new Date(), startDateInvalidErrorMessage)
        }),
      endDate: date()
        .nullable()
        .min(ref('startDate'), endDateBeforeStartDateErrorMessage)
        .typeError(endDateInvalidErrorMessage)
        .default(null),
    })
  )

export { getEducationsValidation }
