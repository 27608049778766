import { node, string } from 'prop-types'
import React, { forwardRef } from 'react'

import withStyle from './style'

export const Section = forwardRef((props, ref) => (
  <section ref={ref} {...props} />
))

Section.propTypes = {
  className: string.isRequired,
  children: node,
}

Section.defaultProps = {}

export default withStyle(Section)
