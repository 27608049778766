import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { map } from 'lodash'
import { string, bool, func, array } from 'prop-types'
import React from 'react'

// TODO: convert into a generic radio field
const AvailabilityRadioField = ({
  value,
  onChange,
  disabled,
  radioOptions,
  label,
}) => (
  <FormControl component='fieldset' disabled={disabled}>
    <FormLabel component='legend'>{label}</FormLabel>
    <RadioGroup
      aria-label='availability'
      name='availability'
      value={value}
      onChange={(e) => onChange(e.target.value)}
    >
      {map(radioOptions, ({ value: optionValue, label: optionLabel }) => (
        <FormControlLabel
          label={optionLabel}
          value={optionValue}
          key={optionValue}
          control={<Radio color='primary' />}
          labelPlacement='end'
        />
      ))}
    </RadioGroup>
  </FormControl>
)

AvailabilityRadioField.defaultProps = {
  value: '',
}

AvailabilityRadioField.propTypes = {
  value: string,
  onChange: func,
  disabled: bool,
  radioOptions: array,
}

export { AvailabilityRadioField }
