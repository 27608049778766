import React, { useEffect } from 'react'
import { bool } from 'prop-types'
import {
  DialogContent,
  IconButton,
  DialogContentText,
  Dialog,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Highlighted } from 'apps/scorecard/components/widgets/buttons'
import { StyledDialogTitle, StyledDialogActions } from './style'

const CounterPopup = ({ open }) => {
  const handleClose = () => {
    window.close()
  }

  useEffect(() => {}, [])

  return (
    <Dialog open={open} onClose={handleClose} size='sm' fullWidth>
      <StyledDialogTitle>
        <span>30 minutes have passed since interview.</span>
        <IconButton onClick={handleClose} size='large'>
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <DialogContent>
        <DialogContentText>
          Please get in touch if you need to make changes.
        </DialogContentText>
      </DialogContent>
      <StyledDialogActions>
        <Highlighted onClick={handleClose}>Ok</Highlighted>
      </StyledDialogActions>
    </Dialog>
  )
}

CounterPopup.propTypes = {
  open: bool.isRequired,
}
export default CounterPopup
