import { truncate, isEmpty, isObject, reduce, get } from 'lodash'
import moment from 'moment'
import normalizeUrl from 'normalize-url'

import { ORGANIZATION_IDS, DOMAINS, HUBSPOT_URL } from 'common/constants'

const capitalize = (name) =>
  (name && name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()) || ''

// TODO: (Dio): Accept different types of plurals, right now only accept adding s
const pluralize = (word) => (count) =>
  count !== 1 && count !== 0 ? `${word}s` : word

const isValidUrl = (string) => {
  try {
    const url = URL(string)
    url()
    return true
  } catch (_) {
    return false
  }
}

const omitEmptyValues = (obj) =>
  reduce(
    obj,
    (acc, value, key) => {
      // check if value is falsy
      if (!value || (isObject(value) && isEmpty(value))) return acc

      return {
        ...acc,
        [key]: value,
      }
    },
    {}
  )

// TODO: there must be a better name for this function. kindly suggest if you can think of any
// adds 's to provided name, e.g. Dave -> Dave's, Dries -> Dries'
const ownerize = (name) => {
  const lastChar = name.charAt(name.length - 1)
  const sChar = 's'

  return `${name}'${lastChar.toLowerCase() === sChar ? '' : sChar}`
}

const maxString = (value, maxLength, omission = '') =>
  maxLength
    ? truncate(value, {
        length: maxLength,
        omission,
      })
    : value

const normalizeWebsiteUrl = (url) => {
  try {
    return normalizeUrl(url)
  } catch (e) {
    return ''
  }
}

const getOrgPublicProfileUrl = (id) => {
  const urlOrgIdMap = {
    [ORGANIZATION_IDS.EXPERTLEAD]: DOMAINS.PORTAL.EXPERTLEAD,
    [ORGANIZATION_IDS.JV]: DOMAINS.PORTAL.JV,
  }
  const protocol = get(window, 'location.protocol', 'https:')
  return `${protocol}//${urlOrgIdMap[id]}/expert`
}

// Converts object values to number from string and return new object
const convertStringToNumber = (obj) =>
  Object.keys(obj).reduce(
    (result, key) => ({ ...result, [key]: +obj[key] }),
    {}
  )

const getMailToLink = (email) => `mailto:${email || ''}`

const getHubspotDealUrl = (dealId) => `${HUBSPOT_URL}/${dealId}`
const getHubspotContactUrl = (dealId) =>
  `${HUBSPOT_URL}/${dealId}?interaction=email`

const getRecruitcrmUrl = (candidateId) =>
  `https://app.recruitcrm.io/candidate/${candidateId}`

const dateFormatter =
  (format) =>
  ({ shouldCapitalize = false } = {}) =>
  (date) => {
    const ret = moment(date).format(format)
    return shouldCapitalize ? ret.toUpperCase() : ret
  }

const gEventWithValue = (eventParams, value) => ({
  ...eventParams,
  value,
})

const maskEmail = (emailId) => {
  const match = emailId.match(
    /@([a-z]|[0-9]|_|\.|-)+\.([a-z]|[0-9]|_|\.|-){2,10}/
  )
  const firstLetter = emailId.slice(0, 1)
  return match ? `${firstLetter}*****${match[0]}` : emailId
}

export {
  capitalize,
  isValidUrl,
  pluralize,
  ownerize,
  omitEmptyValues,
  maxString,
  normalizeWebsiteUrl,
  getOrgPublicProfileUrl,
  convertStringToNumber,
  getMailToLink,
  getHubspotDealUrl,
  getHubspotContactUrl,
  dateFormatter,
  gEventWithValue,
  maskEmail,
  getRecruitcrmUrl,
}
