import identity from 'lodash/identity'
import isEqual from 'lodash/isEqual'

export default (collection = [], identify = identity) => {
  const concat = (stack, item, index, { length }) => {
    const identifier = identify(item)
    const first = !index
    const last = isEqual(index, length - 1)
    const join = () => {
      switch (true) {
        case first:
          return ''
        case last:
          return ' & '
        default:
          return ', '
      }
    }

    return stack.concat(join()).concat(identifier)
  }

  return collection.reduce(concat, '')
}
