/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'
import { CategoryService } from 'sdk/category/Service'
import { Autosuggest } from 'components/Autosuggest'

const buildLabel = (skill) => get(skill, 'name', '')

class SkillAutosuggest extends React.Component {
  static propTypes = {
    skillIds: PropTypes.array,
  }

  onSelect = (skill) => {
    if (isEmpty(skill)) return
    this.props.onChange(skill)
    return buildLabel(skill)
  }

  async getData(inp) {
    const res = await CategoryService.QueryCategories({ name: inp })
    return (res.data.data || []).map((x) => ({
      label: buildLabel(x),
      value: x,
    }))
  }

  getOptionDisabled = ({ value: { id } }) => this.props.skillIds.includes(id)

  render() {
    return (
      <Autosuggest
        async
        id={this.props.id || ''}
        onSelect={this.onSelect}
        defaultValue={buildLabel(this.props.value)}
        getOptionDisabled={this.getOptionDisabled}
        service={this.getData}
        clearAfterSelection
        {...this.props}
      />
    )
  }
}

export { SkillAutosuggest }
