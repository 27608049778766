/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import flow from 'lodash/flow'

import { withTranslation } from 'react-i18next'
import { capitalize } from 'common/utils'
import withStyles from '@mui/styles/withStyles'
import { Paragraph } from '../../../../../components/Paragraph'
import { buildImageURL } from 'common/image'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'

const styles = (theme) => ({
  avatar: {
    width: '100%',
    borderRadius: '0px',
    height: '100%',
  },
  greyFont: {
    color: theme.palette.gray,
  },
})

class AboutMeView extends React.Component {
  static propTypes = {
    photo: PropTypes.object,
    title: PropTypes.string,
    bio: PropTypes.string,
    spokenLanguages: PropTypes.arrayOf(PropTypes.object),
  }

  render() {
    const { t } = this.props
    const getName = () => `${this.props.firstName} ${this.props.lastName}`
    const getFullLocationName = () =>
      this.props.location
        ? `${capitalize(this.props.location.name)}, ${
            this.props.location.country.name
          }`
        : ''

    return (
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        spacing={2}
      >
        <Grid item xs={3}>
          <Grid
            container
            direction='column'
            justifyContent='flex-start'
            alignItems='flex-start'
            spacing={3}
          >
            <Grid item>
              <Avatar
                className={this.props.classes.avatar}
                src={this.props.photo && buildImageURL(this.props.photo)}
              />
            </Grid>
            <Grid item>
              <Typography variant='h5' fontWeight='fontWeightBold'>
                {getName()}
              </Typography>
              <Typography variant='h6' variant='subtitle1'>
                {this.props.headline}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              direction='column'
              justifyContent='flex-start'
              alignItems='flex-start'
              spacing={4}
            >
              <Grid item>
                <Typography variant='subtitle1'>
                  <Box
                    fontWeight='fontWeightRegular'
                    className={this.props.classes.greyFont}
                  >
                    {t('locations')}
                  </Box>
                </Typography>
                <Typography variant='subtitle2'>
                  <Box fontWeight='fontWeightBold'>{getFullLocationName()}</Box>
                </Typography>
              </Grid>
              {!!this.props.spokenLanguages && (
                <Grid item>
                  <Typography variant='subtitle1'>
                    <Box
                      fontWeight='fontWeightRegular'
                      className={this.props.classes.greyFont}
                    >
                      {t('languages')}
                    </Box>
                  </Typography>
                  {this.props.spokenLanguages.map((lang) => (
                    <Grid item xs={12} key={JSON.stringify(lang)}>
                      <Typography variant='subtitle2'>
                        <Box fontWeight='fontWeightLight' id='languagesWrapper'>
                          <strong id='languages'>{lang.language.name}</strong> -{' '}
                          {capitalize(lang.proficiency)}
                        </Box>
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={9}>
          <Grid
            container
            direction='column'
            justifyContent='flex-start'
            alignItems='flex-start'
            spacing={3}
          >
            <Grid item xs={12}>
              <Typography variant='h6'>
                {t('biography')}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Paragraph
                text={this.props.bio}
                limit={150}
                variant='subtitle1'
                fontWeight='fontWeightRegular'
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export const AboutMe = flow(
  withTranslation('about_me'),
  withStyles(styles)
)(AboutMeView)
