import { useCallback, useRef, useState } from 'react'

import { useAsync } from 'apps/scorecard/hooks'
import {
  useGetFileUpload,
  useSetFileUpload,
} from 'apps/scorecard/hooks/services/filesystem'

import { getCSSTransformValuesFrom, promisify } from './helpers'
import {
  getInitialState as getInitialStateFrom,
  setCrop,
  setDistortion,
  setError,
  setSource,
} from './reducers'

export const useUpload = ({
  modal: { close, ...modal },
  bucket,
  onUpload,
  ...settings
}) => {
  const ref = useRef()
  const [state, setState] = useState(getInitialStateFrom(settings))
  const getFileUpload = useGetFileUpload()
  const setFileUpload = useSetFileUpload()
  const load = useCallback(
    ({ target: { result } }) => {
      modal.open()

      return setState(setSource(result))
    },
    [modal]
  )
  const validate = useCallback(
    ({ width, height, naturalWidth, naturalHeight }) => {
      const distortion = {
        x: width / naturalWidth,
        y: height / naturalHeight,
      }
      const valid =
        naturalHeight >= settings.height && naturalWidth >= settings.width
      const reducer = valid
        ? setDistortion(distortion)
        : setError({
            message: `Invalid dimensions (minimum of ${settings.height}x${settings.width} pixels is required)!`,
          })

      return setState(reducer) || valid
    },
    [settings.height, settings.width]
  )
  const onCanvasLoad = useCallback(
    ({ target }) =>
      validate(target) &&
      target.dispatchEvent(new Event('medialoaded', { bubbles: true })),
    [validate]
  )
  const onCropChange = useCallback((crop) => setState(setCrop(crop)), [])
  const onCropComplete = useCallback((crop) => setState(setCrop(crop)), [])
  const onInputChange = useCallback(
    ({
      target: {
        files: [file],
      },
    }) => {
      const reader = new FileReader()

      reader.addEventListener('load', load, false)

      return reader.readAsDataURL(file)
    },
    [load]
  )
  const crop = useCallback(() => {
    const {
      crop: { x, y, width, height },
      distortion,
    } = state
    const canvas = Object.assign(document.createElement('canvas'), {
      width,
      height,
    })
    const [translateX, translateY, scale] = getCSSTransformValuesFrom(
      ref.current.parentNode
    )
    const context = canvas.getContext('2d')

    context.translate(translateX - x, translateY - y)
    context.scale(scale * distortion.x, scale * distortion.y)
    context.drawImage(ref.current, 0, 0)

    return promisify(canvas).then((file) =>
      getFileUpload({
        mimeType: file.type,
        bucket,
      }).then(({ name, html, url }) =>
        setFileUpload({ data: { file, ...html }, url }).then(() =>
          onUpload({ file, name, url, ...html })
        )
      )
    )
  }, [bucket, getFileUpload, onUpload, setFileUpload, state])
  const { resolve: upload, ...uploading } = useAsync({ promise: crop })

  return {
    canvas: ref,
    close,
    onCanvasLoad,
    onCropChange,
    onCropComplete,
    onInputChange,
    upload,
    uploading,
    ...settings,
    ...state,
  }
}
