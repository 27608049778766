import { Factory } from '../client/Factory'

const TaxonomyServiceNamespace = 'taxonomy'

const TaxonomyServiceConfigs = {
  GetData: Factory.createRequestConfig(
    Factory.MethodGet,
    '/taxonomy/:groupName'
  ),
}

const TaxonomyService = {
  GetData: Factory.makeClientCall(TaxonomyServiceConfigs.GetData),
}

export { TaxonomyServiceNamespace, TaxonomyServiceConfigs, TaxonomyService }
