import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import withStyles from '@mui/styles/withStyles'
import { flow } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'

import { ANALYTICS_TAGS, ANALYTICS_ACTIONS } from 'apps/portal/common/constants'
import { withNotifications } from 'hocs'

const styles = (theme) => ({
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  buttonWidth: {
    // eslint-disable-next-line no-useless-computed-key
    ['@media screen and (min-width: 1700px) and (max-width: 2560px)']: {
      width: theme.spacing(41.25),
    },
    '@media screen and (max-width: 1440px)': {
      width: theme.spacing(27),
    },
  },
  popperOnTop: {
    zIndex: 10,
    '@media screen and (min-width: 1700px) and (max-width: 2560px)': {
      width: theme.spacing(41.25),
    },
    '@media screen and (max-width: 1440px)': {
      width: theme.spacing(27),
    },
  },
})

const ContactUsBase = ({
  classes,
  succeed,
  fail,
  buttonClass,
  emailTo,
  buttonLabel,
  t,
  gEvent,
  slugId,
}) => {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const menuList = [
    {
      label: buttonLabel,
      clickHandler: (event, index) => openEmailClient(event, index),
    },
    {
      label: t('copyToClipboard'),
      clickHandler: (event, index) => copyEmailToClipboard(event, index),
    },
  ]
  const contactUsButton = {
    label: t('contactUs'),
    clickHandler: (event) => handleToggle(),
  }
  const openEmailClient = (event, index) => {
    gEvent({
      ...ANALYTICS_TAGS.PUBLIC_PROFILE,
      action: `${ANALYTICS_ACTIONS.CONTACT_US} - Open`,
      label: slugId,
    })
    window && (window.location.href = `mailto:${emailTo}`)
  }

  const copyEmailToClipboard = (event, index) => {
    setOpen(false)
    if (typeof window === 'undefined') {
      fail(`${t('copyToClipboardErrorMessage')}`)
      return
    }
    gEvent({
      ...ANALYTICS_TAGS.PUBLIC_PROFILE,
      action: `${ANALYTICS_ACTIONS.CONTACT_US} - Copy`,
      label: slugId,
    })
    const tempElm = document.createElement('input')
    document.body.appendChild(tempElm)
    tempElm.value = emailTo
    tempElm.select()
    document.execCommand('copy')
    document.body.removeChild(tempElm)
    succeed(`${t('copyToClipboardMessage')}`)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }
  return (
    <div className={classes.container}>
      <ButtonGroup
        variant='contained'
        className={buttonClass}
        ref={anchorRef}
        aria-label='split button'
      >
        <Button
          className={[buttonClass, classes.buttonWidth]}
          onClick={(event) => contactUsButton.clickHandler(event)}
        >
          {contactUsButton.label}
        </Button>
        <Button
          className={buttonClass}
          size='small'
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='menu'
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.popperOnTop}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu'>
                  {menuList.map((option, index) => (
                    <MenuItem
                      key={index}
                      onClick={(event) => option.clickHandler(event, index)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}
const ContactUs = flow(
  withNotifications,
  withTranslation('publicProfile'),
  withStyles(styles)
)(ContactUsBase)
ContactUs.propTypes = {
  buttonClass: PropTypes.string,
  emailTo: PropTypes.string,
}
export { ContactUs }
