import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import flow from 'lodash/flow'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router'

import bikingImg from 'apps/portal/assets/img/biking.png'
import { AuthenticatedNavBar } from 'apps/portal/components'
import Layout from 'components/Layout'

const styles = (theme) => ({
  container: {
    left: '50%',
    marginTop: '35px',
    position: 'absolute',
    top: '50%',
    textAlign: 'center',
    transform: 'translate(-50%, -50%)',
  },
  image: {
    maxWidth: '100%',
  },
  label: {
    color: theme.palette.grey[900],
    fontWeight: theme.typography.fontWeightBold,
    marginTop: theme.spacing(2),
  },
})

class ComingSoonComponent extends React.Component {
  render() {
    const {
      location: { pathname },
      classes,
      t,
    } = this.props
    const label = t('label')

    return (
      <Layout
        navbarComponent={AuthenticatedNavBar}
        currentPage={pathname.substring(1)}
      >
        <div className={classes.container}>
          <img src={bikingImg} className={classes.image} alt={label} />
          <Typography variant='h5' className={classes.label}>
            {label}
          </Typography>
        </div>
      </Layout>
    )
  }
}

const ComingSoon = flow(
  withTranslation('coming_soon'),
  withStyles(styles),
  withRouter
)(ComingSoonComponent)

export { ComingSoon }
