/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import withStyles from '@mui/styles/withStyles'
import flow from 'lodash/flow'
import { connect } from 'react-redux'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Hidden from '@mui/material/Hidden'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import NotificationsIcon from '@mui/icons-material/Notifications'
import Tooltip from '@mui/material/Tooltip'
import { withTranslation } from 'react-i18next'
import { withProfile } from 'hocs/withProfile'
import { NavBar } from '../../../components'
import { PAGES } from '../common/constants'
import { buildImageURL } from '../../../common/image'
import { withConfig } from 'hocs/whitelabel'

const styles = (theme) => {
  const activeLinkStyles = {
    textDecoration: 'none',
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  }

  const baseLinkStyles = {
    color: theme.palette.secondary.dark,
    fontSize: theme.typography.fontSize,
    textDecoration: 'none',
    '&:hover': activeLinkStyles,
  }

  return {
    navLinksContainer: {
      position: 'relative',
      top: theme.spacing(1.43),
    },
    navLink: {
      ...baseLinkStyles,
      margin: theme.spacing(0, 3),
      padding: theme.spacing(0, 0, 2.7),
      borderBottom: `${theme.spacing(0.4)} solid ${
        theme.palette.background.light
      }`,
    },
    notificationsIcon: {
      color: theme.palette.secondary.dark,
      fontSize: theme.typography.fontSize,
      textDecoration: 'none',
    },
    navLinkActive: activeLinkStyles,
    menuButton: {
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    menuButtonAvatar: {
      borderRadius: 0,
      width: theme.spacing(9),
      height: theme.spacing(9),
    },
    menuButtonIconDown: {
      color: theme.palette.secondary.dark,
      padding: `0 ${theme.spacing(1.25)} 0 ${theme.spacing(1.87)}`,
    },
    menuLink: {
      ...baseLinkStyles,
    },
  }
}

class AuthenticatedNavBarClass extends React.Component {
  static propTypes = {
    currentPage: PropTypes.string.isRequired,
    classes: PropTypes.object,
    t: PropTypes.func,
  }

  static defaultProps = {
    roles: [],
  }

  constructor(props) {
    super(props)

    this.state = {
      anchorEl: null,
    }

    this.handleMenuOpen = this.handleMenuOpen.bind(this)
    this.handleMenuClose = this.handleMenuClose.bind(this)
    this.getProfileAvatar = this.getProfileAvatar.bind(this)
  }

  getProfileAvatar() {
    return buildImageURL(this.props.profile.photo)
  }

  handleMenuOpen(event) {
    event.preventDefault()

    this.setState({ anchorEl: event.currentTarget })
  }

  handleMenuClose() {
    this.setState({ anchorEl: null })
  }

  isInterviewer() {
    const {
      props: { roles },
    } = this

    return !!roles.find(
      (role) => String(role).toLocaleLowerCase() === 'interviewer'
    )
  }

  render() {
    const {
      config: {
        navigation: {
          logo,
          links: { services, events, referrals, faq, interviewer },
        },
      },
      classes,
      t,
    } = this.props

    const profileAvatar = this.getProfileAvatar()
    const shouldRenderInterviewerItem = this.isInterviewer() && !!interviewer
    const interviewerTabInView =
      window.location.pathname === `/${PAGES.INTERVIEWER}`

    return (
      <NavBar logo={logo}>
        <React.Fragment>
          {/* navbar content for small screens down */}
          <Hidden mdUp>
            <Box>
              <List component='nav' area-label='main pages'>
                <ListItem button component='a' href={`/${PAGES.DASHBOARD}`}>
                  <ListItemText primary={t('home')} />
                </ListItem>

                <ListItem button component='a' href={`/${PAGES.PROFILE}`}>
                  <ListItemText primary={t('profile')} />
                </ListItem>

                {!!referrals && (
                  <ListItem button component='a' href={`/${PAGES.REFERRALS}`}>
                    <ListItemText primary={t('referrals')} />
                  </ListItem>
                )}

                {!!services && (
                  <ListItem button component='a' href={`/${PAGES.SERVICES}`}>
                    <ListItemText primary={t('services')} />
                  </ListItem>
                )}

                {!!faq && (
                  <ListItem button component='a' href={`/${PAGES.FAQ}`}>
                    <ListItemText primary={t('faq')} />
                  </ListItem>
                )}

                {!!shouldRenderInterviewerItem && (
                  <ListItem button component='a' href={`/${PAGES.INTERVIEWER}`}>
                    <ListItemText primary={t('interviewer')} />
                  </ListItem>
                )}
              </List>

              <Divider />

              <List component='nav' area-label='account actions'>
                <ListItem button component='a' href={`/${PAGES.ACCOUNT}`}>
                  <ListItemText primary={t('account')} />
                </ListItem>
                <ListItem button component='a' href={`/${PAGES.LOGOUT}`}>
                  <ListItemAvatar>
                    <Avatar alt='profile photo' src={profileAvatar} />
                  </ListItemAvatar>

                  <ListItemText primary={t('logout')} />
                </ListItem>
              </List>
            </Box>
          </Hidden>

          {/* navbar content for medium screens up */}
          <Hidden mdDown>
            <Grid item md={9}>
              <Box
                display='flex'
                justifyContent='flex-start'
                className={classes.navLinksContainer}
              >
                <NavLink
                  to={`/${PAGES.DASHBOARD}`}
                  className={classes.navLink}
                  activeClassName={classes.navLinkActive}
                >
                  <Typography variant='body1'>{t('home')}</Typography>
                </NavLink>

                <NavLink
                  to={`/${PAGES.PROFILE}`}
                  className={classes.navLink}
                  activeClassName={classes.navLinkActive}
                >
                  <Typography variant='body1'>{t('profile')}</Typography>
                </NavLink>

                {!!referrals && (
                  <NavLink
                    to={`/${PAGES.REFERRALS}`}
                    className={classes.navLink}
                    activeClassName={classes.navLinkActive}
                  >
                    <Typography variant='body1'>{t('referrals')}</Typography>
                  </NavLink>
                )}

                {!!services && (
                  <NavLink
                    to={`/${PAGES.SERVICES}`}
                    className={classes.navLink}
                    activeClassName={classes.navLinkActive}
                  >
                    <Typography variant='body1'>{t('services')}</Typography>
                  </NavLink>
                )}

                {!!events && (
                  <NavLink
                    to={`/${PAGES.EVENTS}`}
                    className={classes.navLink}
                    activeClassName={classes.navLinkActive}
                  >
                    <Typography variant='body1'>{t('events')}</Typography>
                  </NavLink>
                )}

                {!!faq && (
                  <NavLink
                    to={`/${PAGES.FAQ}`}
                    className={classes.navLink}
                    activeClassName={classes.navLinkActive}
                  >
                    <Typography variant='body1'>{t('faq')}</Typography>
                  </NavLink>
                )}

                {!!shouldRenderInterviewerItem && (
                  <NavLink
                    to={`/${PAGES.INTERVIEWER}`}
                    className={classes.navLink}
                    activeClassName={classes.navLinkActive}
                  >
                    <Typography variant='body1'>{t('interviewer')}</Typography>
                  </NavLink>
                )}
              </Box>
            </Grid>

            <Grid item md={2}>
              <Box display='flex' justifyContent='flex-end'>
                {!!shouldRenderInterviewerItem && interviewerTabInView && (
                  <MenuItem>
                    <Tooltip title="What's new!">
                      <div className='beamerTrigger'>
                        <IconButton
                          size='large'
                          color='primary'
                          className={classes.notificationsIcon}
                        >
                          <NotificationsIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </MenuItem>
                )}
                <ButtonBase
                  focusRipple
                  className={classes.menuButton}
                  onClick={this.handleMenuOpen}
                  ref={this.state.anchorEl}
                >
                  <Avatar
                    id='profile-avatar'
                    src={profileAvatar}
                    className={classes.menuButtonAvatar}
                  />

                  <ArrowDropDownIcon className={classes.menuButtonIconDown} />
                </ButtonBase>

                <Menu
                  id='menu-appbar'
                  anchorEl={this.state.anchorEl}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={!!this.state.anchorEl}
                  onClose={this.handleMenuClose}
                >
                  <NavLink
                    to={`/${PAGES.PROFILE}`}
                    className={classes.menuLink}
                  >
                    <MenuItem>
                      <Typography variant='body1'>{t('profile')}</Typography>
                    </MenuItem>
                  </NavLink>

                  <NavLink
                    to={`/${PAGES.ACCOUNT}`}
                    className={classes.menuLink}
                  >
                    <MenuItem>
                      <Typography variant='body1'>{t('account')}</Typography>
                    </MenuItem>
                  </NavLink>

                  <Divider />

                  <NavLink to={`/${PAGES.LOGOUT}`} className={classes.menuLink}>
                    <MenuItem>
                      <Typography variant='body1'>{t('logout')}</Typography>
                    </MenuItem>
                  </NavLink>
                </Menu>
              </Box>
            </Grid>
          </Hidden>
        </React.Fragment>
      </NavBar>
    )
  }
}

const mapStateToProps = (state) => ({ roles: state.auth && state.auth.roles })

const AuthenticatedNavBar = flow(
  withConfig,
  withTranslation('navbar'),
  withStyles(styles),
  withProfile,
  connect(mapStateToProps)
)(AuthenticatedNavBarClass)

export { AuthenticatedNavBar }
