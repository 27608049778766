import Grid from '@mui/material/Grid'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

import { getConfigByOrganizationId } from 'apps/portal/config'
import { PROFILE_TYPES } from 'common/constants'

import {
  About,
  Badges,
  Footer,
  Header,
  ProfileBackground,
  Skills,
  Experiences,
  Educations,
} from './components'

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    backgroundColor: theme.palette.background.light,
  },
  aboutContainer: {
    marginTop: theme.spacing(5),
    backgroundColor: theme.palette.background.publicProfile,
  },
  publicProfileBgColor: {
    backgroundColor: theme.palette.background.publicProfile,
  },
  marginalSpace: {
    marginTop: theme.spacing(1),
  },
  marginAndFooterSpacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))
const publicProfileMobileViewBase = (props) => {
  const classes = useStyles()
  const { notify, t: translate, history, match, profile } = props
  const profileId = match?.params?.id
  const gEvent =
    history.location.hash === '#triggerGA' ? props.gEvent : () => {}
  const defaultConfig =
    profile?.organizationId && getConfigByOrganizationId(profile.organizationId)
  const config = profileId ? defaultConfig : props.config
  const isPermProfile =
    profile.type === PROFILE_TYPES.FULL_TIME_EMPLOYEE && !config.profileType
  return (
    <Grid container spacing={1}>
      <Grid item container xs={12} className={classes.headerContainer}>
        <Header
          notify={notify}
          translate={translate}
          config={config}
          profileId={profileId}
          match={match}
          history={history}
          gEvent={gEvent}
          slugId={profile.slug}
        />
      </Grid>
      <Grid item xs={12}>
        <ProfileBackground config={config} profile={profile} />
      </Grid>
      <Grid
        item
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        xs={12}
        className={classes.aboutContainer}
      >
        <Grid item xs={11}>
          <About
            config={config}
            photo={profile.photo}
            type={profile.type}
            firstName={profile.firstName}
            headline={profile.headline}
            spokenLanguages={profile.spokenLanguages}
            location={profile.location}
            bio={profile.bio}
            certifications={profile.certifications}
            translate={translate}
            organizationId={profile.organizationId}
            profileId={profileId}
            gEvent={gEvent}
            slugId={profile.slug}
            isPermProfile={isPermProfile}
            match={match}
            notify={notify}
          />
        </Grid>
      </Grid>
      {config.badges && profile && !isPermProfile && (
        <Grid item container className={classes.publicProfileBgColor}>
          <Grid
            item
            container
            alignItems='center'
            justifyContent='center'
            xs={12}
            className={classes.marginalSpace}
          >
            <Badges profile={profile} translate={translate} gEvent={gEvent} />
          </Grid>
        </Grid>
      )}
      <Grid item container className={classes.publicProfileBgColor}>
        <Grid
          item
          container
          alignItems='center'
          justifyContent='center'
          className={classes.marginalSpace}
        >
          <Skills skills={profile.skills} translate={translate} />
        </Grid>
      </Grid>
      <Grid item container className={classes.publicProfileBgColor}>
        <Grid
          item
          container
          alignItems='center'
          justifyContent='center'
          xs={12}
          className={classes.marginalSpace}
        >
          <Experiences
            experiences={profile.experiences}
            translate={translate}
            gEvent={gEvent}
            slugId={profile.slug}
          />
        </Grid>
      </Grid>
      <Grid item container className={classes.publicProfileBgColor}>
        <Grid
          item
          container
          alignItems='center'
          justifyContent='center'
          xs={12}
          className={classes.marginAndFooterSpacing}
        >
          <Educations educations={profile.educations} translate={translate} />
        </Grid>
      </Grid>
      <Grid item container>
        <Grid
          item
          container
          className={classes.marginalSpace}
          alignItems='center'
          justifyContent='center'
          xs={12}
        >
          <Footer config={config} translate={translate} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export { publicProfileMobileViewBase as MobileViewOfPublicProfile }
