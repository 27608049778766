import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Hidden from '@mui/material/Hidden'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import { flow, isEmpty, get, reduce } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import launchImg from 'apps/portal/assets/img/dash_background.svg'
import { setIsTaxIdDialogueShowed } from 'apps/portal/containers/profile/Actions'
import { DialogBox } from 'components/DialogBox'
import { withConfig } from 'hocs/whitelabel'
import { withProfile } from 'hocs/withProfile'
// eslint-disable-next-line import/order
import { withNotifications } from 'hocs'
import { ProfileOutgoing } from 'sdk/profile/ProfileOutgoing'
import { ProfileService } from 'sdk/profile/Service'

import { Layout, Loading } from '../../../../components'
import { PAGES } from '../../common/constants'
import { AuthenticatedNavBar } from '../../components'

import { DashboardForm } from './components'

const styles = (theme) => ({
  root: {
    position: 'relative',
  },
  launchImage: {
    maxWidth: '100%',
  },
  typographyWrapper: {
    marginTop: theme.spacing(5),
  },
  bkg_image: {
    marginTop: theme.spacing(6),
  },
})

class DashboardClass extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    config: PropTypes.object,
  }

  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      focusRoleOptions: undefined,
      isDialogOpen: false,
    }

    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.validateTaxId = this.validateTaxId.bind(this)
    this.handleDialogClickAccept = this.handleDialogClickAccept.bind(this)
    this.handleDialogClickDecline = this.handleDialogClickDecline.bind(this)
  }

  async componentDidMount() {
    const { dispatchers, isTaxIdDialogueShowed } = this.props

    try {
      const [respProfile, respFocus] = await Promise.all([
        ProfileService.getProfile(),
        ProfileService.getFocusRoleList(),
      ])

      dispatchers.loadProfile(respProfile.data)

      this.setState({
        focusRoleOptions: get(respFocus, 'data.data'),
      })
    } catch (err) {
      console.error(err)
    }
    if (!isEmpty(this.props.profile) && !isTaxIdDialogueShowed) {
      this.validateTaxId()
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!isEmpty(props.profile) && !isEmpty(state.focusRoleOptions)) {
      return {
        isLoading: false,
      }
    }
    return null
  }

  async handleFormSubmit(formData, { resetForm, setSubmitting }) {
    const { fail, t } = this.props
    setSubmitting(true)

    const callOpts = {
      path: {
        id: formData.id,
      },
    }
    const transformProfileData = {
      ...formData,
      workPreferences: formData?.workPreferences?.map((item) =>
        typeof item === 'object' ? item.id : item
      ),
      workSetups: formData?.workSetups?.map((item) =>
        typeof item === 'object' ? item.id : item
      ),
    }

    try {
      const res = await ProfileService.updateProfile(
        ProfileOutgoing(transformProfileData),
        callOpts
      )

      resetForm({ values: formData })
      this.props.dispatchers.loadProfile(res.data)
    } catch (err) {
      if (err.response.data.message.includes('vat-invalid')) {
        fail(t('vatError'))
      }
      console.error(err)
    }

    setSubmitting(false)
  }

  handleDialogClickAccept() {
    this.props.setIsTaxIdDialogueShowed()
    this.props.history.push('/account/payment')
    this.setState({
      isDialogOpen: false,
    })
  }

  handleDialogClickDecline() {
    this.setState({
      isDialogOpen: false,
    })
    this.props.setIsTaxIdDialogueShowed()
  }

  async validateTaxId() {
    const { profile } = this.props
    const callOpts = {
      path: {
        id: profile.id,
      },
    }

    try {
      await ProfileService.updateProfile(profile, callOpts)
      this.setState({
        isDialogOpen: false,
      })
    } catch (err) {
      const {
        response: { data: responseError },
      } = err
      if (responseError && responseError.message.includes('vat')) {
        this.setState({
          isDialogOpen: true,
        })
      }
    }
  }

  arrifyFocusRoles() {
    const {
      profile: { focusRole },
    } = this.props
    const { focusRoleOptions } = this.state

    return reduce(
      focusRoleOptions,
      (acc, roleGroup, key) => {
        if (key !== focusRole.category) {
          return acc
        }

        return [
          ...acc,
          {
            group: key,
            roles: roleGroup.list,
          },
        ]
      },
      []
    )
  }

  render() {
    const {
      classes,
      t,
      profile,
      config: {
        content: {
          home: { welcome },
        },
      },
      isTaxIdDialogueShowed,
    } = this.props

    return (
      <Loading loading={this.state.isLoading}>
        <Layout
          navbarComponent={AuthenticatedNavBar}
          currentPage={PAGES.DASHBOARD}
        >
          <DialogBox
            open={this.state.isDialogOpen && !isTaxIdDialogueShowed}
            handleClickAccept={this.handleDialogClickAccept}
            handleClickDecline={this.handleDialogClickDecline}
            title={this.props.t('dialogBox_Tax_Id_title')}
            description={this.props.t('dialogBox_Tax_Id_description')}
            note={this.props.t('dialogBox_Tax_Id_note')}
            acceptBtn={this.props.t('dialogBox_Tax_Id_acceptBtn')}
            declineBtn={this.props.t('dialogBox_Tax_Id_declineBtn')}
          />
          <Grid container component='main' className={classes.root}>
            <Grid item xs={12} sm={12} md={5}>
              <Grid container alignItems='center' justifyContent='center'>
                <Grid item md={10} xs={12}>
                  {!!this.state.focusRoleOptions && !isEmpty(profile) ? (
                    <DashboardForm
                      focusRoles={this.arrifyFocusRoles()}
                      profile={profile}
                      handleSubmit={this.handleFormSubmit}
                    />
                  ) : (
                    <Typography variant='body2'>{t('no_data')}</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>

            {/* hide on screens smaller than md */}
            <Hidden mdDown>
              <Grid item md={1} align='center'>
                <Divider orientation='vertical' />
              </Grid>

              <Grid item md={6}>
                <Grid
                  container
                  flexDirection='column'
                  justifyContent='center'
                  alignItems='center'
                  spacing={0}
                >
                  <Grid item md={5} className={classes.bkg_image}>
                    <img
                      src={launchImg}
                      className={classes.launchImage}
                      alt='dashboard'
                    />
                  </Grid>

                  <Grid item md={6} className={classes.typographyWrapper}>
                    <Box>
                      <Typography variant='h6' gutterBottom align='center'>
                        {welcome || t('welcome_heading')}
                      </Typography>

                      <Typography variant='body1' gutterBottom align='center'>
                        {t('welcome_text')}
                      </Typography>
                      <br />
                      <Typography variant='body1' gutterBottom align='center'>
                        <b>
                          {profile?.owner?.firstName} {profile?.owner?.lastName}
                        </b>
                        :{' '}
                        <a href={`mailto: ${profile?.owner?.email}`}>
                          {profile?.owner?.email}
                        </a>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
        </Layout>
      </Loading>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  isTaxIdDialogueShowed: state.isTaxIdDialogueShowed,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setIsTaxIdDialogueShowed: (state) =>
    dispatch(setIsTaxIdDialogueShowed(state)),
})
const Dashboard = flow(
  withConfig,
  withTranslation('dashboard'),
  withStyles(styles),
  withProfile,
  withRouter,
  withNotifications,
  connect(mapStateToProps, mapDispatchToProps)
)(DashboardClass)

export { Dashboard }
