import Box from '@mui/material/Box'
import { grey } from '@mui/material/colors'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import React from 'react'
import { withTranslation } from 'react-i18next'

import { Education } from './Education'

const styles = (theme) => ({
  companyLogo: {
    width: 'auto',
    height: '50px',
  },
  duration: {
    color: grey[430],
  },
})

export class EducationsComponent extends React.Component {
  render() {
    const { educations = [], classes } = this.props
    return (
      <Box paddingTop={4}>
        <Grid
          container
          direction='column'
          justifyContent='flex-start'
          alignItems='stretch'
          spacing={6}
        >
          {educations.length > 0 ? (
            educations.map((edu) => (
              <Grid key={JSON.stringify(edu)} item xs={12}>
                <Education classes={classes} {...edu} {...this.props} />
              </Grid>
            ))
          ) : (
            <Typography variant='subtitle1'>
              {this.props.t('profile_education_empty_educations_message')}
            </Typography>
          )}
        </Grid>
      </Box>
    )
  }
}

const Educations = withTranslation('profile_view')(
  withStyles(styles)(EducationsComponent)
)

export { Educations }
