import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import flow from 'lodash/flow'
import uniqueId from 'lodash/uniqueId'
import React from 'react'
import { withTranslation } from 'react-i18next'

import { SKILLS_LIMIT, TOP_SKILLS_LIMIT } from 'apps/portal/common/constants'
import withDnD from 'hocs/DnD'
import { withProfile } from 'hocs/withProfile'

import { SkillAutosuggest } from './SkillAutosuggest'

const styles = (theme) => ({
  skillChip: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    cursor: 'move',
  },
})

class SkillsFormComponent extends React.Component {
  renderItem = (profileSkill, index) => {
    const { removeSkill, classes } = this.props

    return (
      <Chip
        className={classes.skillChip}
        key={`${JSON.stringify(profileSkill)}_${uniqueId()}`}
        label={profileSkill.skill.name}
        onDelete={removeSkill(TOP_SKILLS_LIMIT + index)}
        id={`regular-skill-${index}`}
      />
    )
  }

  render() {
    const {
      skills,
      regularSkills,
      addSkill,
      skillIds,
      onFind,
      onSort,
      t,
      createDnD,
    } = this.props
    const { ref, connect } = createDnD({ onFind, onSort })

    return (
      <>
        <Typography variant='h6'>{t('otherSkillsLabel')}</Typography>
        <SkillAutosuggest
          id='regular-skills'
          label={t('upToSkills', { number: SKILLS_LIMIT - TOP_SKILLS_LIMIT })}
          onChange={addSkill(skills.length)}
          skillIds={skillIds}
        />
        <Box m={1} ref={ref}>
          <Grid container direction='row'>
            {regularSkills.map(connect(this.renderItem))}
          </Grid>
        </Box>
      </>
    )
  }
}

export const RegularSkillsForm = flow(
  withTranslation('profileEditSkills'),
  withStyles(styles),
  withDnD({ type: 'regularSkills' }),
  withProfile
)(SkillsFormComponent)
