import { string } from 'prop-types'
import React, { useMemo } from 'react'
import { DialogContent } from '@mui/material'

import withStyle from './style'

export const Content = ({ className, ...props }) => {
  const classes = useMemo(() => ({ root: className }), [className])

  return <DialogContent classes={classes} {...props} />
}

Content.propTypes = {
  className: string.isRequired,
}

Content.defaultProps = {}

export default withStyle(Content)
