/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { capitalize } from 'common/utils'
import { LocationService } from 'sdk/location/Service'
import { Autosuggest } from 'components/Autosuggest'

const buildLabel = (location) => {
  if (!location || !location.name) return
  return `${capitalize(location.accent)}, ${location.country.name}`
}

class LocationAutosuggest extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    id: PropTypes.string,
  }

  onSelect = (location) => {
    this.props.onChange(location)
    return buildLabel(location)
  }

  getLocations = async (inp) => {
    const service = this.props.locationService || LocationService.QueryCities
    const res = await service({ name: inp })
    return (res.data.data || []).map((x) => ({
      label: buildLabel(x),
      value: x,
    }))
  }

  render() {
    const { error, id } = this.props
    const label = buildLabel(this.props.value)
    return (
      <Autosuggest
        id={id}
        async
        key={label}
        onSelect={this.onSelect}
        defaultValue={label}
        error={error}
        service={this.getLocations}
        {...this.props}
      />
    )
  }
}

export { LocationAutosuggest }
