import React from 'react'
import 'date-fns'
import DeleteIcon from '@mui/icons-material/Delete'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import withStyles from '@mui/styles/withStyles'
import { get, flow } from 'lodash'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { LocationAutosuggest } from 'apps/portal/components/LocationAutosuggest'
import { DatePicker, TextField } from 'components'
import { Responsibilities } from 'components/v2/form_fields/experiences/Responsibilities'

import { PlaceAutosuggest } from './PlaceAutosuggest'

const styles = (theme) => ({
  controls: {
    alignItems: 'center',
  },
  control: {
    display: 'flex',
    justifyContent: 'center',
  },
})

class ExperienceFormComponent extends React.Component {
  static propTypes = {
    experience: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      isResetButtonClicked: false,
    }
  }

  setIsResetButtonClicked(isResetButtonClicked) {
    this.setState({ isResetButtonClicked })
  }

  render() {
    const { onChange, errors, experience, classes, outerIndex } = this.props

    return (
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
      >
        <Grid
          container
          direction='column'
          justifyContent='space-between'
          alignItems='stretch'
        >
          <Grid container className={classes.controls}>
            <Grid item xs={10}>
              <Box paddingTop={2} paddingBottom={2}>
                <PlaceAutosuggest
                  error={get(errors, 'company', '')}
                  label={this.props.t('profile_experience_company_label')}
                  value={experience.company}
                  newPlaceLabel={this.props.t('add_new_company')}
                  onChange={(value) => onChange('company', value)}
                />
              </Box>
            </Grid>

            <Grid item xs={1} className={classes.control}>
              <IconButton
                id='delete-experience'
                onClick={this.props.removeExperience(experience)}
                p={1.5}
                size='large'
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Grid item>
            <LocationAutosuggest
              error={get(errors, 'location.id', '')}
              label={this.props.t('profile_experience_location_label')}
              value={experience.location}
              onChange={(value) => onChange('location', value)}
            />
          </Grid>

          <Grid item>
            <TextField
              name={`experiences[${outerIndex}].jobTitle`}
              label={this.props.t('profile_experience_job_title_label')}
              value={get(experience, 'jobTitle', '')}
              onChange={(event) => onChange('jobTitle', event.target.value)}
              error={!!errors.jobTitle}
              helperText={errors.jobTitle || ''}
              data-automator={`experiences[${outerIndex}].jobTitle`}
              inputProps={{ maxLength: 100 }}
              maxLength={100}
            />
          </Grid>

          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <DatePicker
                  id={`from_${experience.id}`}
                  disableFuture
                  label={this.props.t('profile_experience_start_date_label')}
                  value={experience.startDate || null}
                  onChange={(date) => onChange('startDate', date)}
                  KeyboardButtonProps={{
                    'aria-label': this.props.t(
                      'profile_experience_choose_date_text'
                    ),
                  }}
                  error={!!errors.startDate}
                  helperText={get(errors, 'startDate', '')}
                />
              </Grid>
              <Grid item>
                <DatePicker
                  id={`to_${experience.id}`}
                  disableFuture
                  label={this.props.t('profile_experience_end_date_label')}
                  value={experience.endDate || null}
                  onChange={(date) => onChange('endDate', date)}
                  error={!!errors.endDate}
                  helperText={get(errors, 'endDate', '')}
                  KeyboardButtonProps={{
                    'aria-label': this.props.t(
                      'profile_experience_choose_date_text'
                    ),
                  }}
                  minDate={experience.startDate}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Responsibilities
              responsibilities={experience.description}
              onChange={onChange}
              errors={errors.description}
              setIsResetButtonClicked={(isResetButtonClicked) =>
                this.setIsResetButtonClicked(isResetButtonClicked)
              }
              isResetButtonClicked={this.state.isResetButtonClicked}
            />
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export const ExperienceForm = flow(
  withTranslation('profileEditExperience'),
  withStyles(styles)
)(ExperienceFormComponent)
