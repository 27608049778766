import React from 'react'

import { Button } from 'apps/scorecard/components/widgets'

export const Highlighted = (props) => (
  <Button variant='contained' color='primary' {...props} />
)

Highlighted.propTypes = {}

Highlighted.defaultProps = {}

export default Highlighted
