import React from 'react'
import { Link } from 'react-router-dom'

import { PAGES } from '../../common/constants'

// TODO (Dio): Refactor this page to go fully i18n
export const QUESTIONS = {
  [PAGES.FAQ_PERSONAL]: [
    {
      title: 'Your Personal Information & expertlead Profile',
      questions: [
        {
          q: 'Where can I update my availability?',
          a: (
            <>
              You can update your availability on the{' '}
              <Link to='/dashboard'>Home Screen</Link> of our portal. You may
              also indicate until when you would be available and how many hours
              per week you would be available in case of part-time availability.
              Of course, feel free to update your Freelancer Manager directly
              regarding your availability.
{' '}
            </>
          ),
        },
        {
          q: 'How can I change and update my Personal Information and preferences?',
          a: (
            <>
              You may change and update your personal information and
              preferences on <Link to='/account'>My Account</Link>
.
</>
          ),
        },
        {
          q: 'How can I update my tax information?',
          a: (
            <>
              You can update your tax information on the{' '}
              <Link to='/account/payment'>My Account/Payment</Link> tab.
            </>
          ),
        },
        {
          q: 'How can I update my communication preferences?',
          a: (
            <>
              You can update your tax information on the{' '}
              <Link to='/account/communication'>My Account/Communication</Link>{' '}
              tab.
            </>
          ),
        },
        {
          q: 'How can I update my expertlead profile?',
          a: (
            <>
              You can update your CV on the{' '}
              <Link to='/profile/about-me'> My CV </Link> section from the Home
              Page. There you can update your “About me”, Skills, Experiences,
              and Educational Background accordingly.
            </>
          ),
        },
        {
          q: 'What are my “Top skills”?',
          a: `The “Top Skills” on your expertlead profile reflect the tools and technologies you are most proficient in based on your experience, previous communication with your freelancer manager, and the results of your testing process with expertlead. You may adjust your top skills by going to the Skills section of your CV and clicking edit on the upper right hand side.`,
        },
        {
          q: 'What do I do if my university/company is not appearing from the selection?',
          a: `If your university or company does not appear on the drop-down menu, or is not listed on our system, you may request for it to be added by simply entering the official name of the school, university, or company. Should you still encounter any difficulties addition new companies to your profile, you may get in touch with your Freelancer Manager.`,
        },
        {
          q: 'Where can I update my hourly rate?',
          a: (
            <>
              You may update your hourly rate on the{' '}
              <Link to='/account/payment'>Payment section</Link> of your Account
              settings. Please also feel free to get in touch with your
              freelancer manager if you have further information on the range of
              your rate.
            </>
          ),
        },
      ],
    },
  ],

  [PAGES.FAQ_PROJECT]: [
    {
      title: 'expertlead Payment Process & Billing Information',
      questions: [
        {
          q: 'Where do I send my invoice to?',
          a: (
            <>
              Please send your invoice to{' '}
              <a href='mailto:invoices@expertlead.de'>invoices@expertlead.de</a>
            </>
          ),
        },
        {
          q: 'What information needs to be included on my invoice for expertlead?',
          a:
            "Your invoice must include the following information: Period of worked hours Project's/Client's name " +
            'Please use the following billing information for your invoice: expertlead GmbH ' +
            'Lützowufer 6-9 10785 Berlin Germany VAT: DE312654668 . Please always send your invoice and time tracking sheet ' +
            'to invoices@expertlead.de directly to guarantee a smooth payout process. The amount of your invoice will be ' +
            'credited to your provided bank account within the term of payment. Please note that the time tracking sheet ' +
            'must be properly filled in order for your invoice to be reimbursed, meaning it needs to come with a breakdown ' +
            'of the hours you worked per day (15min precision) and a short activity description per day.',
        },
        {
          q: 'How do I get the invoice information and tracking sheet?',
          a: `After signing the contract, the Key Account Manager will send you all the information you will need to invoice expertlead as well as a template for your tracking sheet which will be the basis for your invoicing.`,
        },
        {
          q: 'What billing cycle does expertlead have?',
          a: `We have a monthly billing cycle, meaning you will send an invoice to expertlead at the end of each month according to the actual hours you worked for our client in that respective month. You will receive payment one week after validation of the sent invoice. Please note that your invoice must be sent together with a properly filled time tracking sheet in order for your invoice to be reimbursed, meaning it needs to come with a breakdown of the hours you worked per day (15min precision) and a short activity description per day.`,
        },
        {
          q: 'What happens if a client is delayed with settling my invoice or does not pay at all?',
          a: `At expertlead, we take over the claims management process for you. Hence, you don’t need to worry about clients not paying you or paying with delay only. When you are on a project via expertlead, you send your invoices to us and we will settle your invoice on time.`,
        },
      ],
    },
  ],
  [PAGES.FAQ_COMMUNITY]: [
    {
      title: 'Community Opportunities & Service Partnerships',
      questions: [
        {
          q: 'How can I get access to expertlead’s service partnerships?',
          a: 'You can find our current partnerships in the Services section of our portal. By clicking on the respective partnership you will be redirected to our landing pages with our partners.',
        },
        {
          q: 'How can I actively participate in expertlead’s community activities?',
          a: (
            <>
              Feel free to reach out to our community team to express your ideas
              and let’s investigate how we can collaborate in the future. Drop
              an email to{' '}
              <a href='mailto:freelancers@expertlead.de'>
                freelancers@expertlead.de
              </a>{' '}
              and we will shortly be in touch.
            </>
          ),
        },
      ],
    },
    {
      title: 'Referral Program: Refer a Freelancer or Project to expertlead',
      questions: [
        {
          q: 'How can I refer a freelancer or project to expertlead?',
          a: (
            <>
              In the <Link to='/referrals'>referral section</Link>
, you will
              find both the possibility to invite a freelancer from network by
              entering their name and email. On the same page you can find the
              contact to the expert lead project team to refer some projects.
</>
          ),
        },
        {
          q: 'What do we get from referring a freelancer  to expertlead?',
          a: 'When  the freelancer you referred completes  the first project with us, you will be rewarded  €500.',
        },
        {
          q: 'What is the financial reward for referring a project to expertlead?',
          a: `When the project you recommended  is successfully delivered* by one of our freelancers, you can get up to  5%** of the net project revenue. *A successfully delivered project is defined as a project that has been paid in full by the respective client. **A compensation  of 5% is capped at a maximum payout of 5,000€ for a project referral.`,
        },
        {
          q: 'I cannot find my referral code – what can I do?',
          a: 'You can find your referral code in the Referrals section of the portal. In case your code might not be shown or might not be valid, please reach out to your Freelancer Manager.',
        },
        {
          q: 'How many friends or projects can I refer to expertlead?',
          a: 'There is no limit to how many friends or projects you can refer to us.',
        },
      ],
    },
    {
      title: 'Project Delivery, Extension, and Insurance',
      questions: [
        {
          q: 'What do I do in case a project turns out different from what was discussed initially or when I face any other issue during project delivery?',
          a: 'We are always happy to help and extend support p when required . Whenever you have an issue during project delivery, feel free to reach out  to your Freelancer Manager or the respective  Key Account Manager you were in touch regarding this particular project. Once we understand the concern, we will try to help in any way  we can.',
        },
        {
          q: 'A client wants to extend my project beyond the currently contracted timeline - what should I do?',
          a: 'Congrats, you seem to have done an outstanding job! When a client wants you to support them beyond the contracted timeline, please reach out  to the respective  Key Account Manager you were in touch with for this specific project or your Freelancer Manager. We will take over the administrative part from here and make sure you can continue without any friction.',
        },
        {
          q: 'I’m currently working on a project where a lot more support is required - would I get compensation for referring  expertlead  about  staffing the project?',
          a: `We’d be happy to take over and make sure to increase the team size on this project with experts from the expertlead community. You will be compensated if the project comes through and is additionally staffed with expertlead’s community members. When we hear from you about a project and get freelancers from the expertlead community to work on the project, you could get up to 5%* of the net project revenue once it is successfully delivered**. *The compensation of 5% is capped at a maximum payout of 5,000€ for a project referral. **A successfully delivered project is defined as a project that has been paid in full by the respective client. If you have any freelancers in your own network who are suitable for the project , feel free to refer them to us via the referral page in the expert-portal. Once a freelancer that you referred completes his or her first project with us, you will receive a referral fee of   500€.`,
        },
        {
          q: 'I will not be able to finish the deliverables we agreed on with the client in the time frame I had initially estimated. How do I deal with that?',
          a: `If you are working for a client who asked you for a time estimate that would be difficult to keep, it is important that you let us know as soon as possible. Please turn to your Freelancer Manager or the respective Key Account Manager you have been in touch with regarding this particular project. After we understand the reasons for the delay, we can jointly define next steps and jump in to find a solution that is beneficial  for  both you and the client.`,
        },
        {
          q: 'Do I need to have liability insurance when working on a project for expertlead?',
          a: `We have partnered with a large European insurance provider to make sure that any project you work on via expertlead is insured. Still, we strongly recommend you to have your own liability insurance to make sure you are covered personally as well, including when not on a project with expertlead.`,
        },
      ],
    },
  ],
}
