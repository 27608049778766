import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import flow from 'lodash/flow'
import get from 'lodash/get'
import throttle from 'lodash/throttle'
import uniqueId from 'lodash/uniqueId'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'

import { DEBOUNCE_DELAY, TOP_SKILLS_LIMIT } from 'apps/portal/common/constants'
import { DatePicker } from 'components'
import withDnD from 'hocs/DnD'

import { SkillAutosuggest } from './SkillAutosuggest'

const styles = (theme) => ({
  skillChip: {
    marginRight: theme.spacing(1),
  },
  draggable: {
    listStyleType: 'none',
  },
  dragIcon: {
    cursor: 'move',
    paddingTop: theme.spacing(4),
    color: theme.palette.lightGray,
  },
  actions: {
    paddingTop: theme.spacing(2),
  },
  datePicker: {
    [`& > .${outlinedInputClasses.root} > .${outlinedInputClasses.input}`]: {
      padding: '16.5px 14px',
    },
  },
})

class TopSkillsFormComponent extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    handleChange: PropTypes.func,
    errors: PropTypes.object,
  }

  renderAddButton() {
    return (
      <IconButton id='add-skills' onClick={this.props.addTopSkill} size='large'>
        <AddIcon />
      </IconButton>
    )
  }

  renderItem = (profileSkill, index) => {
    const {
      topSkills = [],
      classes,
      addSkill,
      t,
      errors,
      editSkill,
      skillIds,
      removeSkill,
    } = this.props
    const isLast = index === topSkills.length - 1
    const editSkillFunc = editSkill(index)
    const addSkillFunc = addSkill(index)
    const dateErrorText = get(errors, `skills[${index}].startedAt`, '')
    const changeDate = throttle(
      (date) => editSkillFunc('startedAt')(date || ''),
      DEBOUNCE_DELAY
    )
    const elementId = get(
      profileSkill,
      'skill.id',
      profileSkill.id || uniqueId()
    )

    return (
      <li key={elementId}>
        <Grid container spacing={2}>
          <Grid item>
            <DragIndicatorIcon id='drag-skill' className={classes.dragIcon} />
          </Grid>
          <Grid item xs={11} sm={11} md={4} lg={5}>
            <SkillAutosuggest
              id='top-skills'
              label={t('topSkillNumber', { index: index + 1 })}
              onChange={addSkillFunc}
              skillIds={skillIds}
              value={profileSkill.skill}
              error={get(errors, `skills[${index}].skill.id`, '')}
            />
          </Grid>
          <Grid item xs={10} sm={8} md={4} lg={4}>
            <DatePicker
              disableFuture
              id={`from_${profileSkill.id}`}
              helperText={dateErrorText}
              error={!!dateErrorText}
              label={t('from')}
              value={profileSkill.startedAt || null}
              onChange={changeDate}
              inputClassName={classes.datePicker}
              KeyboardButtonProps={{
                'aria-label': t('from'),
              }}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={3} lg={2}>
            <Box component='div' className={classes.actions}>
              <IconButton
                id='remove-skill'
                {...(!isLast && { className: classes.notLastItem })}
                onClick={removeSkill(index)}
                size='large'
              >
                <DeleteIcon />
              </IconButton>
              {isLast &&
                topSkills.length < TOP_SKILLS_LIMIT &&
                this.renderAddButton()}
            </Box>
          </Grid>
        </Grid>
      </li>
    )
  }

  render() {
    const { topSkills = [], classes, onFind, onSort, createDnD, t } = this.props
    const { ref, connect } = createDnD({ onFind, onSort })

    return (
      <>
        <Typography variant='h6'>
          {t('topSkillsLabel', { limit: TOP_SKILLS_LIMIT })}
        </Typography>
        <ul className={classes.draggable} ref={ref}>
          {topSkills.map(connect(this.renderItem))}
        </ul>
        {topSkills.length === 0 && (
          <Box
            className={classes.actions}
            component='div'
            display='flex'
            justifyContent='flex-end'
          >
            {this.renderAddButton()}
          </Box>
        )}
      </>
    )
  }
}

const TopSkillsForm = flow(
  withStyles(styles),
  withDnD({ type: 'topSkills' }),
  withTranslation('profileEditSkills')
)(TopSkillsFormComponent)

export { TopSkillsForm }
