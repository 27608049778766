import TextField from '@mui/material/TextField'
import withStyles from '@mui/styles/withStyles'
import { flow } from 'lodash'
import React from 'react'

const styles = (theme) => ({
  container: {
    margin: theme.spacing(2, 0),

    '& label': {
      zIndex: 10,
      backgroundColor: theme.palette.white,
    },

    '& .MuiOutlinedInput-root.MuiInputBase-multiline': {
      padding: 0,
    },
  },
})

const InputComponent = ({ inputRef, ...other }) => <div {...other} />

const OutlinedBoxBase = ({ children, label, classes }) => (
  <TextField
    variant='outlined'
    label={label}
    multiline
    className={classes.container}
    InputLabelProps={{ shrink: true }}
    InputProps={{
      inputComponent: InputComponent,
    }}
    inputProps={{ children }}
  />
)

const OutlinedBox = flow(withStyles(styles))(OutlinedBoxBase)

export { OutlinedBox }
