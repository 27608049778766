import * as Yup from 'yup'

const validObject = (label) => {
  const validation = Yup.object().shape({
    id: Yup.string().required(`${label} is required`),
  })
  return validation
}

export { validObject }
