import { useCallback, useEffect } from 'react'

export default (targetKey, cbFunc, options = {}) => {
  const downHandler = useCallback(
    (e) => {
      if (e.key === targetKey) {
        if (options.prevent) {
          e.preventDefault()
        }
        if (!options.isCommentInFocus) {
          cbFunc(options.value ? options.value : e.key)
        }
      }
    },
    [targetKey, cbFunc, options]
  )

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
    }
  }, [cbFunc, downHandler])
}
