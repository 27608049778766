import { useCallback } from 'react'
import { useSnackbar } from 'notistack'

export const DEFAULT_SETTINGS = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
}

export default () => {
  const { closeSnackbar: hide, enqueueSnackbar } = useSnackbar()
  const show = useCallback(
    (message, settings) =>
      enqueueSnackbar(message, {
        ...DEFAULT_SETTINGS,
        ...settings,
      }),
    [enqueueSnackbar]
  )

  return { show, hide }
}
