import MenuIcon from '@mui/icons-material/Menu'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

const styles = (theme) => ({
  appBar: {
    marginBottom: theme.spacing(4),
    backgroundColor: '#ffffff',
  },
  pageTitle: {
    flex: 1,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
})

class MenuBar extends React.Component {
  static propTypes = {
    drawerButton: PropTypes.bool,
  }

  render() {
    return (
      <div>
        <AppBar
          position='static'
          elevation={1}
          color='default'
          className={this.props.classes.appBar}
        >
          <Toolbar>
            {this.props.drawerButton && (
              <IconButton
                color='inherit'
                aria-label='open drawer'
                edge='start'
                size='large'
              >
                <MenuIcon />
              </IconButton>
            )}
            <img
              width={50}
              height={50}
              src='/logo.png'
              className={this.props.classes.logo}
              alt='logo'
            />
            <Typography className={this.props.classes.pageTitle} variant='h6'>
              EXPERTLEAD
            </Typography>
            <Button to='/home' component={Link} color='inherit'>
              Home
            </Button>
            <Button to='/services' component={Link} color='inherit'>
              Services
            </Button>
            <Button to='/news' component={Link} color='inherit'>
              News
            </Button>
            <Button to='/blog' component={Link} color='inherit'>
              Blog
            </Button>
            <Button to='/contact' component={Link} color='inherit'>
              Contact
            </Button>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  active: false,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: () => {
    dispatch({})
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MenuBar))
