import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { loadProfile } from 'apps/portal/containers/profile/Actions'
import { ProfileIncoming } from 'sdk/profile/ProfileIncoming'

const withProfileHOC = (Component) => {
  const getState = (state) => ({
    profile: state.profile,
  })

  const getDispatchers = (dispatch) => ({
    loadProfile: (profile) => dispatch(loadProfile(ProfileIncoming(profile))),
  })

  return (props) => {
    const { profile } = useSelector(getState)
    const dispatch = useDispatch()
    return (
      <Component
        profile={profile}
        dispatchers={getDispatchers(dispatch)}
        {...props}
      />
    )
  }
}

export { withProfileHOC as withProfile }
