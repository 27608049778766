import { useScorecardDispatch } from 'apps/scorecard-v2/hooks'
import { scoreQuestion } from 'apps/scorecard-v2/slices/interview-slice'

const useSaveScore = (accessToken, interviewId, tab) => {
  const dispatch = useScorecardDispatch()
  const handleSetScore = (questionId, payload, isComment) => {
    dispatch(
      scoreQuestion({
        accessToken,
        interviewId,
        questionId,
        payload,
        tab,
        isComment,
      })
    )
  }
  return handleSetScore
}

export default useSaveScore
