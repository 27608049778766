import property from 'lodash/property'
import styled from 'styled-components'

export const getColorBy =
  () =>
  ({ difficulty }) =>
    ({
      DifficultyEasy: '#52bc77',
      DifficultyMedium: '#ffb800',
      DifficultyHard: '#f21313',
    }[difficulty])

export default (component) => styled(component)`
  .MuiCardHeader-root {
    .MuiBadge-root {
      padding-left: ${({ theme: { spacing } }) => spacing(3)};

      .MuiBadge-badge {
        background-color: ${getColorBy()};
        left: 0;
        top: 50%;
        right: unset;
      }
    }
  }

  .MuiCardContent-root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;

    dl {
      &[aria-roledescription='content'] {
        dt {
          display: block;
          ${property('theme.typography.h6')};

          .MuiSvgIcon-root {
            cursor: help;
          }
        }

        dd {
          color: ${property('theme.palette.grey.500')};
          ${property('theme.typography.subtitle1')};
        }

        p {
          margin-top: ${({ theme: { spacing } }) => spacing(1)};
        }
      }

      &[aria-roledescription='skills'] {
        margin-top: ${({ theme: { spacing } }) => spacing(1)};

        ul {
          align-items: center;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }

        li {
          margin-top: ${({ theme: { spacing } }) => spacing(1)};

          &:not(:last-child) {
            margin-right: ${({ theme: { spacing } }) => spacing(1)};
          }
        }
      }
    }

    [aria-roledescription='footer'] {
      margin: ${({ theme: { spacing } }) =>
        `0 -${spacing(2)} -${spacing(3)} -${spacing(2)}`};
      width: ${({ theme: { spacing } }) => `calc(100% + ${spacing(4)})`};
    }

    dt {
      display: none;
    }
  }
`
