import { Box } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import flow from 'lodash/flow'
import PropTypes from 'prop-types'
import React from 'react'

const styles = (theme) => ({
  container: {
    paddingTop: theme.spacing(12),
  },
})

class LayoutClass extends React.Component {
  static propTypes = {
    navbarComponent: PropTypes.any,
    currentPage: PropTypes.string,
    avatarUrl: PropTypes.string,
    className: PropTypes.string,
  }

  render() {
    const {
      props: { navbarComponent: NavBar, classes, className = '' },
    } = this
    const navBarProps = {
      ...(this.props.currentPage && { currentPage: this.props.currentPage }),
      ...{ avatarUrl: this.props.avatarUrl || '' },
      ...(this.props.initialSearchParameters && {
        initialSearchParameters: this.props.initialSearchParameters,
      }),
      ...(this.props.handleSubmit && { handleSubmit: this.props.handleSubmit }),
    }

    return (
      <div>
        {!!navBarProps && <NavBar {...navBarProps} />}
        <Box p={5} className={`${classes.container} ${className}`}>
          {this.props.children}
        </Box>
      </div>
    )
  }
}

const Layout = flow(withStyles(styles))(LayoutClass)

export default Layout
