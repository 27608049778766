/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { DEBOUNCE_DELAY } from 'common/constants'
import { get, isEmpty } from 'lodash'

const Autosuggest = ({
  value,
  onChange,
  error,
  disabled,
  label,
  delay,
  clearAfterSelection,
  invokeSelectWhenEmpty,
  getOptionDisabled,
  buildLabel,
  async,
  service,
  clearOnBlur,
  placeholder,
  items,
  id,
  disableClearable,
  inputVariant,
  'data-automator': dataAutomator = 'auto-suggest',
  onInputChange: _onInputChange = () => {},
}) => {
  let timerValue = useRef(null)
  delay = delay || DEBOUNCE_DELAY

  const [options, setOptions] = useState([])
  const [inputValue, setInputValue] = useState(value ? buildLabel(value) : '')
  const [loading, setLoading] = useState(false)

  const onInputChange = useCallback((event) => {
    if (!event) return
    if (event.type !== 'change') return

    setInputValue(event.currentTarget.value)
    _onInputChange(event.currentTarget.value)
    if (event.currentTarget.value === '') {
      onChange({})
    }
  }, [])

  const onSelect = useCallback(
    (event, option) => {
      const inputValue =
        !!option && !!option.value && !clearOnBlur
          ? buildLabel(option.value)
          : ''
      setInputValue(inputValue)

      !!option && !isEmpty(option) && onChange(get(option, 'value', {}))
      setLoading(false)
      if (isEmpty(inputValue) && event?.type === 'click') {
        onChange({})
      }
    },
    [buildLabel, onChange, clearOnBlur]
  )

  useEffect(() => {
    if (value && Object.keys(value).length > 0) {
      setInputValue(value ? buildLabel(value) : '')
    }
  }, [value])

  // set initial options
  useEffect(() => {
    if (items.length) {
      setOptions(items)
    }
  }, [items])

  /**
   * effect to call the API service and set options
   * this will trigger everytime inputValue has changed
   */
  useEffect(() => {
    if (invokeSelectWhenEmpty && inputValue.length === 0) {
      onSelect(null, {})
    }

    if (!async) return

    clearTimeout(timerValue.current)

    timerValue.current = setTimeout(async () => {
      try {
        setLoading(true)
        const options = inputValue ? await service(inputValue) : []
        setOptions(options)
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    }, delay)

    return () => {
      clearTimeout(timerValue.current)
    }
  }, [
    inputValue,
    async,
    delay,
    invokeSelectWhenEmpty,
    service,
    onSelect,
    items,
  ])

  const stringifyObject = useCallback((obj) => {
    if (typeof obj === 'object') {
      return Object.values(obj).join('\n')
    }
    return obj
  }, [])

  return (
    <Autocomplete
      id={id}
      onChange={onSelect}
      getOptionLabel={(option) => option.label}
      options={options}
      inputValue={inputValue || ''}
      onInputChange={onInputChange}
      getOptionDisabled={getOptionDisabled}
      loading={loading}
      clearOnBlur={clearOnBlur}
      disabled={disabled}
      data-automator={dataAutomator}
      disableClearable={disableClearable}
      renderOption={(props, option) => (
        <li {...props} key={option.value?.id || props.key}>
          {option.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          value={inputValue || ''}
          fullWidth
          helperText={stringifyObject(error)}
          error={!!error}
          margin={'normal'}
          variant={inputVariant || 'outlined'}
          placeholder={placeholder}
          data-automator={dataAutomator}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

Autosuggest.propTypes = {
  label: PropTypes.string,
  service: PropTypes.func,
  async: PropTypes.bool,
  error: PropTypes.string,
  value: PropTypes.object,
  delay: PropTypes.number,
  clearAfterSelection: PropTypes.bool,
  disableClearable: PropTypes.bool,
  validator: function (props, propName, componentName) {
    if (props.async && !props.service) {
      return new Error(
        `You need to specify "service" prop when defining the component as async.`
      )
    }
  },
  invokeSelectWhenEmpty: PropTypes.bool,
}

Autosuggest.defaultProps = {
  disableClearable: false,
  clearOnBlur: false,
  clearAfterSelection: false,
  invokeSelectWhenEmpty: false,
  items: [],
}

export { Autosuggest }
