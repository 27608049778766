/* eslint-disable */
import flow from 'lodash/flow'
import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import ReactCrop from 'react-image-crop'
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { withNotifications } from 'hocs/index'
import {
  CropOriginal as Reset,
  Edit as EditIcon,
  ZoomIn,
  ZoomOut,
} from '@mui/icons-material'
import 'react-image-crop/dist/ReactCrop.css'
import { setFormData } from 'common/upload'
import { buildImageURL } from '../../../common/image'

const FLOATING = /[-+]?[0-9]+(\.[0-9]+)?/gi

const CROPPER_SIZE = 280
const DIMENSION_SIZE = 120
const styles = (theme) => ({
  avatar: {
    width: '100%',
    height: '100%',
    borderRadius: '0px',
  },
  button: {
    height: '40px',
    width: '40px',
  },
  file: {
    display: 'none',
  },
  dialog: {
    zIndex: '9000 !important',
  },
  zoom: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 1,
    '& .MuiIconButton-root': {
      backgroundColor: '#fff',
      '&:not(:first-child)': {
        marginTop: theme.spacing(1),
      },
    },
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '0.75rem',
    paddingBottom: 0,
    paddingTop: 0,
  },
  edit: {
    display: 'flex',
    justifyContent: 'center',
  },
})

const promisify = (canvas) =>
  new Promise((resolve) =>
    canvas.toBlob(
      (blob) => resolve(Object.assign(blob, { lastModifiedDate: new Date() })),
      'image/png',
      1
    )
  )

const initialState = {
  scale: 1,
  crop: {
    width: CROPPER_SIZE,
    height: CROPPER_SIZE,
    unit: 'px',
    aspect: 1,
    x: 0,
    y: 0,
  },
  src: null,
  error: null,
  target: null,
  bucket: process.env.REACT_APP_PROFILES_BUCKET_NAME,
  name: null,
  preview: null,
}

const EditAvatarBase = ({
  invalidDimensionsErrorMessage,
  onChange,
  profileService,
  titleLabel,
  btnCancelLabel,
  btnConfirmLabel,
  classes,
  value,
  fail,
  'data-automator': dataAutomator = 'edit-avataar',
}) => {
  const fileInput = useRef(null)
  const image = useRef(null)
  const avatarImageRef = useRef(value)
  useEffect(() => {
    avatarImageRef.current = value
  }, [value.preview])

  const [collectiveState, setCollectiveState] = useState(initialState)
  const isInitialState = () => isEqual(collectiveState, initialState)

  useEffect(() => {
    clearFile()
  }, [isInitialState()])

  useEffect(() => {
    const { target } = collectiveState
    !!target && disPatchLoadedEvent(target)
  }, [collectiveState.distortion, collectiveState.target])

  useEffect(() => {
    const { bucket, name } = collectiveState
    onChange({ bucket, name })
  }, [collectiveState.bucket, collectiveState.name, collectiveState.preview])

  const disPatchLoadedEvent = useCallback((target) => {
    target.dispatchEvent(new Event('medialoaded', { bubbles: true }))
  }, [])

  const onLoad = useCallback(({ target }) => {
    const { width, height, naturalWidth, naturalHeight } = target
    const distortion = {
      x: width / naturalWidth,
      y: height / naturalHeight,
    }
    const valid =
      Math.min(naturalWidth, naturalHeight, DIMENSION_SIZE) === DIMENSION_SIZE

    switch (true) {
      case !valid:
        fail(
          invalidDimensionsErrorMessage(`${DIMENSION_SIZE}x${DIMENSION_SIZE}`)
        )
        return setCollectiveState({
          ...initialState,
          error: invalidDimensionsErrorMessage(
            `${DIMENSION_SIZE}x${DIMENSION_SIZE}`
          ),
        })
      default:
        setCollectiveState((collectiveState) => ({
          ...collectiveState,
          distortion,
          target,
        }))

        return
    }
  }, [])

  const showFilePrompt = useCallback(() => {
    fileInput.current.click()
  }, [])

  const load = ({ target: { result: src } }) => {
    setCollectiveState((collectiveState) => ({
      ...collectiveState,
      src,
    }))
  }

  const loadFile = (file) => {
    const reader = new FileReader()

    reader.addEventListener('load', load, false)
    reader.readAsDataURL(file)
  }

  const selectFile = async ({
    target: {
      files: [file],
    },
  }) => file && loadFile(file)

  const uploadFile = async (file) => {
    try {
      const bucket = value.bucket || collectiveState.bucket
      const {
        data: { url, name, html },
      } = await profileService.getUploadURL({ mimeType: file.type, bucket })
      const formData = setFormData({ ...html, file })

      await profileService.uploadFile({ url, formData })

      setCollectiveState({
        ...initialState,
        preview: URL.createObjectURL(file),
        bucket,
        name,
      })
      avatarImageRef.current = URL.createObjectURL(file)
      value.preview = URL.createObjectURL(file)
    } catch (err) {
      const {
        response: { data: responseError },
      } = err
      if (responseError && responseError.message.includes('vat')) {
        return fail(
          'Please check for invalid Tax ID under My Account >> Payments section'
        )
      }
    } finally {
      close()
    }
  }

  const cropFile = async () => {
    const {
      crop: { x, y, width, height },
      distortion,
    } = collectiveState
    const { current: croppedImage } = image
    const {
      parentNode: {
        style: { transform },
      },
    } = croppedImage

    const canvas = Object.assign(document.createElement('canvas'), {
      width,
      height,
    })
    const [translateX, translateY, scale] = transform
      .match(FLOATING)
      .map(Number)
    const context = canvas.getContext('2d')

    context.translate(translateX - x, translateY - y)
    context.scale(scale * distortion.x, scale * distortion.y)
    context.drawImage(croppedImage, 0, 0)

    return promisify(canvas)
  }

  const clearFile = useCallback(() => {
    fileInput.current.value = null
  }, [fileInput])

  const onCropClose = () => setCollectiveState(initialState)

  const onCropChange = (crop) =>
    setCollectiveState((collectiveState) => ({
      ...collectiveState,
      crop,
    }))

  const onCropComplete = (crop) =>
    setCollectiveState((collectiveState) => ({
      ...collectiveState,
      crop,
    }))

  const onCropConfirm = async () => {
    return cropFile().then(uploadFile)
  }

  const renderCropperContent = ({ zoomIn, zoomOut, resetTransform }) => {
    const { src } = collectiveState

    return (
      <Fragment>
        <div className={classes.zoom}>
          <IconButton onClick={zoomIn} size='large'>
            <ZoomIn />
          </IconButton>
          <IconButton onClick={resetTransform} size='large'>
            <Reset />
          </IconButton>
          <IconButton onClick={zoomOut} size='large'>
            <ZoomOut />
          </IconButton>
        </div>
        <TransformComponent>
          <img
            className='ReactCrop__image'
            src={src}
            onLoad={onLoad}
            ref={image}
          />
        </TransformComponent>
      </Fragment>
    )
  }

  const renderCropper = () => (
    <TransformWrapper>{renderCropperContent}</TransformWrapper>
  )

  const { crop, src, preview, error } = collectiveState
  const renderComponent = renderCropper()

  return (
    <Fragment>
      {!!src && (
        <Dialog
          aria-labelledby='dialog-title'
          onClose={onCropClose}
          classes={{ root: classes.dialog }}
          open
        >
          <DialogTitle id='dialog-title'>{titleLabel}</DialogTitle>
          <DialogContent>
            <ReactCrop
              src={src}
              crop={crop}
              minHeight={CROPPER_SIZE}
              minWidth={CROPPER_SIZE}
              renderComponent={renderComponent}
              onComplete={onCropComplete}
              onChange={onCropChange}
              ruleOfThirds
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onCropClose} color='primary'>
              {btnCancelLabel}
            </Button>
            <Button onClick={onCropConfirm} color='primary' autoFocus>
              {btnConfirmLabel}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Typography
        className={classes.typography}
        component='div'
        variant='body1'
        data-automator={dataAutomator}
      >
        <Box p={2} zIndex='modal'>
          <Avatar
            src={buildImageURL(avatarImageRef.current)}
            className={classes.avatar}
            data-automator={`${dataAutomator}-photo`}
          />
        </Box>
        <Box p={2} zIndex='tooltip' className={classes.edit}>
          <Button
            className={classes.button}
            color='primary'
            variant='contained'
            onClick={showFilePrompt}
          >
            <EditIcon />
            <input
              className={classes.file}
              type='file'
              accept='image/*'
              ref={fileInput}
              onChange={selectFile}
            />
          </Button>
        </Box>
      </Typography>
    </Fragment>
  )
}

EditAvatarBase.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

EditAvatarBase.defaultProps = {
  value: {},
}

export const EditAvatar = flow(
  withStyles(styles),
  withNotifications
)(EditAvatarBase)
