import * as Yup from 'yup'

const getDateValidation = ({
  errorMessage,
  minDate = null,
  maxDate = null,
  optional = false,
}) => {
  let schema = Yup.date().typeError(errorMessage)

  if (minDate) {
    schema = schema.min(minDate, errorMessage)
  }

  if (maxDate) {
    schema = schema.max(maxDate, errorMessage)
  }

  if (optional === true) {
    return schema.nullable()
  }

  return schema
}

export default getDateValidation
