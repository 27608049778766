/* eslint-disable*/
import React, { useState, useEffect, useCallback } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Grid from '@mui/material/Grid'
import { flow } from 'lodash'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { getConfigByOrganizationId } from 'apps/portal/config'
import { withTracker } from 'components/GoogleAnalytics'
import { withConfig } from 'hocs/whitelabel'
import {
  About,
  Badges,
  Educations,
  Experiences,
  Footer,
  Header,
  Skills,
  ProfileBackground,
  ProfileWSCAssessment,
} from './components'
import { ProfileService } from 'sdk/profile/Service'
import { InterviewerService } from 'sdk/interviewer/Service'
import { withHeatMap } from 'hocs/HeatMap'
import {
  HOTJAR_ID,
  IS_HEATMAP_ACTIVE,
  NullID,
  PROFILE_TYPES,
} from 'common/constants'
import { withNotifications } from '../../../../hocs'
import { ORGANIZATION_IDS } from 'common/constants'
import { useViewport } from 'common/view-port-hook'
import { MobileViewOfPublicProfile } from './mobile-view-of-public-profile'
const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      margin: 0,
      padding: 0,

      // '& > div:first-child': {
      //   display: 'none',
      // },
    },
  },

  [theme.breakpoints.down('md')]: {
    header: {
      height: theme.spacing(8),
      margin: theme.spacing(0, 4),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    mainContainer: {
      backgroundColor: theme.palette.background.publicProfile,
    },
    badgeContainer: {
      marginTop: theme.spacing(0.675),
    },
  },
  [theme.breakpoints.up('md')]: {
    header: {
      height: theme.spacing(6),
      margin: theme.spacing(0, 4),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    backgroundImage: {
      backgroundColor: '#F3F2EF',
      maxHeight: theme.spacing(30),
      '& img': {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
      },
    },
    mainContainer: {
      padding: theme.spacing(0, 4),
      backgroundColor: theme.palette.background.publicProfile,
    },
    badgesWrapper: {
      paddingBottom: theme.spacing(3),

      '& > div': {
        padding: theme.spacing(1, 2, 1, 0),
      },

      '& > div:nth-child(3)': {
        padding: theme.spacing(1, 1, 1, 0),
      },
    },
    leftWrapper: {
      position: 'relative',
    },
    rightWrapper: {
      padding: theme.spacing(4, 0, 10, 4),
    },
  },

  bodyWrapper: {},

  footer: {
    display: 'flex',
    paddingTop: theme.spacing(1),
    marginLeft: theme.spacing(2.5),
    alignItems: 'center',
    paddingRight: theme.spacing(10),
    paddingBottom: theme.spacing(1),
    justifyContent: 'flex-start',
    '-webkit-justify-content': 'flex-start',
    fontWeight: theme.typography.fontWeight.normal,
    '& a': {
      textDecoration: 'none',
      color: 'black',
    },
    '& > span': {
      margin: theme.spacing(0, 1),
    },
  },
}))

function PublicProfileBase(props) {
  const classes = useStyles()

  const [profile, setProfile] = useState({})
  const fetchData = useCallback(async () => {
    const profileData =
      document.querySelector('#profileData') &&
      JSON.parse(document.querySelector('#profileData').innerHTML)
    if (profileData && !props.profile) {
      setProfile(profileData)
    } else {
      let publicProfileData
      try {
        publicProfileData = (
          await ProfileService.getPublicProfile(
            {},
            {
              path: {
                slug: props.match.params.slug,
              },
            }
          )
        ).data
        if (publicProfileData.userId && publicProfileData.userId !== NullID) {
          const { data: p2pInterviewerData } =
            await InterviewerService.getInterviewer(
              {},
              { path: { user_id: publicProfileData.userId } }
            )
          if (p2pInterviewerData) {
            publicProfileData.p2pInterviewerData = p2pInterviewerData
          }
        }
        setProfile(publicProfileData)
      } catch (err) {
        console.error(err)
        if (publicProfileData && !publicProfileData.p2pInterviewerData)
          publicProfileData.p2pInterviewerData = {}
        else if (!publicProfileData || publicProfileData === null)
          publicProfileData = {}
        setProfile(publicProfileData)
      }
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [])

  const { notify, t: translate, history, match } = props
  const profileId = match?.params?.id
  const defaultConfig =
    profile?.organizationId && getConfigByOrganizationId(profile.organizationId)
  const config = profileId ? defaultConfig : props.config
  const isPermProfile =
    profile.type === PROFILE_TYPES.FULL_TIME_EMPLOYEE && !config.profileType
  const gEvent =
    history.location.hash === '#triggerGA' ? props.gEvent : () => {}
  const BrowserViewOfPublicProfile = () => (
    <div>
      {profile && config && (
        <Grid container>
          <Grid item xs={12} className={classes.header}>
            <Header
              notify={notify}
              translate={translate}
              config={config}
              profileId={profileId}
              match={match}
              history={history}
              gEvent={gEvent}
              slugId={profile.slug}
            />
          </Grid>

          <Grid item className={classes.backgroundImage}>
            <ProfileBackground config={config} profile={profile} />
          </Grid>

          <Grid item xs={12} container className={classes.bodyWrapper}>
            <Grid item container className={classes.mainContainer}>
              <Grid item xs={3} className={classes.leftWrapper}>
                <About
                  config={config}
                  photo={profile.photo}
                  type={profile.type}
                  firstName={profile.firstName}
                  headline={profile.headline}
                  spokenLanguages={profile.spokenLanguages}
                  location={profile.location}
                  bio={profile.bio}
                  certifications={profile.certifications}
                  translate={translate}
                  organizationId={profile.organizationId}
                  profileId={profileId}
                  gEvent={gEvent}
                  slugId={profile.slug}
                  isPermProfile={isPermProfile}
                  match={match}
                  notify={notify}
                />
              </Grid>

              <Grid item container xs={9} className={classes.rightWrapper}>
                {config.badges && profile && !isPermProfile && (
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.badgesWrapper}
                  >
                    <Badges
                      profile={profile}
                      translate={translate}
                      gEvent={gEvent}
                    />
                  </Grid>
                )}
                <Grid item container xs={12}>
                  <Grid item xs={8}>
                    <Grid container>
                      <Grid item xs={12}>
                        {profile.organizationId ===
                          ORGANIZATION_IDS.EXPERTLEAD && (
                          <ProfileWSCAssessment report={profile.report} />
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Experiences
                          experiences={profile.experiences}
                          translate={translate}
                          gEvent={gEvent}
                          slugId={profile.slug}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Educations
                          educations={profile.educations}
                          translate={translate}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Skills skills={profile.skills} translate={translate} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={12} className={classes.footer}>
            <Footer config={config} translate={translate} />
          </Grid>
        </Grid>
      )}
    </div>
  )

  const { isDeviceMobile } = useViewport()

  return isDeviceMobile ? (
    <MobileViewOfPublicProfile {...props} profile={profile} />
  ) : (
    <BrowserViewOfPublicProfile />
  )
}

let PublicProfileWrapped = flow(
  withTranslation('publicProfile'),
  withRouter,
  withConfig,
  withNotifications,
  withTracker
)(PublicProfileBase)

if (IS_HEATMAP_ACTIVE) {
  PublicProfileWrapped = withHeatMap(HOTJAR_ID)(PublicProfileWrapped)
}

export { PublicProfileWrapped as PublicProfile }
