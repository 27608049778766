import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React from 'react'

const styles = (theme) => ({
  buttonContainer: {
    padding: '0 30px 10px 0',
    position: 'fixed',
    zIndex: 1000,
    bottom: 0,
    right: 0,
    backgroundColor: '#fff',
  },
  cancelButton: {
    margin: '5px 30px 0 0',
  },
  saveButton: {
    marginTop: 10,
  },
})

class SaveTabComponent extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    disableSave: PropTypes.bool,
    disableCancel: PropTypes.bool,
  }

  render() {
    const { classes, onCancel, disableSave, disableCancel } = this.props
    return (
      <Grid
        className={classes.buttonContainer}
        container
        alignItems='flex-start'
        justifyContent='flex-end'
        direction='row'
      >
        <Button
          className={classes.cancelButton}
          onClick={onCancel}
          size='large'
          disabled={disableCancel}
        >
          Cancel
        </Button>
        <Button
          className={classes.saveButton}
          type='submit'
          variant='contained'
          color='primary'
          disabled={disableSave}
        >
          Save
        </Button>
      </Grid>
    )
  }
}

const SaveTab = withStyles(styles)(SaveTabComponent)
export { SaveTab }
