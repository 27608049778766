import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Typography } from '@mui/material'
import { useMemo, useEffect, useState } from 'react'

import {
  TAB_MAP,
  STATUS,
  FEEDBACK,
  INTERVIEW_STATUS_FINISHED,
  LIVE_CODING,
} from 'apps/scorecard-v2/constants'
import { getIsNonTechnical } from 'apps/scorecard-v2/helpers'
import { archiveInterview } from 'apps/scorecard-v2/slices/interview-slice'
import { useKeyPress, useTracking } from 'apps/scorecard/hooks'

import LoadingIndicator from '../../loading-indicator'
import { Archive, ArchiveConfirmation } from '../archive'

import ActiveTab from './active-tab'
import InterviewTime from './interview-time'
import LiveCodingTime from './live-coding-time'
import {
  StyledDialog,
  StyledDialogContent,
  StyledInterviewHeader,
} from './style'
import TabNames from './tab-names'

const ModalDialog = ({
  handleClose,
  isOpen,
  interview,
  currentTab,
  handleTabChange,
  tabExtraLabel,
  handleEndInterview,
  accessToken,
  tabNamesMap,
  setCurrentTab,
  dispatch,
}) => {
  const [show, setShow] = useState(false)
  const [openArchive, setOpenArchive] = useState(false)
  const [isKeysDisabled, setIsKeysDisabled] = useState(false)

  const { candidate, id, status, vertical } = interview?.value?.interview || {}
  const { questions } = interview.value

  const isDataFetching =
    interview.value.interviewDetailsStatus === STATUS.pending ||
    !interview.value.interviewDetailsStatus

  const getTabItems = () => {
    if (!interview.value) {
      return null
    }

    const tabItems = interview.value?.questions?.nodes?.find(
      ({ title }) => title === currentTab
    )

    return tabItems?.nodes || []
  }

  const tabNames = Object.values(TAB_MAP)
  const sortedTabNames = useMemo(
    () =>
      Object.keys(tabNamesMap).sort(
        (first, second) => tabNames.indexOf(first) - tabNames.indexOf(second)
      ),
    [tabNamesMap]
  )

  useKeyPress('t', () => {
    const currentIndex = sortedTabNames.findIndex(
      (value) => value === currentTab
    )
    const nextIndex = currentIndex + 1

    if (nextIndex === sortedTabNames.length) {
      !isKeysDisabled && setCurrentTab(() => FEEDBACK)
      return
    }

    if (nextIndex > sortedTabNames.length) {
      !isKeysDisabled && setCurrentTab(() => sortedTabNames[0])
      return
    }

    !isKeysDisabled && setCurrentTab(() => sortedTabNames[nextIndex])
  })

  useEffect(() => {
    if (isOpen) {
      setCurrentTab(() => sortedTabNames[0])
    }
  }, [isOpen, sortedTabNames])

  const onArchive = (archivingReason) => {
    const payload = {
      interviewId: id,
      accessToken,
      archivingReason,
    }

    dispatch(archiveInterview(payload))
  }

  const handleArchiveOpen = () => {
    setOpenArchive(true)
    setShow(false)
  }

  const handleFinishInterview = () => {
    setCurrentTab(FEEDBACK)
    setShow(false)
  }

  const tracking = useTracking()
  const eventTracking = (label, action = 'Click') => {
    tracking.event({
      category: 'Scorecard-v2',
      action,
      label: `${action}_${label}`,
    })
  }

  if (!currentTab) {
    return null
  }

  const hasLiveCoding = tabNamesMap[LIVE_CODING]
  const isNonTechnical = getIsNonTechnical(vertical?.name)

  const toggleTabKeyDisable = (value) => {
    setIsKeysDisabled(() => value)
  }

  return (
    <>
      <StyledDialog open={isOpen}>
        <StyledDialogContent>
          {isDataFetching && <LoadingIndicator />}
          {!isDataFetching && (
            <>
              <Box backgroundColor='#f1f3f6' padding={3}>
                <StyledInterviewHeader>
                  <Box sx={{ maxWidth: '40%' }}>
                    <Typography
                      color='#2b254f'
                      fontWeight='700'
                      onClick={() => eventTracking('Title_Text')}
                    >
                      {candidate?.firstName}
                      &nbsp;
                      {candidate?.lastName}
                    </Typography>
                    <Typography color='#a2a1b4'>
                      {candidate?.headLine}
                    </Typography>
                  </Box>

                  <Box display='flex' gap={1} alignItems='center'>
                    {hasLiveCoding && <LiveCodingTime />}
                    <InterviewTime
                      startingTime={interview?.value?.interview?.startingTime}
                    />
                    <IconButton
                      onClick={() => {
                        setShow(true)
                        eventTracking('Close')
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </StyledInterviewHeader>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabNames
                    sortedTabNames={sortedTabNames}
                    tabExtraLabel={tabExtraLabel}
                    currentTab={currentTab}
                    handleTabChange={handleTabChange}
                    eventTracking={eventTracking}
                    isNonTechnical={isNonTechnical}
                  />
                </Box>
              </Box>
              <ActiveTab
                currentTab={currentTab}
                items={getTabItems()}
                accessToken={accessToken}
                interview={interview?.value?.interview}
                task={interview?.value?.interview?.task}
                questions={questions}
                handleEndInterview={handleEndInterview}
                setCurrentTab={setCurrentTab}
                toggleTabKeyDisable={toggleTabKeyDisable}
              />
            </>
          )}
        </StyledDialogContent>
      </StyledDialog>

      <ArchiveConfirmation
        open={show}
        handleClose={handleClose}
        handleArchiveOpen={handleArchiveOpen}
        setShow={setShow}
        handleFinishInterview={handleFinishInterview}
        interviewIsFinished={status === INTERVIEW_STATUS_FINISHED}
      />

      <Archive
        open={openArchive}
        setOpen={setOpenArchive}
        onArchive={onArchive}
      />
    </>
  )
}

export default ModalDialog
