import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'
import React from 'react'

import { Autosuggest } from 'components/Autosuggest'
import { PlaceService } from 'sdk/place/Service'

const buildLabel = (place) => get(place, 'name', '')

class PlaceAutosuggest extends React.Component {
  onSelect = (place) => {
    this.props.onChange(place)
    return isObject(place) ? buildLabel(place) : place
  }

  async getLocations(inp) {
    const res = await PlaceService.QueryPlaces({ name: inp })
    if (isEmpty(res.data.data)) {
      return [
        {
          label: `${inp} (${this?.newPlaceLabel})`,
          value: inp,
        },
      ]
    }
    return (res.data.data || []).map((x) => ({
      label: buildLabel(x),
      value: x,
    }))
  }

  render() {
    return (
      <Autosuggest
        async
        onSelect={this.onSelect}
        defaultValue={buildLabel(this.props.value)}
        service={this.getLocations}
        {...this.props}
      />
    )
  }
}

export { PlaceAutosuggest }
