import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import TungstenOutlinedIcon from '@mui/icons-material/TungstenOutlined'
import { Box, IconButton, List, ListItem, Typography } from '@mui/material'

import StyledTooltip from 'apps/scorecard-v2/components/StyledTooltip'
import { useTracking } from 'apps/scorecard/hooks'

import { StyledKeyText } from '../question-list/style'

import { StyledFooter } from './style'

const DetailsFooter = ({
  onNavigateForward,
  onNavigateBackward,
  canGoBack,
  canGoForward,
  answeredRatio,
}) => {
  const tracking = useTracking()
  const eventTracking = (label, action = 'Click') => {
    tracking.event({
      category: 'Scorecard-v2',
      action,
      label: `${action}_${label}`,
    })
  }

  return (
    <StyledFooter>
      <IconButton>
        <StyledTooltip
          title={
            <List>
              <ListItem>
                <StyledKeyText>1-9</StyledKeyText> scores respectively
              </ListItem>
              <ListItem>
                <StyledKeyText>0</StyledKeyText> scores 10
              </ListItem>
              <ListItem>
                <StyledKeyText>x</StyledKeyText> scores 0
              </ListItem>
              <ListItem>
                <StyledKeyText>&rarr;</StyledKeyText> moves to the next question
              </ListItem>
              <ListItem>
                <StyledKeyText>&larr;</StyledKeyText> moves to the previous
                question
              </ListItem>
            </List>
          }
          arrow
        >
          <TungstenOutlinedIcon sx={{ transform: 'rotate(180deg)' }} />
        </StyledTooltip>
      </IconButton>
      <Box display='flex' alignItems='center'>
        <IconButton
          onClick={() => {
            onNavigateBackward()
            eventTracking('Arrow_Previous')
          }}
          disabled={!canGoBack}
        >
          <ChevronRightOutlinedIcon sx={{ transform: 'rotate(180deg)' }} />
        </IconButton>
        <Typography color='#a7a5b6'>{answeredRatio}</Typography>
        <IconButton
          onClick={() => {
            onNavigateForward()
            eventTracking('Arrow_Next')
          }}
          disabled={!canGoForward}
        >
          <ChevronRightOutlinedIcon />
        </IconButton>
      </Box>
    </StyledFooter>
  )
}

export default DetailsFooter
