import { Search } from '@mui/icons-material'
import { InputAdornment, Icon } from '@mui/material'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import makeStyles from '@mui/styles/makeStyles'
import Downshift from 'downshift'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0, 0.25),
  },
  wrapper: {
    '& .MuiInputBase-fullWidth': {
      scrollbarWidth: 'none',
      display: 'flex',
      overflowX: 'auto',
      border: '1px solid gray',
      borderRadius: '5px',
      width: '100%',
      padding: '6px',
      '&:before': {
        border: 'none',
      },
      '&:after': {
        border: 'none',
      },
      '& .MuiInput-underline:before': {
        border: 'none',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '&:hover': {
        color: 'none',
      },
      '&:hover:not(.Mui-disabled):before': {
        color: 'none',
        borderBottom: 'none',
      },
    },
    '::-webkit-scrollbar': {
      background: '#fff',
      width: '5px',
      height: '5px',
    },
  },
}))

export default function InlineChipInput({ ...props }) {
  const classes = useStyles()
  const {
    placeholder,
    onSubmit,
    keywords,
    afterHandleChangeOrDelete,
    inputClass,
    ...other
  } = props
  const [inputValue, setInputValue] = React.useState('')
  const [searchTerms, setSearchTerms] = React.useState(keywords)
  const globalSearchInputRef = React.createRef()
  const [, setSelectionStart] = React.useState()
  const updateSelectionStart = () =>
    setSelectionStart(globalSearchInputRef.current.selectionStart)
  const handleGlobalSearch = () => {
    const setSubmitting = () => ({})
    onSubmit(null, { setSubmitting })
  }
  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      processWhenEnterPressed(event)
    }
    if (searchTerms.length && !inputValue.length && event.key === 'Backspace') {
      processWhenBackspacePressed()
    }
  }
  const processWhenBackspacePressed = () => {
    const updatedSearchTerms = searchTerms.slice(0, searchTerms.length - 1)
    setSearchTerms(updatedSearchTerms)
    afterHandleChangeOrDelete(updatedSearchTerms)
    handleGlobalSearch()
  }
  const processWhenEnterPressed = (event) => {
    const newSearchTerms = [...searchTerms]
    const duplicatedValues = newSearchTerms.indexOf(event.target.value.trim())

    if (duplicatedValues !== -1) {
      setInputValue('')
      return
    }

    newSearchTerms.push(event.target.value.trim())
    setSearchTerms(newSearchTerms.filter(Boolean))
    setInputValue('')
    updateSelectionStart()
    afterHandleChangeOrDelete(newSearchTerms)
    if (
      searchTerms.filter(Boolean).length >= 1 &&
      !event.target.value.replace(/\s/g, '').length
    ) {
      handleGlobalSearch()
    }
  }

  const handleChange = (item) => {
    let newSelectedItem = [...searchTerms]
    if (newSelectedItem.indexOf(item) === -1) {
      newSelectedItem = [...newSelectedItem, item]
    }
    setInputValue('')
    setSearchTerms(newSelectedItem)
    afterHandleChangeOrDelete(newSelectedItem)
  }

  const handleDelete = (item) => () => {
    const newSelectedItem = [...searchTerms]
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1)
    setSearchTerms(newSelectedItem)
    afterHandleChangeOrDelete(newSelectedItem)
    handleGlobalSearch()
  }

  function handleInputChange(event) {
    setInputValue(event.target.value)
  }

  return (
    <>
      <Downshift
        id='downshift-multiple'
        inputValue={inputValue}
        onChange={handleChange}
        selectedItem={searchTerms}
      >
        {({ getInputProps }) => {
          const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
            onKeyDown: handleKeyDown,
            placeholder,
          })
          let computedPlaceholder = placeholder
          searchTerms && searchTerms.length > 0 && (computedPlaceholder = '')
          return (
            <div className={classes.wrapper}>
              <TextField
                fullWidth
                onSelect={updateSelectionStart}
                inputRef={globalSearchInputRef}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Icon edge='end'>
                        <Search />
                      </Icon>
                    </InputAdornment>
                  ),
                  startAdornment: searchTerms
                    .filter(Boolean)
                    .map((item) => (
                      <Chip
                        key={item}
                        tabIndex={-1}
                        label={item}
                        className={classes.chip}
                        onDelete={handleDelete(item)}
                      />
                    )),

                  onBlur,
                  onChange: (event) => {
                    handleInputChange(event)
                    onChange(event)
                  },
                  onFocus,
                  className: inputClass,
                }}
                {...other}
                {...inputProps}
                placeholder={computedPlaceholder}
              />
            </div>
          )
        }}
      </Downshift>
    </>
  )
}

InlineChipInput.defaultProps = {
  tags: [],
}
InlineChipInput.propTypes = {
  selectedTags: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
}
