/*eslint-disable*/
import isObject from 'lodash/isObject'
import isEmpty from 'lodash/isEmpty'
import { PLACE_STATUS, PLACE_TYPE } from 'apps/portal/common/constants'
import { listToMarkdownText } from 'common/markdown'

export const ProfileOutgoing = (profile) => {
  const skills = profile.skills.map((skill, index) => ({
    ...skill,
    order: index + 1,
  }))

  const educations = (profile.educations || []).map((edu) => {
    if (!isObject(edu.school) || isEmpty(edu.school)) {
      edu = {
        ...edu,
        school: {
          name: edu.school,
          status: PLACE_STATUS.STATUS_UNDER_REVIEW,
          type: PLACE_TYPE.TYPE_SCHOOL,
          city: edu.location,
        },
      }
    }

    return edu
  })

  const experiences = (profile.experiences || []).map((exp) => {
    if (!isObject(exp.company) || isEmpty(exp.company)) {
      exp = {
        ...exp,
        company: {
          name: exp.company,
          status: PLACE_STATUS.STATUS_UNDER_REVIEW,
          type: PLACE_TYPE.TYPE_ORGANISATION,
          city: exp.location,
        },
      }
    }

    return {
      ...exp,
      description: Array.isArray(exp.description)
        ? listToMarkdownText(exp.description)
        : exp.description,
    }
  })

  const certifications = (profile.certifications || []).map((certification) => {
    if (
      !isObject(certification.authority) ||
      isEmpty(certification.authority)
    ) {
      certification = {
        ...certification,
        authority: {
          name: certification.authority,
          status: PLACE_STATUS.STATUS_UNDER_REVIEW,
          type: PLACE_TYPE.TYPE_SCHOOL,
        },
      }
    }

    return certification
  })

  return {
    ...profile,
    skills,
    educations,
    experiences,
    certifications,
  }
}
