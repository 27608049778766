/* eslint-disable*/
import React, { memo } from 'react'
import ELBGImg from 'assets/img/expertlead_bg.png'
import PermBGImg from 'assets/img/permanent_bg.png'
import FuturepathBGImg from 'assets/img/futurepath_bg.png'
import { PROFILE_TYPES } from 'common/constants'
import { styled } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down('md')]: {
    profileBackgroundImage: {
      position: 'absolute',
      left: '0px',
      top: theme.spacing(8),
    },
    imageClass: {
      height: '30vh',
      width: '100vw',
    },
  },
  [theme.breakpoints.up('md')]: {
    profileBackgroundImage: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
  },
}))
function ProfileBackground(props) {
  const classes = useStyles()
  const {
    config: {
      navigation: { bgImg },
      isDefaultBg,
    },
    profile: { type },
  } = props
  return (
    <div className={classes.profileBackgroundImage}>
      <img
        src={
          isDefaultBg
            ? type === PROFILE_TYPES.FREELANCER
              ? ELBGImg
              : PermBGImg
            : FuturepathBGImg
        }
        alt='background'
        className={classes.imageClass}
      />
    </div>
  )
}

export default memo(ProfileBackground)
