import styled from 'styled-components'

export default (component) => styled(component)`
  width: 100%;

  .MuiListSubheader-sticky {
    color: black;
    background-color: white;
    font-size: 16px;
    width: 90%;
  }

  .MuiListSubheader-sticky::first-letter {
    text-transform: capitalize;
  }

  .section-list {
    padding: 20px 0px;
    border-bottom: 1px solid #ebebeb;
  }
`
