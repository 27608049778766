import { lazy } from 'react'

export const Interview = lazy(() => import('./interview'))

export const Interviews = lazy(() => import('./interviews'))

export const NotFound = lazy(() => import('./not-found'))

export const Properties = lazy(() => import('./properties'))

export const Questions = lazy(() => import('./questions'))
