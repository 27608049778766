import FormLabel from '@mui/material/FormLabel'
import { Form, Formik } from 'formik'
import update from 'immutability-helper'
import flow from 'lodash/flow'
import get from 'lodash/get'
import uniqueId from 'lodash/uniqueId'
import React from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { SKILLS_LIMIT, TOP_SKILLS_LIMIT } from 'apps/portal/common/constants'
import { SaveTab } from 'apps/portal/components/SaveTab'
import { capitalize } from 'common/utils'
import { FormWarnOnLeave } from 'components/FormWarnOnLeave'
import { withNotifications } from 'hocs'

import { onCancel, onSubmit } from '../common'

import { RegularSkillsForm } from './RegularSkillsForm'
import { TopSkillsForm } from './TopSkillsForm'

class SkillsFormComponent extends React.Component {
  constructor(props) {
    super(props)
    this.onCancel = onCancel.bind(this)
    this.onSubmit = onSubmit.bind(this)
    this.state = {
      clickCancel: false,
    }
  }

  addSkill = (setter) => (skills) => (index) => (skill) => {
    const addedSkill = {
      id: `regularSkill_${uniqueId()}`,
      skill,
      order: index,
    }

    const updatedSkills = update(skills, {
      [index]: { $set: addedSkill },
    })

    setter(updatedSkills)
  }

  addTopSkill = (setter) => (skills) => () => {
    const blankSkill = {
      skill: {},
      id: `topSkill_${uniqueId()}`,
      order: skills.length + 1,
    }

    setter([...skills, blankSkill])
  }

  editSkill = (setter) => (skills) => (index) => (field) => (value) => {
    const editedSkills = update(skills, {
      [index]: { $merge: { [field]: value } },
    })
    setter(editedSkills)
  }

  removeSkill = (setter) => (skills) => (index) => (e) => {
    const filteredSkills = update(skills, {
      $splice: [[index, 1]],
    })

    setter(filteredSkills)
  }

  skillIds = (skills = []) =>
    skills.map((profileSkill) => get(profileSkill, 'skill.id'))

  validationSchema = () => {
    const { t } = this.props
    const today = new Date()
    const dateSchema = Yup.date().typeError(t('dateInvalidLabel'))

    return Yup.object({
      skills: Yup.array(
        Yup.object({
          skill: Yup.object({
            id: Yup.string().required(t('skillRequiredLabel')),
            name: Yup.string(),
          }),
          startedAt: Yup.lazy((value, { path }) => {
            const group = path.match(/\[(\d+)\]/)[1]
            const index = parseInt(group)

            if (index < TOP_SKILLS_LIMIT) {
              return dateSchema
                .max(today, t('dateFutureErrorLabel'))
                .required(t('dateRequiredLabel'))
            }
            return dateSchema
          }),
        })
      ).max(SKILLS_LIMIT, t('skillsLimit', { number: SKILLS_LIMIT })),
    })
  }

  renderForm = ({
    values,
    handleSubmit,
    errors,
    dirty,
    isSubmitting,
    setFieldValue,
  }) => {
    const skills = values.skills || []
    const skillIds = this.skillIds(skills)

    const topSkills = skills.slice(0, TOP_SKILLS_LIMIT)
    const regularSkills = skills.slice(TOP_SKILLS_LIMIT)
    const setter = (skills) => setFieldValue('skills', skills)
    const onFind = (find) => find(values.skills)
    const onSort = (sort) => setter(sort(values.skills))

    const skillProps = {
      skillIds,
      onFind,
      onSort,
      errors,
      topSkills,
      skills,
      regularSkills,
      setter,
      addSkill: this.addSkill(setter)(values.skills),
      addTopSkill: this.addTopSkill(setter)(values.skills),
      editSkill: this.editSkill(setter)(values.skills),
      removeSkill: this.removeSkill(setter)(values.skills),
      setFieldValue,
    }

    return (
      <Form>
        <FormWarnOnLeave shouldWarn={dirty && !this.state.clickCancel} />
        <TopSkillsForm {...skillProps} />
        <RegularSkillsForm {...skillProps} />
        {typeof errors.skills === 'string' && (
          <FormLabel error>{capitalize(errors.skills)}</FormLabel>
        )}
        <SaveTab
          disableSave={!dirty || isSubmitting}
          onSave={handleSubmit}
          onCancel={this.onCancel}
        />
      </Form>
    )
  }

  render() {
    const {
      profile: { skills },
      fail,
      t,
    } = this.props

    const formikProps = {
      initialValues: { skills },
      validationSchema: this.validationSchema,
      onSubmit: (formData, { resetForm }) =>
        this.onSubmit(formData, { resetForm }, fail, t),
      validateOnChange: false,
      validateOnBlur: false,
    }

    return <Formik {...formikProps} render={this.renderForm} />
  }
}

const SkillsForm = flow(
  withTranslation('profileEditSkills'),
  withNotifications
)(SkillsFormComponent)

export { SkillsForm }
