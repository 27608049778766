/* eslint-disable */
import * as React from 'react'
import { func, bool } from 'prop-types'
import { Formik } from 'formik'
import { SaveTab } from 'apps/portal/components/SaveTab'
import { FormWarnOnLeave } from 'components/FormWarnOnLeave'
import { withTranslation } from 'react-i18next'
import { Autosuggest } from 'components/Autosuggest'
import { LocationService } from 'sdk/location/Service'
import { TextField } from 'components'
import * as Yup from 'yup'
import { get, isEmpty } from 'lodash'
import { NullID } from 'common/constants'

class PaymentComponent extends React.Component {
  static propTypes = {
    onChange: func,
    onBlur: func,
    isSubmitting: bool,
    t: func,
  }

  constructor(props) {
    super(props)
    this.changes = {}
    this.values = this.props.values
  }

  async getCountries(name) {
    const res = await LocationService.QueryCountries({ name })
    return (res.data.data || []).map((x) => ({
      label: x.name,
      value: x,
    }))
  }

  onLocationSelect = (setter) => (val) => {
    setter('locationVat', val)
    return val.name
  }

  getValidationSchema = (translate) => {
    const { object, string } = Yup

    return object({
      locationVat: object({
        id: string(),
        name: string(),
      }),
      vat: string(),
    })
  }

  isVatLocationPopulated = (vatLocation) => {
    return isEmpty(vatLocation) || vatLocation.id === NullID
  }

  render() {
    const { t, profile, onSubmit } = this.props
    return (
      <Formik
        initialValues={{ ...profile }}
        onSubmit={onSubmit}
        validationSchema={this.getValidationSchema(t)}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          values,
          dirty,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          isSubmitting,
          setFieldValue,
          handleReset,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormWarnOnLeave shouldWarn={dirty} />
            <Autosuggest
              defaultValue={
                (values.locationVat && values.locationVat.name) || ''
              }
              async
              onSelect={this.onLocationSelect(setFieldValue)}
              service={this.getCountries}
              label={t('tax_location')}
              error={get(errors, 'locationVat.id', '')}
              onInputClear={() => {
                setFieldValue('vat', '')
                setFieldValue('locationVat', {})
              }}
              key={(values.locationVat && values.locationVat.name) || ''}
            />

            <TextField
              label={t('tax_id')}
              name='vat'
              onBlur={handleBlur}
              onChange={(e) => {
                setFieldValue('vat', e.target.value)
              }}
              error={errors.vat && touched.vat}
              helperText={errors.vat}
              value={
                !this.isVatLocationPopulated(values.locationVat)
                  ? values.vat
                  : ''
              }
              disabled={
                isSubmitting || this.isVatLocationPopulated(values.locationVat)
              }
            />

            <TextField
              label={t('hourly_rate')}
              inputProps={{
                disabled: true,
              }}
              onBlur={handleBlur}
              helperText={t('hourly_rate_helper')}
              value={values.hourlyRateMin}
              disabled={isSubmitting}
              name={'hourlyRateMin'}
            />
            <SaveTab
              onSave={handleSubmit}
              onCancel={handleReset}
              disableSave={!dirty || isSubmitting}
              disableCancel={!dirty || isSubmitting}
            />
          </form>
        )}
      </Formik>
    )
  }
}

const PaymentForm = withTranslation('account_form')(PaymentComponent)
export { PaymentForm }
