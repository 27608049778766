import { createSelectorHook } from 'react-redux'

import scorecardContext from '../context'

const useScorecardSelector = () => {
  const useSelector = createSelectorHook(scorecardContext)

  return useSelector
}

export default useScorecardSelector
