import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { getDimensionQuestions } from '../api'

export const fetchDimensionQuestions = createAsyncThunk(
  'dimensions/questions',
  async (payload) => {
    const { subDimensionId, skillId } = payload
    const response = await getDimensionQuestions(payload)
    return { ...response.data, subDimensionId, skillId }
  }
)

const questionsSlice = createSlice({
  name: 'questions',
  initialState: { value: {} },
  extraReducers: {
    [fetchDimensionQuestions.fulfilled]: (state, { payload }) => {
      const itemId = payload.skillId || payload.subDimensionId

      state.value[itemId] = payload
      return state
    },
  },
})

export default questionsSlice.reducer
