import { useCallback } from 'react'

import { useExpertlead } from 'apps/scorecard/hooks/clients'

export default () => {
  const { post } = useExpertlead()
  const API = useCallback(({ data }) => post('/question/suggest', data), [post])

  return API
}
