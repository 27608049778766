import { Box, CircularProgress, Typography } from '@mui/material'

import {
  CASE_STUDY,
  GENERIC_QUESTIONS,
  LANGUAGES_AND_FRAMEWORKS,
  LIVE_CODING,
} from 'apps/scorecard-v2/constants'

import Score from './score'
import {
  StyledOverallScore,
  StyledCircleWrapper,
  StyledScoreText,
} from './style'

const OverallScore = ({ scores, isNonTechnical }) => {
  const totalScore = Object.values(scores).reduce((acc, curr, _, list) => {
    acc += curr.computedScore / list.length
    return acc
  }, 0)

  return (
    <StyledOverallScore>
      <Box flex='1'>
        <Score
          score={scores[LIVE_CODING]?.computedScore}
          label={scores[LIVE_CODING]?.label}
          hasError={scores[LIVE_CODING]?.hasError}
          tabName={isNonTechnical ? CASE_STUDY : LIVE_CODING}
        />
        <Score
          score={scores[GENERIC_QUESTIONS]?.computedScore}
          tabName={GENERIC_QUESTIONS}
          label={scores[GENERIC_QUESTIONS]?.label}
          hasError={scores[GENERIC_QUESTIONS]?.hasError}
        />
        <Score
          score={scores[LANGUAGES_AND_FRAMEWORKS]?.computedScore}
          tabName={LANGUAGES_AND_FRAMEWORKS}
          label={scores[LANGUAGES_AND_FRAMEWORKS]?.label}
          hasError={scores[LANGUAGES_AND_FRAMEWORKS]?.hasError}
        />
      </Box>
      <StyledCircleWrapper score={totalScore}>
        <CircularProgress variant='determinate' value={100} size='10rem' />
        <CircularProgress
          className='score'
          variant='determinate'
          value={totalScore * 10}
          size='10rem'
        />
        <StyledScoreText score={totalScore}>
          <Typography>Overall Score</Typography>
          <Box display='flex' justifyContent='center' alignItems='center'>
            <Typography component='span' className='score'>
              {totalScore.toFixed(1)}
            </Typography>
            <Typography component='span'>&nbsp;/&nbsp;10</Typography>
          </Box>
        </StyledScoreText>
      </StyledCircleWrapper>
    </StyledOverallScore>
  )
}

export default OverallScore
