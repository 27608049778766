import styled, { css } from 'styled-components'

export const adapt =
  () =>
  ({ loading }) =>
    !!loading &&
    css`
      .MuiPaper-root {
        background: none;
        box-shadow: none;
        height: 5vmax;
        overflow: hidden;
        width: 5vmax;
      }
    `

export default (component) => styled(component)`
  ${adapt()};
`
