import 'date-fns'
import DeleteIcon from '@mui/icons-material/Delete'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import withStyles from '@mui/styles/withStyles'
import { get, flow } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'

import { LocationAutosuggest } from 'apps/portal/components/LocationAutosuggest'
import { DatePicker, TextField } from 'components'

import { PlaceAutosuggest } from './PlaceAutosuggest'

const styles = (theme) => ({
  controls: {
    alignItems: 'center',
  },
  control: {
    display: 'flex',
    justifyContent: 'center',
  },
})

class EducationFormComponent extends React.Component {
  static propTypes = {
    education: PropTypes.object,
  }

  render() {
    const { errors, onChange, education, classes, outerIndex } = this.props

    return (
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
      >
        <Grid item xs={12}>
          <Grid
            container
            direction='column'
            justifyContent='space-between'
            alignItems='stretch'
          >
            <Grid container className={classes.controls}>
              <Grid item xs={10}>
                <Box paddingTop={2} paddingBottom={2}>
                  <PlaceAutosuggest
                    error={get(errors, 'school', '')}
                    label={this.props.t('profile_education_school_label')}
                    newPlaceLabel={this.props.t('add_new_school')}
                    value={education.school}
                    onChange={(value) => onChange('school', value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={1} className={classes.control}>
                <IconButton
                  id='delete-education'
                  onClick={this.props.removeEducation(education)}
                  p={1.5}
                  size='large'
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item>
              <LocationAutosuggest
                error={get(errors, 'location.id', '')}
                label={this.props.t('profile_education_location_label')}
                value={education.location}
                onChange={(value) => onChange('location', value)}
              />
            </Grid>
            <Grid item>
              <TextField
                name={`educations[${outerIndex}].degree`}
                label={this.props.t('profile_education_degree_label')}
                value={get(education, 'degree', '')}
                onChange={(event) => onChange('degree', event.target.value)}
                error={!!errors.degree}
                helperText={errors.degree || ''}
                data-automator={`educations[${outerIndex}].degree`}
                inputProps={{ maxLength: 100 }}
                maxLength={100}
              />
            </Grid>

            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <DatePicker
                        id={`from${education.id}`}
                        disableFuture
                        error={!!errors.startDate}
                        helperText={get(errors, 'startDate', '')}
                        label={this.props.t(
                          'profile_education_start_date_label'
                        )}
                        value={education.startDate || null}
                        onChange={(date) => onChange('startDate', date)}
                        KeyboardButtonProps={{
                          'aria-label': this.props.t(
                            'profile_education_choose_date_text'
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <DatePicker
                        id={`to${education.id}`}
                        label={this.props.t('profile_education_end_date_label')}
                        value={education.endDate || null}
                        onChange={(date) => onChange('endDate', date)}
                        KeyboardButtonProps={{
                          'aria-label': this.props.t(
                            'profile_education_choose_date_text'
                          ),
                        }}
                        minDate={education.startDate}
                        helperText={get(errors, 'endDate', '')}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export const EducationForm = flow(
  withTranslation('profileEditEducation'),
  withStyles(styles)
)(EducationFormComponent)
