import castArray from 'lodash/castArray'
import { useMemo } from 'react'

import { useElements } from 'apps/scorecard/hooks'

export const useQuestion = ({ children, ...props }) => {
  const { findBy } = useElements({ children })
  const skills = useMemo(() => castArray(props.skills), [props.skills])

  return { findBy, skills }
}
