import { Box, Typography } from '@mui/material'

import { useTracking } from 'apps/scorecard/hooks'

import Marks from './marks'
import { SliderWrapper, StyledSlider, ScoreContainer } from './style'

const QuestionSlider = ({ handleItemSave, itemScore, setItemScore }) => {
  const handleScoreChange = (_, value) => {
    setItemScore(value)
  }

  const tracking = useTracking()
  const eventTracking = (label, action = 'Click') => {
    tracking.event({
      category: 'Scorecard-v2',
      action,
      label: `${action}_${label}`,
    })
  }

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      mb={3}
    >
      <SliderWrapper>
        <StyledSlider
          value={itemScore}
          valueLabelDisplay='auto'
          step={1}
          marks
          min={0}
          max={10}
          onChange={handleScoreChange}
          onChangeCommitted={handleItemSave}
          onMouseEnter={() => eventTracking('Tooltip', 'Hover')}
        />
        <Marks
          score={itemScore}
          setItemScore={setItemScore}
          handleItemSave={handleItemSave}
        />

        <ScoreContainer
          bold={!itemScore}
          backgroundColor='#e4e4e4'
          sx={{ borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }}
          onClick={() => eventTracking('Skip')}
          width='10%'
        >
          <Typography>Skip</Typography>
        </ScoreContainer>
        <ScoreContainer
          bold={itemScore > 0 && itemScore < 2}
          backgroundColor='#fcebed'
          onClick={() => eventTracking('No_Idea')}
          width='10%'
        >
          <Typography>No idea</Typography>
        </ScoreContainer>

        <ScoreContainer
          bold={itemScore >= 2 && itemScore < 3}
          backgroundColor='#fad9dc'
          onClick={() => eventTracking('Wrong')}
          width='10%'
        >
          <Typography>Wrong</Typography>
        </ScoreContainer>
        <ScoreContainer
          bold={itemScore >= 3 && itemScore < 5}
          backgroundColor='#fee6d9'
          onClick={() => eventTracking('Poor')}
        >
          <Typography>Poor</Typography>
        </ScoreContainer>

        <ScoreContainer
          bold={itemScore >= 5 && itemScore < 7}
          backgroundColor='#fdf7d6'
          onClick={() => eventTracking('Okay')}
        >
          <Typography>Okay</Typography>
        </ScoreContainer>
        <ScoreContainer
          bold={itemScore >= 7 && itemScore < 9}
          backgroundColor='#cbe8fa'
          onClick={() => eventTracking('Good')}
        >
          <Typography>Good</Typography>
        </ScoreContainer>
        <ScoreContainer
          bold={itemScore >= 9}
          backgroundColor='#d6f4e5'
          sx={{ borderTopRightRadius: '5px', borderBottomRightRadius: '5px' }}
          onClick={() => eventTracking('Great')}
        >
          <Typography>Great</Typography>
        </ScoreContainer>
      </SliderWrapper>
    </Box>
  )
}

export default QuestionSlider
