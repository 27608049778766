const PAGES = {
  LOGIN: 'login',
  LOGIN_FORGOT_PASSWORD: 'login/forgot-password',
  LOGIN_RESET_PASSWORD: 'login/reset-password',
  THIRD_PARTY: 'third-party',
  REGISTER: 'register',
  DASHBOARD: 'dashboard',
  PROFILE: 'profile',
  EXPERT_PROFILE: 'expert',
  PROFILE_ABOUT_ME_SECTION: 'profile/about-me',
  PROFILE_SKILLS_SECTION: 'profile/skills',
  PROFILE_EDUCATION_SECTION: 'profile/education',
  PROFILE_EXPERIENCE_SECTION: 'profile/experience',
  PROFILE_CERTIFICATION_SECTION: 'profile/certification',
  PROFILE_EDIT: 'profile/edit',
  PROFILE_EDIT_ABOUT_ME_SECTION: 'profile/edit/about-me',
  PROFILE_EDIT_SKILLS_SECTION: 'profile/edit/skills',
  PROFILE_EDIT_EDUCATION_SECTION: 'profile/edit/education',
  PROFILE_EDIT_EXPERIENCE_SECTION: 'profile/edit/experience',
  PROFILE_EDIT_CERTIFICATION_SECTION: 'profile/edit/certification',
  ACCOUNT: 'account',
  ACCOUNT_PAYMENT: 'account/payment',
  ACCOUNT_COMMUNICATION: 'account/communication',
  ACCOUNT_PREFERENCES: 'account/preferences',
  ACCOUNT_CHANGE_PASSWORD: 'account/password-change',
  REFERRALS: 'referrals',
  SERVICES: 'services',
  EVENTS: 'events',
  FAQ: 'faq',
  FAQ_PERSONAL: 'faq/your-personal-information-and-expertlead-profile',
  FAQ_PROJECT: 'faq/project-related-information',
  FAQ_COMMUNITY: 'faq/community-efforts',
  INTERVIEWER: 'scorecard',
  VIEW_CV: 'view-profile-cv',
  LOGOUT: 'logout',
  ROOT: '',
  JOB_PAGE: 'job',
}

/**
 * The URL to use to images when their actual src is undefined
 */
const MISSING_IMG_URL = '/thumbnail_default.png'

/**
 * The constant to pass to datepickers or any date formatter
 * Currently used for datepickers in forms
 */
const PORTAL_DATES_FORMAT = 'MM/dd/yyyy'

/**
 * The constant to pass to date formatter (mostly momentjs)
 * Currently used for backend services into requests
 */
const SERVICES_DATES_FORMAT = 'dd-mm-yyyy'

const SERVICES_TIMES_FORMAT = 'T00:00:00Z'

/**
 * Used as timeout ms to scroll elements when adding
 */
const TIME_TO_SCROLL_MS = 100

/**
 * Used as default debounce delay in async operations
 */
const DEBOUNCE_DELAY = 300

const TOP_SKILLS_LIMIT = 5
const SKILLS_LIMIT = 45
const ONE_YEAR_EXPERIENCE = 1

const DASHBOARD_DATES_FORMAT = 'dd-MM-yyyy'

const AVAILABILITY_OPTIONS = {
  HOURS_STEP: 5,
  HOURS_LENGTH: 8,
  HOURS_MAX: 40,
}

const PLACE_TYPE = {
  TYPE_ORGANISATION: 'TYPE_ORGANISATION',
  TYPE_SCHOOL: 'TYPE_SCHOOL',
}

const PLACE_STATUS = {
  STATUS_UNDER_REVIEW: 'STATUS_UNDER_REVIEW',
  STATUS_PENDING: 'STATUS_PENDING',
  STATUS_REJECTED: 'STATUS_REJECTED',
  STATUS_PUBLISHED: 'STATUS_PUBLISHED',
}

const DATETIME_FORMAT = 'DD/MM/YYYY HH:MM'
const DATE_FORMAT = 'DD/MM/YYYY'

const HUBSPOT_URL = 'https://app.hubspot.com/contacts/5492847/deal/'

const CERTIFICATION_DATE_FORMAT = 'MM.DD.yyyy'

const PUBLIC_PROFILE_HEADING = {
  ABOUT: 'About',
  SKILLS: 'Skills',
  EXPERIENCE: 'Experiences',
  EDUCATION: 'Educations',
  CERTIFICATIONS: 'Certifications',
}

const LIVE_CODING_VERTICALS = ['Development', 'Data Science']
const COMPLETED_CASE_STUDY_VERTICALS = ['PM/PO']

const ANALYTICS_TAGS = {
  PUBLIC_PROFILE: {
    category: 'Public Profile',
    action: 'Open',
    label: null,
  },
}

const ANALYTICS_ACTIONS = {
  COPY_URL: 'Copy Profile URL',
  BADGE_HELP_TEXT: 'Badge help text',
  READ_MORE: 'Read More',
  CONTACT_US: 'Contact us',
}

const JOB_APPROACH_RESPONSE = {
  APPROACH_RESPONSE_INTERESTED: 'APPROACH_RESPONSE_INTERESTED',
  APPROACH_RESPONSE_NOT_INTERESTED: 'APPROACH_RESPONSE_NOT_INTERESTED',
}

const NOT_INTERESTED_OPTIONS = [
  'Not enough information provided about the job',
  "The project timeline doesn't fit with my schedule",
  'The project fee or compensation is too low',
  'Not enough experience or expertise to complete the job',
  'The job is not related to my skills or interests',
  'Unreasonable or unclear expectations for the project',
  'The project conflicts with my values and beliefs',
  'I have already committed to other projects or clients',
  'Personal or professional circumstances prevent me from accepting this job.',
  'Other',
]

const CALENDLY_LINK = 'https://calendly.com/freelance-intro/30min?month=2023-04'

export {
  DEBOUNCE_DELAY,
  PAGES,
  MISSING_IMG_URL,
  PORTAL_DATES_FORMAT,
  DASHBOARD_DATES_FORMAT,
  SERVICES_DATES_FORMAT,
  SERVICES_TIMES_FORMAT,
  ONE_YEAR_EXPERIENCE,
  TIME_TO_SCROLL_MS,
  AVAILABILITY_OPTIONS,
  SKILLS_LIMIT,
  TOP_SKILLS_LIMIT,
  PLACE_STATUS,
  PLACE_TYPE,
  DATETIME_FORMAT,
  DATE_FORMAT,
  HUBSPOT_URL,
  CERTIFICATION_DATE_FORMAT,
  PUBLIC_PROFILE_HEADING,
  LIVE_CODING_VERTICALS,
  COMPLETED_CASE_STUDY_VERTICALS,
  ANALYTICS_TAGS,
  ANALYTICS_ACTIONS,
  NOT_INTERESTED_OPTIONS,
  CALENDLY_LINK,
  JOB_APPROACH_RESPONSE,
}
