/* eslint-disable consistent-return */
import { get, map, memoize } from 'lodash'
import RichTextEditor from 'react-rte/lib/RichTextEditor'

import { TOP_SKILLS_LIMIT } from 'apps/portal/common/constants'
import {
  AVAILABILITY_HOURS_OPTIONS,
  FORM_FIELDS,
  EMPLOYMENT_TYPE_FILTER,
  ASSIGNEE_TYPE,
  HIREABLE_TYPE,
} from 'common/constants'
import { capitalize } from 'common/utils'
import {
  Autosuggest,
  Checkbox,
  DatePicker,
  Dropdown,
  EditAvatar,
  Educations,
  GenderRadioField,
  PasswordField,
  SeniorityPoolRadioField,
  Skills,
  TestedSkills,
  Slider,
  SpokenLanguages,
  Switch,
  TextField,
  PhoneNumberField,
  RichTextEditorField,
  CvUpload,
} from 'components/v2'
import { AvailabilityRadioField } from 'components/v2/form_fields/AvailabilityRadioField'
import { Certifications } from 'components/v2/form_fields/certifications'
import { Experiences } from 'components/v2/form_fields/experiences'
import { AccountsService } from 'sdk/account/Service'
import { CategoryService } from 'sdk/category/Service'
import { LanguageService } from 'sdk/language/Service'
import { LocationService } from 'sdk/location/Service'
import { PlaceService } from 'sdk/place/Service'
import { ProfileService } from 'sdk/profile/Service'

import {
  validLoginPassword,
  validString,
  validObject,
  validScore,
  validEmail,
  validRate,
  getSpokenLanguagesValidation,
  validCountryCode,
} from '../../validation'

const customMemoize = (fn) => memoize((name) => fn({ name }))

const queryCategories = customMemoize(CategoryService.QueryCategories)
const queryCities = customMemoize(LocationService.QueryCities)
const queryCountries = customMemoize(LocationService.QueryCountries)
const queryManagers = customMemoize(AccountsService.queryManagers)
const queryPlaces = customMemoize(PlaceService.QueryPlaces)
const queryLanguages = customMemoize(LanguageService.QueryLanguages)

const getConfig = ({ field, translate, overrides }) => {
  const {
    AUTOSUGGEST_CITIES,
    AUTOSUGGEST_COUNTRIES,
    CHECKBOX_AVAILABLE_FOR_TRAVEL,
    CHECKBOX_OPEN_TO_RELOCATION,
    CUSTOM_SPOKEN_LANGUAGES,
    CUSTOM_SKILLS,
    TESTED_SKILLS,
    CUSTOM_AVATAR,
    CV_UPLOAD,
    CUSTOM_EDUCATIONS,
    CUSTOM_EXPERIENCES,
    CUSTOM_CERTIFICATIONS,
    DATEPICKER_AVAILABILITY,
    RADIO_AVAILABILITY,
    DROPDOWN_EMAIL,
    DROPDOWN_FOCUS_ROLE,
    DROPDOWN_WORK_PREFERENCES,
    DROPDOWN_WORK_SETUP,

    TEXTFIELD_EMAIL,
    TEXTFIELD_PASSWORD,
    TEXTFIELD_FIRST_NAME,
    TEXTFIELD_WEBSITE,
    TEXTFIELD_ADDRESS,
    TEXTFIELD_LAST_NAME,
    TEXTFIELD_HEADLINE,
    TEXTFIELD_NOTICE_PERIOD,
    TEXTFIELD_NOTE,
    RADIO_GENDER,
    RADIO_SENIORITY_POOL,
    SLIDER_AVAILABILITY_HOURS,
    SWITCH_AVAILABILITY,
    SWITCH_REMEMBER_ME,
    TEXTFIELD_SCORE,
    AUTOSUGGEST_FREELANCE_MANAGER,
    TEXTFIELD_MOBILE,
    TEXTFIELD_POSTAL_CODE,
    TEXTFIELD_GITHUB,
    TEXTFIELD_LINKEDIN,
    TEXTFIELD_HUBSPOT,
    DATEPICKER_DOB,
    TEXTFIELD_BIO,
    TEXTFIELD_TAX_ID,
    TEXTFIELD_HOURLY_RATE,
    TEXTFIELD_ANNUAL_SALARY,
    TEXTFIELD_REFERRAL_CODE,
    AUTOSUGGEST_LOCATION_VAT,
    TEXTFIELD_LOCATION,
    DROPDOWN_EMPLOYMENT,
    DROPDOWN_ASSIGNEE,
    TEXTFIELD_NAME,
    ONLY_VALID_WEBSITE,
    DROPDOWN_HIREABLE,
    TEXTFIELD_BIO_OR,
    TEXTFIELD_BIO_AND,
    TEXTFIELD_NEW_EMAIL,
    TEXTFIELD_CURRENT_EMAIL,
    TEXTFIELD_PREFERRED_RATE,
    TEXTFIELD_PREFERRED_SALARY,
    RICH_TEXTFIELD_RECRUITERS_INSIGHTS,
  } = FORM_FIELDS

  const fieldMap = {
    [AUTOSUGGEST_CITIES]: (() => {
      const buildLabel = (location) => {
        if (!location || !location.name) return
        return `${capitalize(location.accent)}, ${location.country.name}`
      }

      return {
        name: AUTOSUGGEST_CITIES,
        value: {},
        component: Autosuggest,
        props: {
          id: AUTOSUGGEST_CITIES,
          label: translate('cities'),
          async: true,
          service: async (input) => {
            const res = await queryCities(input)
            return get(res, 'data.data', []).map((x) => ({
              label: buildLabel(x),
              value: x,
            }))
          },
          buildLabel,
        },
        validation: validObject(translate('cities')),
      }
    })(),
    [AUTOSUGGEST_COUNTRIES]: (() => {
      const buildLabel = (location) => {
        if (!location || !location.name) return
        return location.name
      }

      return {
        name: AUTOSUGGEST_COUNTRIES,
        value: {},
        component: Autosuggest,
        props: {
          id: AUTOSUGGEST_COUNTRIES,
          label: translate('countries'),
          async: true,
          service: async (input) => {
            const res = await queryCountries(input)
            return get(res, 'data.data', []).map((x) => ({
              label: buildLabel(x),
              value: x,
            }))
          },
          buildLabel,
        },
        validation: validObject(translate('countries')),
      }
    })(),
    [AUTOSUGGEST_FREELANCE_MANAGER]: (() => {
      const buildLabel = (item) => {
        if (!item || !item.firstName) return
        return `${capitalize(item.firstName)}, ${item.lastName}`
      }
      return {
        name: AUTOSUGGEST_FREELANCE_MANAGER,
        value: {},
        component: Autosuggest,
        props: {
          id: AUTOSUGGEST_FREELANCE_MANAGER,
          label: translate('freelanceManager'),
          async: true,
          service: async (input) => {
            const res = await queryManagers(input)
            return get(res, 'data.data', []).map((x) => ({
              label: buildLabel(x),
              value: x,
            }))
          },
          buildLabel,
        },
        validation: validObject(translate('freelanceManager')),
      }
    })(),
    [AUTOSUGGEST_LOCATION_VAT]: (() => {
      const buildLabel = (location) => {
        if (!location || !location.name) return
        return location.name
      }
      return {
        name: AUTOSUGGEST_LOCATION_VAT,
        value: {},
        component: Autosuggest,
        props: {
          id: AUTOSUGGEST_LOCATION_VAT,
          label: translate('locationVat'),
          async: true,
          service: async (input) => {
            const res = await queryCountries(input)
            return get(res, 'data.data', []).map((x) => ({
              label: buildLabel(x),
              value: x,
            }))
          },
          buildLabel,
        },
      }
    })(),
    [DATEPICKER_AVAILABILITY]: {
      name: DATEPICKER_AVAILABILITY,
      value: null,
      component: DatePicker,
      props: {
        id: DATEPICKER_AVAILABILITY,
        label: translate('availabilityDate'),
        hasTooltip: true,
        disablePast: true,
        tooltipLabel: translate('availabilityTooltip'),
      },
    },
    [CUSTOM_AVATAR]: {
      name: CUSTOM_AVATAR,
      value: {},
      component: EditAvatar,
      props: {
        titleLabel: translate('avatarTitle'),
        btnCancelLabel: translate('avatarButtonCancel'),
        btnConfirmLabel: translate('avatarButtonConfirm'),
        invalidDimensionsErrorMessage: (dimensions) =>
          translate('avatarInvalidDimensionsError', { dimensions }),
        profileService: ProfileService,
      },
    },
    [CV_UPLOAD]: {
      name: CV_UPLOAD,
      value: {},
      component: CvUpload,
      props: {},
    },
    [CUSTOM_SPOKEN_LANGUAGES]: {
      name: CUSTOM_SPOKEN_LANGUAGES,
      value: [],
      component: SpokenLanguages,
      props: {
        languagesAutosuggest: (() => {
          const buildLabel = (language) => `${capitalize(language.name)}`

          return {
            component: Autosuggest,
            label: translate('spokenLanguage'),
            async: true,
            service: async (input) => {
              const res = await queryLanguages(input)
              return get(res, 'data.data', []).map((x) => ({
                label: buildLabel(x),
                value: x,
              }))
            },
            buildLabel,
            value: {},
          }
        })(),
        proficiencyDropdown: {
          label: translate('spokenLanguageProficiency'),
        },
      },
      validation: getSpokenLanguagesValidation({
        spokenLanguageNameError: translate('spokenLanguageError'),
        spokenLanguageProficiencyError: translate(
          'spokenLanguageProficiencyError'
        ),
      }),
    },
    [CUSTOM_SKILLS]: {
      name: CUSTOM_SKILLS,
      value: [],
      component: Skills,
      props: {
        regularSkillsWidth: 6,
        isOnlyRegularSkills: false,
        skillsAutosuggest: (() => {
          const buildLabel = (skill) => get(skill, 'name', '')

          return {
            component: Autosuggest,
            buildTopSkillsLabel: (index) =>
              translate('topSkillsAutosuggestLabel', {
                index,
              }),
            buildRegularSkillsLabel: (number) =>
              translate('regularSkillsAutosuggestLabel', {
                number,
              }),
            async: true,
            clearAfterSelection: true,
            service: async (input) => {
              const res = await queryCategories(input)
              return (res.data.data || []).map((x) => ({
                label: buildLabel(x),
                value: x,
              }))
            },
            buildLabel,
            value: {},
          }
        })(),
        skillsDatePicker: {
          component: DatePicker,
          fromLabel: translate('skillsDatepickerFrom'),
        },
        topSkillsLabel: translate('topSkills', { limit: TOP_SKILLS_LIMIT }),
        regularSkillsLabel: translate('regularSkills'),
      },
    },
    [TESTED_SKILLS]: {
      name: TESTED_SKILLS,
      value: [],
      component: TestedSkills,
      props: {
        skillsAutosuggest: (() => {
          const buildLabel = (skill) => get(skill, 'name', '')
          return {
            component: Autosuggest,
            buildSkillsLabel: (number) =>
              translate('regularSkillsAutosuggestLabel', {
                number,
              }),
            async: true,
            clearAfterSelection: true,
            service: async (input) => {
              const res = await queryCategories(input)
              return (res.data.data || []).map((x) => ({
                label: buildLabel(x),
                value: x,
              }))
            },
            buildLabel,
            value: {},
          }
        })(),
        skillsLabel: translate('testedSkills'),
      },
    },
    [CUSTOM_EDUCATIONS]: {
      name: CUSTOM_EDUCATIONS,
      value: [],
      component: Educations,
      props: {
        label: translate('educations'),
        placeAutosuggest: (() => {
          const buildLabel = (place) => get(place, 'name', '')

          return {
            component: Autosuggest,
            async: true,
            clearAfterSelection: true,
            service: async (input) => {
              const res = await queryPlaces(input)

              return get(res, 'data.data', []).map((x) => ({
                label: buildLabel(x),
                value: x,
              }))
            },
            buildLabel,
            value: {},
            label: translate('educationPlaceAutosuggestLabel'),
          }
        })(),
        locationAutosuggest: (() => {
          const buildLabel = (location) => {
            if (!location || !location.accent || !location.country) return ''

            return `${capitalize(location.accent)}, ${location.country.name}`
          }

          return {
            component: Autosuggest,
            async: true,
            clearAfterSelection: true,
            service: async (input) => {
              const res = await queryCities(input)
              return get(res, 'data.data', []).map((x) => ({
                label: buildLabel(x),
                value: x,
              }))
            },
            buildLabel,
            value: {},
            label: translate('educationLocationAutosuggestLabel'),
          }
        })(),
        degreeTextField: {
          label: translate('educationDegreeTextFieldLabel'),
          value: {},
        },
        datePicker: {
          fromLabel: translate('educationStartDatePickerLabel'),
          toLabel: translate('educationEndDatePickerLabel'),
          chooseDateLabel: translate('educationDatePickerChooseDateLabel'),
        },
      },
    },
    [CUSTOM_EDUCATIONS]: {
      name: CUSTOM_EDUCATIONS,
      value: [],
      component: Educations,
      props: {
        label: translate('educations'),
        placeAutosuggest: (() => {
          const buildLabel = (place) => get(place, 'name', '')

          return {
            component: Autosuggest,
            async: true,
            clearAfterSelection: true,
            service: async (input) => {
              const res = await queryPlaces(input)

              return get(res, 'data.data', []).map((x) => ({
                label: buildLabel(x),
                value: x,
              }))
            },
            buildLabel,
            value: {},
            label: translate('educationPlaceAutosuggestLabel'),
          }
        })(),
        locationAutosuggest: (() => {
          const buildLabel = (location) => {
            if (!location.id && location.name) return location.name
            if (!location || !location.accent || !location.country) return ''

            return `${capitalize(location.accent)}, ${location.country.name}`
          }

          return {
            component: Autosuggest,
            async: true,
            clearAfterSelection: true,
            service: async (input) => {
              const res = await queryCities(input)
              return get(res, 'data.data', []).map((x) => ({
                label: buildLabel(x),
                value: x,
              }))
            },
            buildLabel,
            value: {},
            label: translate('educationLocationAutosuggestLabel'),
          }
        })(),
        degreeTextField: {
          label: translate('educationDegreeTextFieldLabel'),
          value: {},
        },
        datePicker: {
          fromLabel: translate('educationStartDatePickerLabel'),
          toLabel: translate('educationEndDatePickerLabel'),
          chooseDateLabel: translate('educationDatePickerChooseDateLabel'),
        },
      },
    },
    [CUSTOM_CERTIFICATIONS]: {
      name: CUSTOM_CERTIFICATIONS,
      value: [],
      component: Certifications,
      props: {
        label: translate('certifications'),
        placeAutosuggest: (() => {
          const buildLabel = (place) => get(place, 'name', '')

          return {
            component: Autosuggest,
            async: true,
            clearAfterSelection: true,
            service: async (input) => {
              const res = await queryPlaces(input)

              return get(res, 'data.data', []).map((x) => ({
                label: buildLabel(x),
                value: x,
              }))
            },
            buildLabel,
            value: {},
            label: translate('issuingAuthorityAutosuggestLabel'),
          }
        })(),
        licensesCertificationTextField: {
          label: translate('licensesCertificationTextFieldLabel'),
          value: {},
        },
        datePicker: {
          issuedOnDateLabel: translate('issuedOnDateLabel'),
          expirationDateLabel: translate('expirationDateLabel'),
          chooseDateLabel: translate('chooseDateLabel'),
        },
      },
    },
    [CUSTOM_EXPERIENCES]: {
      name: CUSTOM_EXPERIENCES,
      value: [],
      component: Experiences,
      props: {
        label: translate('experiences'),
        placeAutosuggest: (() => {
          const buildLabel = (place) => get(place, 'name', '')

          return {
            component: Autosuggest,
            async: true,
            clearAfterSelection: true,
            invokeSelectWhenEmpty: true,
            service: async (input) => {
              const res = await queryPlaces(input)

              return get(res, 'data.data', []).map((x) => ({
                label: buildLabel(x),
                value: x,
              }))
            },
            buildLabel,
            value: {},
            label: translate('experiencePlaceAutosuggestLabel'),
          }
        })(),
        locationAutosuggest: (() => {
          const buildLabel = (location) => {
            if (!location?.id && location.name) return location.name
            if (!location || !location.accent || !location.country) return ''

            return `${capitalize(location.accent)}, ${location.country.name}`
          }

          return {
            component: Autosuggest,
            async: true,
            clearAfterSelection: true,
            service: async (input) => {
              const res = await queryCities(input)
              return get(res, 'data.data', []).map((x) => ({
                label: buildLabel(x),
                value: x,
              }))
            },
            buildLabel,
            value: {},
            label: translate('experienceLocationAutosuggestLabel'),
          }
        })(),

        jobTitleTextField: {
          label: translate('experienceJobTitleTextFieldLabel'),
          value: {},
        },
        datePicker: {
          fromLabel: translate('experienceStartDatePickerLabel'),
          toLabel: translate('experienceEndDatePickerLabel'),
          chooseDateLabel: translate('educationDatePickerChooseDateLabel'),
        },
        responsibilityTextField: {
          label: translate('experienceResponsibilityTextFieldLabel'),
        },
        industryLabels: {
          buildIndustryPlaceholder: (number) =>
            translate('experienceIndustryPlaceholder', {
              number,
            }),
          label: translate('experienceIndustryTextFieldLabel'),
        },
      },
    },
    [RADIO_AVAILABILITY]: {
      name: RADIO_AVAILABILITY,
      value: '',
      component: AvailabilityRadioField,
      props: {
        id: RADIO_AVAILABILITY,
        label: translate('availabilityFulltime'),
        disabled: false,
        isNative: true,
      },
    },
    [DROPDOWN_EMAIL]: {
      name: DROPDOWN_EMAIL,
      value: '',
      component: Dropdown,
      props: {
        id: DROPDOWN_EMAIL,
        label: translate('email'),
        disabled: false,
        isMultiSelect: false,
        selectOptions: [],
        isNative: true,
      },
    },
    [TEXTFIELD_CURRENT_EMAIL]: {
      name: TEXTFIELD_CURRENT_EMAIL,
      value: '',
      component: TextField,
      props: {
        id: TEXTFIELD_CURRENT_EMAIL,
        label: translate('currentEmail'),
        disabled: true,
        type: 'email',
      },
    },
    [TEXTFIELD_NEW_EMAIL]: {
      name: TEXTFIELD_NEW_EMAIL,
      value: '',
      component: TextField,
      props: {
        id: TEXTFIELD_NEW_EMAIL,
        label: translate('newEmail'),
        disabled: false,
        type: 'email',
      },
    },
    [TEXTFIELD_TAX_ID]: {
      name: TEXTFIELD_TAX_ID,
      value: '',
      component: TextField,
      props: {
        type: 'text',
        id: TEXTFIELD_TAX_ID,
        label: translate('vat'),
      },
    },
    [TEXTFIELD_MOBILE]: {
      name: TEXTFIELD_MOBILE,
      value: '',
      component: PhoneNumberField,
      props: {
        type: 'tel',
        id: TEXTFIELD_MOBILE,
        label: translate('phone'),
      },
      validation: validCountryCode(
        translate('phone'),
        translate('phoneErrorMessage')
      ),
    },
    [TEXTFIELD_POSTAL_CODE]: {
      name: TEXTFIELD_POSTAL_CODE,
      value: '',
      component: TextField,
      props: {
        type: 'number',
        id: TEXTFIELD_POSTAL_CODE,
        label: translate('postalCode'),
      },
    },
    [DROPDOWN_FOCUS_ROLE]: {
      name: DROPDOWN_FOCUS_ROLE,
      value: '',
      component: Dropdown,
      props: {
        id: DROPDOWN_FOCUS_ROLE,
        label: translate('focusRole'),
        isGroping: false,
        isMultiSelect: false,
        selectOptions: [],
      },
      validation: validString(translate('focusRole')),
    },
    [DROPDOWN_WORK_PREFERENCES]: {
      name: DROPDOWN_WORK_PREFERENCES,
      value: [],
      component: Dropdown,
      props: {
        id: DROPDOWN_WORK_PREFERENCES,
        label: translate('workPreferences'),
        isNative: false,
        isMultiSelect: true,
      },
    },
    [DROPDOWN_WORK_SETUP]: {
      name: DROPDOWN_WORK_SETUP,
      value: [],
      component: Dropdown,
      props: {
        id: DROPDOWN_WORK_SETUP,
        label: translate('workSetups'),
        isNative: false,
        isMultiSelect: true,
      },
    },
    [TEXTFIELD_EMAIL]: {
      name: TEXTFIELD_EMAIL,
      value: '',
      component: TextField,
      props: {
        type: 'email',
        id: TEXTFIELD_EMAIL,
        label: translate('email'),
      },
      validation: validEmail(translate('emailFormatError'), translate('email')),
    },
    [TEXTFIELD_PASSWORD]: {
      name: TEXTFIELD_PASSWORD,
      value: '',
      component: PasswordField,
      props: {
        id: TEXTFIELD_PASSWORD,
        label: translate('password'),
      },
      validation: validLoginPassword,
    },
    [TEXTFIELD_FIRST_NAME]: {
      name: TEXTFIELD_FIRST_NAME,
      value: '',
      component: TextField,
      props: {
        type: 'text',
        id: TEXTFIELD_FIRST_NAME,
        label: translate('firstName'),
      },
      validation: validString(translate('firstName')),
    },
    [TEXTFIELD_GITHUB]: {
      name: TEXTFIELD_GITHUB,
      value: '',
      component: TextField,
      props: {
        type: 'text',
        id: TEXTFIELD_GITHUB,
        label: translate('github'),
      },
    },
    [TEXTFIELD_LINKEDIN]: {
      name: TEXTFIELD_LINKEDIN,
      value: '',
      component: TextField,
      props: {
        type: 'text',
        id: TEXTFIELD_LINKEDIN,
        label: translate('linkedin'),
      },
    },
    [TEXTFIELD_HUBSPOT]: {
      name: TEXTFIELD_HUBSPOT,
      value: '',
      component: TextField,
      props: {
        type: 'text',
        id: TEXTFIELD_HUBSPOT,
        label: translate('hubspot'),
      },
    },
    [TEXTFIELD_ADDRESS]: {
      name: TEXTFIELD_ADDRESS,
      value: '',
      component: TextField,
      props: {
        type: 'text',
        id: TEXTFIELD_ADDRESS,
        label: translate('address'),
      },
    },
    [TEXTFIELD_WEBSITE]: {
      name: TEXTFIELD_WEBSITE,
      value: '',
      component: TextField,
      props: {
        type: 'text',
        id: TEXTFIELD_WEBSITE,
        label: translate('website'),
      },
    },
    [TEXTFIELD_LAST_NAME]: {
      name: TEXTFIELD_LAST_NAME,
      value: '',
      component: TextField,
      props: {
        type: 'text',
        id: TEXTFIELD_LAST_NAME,
        label: translate('lastName'),
      },
      validation: validString(translate('lastName')),
    },
    [TEXTFIELD_HEADLINE]: {
      name: TEXTFIELD_HEADLINE,
      value: '',
      component: TextField,
      props: {
        type: 'text',
        id: TEXTFIELD_HEADLINE,
        label: translate('headline'),
        multiline: true,
      },
      validation: validString(translate('headline')),
    },
    [TEXTFIELD_NOTICE_PERIOD]: {
      name: TEXTFIELD_NOTICE_PERIOD,
      value: '',
      component: TextField,
      props: {
        type: 'text',
        id: TEXTFIELD_NOTICE_PERIOD,
        label: translate('noticePeriod'),
      },
    },

    [TEXTFIELD_NOTE]: {
      name: TEXTFIELD_NOTE,
      value: '',
      component: TextField,
      props: {
        type: 'text',
        id: TEXTFIELD_NOTE,
        label: translate('note'),
        multiline: true,
      },
      validation: validString(translate('note')),
    },
    [DATEPICKER_DOB]: {
      name: DATEPICKER_DOB,
      value: '',
      component: DatePicker,
      props: {
        id: DATEPICKER_DOB,
        label: translate('dob'),
        hasTooltip: false,
        disableFuture: true,
        tooltipLabel: translate('dobToolTipLabel'),
      },
    },
    [TEXTFIELD_BIO]: {
      name: TEXTFIELD_BIO,
      value: '',
      component: TextField,
      props: {
        type: 'text',
        id: TEXTFIELD_BIO,
        label: translate('bio'),
        multiline: true,
      },
    },
    [TEXTFIELD_SCORE]: {
      name: TEXTFIELD_SCORE,
      value: 0,
      component: TextField,
      props: {
        type: 'number',
        id: TEXTFIELD_SCORE,
        label: translate('score'),
        inputProps: {
          step: 0.1,
          max: 10,
          min: 0,
        },
      },
      validation: validScore(translate('score')),
    },
    [TEXTFIELD_HOURLY_RATE]: {
      name: TEXTFIELD_HOURLY_RATE,
      value: '',
      component: TextField,
      props: {
        type: 'number',
        id: TEXTFIELD_HOURLY_RATE,
        label: translate('hourlyRateMin'),
        inputProps: {
          min: 0,
        },
      },
      validation: validRate(translate('hourlyRateMin')),
    },
    [TEXTFIELD_ANNUAL_SALARY]: {
      name: TEXTFIELD_ANNUAL_SALARY,
      value: '',
      component: TextField,
      props: {
        type: 'number',
        id: TEXTFIELD_ANNUAL_SALARY,
        label: translate('annualSalaryMin'),
        inputProps: {
          min: 0,
        },
      },
      validation: validRate(translate('annualSalaryMin')),
    },
    [TEXTFIELD_PREFERRED_RATE]: {
      name: TEXTFIELD_PREFERRED_RATE,
      value: '',
      component: TextField,
      props: {
        type: 'number',
        id: TEXTFIELD_PREFERRED_RATE,
        label: translate('hourlyRatePreferred'),
        inputProps: {
          min: 0,
        },
      },
      validation: validRate(translate('hourlyRatePreferred')),
    },
    [TEXTFIELD_PREFERRED_SALARY]: {
      name: TEXTFIELD_PREFERRED_SALARY,
      value: '',
      component: TextField,
      props: {
        type: 'number',
        id: TEXTFIELD_PREFERRED_SALARY,
        label: translate('annualSalaryPreferred'),
        inputProps: {
          min: 0,
        },
      },
      validation: validRate(translate('annualSalaryPreferred')),
    },
    [TEXTFIELD_REFERRAL_CODE]: {
      name: TEXTFIELD_REFERRAL_CODE,
      value: '',
      component: TextField,
      props: {
        type: 'text',
        id: TEXTFIELD_REFERRAL_CODE,
        label: translate('referralCode'),
      },
    },
    [RADIO_GENDER]: {
      name: RADIO_GENDER,
      value: 'MALE',
      component: GenderRadioField,
      props: {
        id: RADIO_GENDER,
        labelMale: translate('genderMale'),
        labelFemale: translate('genderFemale'),
      },
      validation: validString(RADIO_GENDER),
    },
    [RADIO_SENIORITY_POOL]: {
      name: RADIO_SENIORITY_POOL,
      value: 'EMERGING',
      component: SeniorityPoolRadioField,
      props: {
        id: RADIO_SENIORITY_POOL,
        labelEmerging: translate('seniorityPoolEmerging'),
        labelSenior: translate('seniorityPoolSenior'),
        labelPrincipal: translate('seniorityPoolPrincipal'),
      },
    },
    [SLIDER_AVAILABILITY_HOURS]: {
      name: SLIDER_AVAILABILITY_HOURS,
      value: 40,
      component: Slider,
      props: {
        id: SLIDER_AVAILABILITY_HOURS,
        label: translate('availabilityHours'),
        minValue: AVAILABILITY_HOURS_OPTIONS.HOURS_STEP,
        maxValue: AVAILABILITY_HOURS_OPTIONS.HOURS_MAX,
        stepValue: AVAILABILITY_HOURS_OPTIONS.HOURS_STEP,
        steps: Array.from(
          Array(AVAILABILITY_HOURS_OPTIONS.HOURS_LENGTH),
          (x, i) => ({
            label: (i + 1) * AVAILABILITY_HOURS_OPTIONS.HOURS_STEP,
            value: (i + 1) * AVAILABILITY_HOURS_OPTIONS.HOURS_STEP,
          })
        ),
      },
    },
    [SWITCH_AVAILABILITY]: {
      name: SWITCH_AVAILABILITY,
      value: false,
      component: Switch,
    },
    [SWITCH_REMEMBER_ME]: {
      name: 'rememberMe',
      value: false,
      component: Switch,
      props: {
        label: translate('rememberMe'),
      },
    },
    [TEXTFIELD_LOCATION]: {
      name: TEXTFIELD_LOCATION,
      value: [],
      component: TextField,
      props: {
        type: 'text',
        hasChips: true,
        id: TEXTFIELD_LOCATION,
        label: translate('location'),
      },
    },
    [DROPDOWN_EMPLOYMENT]: {
      name: DROPDOWN_EMPLOYMENT,
      value: 'CONTRIBUTOR_TYPE_ALL',
      component: Dropdown,
      props: {
        id: DROPDOWN_EMPLOYMENT,
        label: translate('employmentType'),
        isNative: true,
        isMultiSelect: false,
        selectOptions: map(EMPLOYMENT_TYPE_FILTER, (item, i) => ({
          label: item,
          value: i,
        })),
      },
    },
    [DROPDOWN_ASSIGNEE]: {
      name: DROPDOWN_ASSIGNEE,
      value: 'FILTER_ALL',
      component: Dropdown,
      props: {
        id: DROPDOWN_ASSIGNEE,
        label: translate('assignee'),
        isNative: true,
        isMultiSelect: false,
        selectOptions: map(ASSIGNEE_TYPE, (item, i) => ({
          label: item,
          value: i,
        })),
      },
    },
    [TEXTFIELD_NAME]: {
      name: TEXTFIELD_NAME,
      value: '',
      component: TextField,
      props: {
        type: 'text',
        id: TEXTFIELD_NAME,
        label: translate('name'),
      },
    },
    [ONLY_VALID_WEBSITE]: {
      name: ONLY_VALID_WEBSITE,
      component: Checkbox,
      props: {
        label: translate('websiteLink'),
      },
    },
    [DROPDOWN_HIREABLE]: {
      name: DROPDOWN_HIREABLE,
      value: 'HIREABLE_ALL',
      component: Dropdown,
      props: {
        id: DROPDOWN_HIREABLE,
        label: translate('hireable'),
        isMultiSelect: false,
        isNative: true,
        selectOptions: map(HIREABLE_TYPE, (item, i) => ({
          label: item,
          value: i,
        })),
      },
    },
    [TEXTFIELD_BIO_OR]: {
      name: TEXTFIELD_BIO_OR,
      value: '',
      component: TextField,
      props: {
        type: 'text',
        id: TEXTFIELD_BIO_OR,
        label: translate('bioOr'),
      },
    },
    [TEXTFIELD_BIO_AND]: {
      name: TEXTFIELD_BIO_AND,
      value: '',
      component: TextField,
      props: {
        type: 'text',
        id: TEXTFIELD_BIO_AND,
        label: translate('bioAnd'),
      },
    },
    [RICH_TEXTFIELD_RECRUITERS_INSIGHTS]: {
      name: RICH_TEXTFIELD_RECRUITERS_INSIGHTS,
      value: '',
      component: RichTextEditorField,
      props: {
        type: 'text',
        id: RICH_TEXTFIELD_RECRUITERS_INSIGHTS,
        label: translate('recruitersInsights'),
        multiline: true,
        maxLength: 800,
      },
    },
    [CHECKBOX_AVAILABLE_FOR_TRAVEL]: {
      name: CHECKBOX_AVAILABLE_FOR_TRAVEL,
      value: false,
      component: Checkbox,
      props: {
        label: translate('availableForTravel'),
      },
    },
    [CHECKBOX_OPEN_TO_RELOCATION]: {
      name: CHECKBOX_OPEN_TO_RELOCATION,
      value: false,
      component: Checkbox,
      props: {
        label: translate('openToRelocation'),
      },
    },
  }

  return (() => {
    const config = fieldMap[field]

    return {
      ...config,
      ...overrides,
      props: {
        ...(config && config.props),
        ...(overrides && overrides.props),
      },
    }
  })()
}

const getConfigWithTranslation = (translate) => (field, overrides) =>
  getConfig({ field, translate, overrides })

const getBulkConfigWithTranslation = (translate) => (fields) =>
  fields.map((item) => {
    let field = item
    let overrides = {}

    if (Array.isArray(item)) {
      ;[field, overrides] = item
    }

    return getConfigWithTranslation(translate)(field, overrides)
  })

export { getConfigWithTranslation, getBulkConfigWithTranslation }
