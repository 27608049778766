/* eslint-disable */
import flow from 'lodash/flow'
import PropTypes from 'prop-types'
import React, { createRef, Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import ReactCrop from 'react-image-crop'
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {
  CropOriginal as Reset,
  Edit as EditIcon,
  ZoomIn,
  ZoomOut,
} from '@mui/icons-material'
import 'react-image-crop/dist/ReactCrop.css'

import { ProfileService } from 'sdk/profile/Service'
import { setFormData } from 'common/upload'
import { withNotifications } from 'hocs'

const FLOATING = /[-+]?[0-9]+(\.[0-9]+)?/gi

const CROPPER_SIZE = 388

const styles = (theme) => ({
  avatar: {
    width: '100%',
    height: '100%',
    borderRadius: '0px',
  },
  button: {
    height: '40px',
    width: '40px',
  },
  file: {
    display: 'none',
  },
  dialog: {
    zIndex: '9000 !important',
  },
  zoom: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 1,
    '& .MuiIconButton-root': {
      backgroundColor: '#fff',
      '&:not(:first-child)': {
        marginTop: theme.spacing(1),
      },
    },
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '0.75rem',
    paddingBottom: 0,
    paddingTop: 0,
  },
  edit: {
    display: 'flex',
    justifyContent: 'center',
  },
})

const getInitialState = (override) => ({
  scale: 1,
  crop: {
    width: CROPPER_SIZE,
    height: CROPPER_SIZE,
    unit: 'px',
    aspect: 1,
    x: 0,
    y: 0,
  },
  src: null,
  error: null,
  ...override,
})

const promisify = (canvas) =>
  new Promise((resolve) =>
    canvas.toBlob(
      (blob) => resolve(Object.assign(blob, { lastModifiedDate: new Date() })),
      'image/png',
      1
    )
  )

class EditAvatarClass extends React.Component {
  static propTypes = {
    photo: PropTypes.object,
  }

  constructor(...params) {
    super(...params)

    this.fileInput = createRef()
    this.image = createRef()
    this.state = getInitialState()
  }

  onLoad = ({ target }) => {
    const {
      props: { t },
    } = this
    const { width, height, naturalWidth, naturalHeight } = target
    const distortion = {
      x: width / naturalWidth,
      y: height / naturalHeight,
    }
    const valid =
      Math.min(naturalWidth, naturalHeight, CROPPER_SIZE) === CROPPER_SIZE

    switch (true) {
      case !valid:
        return this.setState(
          getInitialState({
            error: t('invalid_dimensions', {
              dimensions: `${CROPPER_SIZE}x${CROPPER_SIZE}`,
            }),
          })
        )
      default:
        return this.setState({ distortion }, () =>
          target.dispatchEvent(new Event('medialoaded', { bubbles: true }))
        )
    }
  }

  showFilePrompt = () => {
    this.fileInput.current.click()
  }

  load = ({ target: { result: src } }) => this.setState({ src })

  loadFile = (file) => {
    const { load } = this
    const reader = new FileReader()

    reader.addEventListener('load', load, false)
    reader.readAsDataURL(file)
  }

  selectFile = async ({
    target: {
      files: [file],
    },
  }) => file && this.loadFile(file)

  uploadFile = async (file) => {
    const {
      props: { onChange, t, fail },
      onCropClose: close,
    } = this

    try {
      const bucket = process.env.REACT_APP_PROFILES_BUCKET_NAME
      const {
        data: { url, name, html },
      } = await ProfileService.getUploadURL({ mimeType: file.type, bucket })
      const formData = setFormData({ ...html, file })

      await ProfileService.uploadFile({ url, formData })

      this.setState(
        getInitialState({ preview: URL.createObjectURL(file) }),
        () => onChange({ bucket, name })
      )
    } catch (err) {
      if (err.response.data.message.includes('vat-invalid')) {
        fail(t('vatError'))
      }
      console.error(err)
    } finally {
      close()
    }
  }

  cropFile = () => {
    const {
      state: {
        crop: { x, y, width, height },
        distortion,
      },
      image: { current: image },
    } = this
    const {
      parentNode: {
        style: { transform },
      },
    } = image
    const canvas = Object.assign(document.createElement('canvas'), {
      width,
      height,
    })
    const [translateX, translateY, scale] = transform
      .match(FLOATING)
      .map(Number)
    const context = canvas.getContext('2d')

    context.translate(translateX - x, translateY - y)
    context.scale(scale * distortion.x, scale * distortion.y)
    context.drawImage(image, 0, 0)

    return promisify(canvas)
  }

  clearFile = () => {
    this.fileInput.current.value = null
  }

  onCropClose = () => this.setState(getInitialState(), this.clearFile)

  onCropChange = (crop) => this.setState({ crop })

  onCropComplete = (crop) => this.setState({ crop })

  onCropConfirm = () => {
    const { cropFile, uploadFile } = this

    return cropFile().then(uploadFile)
  }

  renderCropperContent = ({ zoomIn, zoomOut, resetTransform }) => {
    const {
      state: { src },
      props: { classes },
      image,
      onLoad,
    } = this

    return (
      <Fragment>
        <div className={classes.zoom}>
          <IconButton onClick={zoomIn} size="large">
            <ZoomIn />
          </IconButton>
          <IconButton onClick={resetTransform} size="large">
            <Reset />
          </IconButton>
          <IconButton onClick={zoomOut} size="large">
            <ZoomOut />
          </IconButton>
        </div>
        <TransformComponent>
          <img
            className='ReactCrop__image'
            src={src}
            onLoad={onLoad}
            ref={image}
          />
        </TransformComponent>
      </Fragment>
    );
  }

  renderCropper = () => (
    <TransformWrapper>{this.renderCropperContent}</TransformWrapper>
  )

  render() {
    const {
      state: { crop, src, preview, error },
      props: { classes, photo, t },
      fileInput,
      showFilePrompt,
      selectFile,
      renderCropper,
      onCropChange,
      onCropComplete,
      onCropClose,
      onCropConfirm,
    } = this
    const renderComponent = renderCropper()

    return (
      <Fragment>
        {!!src && (
          <Dialog
            aria-labelledby='dialog-title'
            onClose={onCropClose}
            classes={{ root: classes.dialog }}
            open
          >
            <DialogTitle id='dialog-title'>{t('title')}</DialogTitle>
            <DialogContent>
              <ReactCrop
                src={src}
                crop={crop}
                minHeight={CROPPER_SIZE}
                minWidth={CROPPER_SIZE}
                renderComponent={renderComponent}
                onComplete={onCropComplete}
                onChange={onCropChange}
                ruleOfThirds
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onCropClose} color='primary'>
                {t('cancel')}
              </Button>
              <Button onClick={onCropConfirm} color='primary' autoFocus>
                {t('confirm')}
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <Typography
          className={classes.typography}
          component='div'
          variant='body1'
        >
          <Box p={2} zIndex='modal'>
            <Avatar src={preview || photo.url} className={classes.avatar} />
          </Box>
          {!!error && (
            <Box p={2} zIndex='modal' className={classes.error}>
              {error}
            </Box>
          )}
          <Box p={2} zIndex='tooltip' className={classes.edit}>
            <Button
              className={classes.button}
              color='primary'
              variant='contained'
              onClick={showFilePrompt}
              id='edit-icon'
            >
              <EditIcon />
              <input
                className={classes.file}
                type='file'
                accept='image/*'
                ref={fileInput}
                onChange={selectFile}
              />
            </Button>
          </Box>
        </Typography>
      </Fragment>
    )
  }
}

export const EditAvatar = flow(
  withTranslation('edit_avatar'),
  withStyles(styles),
  withNotifications
)(EditAvatarClass)
