import { Box, Typography } from '@mui/material'
import styled from 'styled-components'

export const StyledHeader = styled(Box)`
  display: flex;
  background: rgba(241, 243, 246, 0.4);
  gap: 5px;
  flex-wrap: wrap;
  border-bottom: 1px solid #e6e9ef;
`

export const StyledText = styled(Typography)`
  background: #fff;
  padding: 7px;
  height: fit-content;
  border-radius: 4px;
  border: 1px solid #e6e9ef;
  color: #77738e;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  gap: 4px;

  & span {
    color: #d6d5dd;
  }
`
