import CloseIcon from '@mui/icons-material/Close'
import {
  DialogContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  IconButton,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'

import {
  StyledDialogTitle,
  StyledDialog,
  StyledDialogActions,
  StyledTextField,
  StyledButton,
  StyledRadio,
} from './style'

const Archive = ({ open, setOpen, onArchive }) => {
  const {
    unregister,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm()

  const feedbackOptions = [
    'The interview was rescheduled.',
    'The interview was cancelled.',
  ]

  const [option, setOption] = useState(feedbackOptions[0])
  const [textFeedback, setTextFeedback] = useState('')

  const isOtherOption = option === 'other'

  useEffect(() => {
    setOption(feedbackOptions[0])
  }, [open])

  const handleFeedbackChange = (event) => {
    const { value } = event.target
    setTextFeedback(value)
    setValue('textFeedback', value)
  }

  const handleOptionChange = (event) => {
    const { value } = event.target
    if (value !== 'other') {
      unregister('textFeedback')
    }
    setOption(value)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onSubmit = () => {
    const archivingReason = isOtherOption ? textFeedback : option
    onArchive(archivingReason)
    handleClose()
  }

  return (
    <>
      <StyledDialog onClose={handleClose} open={open} maxWidth='sm' fullWidth>
        <StyledDialogTitle>
          <span>What is the reason to archive this interview?</span>
          <IconButton onClick={handleClose} size='large'>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <RadioGroup
              aria-labelledby='archive-buttons-group-label'
              name='archive-buttons-group'
              onChange={handleOptionChange}
            >
              <FormControlLabel
                value={feedbackOptions[0]}
                control={<StyledRadio />}
                label={feedbackOptions[0]}
                checked={option === feedbackOptions[0]}
              />
              <FormControlLabel
                value={feedbackOptions[1]}
                control={<StyledRadio />}
                label={feedbackOptions[1]}
                checked={option === feedbackOptions[1]}
              />
              <FormControlLabel
                value='other'
                control={<StyledRadio />}
                label='Other'
                checked={isOtherOption}
              />
            </RadioGroup>

            {isOtherOption && (
              <Controller
                control={control}
                name='textFeedback'
                rules={{ required: true, minLength: 4 }}
                render={({ name, value }) => (
                  <StyledTextField
                    variant='outlined'
                    value={value}
                    name={name}
                    onChange={handleFeedbackChange}
                    size='medium'
                    color='default'
                    rows={3}
                    multiline
                    error={errors.textFeedback || ''}
                    helperText={
                      errors.textFeedback &&
                      'Please enter a reason (min 4 characters)'
                    }
                  />
                )}
              />
            )}
          </FormControl>
        </DialogContent>
        <StyledDialogActions style={{ justifyContent: 'flex-end' }}>
          <StyledButton
            sx={{ background: '#3c26de', '&:hover': { background: '#1f0f93' } }}
            variant='contained'
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog>
    </>
  )
}

export default Archive
