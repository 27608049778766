import { Factory } from '../client/Factory'

const CategoryServiceNamespace = 'category'

const CategoryServiceConfigs = {
  GetAllCategories: Factory.createRequestConfig(
    Factory.MethodGet,
    '/category-list'
  ),
  QueryCategories: Factory.createRequestConfig(Factory.MethodGet, '/category'),
}

/**
 *
 * @type {{UpdateProfile: (*|(function(string, Object): Promise<any>))}}
 */
const CategoryService = {
  GetAllCategories: Factory.makeAuthenticatedClientCall(
    CategoryServiceConfigs.GetAllCategories
  ),
  QueryCategories: Factory.makeAuthenticatedClientCall(
    CategoryServiceConfigs.QueryCategories
  ),
  QueryPublicCategories: Factory.makeClientCall(
    CategoryServiceConfigs.QueryCategories
  ),
}

export { CategoryServiceNamespace, CategoryServiceConfigs, CategoryService }
