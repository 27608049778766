import logo from 'assets/img/logo-fp.png'
import { JV_HOMEPAGE } from 'common/constants'

const config = {
  meta: {
    favicon: false,
    pageTitle: 'FuturePath Portal',
  },
  navigation: {
    logo: {
      image: logo,
      altText: 'FuturePath',
    },
    links: {
      login: false,
      aboutUs: false,
      whyExpertlead: false,
      services: false,
      referrals: false,
      faq: false,
      interviewer: false,
      events: true,
    },
    contactNumber: false,
    homePageUrl: JV_HOMEPAGE,
  },
  pages: {
    services: false,
    referrals: false,
    faq: false,
    interviewer: false,
    events: true,
  },
  content: {
    home: {
      welcome: 'Meet your personal manager!',
    },
  },
  footer: {
    companyName: 'Futurepath GmbH',
    links: {
      privacyPolicy: 'https://www.futurepath.io/privacy-policy',
      imprint: 'https://www.futurepath.io/imprint',
    },
  },
  badges: false,
  profileType: true,
  isDefaultBg: false,
}

export { config }
