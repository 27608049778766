import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import MuiSlider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'
import { defaultTo } from 'lodash'
import React from 'react'

const Slider = ({
  value,
  minValue,
  maxValue,
  stepValue,
  steps,
  onChange,
  classes,
  helperText,
  maxLength,
  error,
  disabled,
  label,
  id,
  ...props
}) => {
  const dataAutomator = props['data-automator'] || 'slider'
  const resolvedValue = defaultTo(value, maxValue)

  return (
    <FormControl fullWidth disabled={disabled} data-automator={dataAutomator}>
      <FormLabel>
        {label}
        <Typography variant='body1'>
          &nbsp;
          {resolvedValue}
        </Typography>
      </FormLabel>
      <MuiSlider
        name={id}
        value={resolvedValue}
        defaultValue={resolvedValue}
        getAriaValueText={(value) => value}
        aria-labelledby='discrete-slider'
        valueLabelDisplay='off'
        step={stepValue}
        marks={steps}
        min={minValue}
        max={maxValue}
        onChange={(_, value) => onChange(value)}
      />
    </FormControl>
  )
}

export { Slider }
