export const Actions = {
  AUTH_USER: 'AUTH_USER',
}

export function authenticateUser(auth) {
  return {
    type: Actions.AUTH_USER,
    value: auth,
  }
}
