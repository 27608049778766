import { DialogTitle, Button, Dialog, DialogActions } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'

import { StyledContainedButton } from './style'

const ScoringRequired = ({ open, onConfirm, onClose }) => (
  <Dialog
    open={open}
    aria-labelledby='alert-dialog-title'
    aria-describedby='alert-dialog-description'
    maxWidth='md'
  >
    <DialogTitle>Some required questions miss scoring</DialogTitle>
    <DialogContent>
      <Typography>
        Please, score all required questions before ending the interview.
      </Typography>
    </DialogContent>
    <DialogActions sx={{ margin: 2 }}>
      <Button
        onClick={onClose}
        variant='text'
        sx={{ textTransform: 'none', mr: 1 }}
      >
        Cancel
      </Button>
      <StyledContainedButton variant='contained' onClick={onConfirm}>
        Score
      </StyledContainedButton>
    </DialogActions>
  </Dialog>
)

export default ScoringRequired
