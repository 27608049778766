import { Factory } from '../client/Factory'

const AccountsServiceNamespace = 'account'

// TODO (Dio): Change to UpperCase in method names
/**
 * @type {{addAccount: (*|{method: string, uri: string, params: string[]})}}
 */
const AccountsServiceConfigs = {
  addAccount: Factory.createRequestConfig(Factory.MethodPost, '/users'),
  authenticate: Factory.createRequestConfig(Factory.MethodPost, '/auth'),
  updateProfile: Factory.createRequestConfig(
    Factory.MethodPatch,
    '/profile/:id'
  ),
  changePassword: Factory.createRequestConfig(
    Factory.MethodPatch,
    '/users/change-password'
  ),
  sendEmailChangeRequest: Factory.createRequestConfig(
    Factory.MethodPost,
    '/users/:id/email-update-request'
  ),
  updateEmailChangeRequest: Factory.createRequestConfig(
    Factory.MethodPatch,
    '/users/:id/email-update-request'
  ),
  getEmailChangeRequest: Factory.createRequestConfig(
    Factory.MethodGet,
    '/users/:id/email-update-request'
  ),
  forgotPassword: Factory.createRequestConfig(
    Factory.MethodPost,
    '/auth/forgot-password'
  ),
  resetPassword: Factory.createRequestConfig(
    Factory.MethodPatch,
    '/auth/reset-password'
  ),
  queryManagers: Factory.createRequestConfig(
    Factory.MethodGet,
    '/users?role=internal'
  ),
  queryOwners: Factory.createRequestConfig(
    Factory.MethodGet,
    '/profile/owners'
  ),
  confirmEmailChangeRequest: Factory.createRequestConfig(
    Factory.MethodPut,
    '/users/:id/email-change-confirmation'
  ),
  getUser: Factory.createRequestConfig(Factory.MethodGet, '/users/:id'),
  getUserProfile: Factory.createRequestConfig(Factory.MethodGet, '/users/me'),
}

/**
 * @type {{addAccount: (*|(function(Object, Object): Promise<any>))}}
 */
const AccountsService = {
  addAccount: Factory.makeClientCall(AccountsServiceConfigs.addAccount),
  authenticate: Factory.makeClientCall(AccountsServiceConfigs.authenticate),
  sendEmailChangeRequest: Factory.makeAuthenticatedClientCall(
    AccountsServiceConfigs.sendEmailChangeRequest
  ),
  updateEmailChangeRequest: Factory.makeAuthenticatedClientCall(
    AccountsServiceConfigs.updateEmailChangeRequest
  ),
  getEmailChangeRequest: Factory.makeAuthenticatedClientCall(
    AccountsServiceConfigs.getEmailChangeRequest
  ),
  confirmEmailChangeRequest: Factory.makeAuthenticatedClientCall(
    AccountsServiceConfigs.confirmEmailChangeRequest
  ),
  changePassword: Factory.makeAuthenticatedClientCall(
    AccountsServiceConfigs.changePassword
  ),
  forgotPassword: Factory.makeClientCall(AccountsServiceConfigs.forgotPassword),
  resetPassword: Factory.makeClientCall(AccountsServiceConfigs.resetPassword),
  queryManagers: Factory.makeAuthenticatedClientCall(
    AccountsServiceConfigs.queryManagers
  ),
  queryOwners: Factory.makeAuthenticatedClientCall(
    AccountsServiceConfigs.queryOwners
  ),
  getUser: Factory.makeAuthenticatedClientCall(AccountsServiceConfigs.getUser),
  getUserProfile: Factory.makeAuthenticatedClientCall(
    AccountsServiceConfigs.getUserProfile
  ),
}

export { AccountsServiceNamespace, AccountsServiceConfigs, AccountsService }
