import 'date-fns'
import DeleteIcon from '@mui/icons-material/Delete'
import { autocompleteClasses } from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import withStyles from '@mui/styles/withStyles'
import { get, flow } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'

import { DatePicker, TextField } from 'components'

import { PlaceAutosuggest } from './PlaceAutosuggest'

const styles = (theme) => ({
  controls: {
    alignItems: 'center',
  },
  control: {
    display: 'flex',
    justifyContent: 'center',
  },
  toolTipClass: {
    margin: theme.spacing(4, 2),
  },
  wrapper: {
    marginTop: theme.spacing(-6),
  },
  autosuggestContainer: {
    [`& .${autocompleteClasses.root} .${outlinedInputClasses.root}`]: {
      padding: theme.spacing(1.3),
    },
  },
})

class CertificationFormComponent extends React.Component {
  static propTypes = {
    certification: PropTypes.object,
  }

  render() {
    const { errors, onChange, certification, classes, outerIndex } = this.props

    return (
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
      >
        <Grid item xs={12} className={classes.wrapper}>
          <Grid
            container
            direction='column'
            justifyContent='space-between'
            alignItems='stretch'
          >
            <Grid container className={classes.control} spacing={2}>
              <Grid item xs={6}>
                <TextField
                  name={`certifications[${outerIndex}].name`}
                  label={this.props.t('certification_label')}
                  value={get(certification, 'name', '')}
                  onChange={(event) => onChange('name', event.target.value)}
                  error={!!errors.name}
                  helperText={errors.name || ''}
                  data-automator={`certifications[${outerIndex}].name`}
                  inputProps={{ maxLength: 100 }}
                  maxLength={100}
                />
              </Grid>
              <Grid item xs={5}>
                <Box className={classes.autosuggestContainer}>
                  <PlaceAutosuggest
                    error={get(errors, 'authority', '')}
                    label={this.props.t('authority_label')}
                    newPlaceLabel={this.props.t('add_new_authority')}
                    value={certification.authority}
                    onChange={(value) => onChange('authority', value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={1} className={classes.control}>
                <IconButton
                  id='delete-certification'
                  onClick={this.props.removeCertification(certification)}
                  p={1.5}
                  size='large'
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <DatePicker
                        id={`from${certification.id}`}
                        disableFuture
                        error={!!errors.issueDate}
                        helperText={get(errors, 'issueDate', '')}
                        label={this.props.t('issue_date_label')}
                        value={certification.issueDate || null}
                        onChange={(date) => onChange('issueDate', date)}
                        KeyboardButtonProps={{
                          'aria-label': this.props.t('choose_date_text'),
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <DatePicker
                        id={`to${certification.id}`}
                        label={this.props.t('exp_date_label')}
                        value={certification.expiryDate || null}
                        onChange={(date) => onChange('expiryDate', date)}
                        KeyboardButtonProps={{
                          'aria-label': this.props.t('choose_date_text'),
                        }}
                        hasTooltip
                        toolTipText={this.props.t('exp_helper_text')}
                        toolTipClass={classes.toolTipClass}
                        minDate={certification.issueDate}
                        helperText={get(errors, 'expiryDate', '')}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export const CertificationForm = flow(
  withTranslation('profileEditCertification'),
  withStyles(styles)
)(CertificationFormComponent)
