import React, { createElement, useContext } from 'react'

import Config from 'contexts/config'

const Renderer = ({ component, props }) => {
  const config = useContext(Config)

  return createElement(component, { ...props, config })
}

export const withConfig = (component) => (props) =>
  <Renderer component={component} props={props} />
