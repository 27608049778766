import property from 'lodash/property'
import styled from 'styled-components'

export default (component) => styled(component)`
  ${property('theme.typography.body2')};

  & > p {
    &:not(:first-of-type) {
      margin-top: ${({ theme: { spacing } }) => spacing(2)};
    }

    strong {
      font-weight: ${property('theme.typography.fontWeightBold')};
    }
  }
`
