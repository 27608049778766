/* eslint-disable*/
import React, { useMemo } from 'react'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ToolTip } from 'components'
import { Info } from '@mui/icons-material'
import { pick } from 'lodash'
import { INTERVIEWER_STATUSES } from 'common/constants'
import Grid from '@mui/material/Grid'
import {
  COMPLETED_CASE_STUDY_VERTICALS,
  LIVE_CODING_VERTICALS,
  ANALYTICS_TAGS,
  ANALYTICS_ACTIONS,
} from 'apps/portal/common/constants'
import circleCheck from 'assets/img/circle_check.svg'
import messaging from 'assets/img/messaging.svg'
import code from 'assets/img/code.svg'
import computer from 'assets/img/computer.svg'
import findInPage from 'assets/img/find_in_page.svg'
import quiz from 'assets/img/quiz.svg'
import { useViewport } from 'common/view-port-hook'
const useStyles = makeStyles((theme) => {
  const badgeDesign = {
    width: theme.spacing(8),
    height: theme.spacing(8),
    borderRadius: theme.spacing(1.8),
    boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
    marginRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& img': {
      width: theme.spacing(3),
    },
  }

  return {
    badge: {
      background: '#fff',
      height: theme.spacing(8),
      borderRadius: theme.spacing(1),
      padding: theme.spacing(3),
      justifyContent: 'start',
      display: 'flex',
      boxShadow: `0px 6.22px 6.22px rgba(0, 0, 0, 0.1)`,
      position: 'relative',

      '& svg ': {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        color: theme.palette.lightGray6,
        width: theme.spacing(2),
        height: theme.spacing(2),
      },
    },
    badgeContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      '& .MuiTypography-subtitle2': {
        color: theme.palette.lightGray2,
      },
      '& .MuiTypography-h6': {
        fontSize: theme.spacing(2.31),
      },
    },
    projectsCompletedBadge: {
      ...badgeDesign,
      backgroundColor: '#28CBEF',
    },
    p2pInterviewer: {
      ...badgeDesign,
      backgroundColor: '#3C26DE',
    },
    testedQA: {
      ...badgeDesign,
      backgroundColor: '#52BC77',
    },
    completedCaseStudy: {
      ...badgeDesign,
      backgroundColor: '#8F42E0',
    },
    testedLiveCoding: {
      ...badgeDesign,
      backgroundColor: '#FFBF3A',
    },
    eventsHost: {
      ...badgeDesign,
      backgroundColor: '#F58C57',
    },
  }
})

function Badges(props) {
  const classes = useStyles()
  const { profile, translate, gEvent } = props
  const { p2pInterviewerData, project, webinars } = pick(profile, [
    'p2pInterviewerData',
    'project',
    'webinars',
  ])
  const category = profile.focusRole && profile.focusRole.category
  const isProjectsDataPresent = project && project?.numberOfProjectsCompleted
  const textForBadge =
    isProjectsDataPresent && isProjectsDataPresent === 1
      ? translate('projectCompleted')
      : translate('projectsCompleted')

  const isCompletedCaseStudy = COMPLETED_CASE_STUDY_VERTICALS.includes(category)
  const isTestedLiveCoding = LIVE_CODING_VERTICALS.includes(category)
  const hasHostedWebinar =
    webinars && Array.isArray(webinars) && webinars.length > 0

  const BadgeTemplate = ({ cssClass, value, tooltipText, icon }) =>
    useMemo(() => (
      <Grid item xs={11} sm={11} md={4}>
        <div className={classes.badge}>
          <div className={cssClass}>
            {' '}
            <img src={icon} alt='' />
          </div>
          <div className={classes.badgeContent}>
            <Typography variant='subtitle2'>
              {translate('achievement')}
            </Typography>
            <Typography variant='h6'>{value}</Typography>
          </div>
          <ToolTip
            arrow
            title={tooltipText}
            onClose={() => {
              gEvent({
                ...ANALYTICS_TAGS.PUBLIC_PROFILE,
                action: `${ANALYTICS_ACTIONS.BADGE_HELP_TEXT} - ${value}`,
                label: profile.slug,
              })
            }}
          >
            <Info />
          </ToolTip>
        </div>
      </Grid>
    ))
  const isP2PInterviewer =
    p2pInterviewerData &&
    p2pInterviewerData.p2pStatus &&
    p2pInterviewerData.p2pStatus !== INTERVIEWER_STATUSES.NONE

  const { isDeviceMobile } = useViewport()
  let alignItems = 'flex-start'
  let justifyContent = 'flex-start'
  if (isDeviceMobile) {
    alignItems = 'center'
    justifyContent = 'center'
  } else {
    alignItems = 'flex-start'
    justifyContent = 'flex-start'
  }

  return (
    <Grid
      item
      container
      alignItems={alignItems}
      justifyContent={justifyContent}
      spacing={1}
    >
      {isP2PInterviewer && (
        <BadgeTemplate
          cssClass={classes.p2pInterviewer}
          value={translate('p2pInterviewer')}
          tooltipText={translate('p2pInterviewerTooltip')}
          icon={messaging}
        />
      )}
      <BadgeTemplate
        cssClass={classes.testedQA}
        value={translate('testedQA')}
        tooltipText={translate('testedQATooltip')}
        icon={quiz}
      />
      {project && project.numberOfProjectsCompleted && (
        <BadgeTemplate
          cssClass={classes.projectsCompletedBadge}
          value={`${project.numberOfProjectsCompleted} ${textForBadge}`}
          tooltipText={translate('projectsCompletedTooltip')}
          icon={circleCheck}
        />
      )}
      {isCompletedCaseStudy && (
        <BadgeTemplate
          cssClass={classes.completedCaseStudy}
          value={translate('completedCaseStudy')}
          tooltipText={translate('completedCaseStudyTooltip')}
          icon={findInPage}
        />
      )}
      {isTestedLiveCoding && (
        <BadgeTemplate
          cssClass={classes.testedLiveCoding}
          value={translate('testedLiveCoding')}
          tooltipText={translate('testedLiveCodingTooltip')}
          icon={code}
        />
      )}
      {hasHostedWebinar && (
        <BadgeTemplate
          cssClass={classes.eventsHost}
          value={translate('eventsHost')}
          tooltipText={translate('eventsHostTooltip')}
          icon={computer}
        />
      )}
    </Grid>
  )
}

export default Badges
