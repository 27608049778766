import { useCallback } from 'react'

import { useExpertlead } from 'apps/scorecard/hooks/clients'

export default () => {
  const { get } = useExpertlead()
  const API = useCallback(
    ({ bucket, mimeType, name, url }) =>
      get('/file-upload', { params: { bucket, mimeType, name, url } }),
    [get]
  )

  return API
}
