import { DialogTitle, DialogActions } from '@mui/material'
import styled from 'styled-components'

export const StyledDialogTitle = styled(DialogTitle)`
  .MuiTypography-root {
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    width: 100%;
    align-items: center;
  }
`

export const StyledDialogActions = styled(DialogActions)`
  margin: 10px 20px 10px;
  padding: 8px 3px;
`
