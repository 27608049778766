import 'date-fns'
import DeleteIcon from '@mui/icons-material/Delete'
import { autocompleteClasses } from '@mui/material/Autocomplete'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import flow from 'lodash/flow'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'

import { Autosuggest } from '../Autosuggest'
import { DatePicker } from '../DatePicker'
import { TextField } from '../TextField'

const styles = (theme) => ({
  container: {
    display: 'inline-flex',
    width: '100%',
  },
  wrapper: {
    marginBottom: theme.spacing(3),
  },
  controls: {
    alignItems: 'center',
  },
  control: {
    display: 'flex',
    justifyContent: 'center',
  },
  labelWrapper: {
    marginBottom: theme.spacing(-3),
  },
  helpIconWrapper: {
    marginTop: '2%',
  },
  toolTipClass: {
    margin: theme.spacing(4, 2),
  },
  autosuggestContainer: {
    [`& .${autocompleteClasses.root} .${outlinedInputClasses.root}`]: {
      padding: theme.spacing(1.3),
    },
  },
})

const CertificationBase = ({
  t,
  classes,
  value: certification = {},
  error,
  onChange,
  licensesCertificationTextFieldProps,
  placeAutosuggestProps,
  datePickerProps,
  removeCertification,
  label,
  outerIndex,
}) => (
  <Grid
    container
    direction='row'
    justifyContent='flex-start'
    alignItems='flex-start'
    className={classes.wrapper}
  >
    <Grid item xs={12}>
      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        className={classes.labelWrapper}
        spacing={2}
      >
        <Grid item>
          <Typography variant='h6'>{`${label} #${outerIndex + 1}`}</Typography>
        </Grid>
        <Grid item>
          <IconButton
            onClick={removeCertification(certification)}
            p={1.5}
            color='primary'
            id='delete-certification'
            size='large'
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            {...licensesCertificationTextFieldProps}
            value={get(certification, 'name', '')}
            onChange={(value) => onChange('name', value)}
            error={!!error.name}
            helperText={get(error, 'name.message', '')}
            data-automator={`certification-name-${outerIndex}`}
            inputProps={{ maxLength: 100 }}
            maxLength={100}
          />
        </Grid>
        <Grid item xs={6} className={classes.autosuggestContainer}>
          <Autosuggest
            {...placeAutosuggestProps}
            onChange={(value) => onChange('authority', value)}
            onInputChange={(value) => onChange('authorityFreeText', value)}
            error={
              get(error, 'authority.message', '') ||
              get(error, 'authority.id.message', '')
            }
            value={certification.authority}
            data-automator={`certification-issuing-authority-${outerIndex}`}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item>
          <DatePicker
            id={`from${certification.id}`}
            disableFuture
            error={get(error, 'issueDate.message', '')}
            helperText={get(error, 'issueDate.message', '')}
            label={datePickerProps.issuedOnDateLabel}
            value={certification.issueDate || null}
            onChange={(date) => onChange('issueDate', date)}
            KeyboardButtonProps={{
              'aria-label': datePickerProps.chooseDateLabel,
            }}
            data-automator={`certification-issueDate-${outerIndex}`}
          />
        </Grid>
        <Grid item>
          <DatePicker
            id={`to${certification.id}`}
            error={get(error, 'expiryDate.message', '')}
            helperText={get(error, 'expiryDate.message', '')}
            label={datePickerProps.expirationDateLabel}
            value={certification.expiryDate || null}
            onChange={(date) => onChange('expiryDate', date)}
            KeyboardButtonProps={{
              'aria-label': datePickerProps.chooseDateLabel,
            }}
            minDate={certification.issueDate}
            data-automator={`certification-expiryDate-${outerIndex}`}
            hasTooltip
            tooltipLabel={t('certificationExpiryDateToolTip')}
            toolTipClass={classes.toolTipClass}
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
)

CertificationBase.propTypes = {
  value: PropTypes.object,
  classes: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  outerIndex: PropTypes.number,
  placeAutosuggestProps: PropTypes.object,
  licensesCertificationTextFieldProps: PropTypes.object,
  datePickerProps: PropTypes.object,
  removeCertification: PropTypes.func,
  label: PropTypes.string,
}

export const Certification = flow(
  withStyles(styles),
  withTranslation('certifications')
)(CertificationBase)
