import { string } from 'prop-types'
import React from 'react'
import { Card as Container } from '@mui/material'

import withStyle from './style'

export const Card = (props) => <Container {...props} />

Card.propTypes = {
  className: string.isRequired,
}

Card.defaultProps = {}

export default withStyle(Card)
