import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Loading } from 'components'
import { ProfileService } from 'sdk'

export const ViewCV = () => {
  const { profileId } = useParams()

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await ProfileService.getCVSignedURL(
          {},
          { path: { id: profileId } }
        )
        const { src } = data
        console.log(data)
        window.location = src
      } catch (err) {
        console.error(err)
      }
    })()
  }, [profileId])

  return (
    <Loading loading>
      <div />
    </Loading>
  )
}

export default ViewCV
