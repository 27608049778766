import makeStyles from '@mui/styles/makeStyles'
import PropTypes from 'prop-types'
import React from 'react'
import { useRouteMatch } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  hidden: {
    display: 'none',
  },
}))

/*
  Component that works exactly like Route
  except it doesn't unmount the component.
  Useful for persisting internal states of
  components
*/
const RouteRenderer = ({ path, component: Component, children }) => {
  const classes = useStyles()
  const match = useRouteMatch(path)

  return (
    <div className={match ? '' : classes.hidden}>
      {Component ? <Component /> : children}
    </div>
  )
}

RouteRenderer.propTypes = {
  path: PropTypes.string.isRequired,
  children: PropTypes.node,
  component: PropTypes.elementType,
}

RouteRenderer.defaultProps = {
  children: null,
  component: null,
}

export default RouteRenderer
