import AddIcon from '@mui/icons-material/Add'
import { FormHelperText } from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import withStyles from '@mui/styles/withStyles'
import { Form, Formik } from 'formik'
import update from 'immutability-helper'
import flow from 'lodash/flow'
import get from 'lodash/get'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { object } from 'yup'

import { TIME_TO_SCROLL_MS } from 'apps/portal/common/constants'
import { SaveTab } from 'apps/portal/components/SaveTab'
import { FormWarnOnLeave } from 'components/FormWarnOnLeave'
import { withNotifications } from 'hocs'
import { withProfile } from 'hocs/withProfile'
import { getExperiencesValidation } from 'validation'

import { onCancel, onSubmit } from './common'
import { ExperienceForm } from './ExperienceForm'

const styles = () => ({
  add: {
    textAlign: 'center',
  },
})

class ExperiencesFormComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      clickCancel: false,
    }

    this.onCancel = onCancel.bind(this)
    this.onSubmit = onSubmit.bind(this)

    const { t } = props

    this.validationSchema = object({
      experiences: getExperiencesValidation({
        companyErrorMessage: t(
          'profile_experience_invalid_company_error_message'
        ),
        locationErrorMessage: t(
          'profile_experience_invalid_location_error_message'
        ),
        jobTitleErrorMessage: t(
          'profile_experience_invalid_job_title_error_message'
        ),
        startDateInvalidErrorMessage: t(
          'profile_experience_invalid_start_date_label_error_message'
        ),
        startDateAfterEndDateErrorMessage: t(
          'profile_experience_start_date_after_end_date_error_message'
        ),
        endDateInvalidErrorMessage: t(
          'profile_experience_invalid_end_date_label_error_message'
        ),
        endDateBeforeStartDateErrorMessage: t(
          'profile_experience_end_date_before_start_date_error_message'
        ),
        isIndustryOptional: true,
      }),
    })
  }

  editExperience = (setter, experiences, index) => (field, value) => {
    const editedExperiences = update(experiences, {
      [index]: { $merge: { [field]: value } },
    })

    setter('experiences', editedExperiences)
  }

  addExperience = (setter, experiences) => (e) => {
    setter('experiences', [
      ...experiences,
      { id: '', description: '', jobTitle: '', endDate: '', startDate: '' },
    ])
    setTimeout(
      () =>
        typeof window !== 'undefined' &&
        window.scrollTo(0, document.body.clientHeight),
      TIME_TO_SCROLL_MS
    )
  }

  removeExperience = (setter, experiences) => (exp) => (e) => {
    const filteredExperiences = experiences.filter((s) => s.id !== exp.id)
    setter('experiences', filteredExperiences)
  }

  renderForm = ({ values, dirty, setFieldValue, errors }) => {
    const { classes, t } = this.props
    const disableSave = !values.experiences.length ? false : !dirty

    return (
      <Form>
        <FormWarnOnLeave shouldWarn={dirty && !this.state.clickCancel} />
        {errors.empty && (
          <FormHelperText error>
            <span
              dangerouslySetInnerHTML={{
                __html: t('profile_experience_empty'),
              }}
            />
          </FormHelperText>
        )}

        {values.experiences.map((edu, index) => (
          <React.Fragment key={edu.id || index}>
            <Grid item>
              <ExperienceForm
                experience={edu}
                outerIndex={index}
                onChange={this.editExperience(
                  setFieldValue,
                  values.experiences,
                  index
                )}
                removeExperience={this.removeExperience(
                  setFieldValue,
                  values.experiences
                )}
                errors={get(errors, `experiences[${index}]`, {})}
              />
            </Grid>
            {index < values.experiences.length - 1 && (
              <Box p={3}>
                <Divider />
              </Box>
            )}
          </React.Fragment>
        ))}
        <Box component='div' className={classes.add}>
          <IconButton
            id='add-experience'
            onClick={this.addExperience(setFieldValue, values.experiences)}
            size='large'
          >
            <AddIcon />
          </IconButton>
        </Box>
        <SaveTab disableSave={disableSave} onCancel={this.onCancel} />
      </Form>
    )
  }

  render() {
    const { profile, fail, t } = this.props
    const experiences = [...(profile.experiences || [])]

    const formikProps = {
      initialValues: { experiences },
      validationSchema: this.validationSchema,
      onSubmit: (formData, { resetForm }) =>
        this.onSubmit(formData, { resetForm }, fail, t),
      validateOnChange: false,
      validateOnBlur: false,
    }

    return (
      <Grid
        container
        direction='column'
        justifyContent='flex-start'
        alignItems='stretch'
      >
        <Formik {...formikProps} component={this.renderForm} />
      </Grid>
    )
  }
}

const ExperiencesForm = flow(
  withStyles(styles),
  withTranslation('profileEditExperience'),
  withProfile,
  withNotifications
)(ExperiencesFormComponent)

export { ExperiencesForm }
