import React, { useCallback, useRef } from 'react'

import { Upload } from 'apps/scorecard/components/widgets'

export default (settings) => {
  const ref = useRef()
  const pick = useCallback(() => {
    ref.current.value = null

    return ref.current.click()
  }, [])
  const Container = useCallback(
    (props) => <Upload pick={pick} ref={ref} {...settings} {...props} />,
    [pick, settings]
  )

  return { components: { Container }, pick }
}
