import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import withStyles from '@mui/styles/withStyles'
import { Formik, Form } from 'formik'
import update from 'immutability-helper'
import flow from 'lodash/flow'
import get from 'lodash/get'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { object } from 'yup'

import { TIME_TO_SCROLL_MS } from 'apps/portal/common/constants'
import { SaveTab } from 'apps/portal/components/SaveTab'
import { FormWarnOnLeave } from 'components/FormWarnOnLeave'
import { withNotifications } from 'hocs'
import { withProfile } from 'hocs/withProfile'
import { getCertificationsValidation } from 'validation'

import { CertificationForm } from './CertificationForm'
import { onCancel, onSubmit } from './common'

const styles = () => ({
  add: {
    textAlign: 'center',
  },
})

class CertificationsFormComponent extends React.Component {
  constructor(props) {
    super(props)
    this.onSubmit = onSubmit.bind(this)
    this.state = {
      clickCancel: false,
    }
    this.onCancel = onCancel.bind(this)

    const { t } = props

    this.validationSchema = object({
      certifications: getCertificationsValidation({
        certificationErrorMessage: t('invalid_certification_error_message'),
        authorityErrorMessage: t('invalid_authority_error_message'),
        issueDateInvalidErrorMessage: t(
          'invalid_issue_date_label_error_message'
        ),
        issueDateAfterExpDateErrorMessage: t(
          'issue_date_after_exp_date_error_message'
        ),
        expDateInvalidErrorMessage: t('invalid_exp_date_label_error_message'),
        expDateBeforeIssueDateErrorMessage: t(
          'exp_date_before_issue_date_error_message'
        ),
      }),
    })
  }

  editCertification = (setter, certifications, index) => (field, value) => {
    const editedCertifications = update(certifications, {
      [index]: { $merge: { [field]: value } },
    })

    setter('certifications', editedCertifications)
  }

  addCertification = (setter, certifications) => (e) => {
    setter('certifications', [...certifications, { id: '', name: '' }])
    setTimeout(
      () =>
        typeof window !== 'undefined' &&
        window.scrollTo(0, document.body.clientHeight),
      TIME_TO_SCROLL_MS
    )
  }

  removeCertification =
    (setter, certifications, index) => (certification) => (e) => {
      const certificationsArray = [...certifications]
      certificationsArray.splice(index, 1)
      setter('certifications', certificationsArray)
    }

  render() {
    const { classes, fail, t } = this.props
    const formikProps = {
      initialValues: {
        certifications: this.props.profile.certifications || [],
      },
      validationSchema: this.validationSchema,
      onSubmit: (formData, { resetForm }) =>
        this.onSubmit(formData, { resetForm }, fail, t),
      validateOnChange: false,
      validateOnBlur: false,
    }

    return (
      <Grid
        container
        direction='column'
        justifyContent='flex-start'
        alignItems='stretch'
      >
        <Formik {...formikProps}>
          {({ values, errors, dirty, handleSubmit, setFieldValue }) => (
            <Form>
              <FormWarnOnLeave shouldWarn={dirty && !this.state.clickCancel} />
              {values.certifications.map((certification, index) => (
                <React.Fragment key={certification.id || index}>
                  <Grid item>
                    <CertificationForm
                      certification={certification}
                      onChange={this.editCertification(
                        setFieldValue,
                        values.certifications,
                        index
                      )}
                      index={index}
                      outerIndex={index}
                      errors={get(errors, `certifications[${index}]`, {})}
                      removeCertification={this.removeCertification(
                        setFieldValue,
                        values.certifications,
                        index
                      )}
                    />
                  </Grid>
                  {index < values.certifications.length - 1 && (
                    <Box p={3} mb={5}>
                      <Divider />
                    </Box>
                  )}
                </React.Fragment>
              ))}
              <Box component='div' className={classes.add}>
                <IconButton
                  id='add-certification'
                  onClick={this.addCertification(
                    setFieldValue,
                    values.certifications
                  )}
                  size='large'
                >
                  <AddIcon />
                </IconButton>
              </Box>
              <SaveTab disableSave={!dirty} onCancel={this.onCancel} />
            </Form>
          )}
        </Formik>
      </Grid>
    )
  }
}

const CertificationsForm = flow(
  withStyles(styles),
  withTranslation('profileEditCertification'),
  withProfile,
  withNotifications
)(CertificationsFormComponent)

export { CertificationsForm }
