import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import withStyles from '@mui/styles/withStyles'
import update from 'immutability-helper'
import { flow, get } from 'lodash'
import React, { useRef, useEffect } from 'react'

import { TIME_TO_SCROLL_MS } from 'common/constants'
import { generateKey } from 'common/key-generator'

import { Experience } from './Experience'

const styles = () => ({
  add: {
    textAlign: 'center',
  },
})

const ExperiencesBase = ({
  classes,
  value: experiences = [{}],
  onChange,
  error,
  placeAutosuggest,
  locationAutosuggest,
  jobTitleTextField,
  datePicker,
  responsibilityTextField,
  label,
  industryLabels,
  clearErrors,
  name,
  setUpdatedExperiences,
  isResetButtonClicked,
  setIsResetButtonClicked,
}) => {
  const updatedExperiences = useRef(null)

  useEffect(() => {
    updatedExperiences.current = experiences
  }, [experiences])

  const editExperience = (setter, index) => (field, value) => {
    const editedExperiences = update(updatedExperiences.current, {
      [index]: { $merge: { [field]: value } },
    })

    setter(editedExperiences)
    setUpdatedExperiences(editedExperiences)
  }

  const addExperience = (setter) => (e) => {
    setter([
      ...experiences,
      {
        id: generateKey(),
        description: '',
        jobTitle: '',
        endDate: null,
        startDate: null,
      },
    ])
    setTimeout(
      () =>
        typeof window !== 'undefined' &&
        window.scrollTo(0, document.body.clientHeight),
      TIME_TO_SCROLL_MS
    )
  }

  const removeExperience = (index) => (e) => {
    const filteredExperiences = experiences.filter((_, i) => i !== index)

    // clear validation errors
    clearErrors(name)

    onChange(filteredExperiences)
  }
  const experienceComponentProps = {
    placeAutosuggestProps: placeAutosuggest,
    locationAutosuggestProps: locationAutosuggest,
    jobTitleTextFieldProps: jobTitleTextField,
    datePickerProps: datePicker,
    responsibilityTextFieldProps: responsibilityTextField,
    industryLabelProps: industryLabels,
    isResetButtonClicked,
    setIsResetButtonClicked,
  }

  return (
    <Grid
      container
      direction='column'
      justifyContent='flex-start'
      alignItems='stretch'
    >
      {experiences.map((exp, index) => (
        <React.Fragment key={exp.id || index}>
          <Grid item>
            <Experience
              {...experienceComponentProps}
              experience={exp}
              outerIndex={index}
              label={label}
              onChange={editExperience(onChange, index)}
              removeExperience={removeExperience(index)}
              error={get(error, index, {})}
              value={exp}
            />
          </Grid>
        </React.Fragment>
      ))}
      <Box component='div' className={classes.add}>
        <IconButton
          onClick={addExperience(onChange)}
          id='add-experience'
          size='large'
        >
          <AddIcon />
        </IconButton>
      </Box>
    </Grid>
  )
}

const Experiences = flow(withStyles(styles))(ExperiencesBase)

export { Experiences }
