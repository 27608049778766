/**
 *
 * For more details:
 *
 * https://react-dnd.github.io/react-dnd/docs/overview#drag-sources-and-drop-targets
 *
 * https://react-dnd.github.io/react-dnd/docs/api/drop-target
 *
 * */

import { createElement } from 'react'

import { useDnD } from 'hooks'

const Target = ({ type, component, props }) => {
  const createDnD = useDnD({ type })

  return createElement(component, { ...props, createDnD })
}

export default Target
