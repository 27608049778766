import { Button } from '@mui/material'
import add from 'date-fns/add'
import format from 'date-fns/format'
import fromUnixTime from 'date-fns/fromUnixTime'
import { useMemo } from 'react'

import { DATE_TIME_FORMAT, HOURS_TO_SUBMIT } from 'apps/scorecard-v2/constants'
import { isFirefox } from 'apps/scorecard-v2/helpers'
import { useTracking } from 'apps/scorecard/hooks'

import { StyledFooter, StyledMessage } from './style'

const FeedbackFooter = ({ dateTime, onSubmit, endingTime }) => {
  const formattedDateTime = useMemo(() => {
    const dateFormat = isFirefox() ? 'dd/MMM/yy HH:mm' : DATE_TIME_FORMAT
    return format(fromUnixTime(dateTime), dateFormat)
  }, [dateTime])

  const timeToSubmit = format(
    add(new Date(formattedDateTime), { hours: HOURS_TO_SUBMIT }),
    DATE_TIME_FORMAT
  )

  const tracking = useTracking()
  const eventTracking = (label, action = 'Click') => {
    tracking.event({
      category: 'Scorecard-v2',
      action,
      label: `${action}_${label}`,
    })
  }

  return (
    <StyledFooter>
      <StyledMessage>
        <p>
          Please submit your feedback immediately after the interview,&nbsp;
          <em>
            until&nbsp;
            {timeToSubmit}
          </em>
        </p>
      </StyledMessage>
      {!endingTime && (
        <Button
          onClick={() => {
            onSubmit()
            eventTracking('Save_and_Finish_Interview')
          }}
          sx={{
            textTransform: 'none',
            background: '#3C26DE',
            '&:hover': { background: '#5a47ea' },
          }}
          variant='contained'
        >
          Save and Finish Interview
        </Button>
      )}
    </StyledFooter>
  )
}

export default FeedbackFooter
