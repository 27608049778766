import { withSnackbar } from 'notistack'
import { createElement } from 'react'

const defaults = { anchorOrigin: { vertical: 'bottom', horizontal: 'right' } }

const Notifications = (component) =>
  withSnackbar(({ enqueueSnackbar, ...props }) =>
    createElement(component, {
      notify: (content) =>
        enqueueSnackbar(content || 'Unknown Error!', {
          variant: 'default',
          ...defaults,
        }),
      inform: (content) =>
        enqueueSnackbar(content, {
          variant: 'info',
          ...defaults,
        }),
      warn: (content) =>
        enqueueSnackbar(content, {
          variant: 'warning',
          ...defaults,
        }),
      succeed: (content) =>
        enqueueSnackbar(content, {
          variant: 'success',
          ...defaults,
        }),
      fail: (content) =>
        enqueueSnackbar(content, {
          variant: 'error',
          ...defaults,
        }),
      ...props,
    })
  )

export default Notifications
