import property from 'lodash/property'
import styled from 'styled-components'

export default (component) => styled(component)`
  & > {
    h1:not([class]),
    h2:not([class]),
    p:not([class]) {
      color: ${property('theme.palette.common.black')};

      strong {
        font-weight: ${property('theme.typography.fontWeightBold')};
      }
    }

    h1 {
      ${property('theme.typography.h5')};
    }

    h2 {
      ${property('theme.typography.h6')};
      margin-top: ${({ theme: { spacing } }) => spacing(4)};
    }

    p {
      ${property('theme.typography.body2')};
      margin-top: ${({ theme: { spacing } }) => spacing(2)};
    }
  }
`
