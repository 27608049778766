import moment from 'moment'
import { array, object, string, date, ref, lazy } from 'yup'

const getExperiencesValidation = ({
  companyErrorMessage,
  locationErrorMessage,
  jobTitleErrorMessage,
  startDateInvalidErrorMessage,
  startDateAfterEndDateErrorMessage,
  endDateInvalidErrorMessage,
  endDateBeforeStartDateErrorMessage,
  industryErrorMessage,
  isIndustryOptional = false,
}) =>
  array().of(
    object({
      company: lazy((value) => {
        switch (typeof value) {
          case 'object':
            return object()
              .shape({
                id: string().required(companyErrorMessage),
              })
              .required(companyErrorMessage)
          default:
            return string().required(companyErrorMessage)
        }
      }),
      location: object()
        .shape({
          id: string().required(locationErrorMessage),
        })
        .required(locationErrorMessage),
      startDate: date()
        .required(startDateInvalidErrorMessage)
        .typeError(startDateInvalidErrorMessage)
        .when('endDate', (endDate, schema) => {
          if (endDate) {
            const endDateParsed = moment(endDate)

            /**
             * this custom date parsing is implemented because:
             * 1. start date should be before the end date (if end date exists and is valid)
             * 2. start date should always be before the current date
             *
             * as far as i know, directly calling the param endDate's `isValid()`
             * method (as part of `Yup` schema) doesn't work since it's nullable. therefore it will always
             * evaluate to true regardless of the input. that's why we had to use
             * `moment` to add custom date parsing
             */
            if (endDateParsed.isValid() && endDateParsed.isBefore(new Date())) {
              return schema.max(
                ref('endDate'),
                startDateAfterEndDateErrorMessage
              )
            }
          }

          return schema.max(new Date(), startDateInvalidErrorMessage)
        }),

      endDate: date()
        .nullable()
        .min(ref('startDate'), endDateBeforeStartDateErrorMessage)
        .typeError(endDateInvalidErrorMessage)
        .default(null),
      jobTitle: string().required(jobTitleErrorMessage),
      description: string(),
      industry: (() => {
        const industryValidationSchema = array().of(
          object()
            .shape({
              id: string(),
              name: string(),
            })
            .typeError(industryErrorMessage)
        )

        if (isIndustryOptional) {
          return industryValidationSchema.nullable()
        }

        return industryValidationSchema.required(industryErrorMessage)
      })(),
    })
  )

export { getExperiencesValidation }
