import { Factory } from '../client/Factory'

const ContributorsServiceNamespace = 'contributors'

const ContributorsServiceConfigs = {
  queryContributors: Factory.createRequestConfig(
    Factory.MethodGet,
    '/contributors',
    [],
    35000
  ), // TODO: (Tanveer) Remove timeout once elasticsearch implemented
  assignToMe: Factory.createRequestConfig(
    Factory.MethodPost,
    '/contributors/:contributorId/assign/:userId'
  ),
  unassign: Factory.createRequestConfig(
    Factory.MethodDelete,
    '/contributors/:contributorId/assign/:userId'
  ),
  fetchContributor: Factory.createRequestConfig(
    Factory.MethodGet,
    '/contributors/:contributorId'
  ),
  updateEmployeeType: Factory.createRequestConfig(
    Factory.MethodPatch,
    '/contributors/:contributorId'
  ),
  fetchContributionsById: Factory.createRequestConfig(
    Factory.MethodGet,
    '/contributors/:contributorId/contributions'
  ),
  createHubSpotDealId: Factory.createRequestConfig(
    Factory.MethodPost,
    '/contributors/:contributorId/deals'
  ),
  fetchGithubActivity: Factory.createRequestConfig(
    Factory.MethodGet,
    '/subo/candidates/:contributorId/github-activities',
    [],
    10000
  ),
}

const ContributorsService = {
  queryContributors: (data) =>
    Factory.makeAuthenticatedClientCall(
      ContributorsServiceConfigs.queryContributors
    )(data, { arrayFormat: 'none' }),
  assignToMe: Factory.makeAuthenticatedClientCall(
    ContributorsServiceConfigs.assignToMe
  ),
  unassign: Factory.makeAuthenticatedClientCall(
    ContributorsServiceConfigs.unassign
  ),
  fetchContributor: Factory.makeAuthenticatedClientCall(
    ContributorsServiceConfigs.fetchContributor
  ),
  updateEmployeeType: Factory.makeAuthenticatedClientCall(
    ContributorsServiceConfigs.updateEmployeeType
  ),
  fetchContributionsById: Factory.makeAuthenticatedClientCall(
    ContributorsServiceConfigs.fetchContributionsById
  ),
  createHubSpotDealId: Factory.makeAuthenticatedClientCall(
    ContributorsServiceConfigs.createHubSpotDealId
  ),
  fetchGithubActivity: Factory.makeAuthenticatedClientCall(
    ContributorsServiceConfigs.fetchGithubActivity
  ),
}

export {
  ContributorsServiceNamespace,
  ContributorsServiceConfigs,
  ContributorsService,
}
