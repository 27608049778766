/* eslint-disable */
import React, { useState } from 'react'
import withStyles from '@mui/styles/withStyles'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import ReactMarkdown from 'react-markdown'
import { ANALYTICS_TAGS, ANALYTICS_ACTIONS } from 'apps/portal/common/constants'
import { grey as Grey } from '@mui/material/colors'

const styles = (theme) => ({
  displayableText: {
    '&:after': {
      content: '"..."',
    },
    '& p': {
      '&:after': {
        content: '"..."',
      },
    },
  },
  displayableContainer: {
    lineHeight: '1.5',
  },
  typographyContainer: {
    wordBreak: 'break-word',
  },
  readMoreLink: {
    color: Grey[500],
    cursor: 'pointer',
    textDecoration: 'underline',
    marginLeft: theme.spacing(1),
  },
})

const ParagraphByWordBase = (props) => {
  const [isReadMore, setIsReadMore] = useState(false)
  const {
    text,
    limit,
    variant,
    fontWeight,
    classes,
    renderAsMarkdown,
    component,
    slugId,
    type,
    gEvent,
  } = props
  const toggleReadMore = (e) => {
    e.preventDefault()
    setIsReadMore(!isReadMore)
    !isReadMore &&
      gEvent({
        ...ANALYTICS_TAGS.PUBLIC_PROFILE,
        action: `${ANALYTICS_ACTIONS.READ_MORE} - ${type}`,
        label: slugId,
      })
  }

  const splitWords = (text, numWords) => {
    const words = text?.split(' ')
    let displayableText = '',
      hiddenText = ''
    words?.forEach((word, idx) => {
      if (idx < numWords) {
        displayableText += ' ' + word
      } else {
        hiddenText += ' ' + word
      }
    })
    return {
      displayableText: displayableText.trim(),
      hiddenText: hiddenText.trim(),
    }
  }

  const hasLineBreak = text?.startsWith('-') || text?.startsWith('*')
  const textToRender = renderAsMarkdown ? (
    <ReactMarkdown>{text}</ReactMarkdown>
  ) : (
    text
  )
  const words = text?.split(' ').filter(Boolean)
  const isTextShrinkable = !hasLineBreak && text ? limit < words.length : 0
  const bulletPoints = (hasLineBreak && text?.split('\n').filter(Boolean)) || []
  const firstLastBullet =
    bulletPoints?.length === 1
      ? bulletPoints[0]
      : bulletPoints?.length > 1 &&
        `${bulletPoints[0]}\n ${
          bulletPoints[text?.split('\n').filter(Boolean).length - 1]
        }`
  const displayableText = isTextShrinkable ? (
    <ReactMarkdown>
      {hasLineBreak ? firstLastBullet : splitWords(text, limit).displayableText}
    </ReactMarkdown>
  ) : hasLineBreak ? (
    <ReactMarkdown>{firstLastBullet}</ReactMarkdown>
  ) : (
    textToRender
  )
  return (
    <Box fontWeight={fontWeight} className={classes.displayableContainer}>
      <Typography
        variant={variant}
        className={classes.typographyContainer}
        component={component}
      >
        <span
          className={
            isReadMore || !isTextShrinkable
              ? undefined
              : classes.displayableText
          }
        >
          {isReadMore ? textToRender : displayableText}
        </span>

        {(!!isTextShrinkable || bulletPoints.length > 2) && (
          <Link onClick={toggleReadMore} className={classes.readMoreLink}>
            {isReadMore ? 'Read less' : 'Read more'}
          </Link>
        )}
      </Typography>
    </Box>
  )
}

export const ParagraphByWord = withStyles(styles)(ParagraphByWordBase)
