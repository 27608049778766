import { Factory } from '../client/Factory'

const ReferralServiceNamespace = 'referral'

const ReferralServiceConfigs = {
  referProject: Factory.createRequestConfig(
    Factory.MethodPost,
    '/referrals/project'
  ),
  referFreelancer: Factory.createRequestConfig(
    Factory.MethodPost,
    '/referrals/freelancer'
  ),
}

const ReferralService = {
  referProject: Factory.makeAuthenticatedClientCall(
    ReferralServiceConfigs.referProject
  ),
  referFreelancer: Factory.makeAuthenticatedClientCall(
    ReferralServiceConfigs.referFreelancer
  ),
}

export { ReferralServiceNamespace, ReferralServiceConfigs, ReferralService }
