import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {
  getInterview,
  putQuestionScore,
  updateInterview,
  putQuestionFeedback,
  deleteQuestions,
  addQuestions,
  postArchiveFeedback,
} from '../api'
import { STATUS } from '../constants'

export const getInterviewDetails = createAsyncThunk(
  'interview/details',
  async (payload) => {
    const response = await getInterview(payload)
    return response.data
  }
)

export const scoreQuestion = createAsyncThunk(
  'interview/questions',
  async (payload) => {
    const { isComment, ...rest } = payload
    await putQuestionScore(rest)
    return payload
  }
)

export const updateInterviewDetails = createAsyncThunk(
  'interview/interviewDetails',
  async (payload) => {
    await updateInterview(payload)
    return payload
  }
)

export const postQuestionFeedback = createAsyncThunk(
  'question/feedback',
  async (payload) => {
    await putQuestionFeedback(payload)
    return payload
  }
)

export const postQuestionsDelete = createAsyncThunk(
  'questions/delete',
  async (payload) => {
    payload.toDelete = payload.questions

    payload.questions = payload.questions.reduce((acc, curr) => {
      acc.push({ id: curr })
      return acc
    }, [])

    await deleteQuestions(payload)
    return payload
  }
)

export const postAddQuestions = createAsyncThunk(
  'questions/add',
  async (payload) => {
    payload.questions = payload.questions.reduce((acc, curr) => {
      acc.push({ id: curr })
      return acc
    }, [])

    await addQuestions(payload)
    return payload
  }
)

export const archiveInterview = createAsyncThunk(
  'interview/archive',
  async (payload) => {
    await postArchiveFeedback(payload)
    return {}
  }
)

const interviewSlice = createSlice({
  name: 'interview',
  initialState: { value: {} },
  reducers: {
    setIdleStatus: (state, { payload }) => {
      payload.forEach((key) => {
        state.value[key] = STATUS.idle
      })
    },
  },
  extraReducers: {
    [getInterviewDetails.fulfilled]: (state, { payload }) => {
      state.value = payload
      state.value.interviewDetailsStatus = STATUS.fulfilled
    },
    [getInterviewDetails.rejected]: (state) => {
      state.value.interviewDetailsStatus = STATUS.rejected
    },
    [getInterviewDetails.pending]: (state) => {
      state.value.interviewDetailsStatus = STATUS.pending
    },
    [scoreQuestion.fulfilled]: (state, { payload }) => {
      const { isComment } = payload
      const nodeIndex = state.value.questions.nodes.findIndex(
        ({ title }) => title === payload.tab
      )

      state.value.questions.nodes[nodeIndex].nodes.forEach(({ nodes }) => {
        nodes.forEach((value) => {
          if (value.id === payload.questionId) {
            value.score = payload.payload.questionAnswer.answer.score
            value.note = payload.payload.questionAnswer.answer.note
          }
        })
      })

      if (!isComment) {
        state.value.scoreStatus = STATUS.fulfilled
      }

      return state
    },
    [scoreQuestion.rejected]: (state) => {
      state.value.scoreStatus = STATUS.rejected
    },
    [scoreQuestion.pending]: (state) => {
      state.value.scoreStatus = STATUS.pending
    },
    [updateInterviewDetails.fulfilled]: (state, { payload }) => {
      const { startingTime, endingTime, multiFeedback } = payload.payload

      if (multiFeedback) {
        state.value.interview.multiFeedback = multiFeedback
        state.value.interview.feedbackStatus = STATUS.fulfilled
      }

      if (startingTime) {
        state.value.interview.startingTime = startingTime
      }

      if (endingTime) {
        state.value.interviewEndingStatus = STATUS.fulfilled
      }

      return state
    },
    [updateInterviewDetails.rejected]: (state, { meta }) => {
      const { endingTime, multiFeedback } = meta.arg.payload || {}

      if (endingTime) {
        state.value.interviewEndingStatus = STATUS.rejected
      }

      if (multiFeedback) {
        state.value.interview.feedbackStatus = STATUS.rejected
      }
    },
    [updateInterviewDetails.pending]: (state, { meta }) => {
      const { endingTime, multiFeedback } = meta.arg.payload

      if (endingTime) {
        state.value.interviewEndingStatus = STATUS.pending
      }

      if (multiFeedback) {
        state.value.interview.feedbackStatus = STATUS.pending
      }
    },
    [postQuestionFeedback.fulfilled]: (state) => {
      state.value.questionFeedbackStatus = STATUS.fulfilled
    },
    [postQuestionFeedback.pending]: (state) => {
      state.value.questionFeedbackStatus = STATUS.pending
    },
    [postQuestionFeedback.rejected]: (state) => {
      state.value.questionFeedbackStatus = STATUS.rejected
    },
    [postQuestionsDelete.fulfilled]: (state, { payload }) => {
      const nodeIndex = state.value.questions.nodes.findIndex(
        ({ title }) => title === payload.tabTitle
      )

      const subDimIndex = state.value.questions.nodes[
        nodeIndex
      ].nodes.findIndex(({ title }) => title === payload.subDimTitle)

      state.value.questions.nodes[nodeIndex].nodes[subDimIndex].nodes =
        state.value.questions.nodes[nodeIndex].nodes[subDimIndex].nodes.filter(
          ({ id }) => !payload.toDelete.includes(id)
        )
      return state
    },
    [postAddQuestions.fulfilled]: (state, { payload }) => {
      const nodeIndex = state.value.questions.nodes.findIndex(
        ({ title }) => title === payload.tabTitle
      )

      const subDimIndex = state.value.questions.nodes[
        nodeIndex
      ].nodes.findIndex(({ title }) => title === payload.subDimTitle)

      state.value.questions.nodes[nodeIndex].nodes[subDimIndex].nodes.push(
        ...payload.questionsToAdd
      )

      return state
    },
    [archiveInterview.fulfilled]: (state) => {
      state.value.archiveStatus = STATUS.fulfilled
      return state
    },
    [archiveInterview.pending]: (state) => {
      state.value.archiveStatus = STATUS.pending
      return state
    },
    [archiveInterview.rejected]: (state) => {
      state.value.archiveStatus = STATUS.rejected
      return state
    },
  },
})

export const { setIdleStatus } = interviewSlice.actions

export default interviewSlice.reducer
