export const DIFFICULTY_MAP = {
  DifficultyEasy: 'Easy',
  DifficultyMedium: 'Medium',
  DifficultyHard: 'Hard',
}

export const DIFFICULTY_WEIGHT = {
  DifficultyEasy: 1,
  DifficultyMedium: 2,
  DifficultyHard: 3,
}

export const INTERVIEW_TYPE_EM = 'InterviewTypeEM'
