import { useMemo, useState } from 'react'

export const useInterviewer = ({ path }) => {
  const [loading] = useState(false)
  const routes = useMemo(
    () => ({
      interviews: `${path}/:type?`,
      interview: {
        root: `${path}/interview/:interview`,
        edit: [
          `${path}/interview/:interview/dimension/:dimension`,
          `${path}/interview/:interview`,
        ],
        call: [
          `${path}/interview/:interview/call/dimension/:dimension/question/:question`,
          `${path}/interview/:interview/call/dimension/:dimension`,
          `${path}/interview/:interview/call/conclusion`,
          `${path}/interview/:interview/call`,
        ],
        report: [
          `${path}/interview/:interview/report/:mode`,
          `${path}/interview/:interview/report`,
        ],
      },
      questions: {
        suggest: `${path}/questions/suggest`,
      },
      properties: `${path}/properties`,
    }),
    [path]
  )

  return { loading, routes }
}
