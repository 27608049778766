import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import flow from 'lodash/flow'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'

import { TOP_SKILLS_LIMIT } from 'apps/portal/common/constants'
import { pluralize } from 'common/utils'
import { withProfile } from 'hocs/withProfile'

const styles = (theme) => ({
  avatar: {
    width: '100%',
    borderRadius: '0px',
    height: '100%',
  },
  topSkillChip: {
    backgroundColor: theme.palette.background.topSkills,
    color: theme.palette.grey[600],
    '& strong': {
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeight.strong,
    },
  },
  title: {
    fontWeight: theme.typography.fontWeight.bold,
    fontSize: theme.typography.fontSizeTitle,
    marginBottom: theme.spacing(2),
    display: 'block',
  },
  otherTitle: {
    marginTop: theme.spacing(5.25),
  },
})

class SkillsView extends React.Component {
  static propTypes = {
    skills: PropTypes.arrayOf(PropTypes.object),
  }

  calculateYearsOfXP = (skill) => {
    const now = new Date().getYear()
    return now - new Date(skill.startedAt).getYear()
  }

  calculateTopSkills(skills) {
    const processedSkills = skills.map((skill) => ({
      ...skill,
      years: this.calculateYearsOfXP(skill),
    }))

    return {
      topSkills: processedSkills.slice(0, TOP_SKILLS_LIMIT),
      regularSkills: processedSkills.slice(TOP_SKILLS_LIMIT),
    }
  }

  renderChip = (profileSkill) => (
    <div>
      <strong>{profileSkill.skill.name} </strong>
      {profileSkill.years} {pluralize('Year')(profileSkill.years)}
    </div>
  )

  render() {
    const { classes, t } = this.props
    const { topSkills, regularSkills } = this.calculateTopSkills(
      this.props.profile.skills
    )

    return (
      <Box>
        <Typography variant='body1' className={classes.title}>
          {t('top_skills')}
        </Typography>

        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
          spacing={2}
        >
          {topSkills.length > 0 ? (
            topSkills.map((topSkill) => (
              <Grid item key={topSkill.id}>
                <Chip
                  className={classes.topSkillChip}
                  label={this.renderChip(topSkill)}
                />
              </Grid>
            ))
          ) : (
            <Typography variant='caption' m={1}>
              {t('no_top_skills')}
            </Typography>
          )}
        </Grid>

        <Typography
          variant='body1'
          className={`${classes.title} ${classes.otherTitle}`}
        >
          {t('other_skills')}
        </Typography>

        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
          spacing={2}
        >
          {regularSkills.length > 0 ? (
            regularSkills.map((skill) => (
              <Grid item key={skill.id}>
                <Chip label={skill.skill.name} />
              </Grid>
            ))
          ) : (
            <Typography variant='caption' fontWeight='fontWeightRegular' m={1}>
              {this.props.t('no_other_skills')}
            </Typography>
          )}
        </Grid>
      </Box>
    )
  }
}

export const Skills = flow(
  withStyles(styles),
  withTranslation('profile_view_skills'),
  withProfile
)(SkillsView)
