import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography,
  Select,
  MenuItem,
  TextField,
  TablePagination,
} from '@mui/material'
import debounce from 'lodash.debounce'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { GENERIC_QUESTIONS } from 'apps/scorecard-v2/constants'
import { useScorecardDispatch } from 'apps/scorecard-v2/hooks'
import useScorecardSelector from 'apps/scorecard-v2/hooks/useScorecardSelector'
import {
  postAddQuestions,
  postQuestionsDelete,
} from 'apps/scorecard-v2/slices/interview-slice'
import { fetchDimensionQuestions } from 'apps/scorecard-v2/slices/questions-slice'
import { useAuth } from 'apps/scorecard/hooks'

import { StyledDialog } from './style'
import Question from './sub-dimension-question'

const QuestionsModal = ({
  isOpen,
  handleClose,
  interviewId,
  itemId,
  title,
  questionsMap,
  tabTitle,
  toggleTabKeyDisable,
}) => {
  const dispatch = useScorecardDispatch()
  const useSelector = useScorecardSelector()
  const [difficultyFilter, setDifficultyFilter] = useState('')
  const [search, setSearch] = useState('')
  const [hasChanged, setHasChange] = useState(false)
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(50)

  const initialCheckedQuestions = Object.entries(questionsMap).reduce(
    (acc, [key]) => {
      acc[key] = true
      return acc
    },
    {}
  )

  const [checkedQuestions, setCheckedQuestions] = useState(
    initialCheckedQuestions
  )

  const questionsList = useSelector((state) => state.questions.value)

  const { accessToken } = useAuth()

  const isGenericQuestions = tabTitle === GENERIC_QUESTIONS
  const itemDynamicId = {
    [isGenericQuestions ? 'subDimensionId' : 'skillId']: itemId,
  }

  useEffect(() => {
    toggleTabKeyDisable(isOpen)
    if (isOpen) {
      dispatch(
        fetchDimensionQuestions({
          sortBy: 'difficulty',
          ...itemDynamicId,
          interviewId,
          accessToken,
          page: page + 1,
          perPage,
        })
      )
    }
  }, [isOpen, itemId, page, perPage])

  useEffect(() => {
    if (!isOpen) {
      setSearch('')
      setDifficultyFilter('')
      setHasChange(false)
    }
  }, [isOpen])

  const questionsCopy = [...(questionsList[itemId]?.questions || [])]

  questionsCopy.sort(
    (first, second) =>
      Number(!!questionsMap[second.id]) - Number(!!questionsMap[first.id])
  )

  const questionsToChange = useMemo(() => new Map(), [isOpen])

  const questionsTouched = useMemo(() => new Map(), [isOpen])

  const handleChange = (questionId, isSelected) => {
    if (!hasChanged) {
      setHasChange(true)
    }

    setCheckedQuestions((prevState) => ({
      ...prevState,
      [questionId]: !prevState[questionId],
    }))

    if (questionsToChange.has(questionId)) {
      questionsToChange.delete(questionId)
    } else {
      questionsToChange.set(questionId, isSelected)
    }
    const questionObject = questionsList[itemId].questions.find(
      ({ id }) => id === questionId
    )

    questionsTouched.set(questionId, questionObject)
  }

  const handleSave = () => {
    const toDelete = []
    const toAdd = []

    questionsToChange.forEach((value, key) => {
      if (value) {
        toDelete.push(key)
      } else {
        toAdd.push(key)
      }
    })

    if (toDelete.length) {
      dispatch(
        postQuestionsDelete({
          interviewId,
          accessToken,
          questions: toDelete,
          tabTitle,
          subDimTitle: title,
        })
      )
    }

    if (toAdd.length) {
      const questionsToAdd = toAdd.reduce((acc, curr) => {
        acc.push(questionsTouched.get(curr))
        return acc
      }, [])

      dispatch(
        postAddQuestions({
          interviewId,
          accessToken,
          questions: toAdd,
          tabTitle,
          subDimTitle: title,
          questionsToAdd,
        })
      )
    }

    handleClose()
  }

  const handleDifficultyFilter = ({ target }) => {
    setDifficultyFilter(() => {
      dispatch(
        fetchDimensionQuestions({
          sortBy: 'difficulty',
          ...itemDynamicId,
          interviewId,
          accessToken,
          difficulty: target.value || undefined,
        })
      )
      return target.value
    })
  }

  const debouncedSearch = useCallback(
    debounce(
      (text) =>
        dispatch(
          fetchDimensionQuestions({
            sortBy: 'difficulty',
            ...itemDynamicId,
            interviewId,
            accessToken,
            text,
          })
        ),
      750
    ),
    []
  )

  const handleSearch = ({ target }) => {
    setSearch(target.value)
    debouncedSearch(target.value)
  }

  const { total = 1 } = questionsList[itemId]?.pagination || {}

  const handlePageChange = (_, page) => {
    setPage(page)
  }

  const handlePerPageChange = ({ target }) => {
    setPerPage(Number(target.value))
  }

  return (
    <StyledDialog open={isOpen} onClose={handleClose}>
      <DialogContent>
        <Box display='flex' justifyContent='space-between' mb={2}>
          <Typography component='h2' fontSize='1.5rem'>
            {title}
          </Typography>

          <Select
            value={difficultyFilter}
            onChange={handleDifficultyFilter}
            displayEmpty
            sx={{ width: '130px', padding: 1, height: '43px' }}
          >
            <MenuItem value=''>
              <em>All</em>
            </MenuItem>
            <MenuItem value='DifficultyEasy'>Easy</MenuItem>
            <MenuItem value='DifficultyMedium'>Medium</MenuItem>
            <MenuItem value='DifficultyHard'>Hard</MenuItem>
          </Select>
        </Box>

        <TextField
          placeholder='Search'
          onChange={handleSearch}
          value={search}
          sx={{ width: '100%' }}
        />

        {!questionsList[itemId]?.questions?.length && (
          <Typography mt={2} fontSize='1.1rem' color='#eb5757'>
            No results found
          </Typography>
        )}

        {questionsCopy.map((question) => (
          <Question
            key={question.id}
            question={question}
            isSelected={!!checkedQuestions[question.id]}
            handleChange={handleChange}
            hasScore={questionsMap[question.id]?.hasScore}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <TablePagination
          component='div'
          count={Number(total)}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={perPage}
          onRowsPerPageChange={handlePerPageChange}
        />
        <Button
          variant='text'
          sx={{ textTransform: 'none', minWidth: '90px' }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          sx={{ textTransform: 'none', marginBlock: 2 }}
          variant='contained'
          disabled={!hasChanged}
        >
          Save
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export default QuestionsModal
