/* eslint-disable */
import {
  Box,
  Grid,
  Typography,
  Hidden,
  FormControlLabel,
  IconButton,
  Divider,
  Link,
} from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { ReferralService } from '../../../../sdk/referral/Service'
import Layout from 'components/Layout'
import React from 'react'
import flow from 'lodash/flow'
import { withTranslation } from 'react-i18next'
import Redirect from 'components/Redirect'
import { withProfile } from 'hocs/withProfile'
import { ReferralForm } from './forms/ReferralForm'
import PhoneIcon from '@mui/icons-material/Phone'
import EmailOutline from '@mui/icons-material/Email'
import successRefImg from '../../assets/img/success.png'
import { DialogBox } from 'components/DialogBox'
import { AuthenticatedNavBar } from '../../components'
import { PAGES } from '../../common/constants'
import { Loading } from 'components'
import { ProfileService } from 'sdk/profile/Service'
import { withNotifications } from '../../../../hocs'

const formData = {
  contactEmail: 'community@expertlead.de',
  contactEmailSubject: 'Project Referral',
  referProjectPage: 'https://www.expertlead.com/refer-project',
  referFreelancerPage: 'https://www.expertlead.com/referral',
}

const styles = (theme) => ({
  contentContainer: {
    margin: '1vh auto',
  },
  successImg: {
    backgroundImage: `url(${successRefImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '90%',
    backgroundPosition: 'center center',
    width: '25vw',
    height: '30vw',
  },
  referralCode: {
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(1),
    fontWeight: 700,
    display: 'inline',
  },
  inviteBtn: {
    marginTop: theme.spacing(4),
  },
  leftContent: {
    paddingLeft: theme.spacing(2),
  },
  callLink: {
    verticalAlign: 'top',
    paddingLeft: '1vh',
  },
  divider: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
})

class ReferralClass extends React.Component {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.state = {
      to: '',
      dialogOpen: false,
      loading: false,
    }

    this.handleDialogOpen = this.handleDialogOpen.bind(this)
    this.handleDialogClickDecline = this.handleDialogClickDecline.bind(this)
    this.handleDialogClickAccept = this.handleDialogClickAccept.bind(this)
  }

  async componentDidMount() {
    const { data } = await ProfileService.getProfile()
    this.props.dispatchers.loadProfile(data)
  }

  handleDialogOpen(e) {
    e.preventDefault()

    this.setState({
      dialogOpen: true,
    })
  }

  async handleDialogClickAccept() {
    const {
      props: { notify, t },
    } = this
    let message

    this.setState({ loading: true })

    try {
      await ReferralService.referProject({})

      message = 'valid'
    } catch ({
      response: {
        data: { error },
      },
    }) {
      message = error
    } finally {
      this.setState({ loading: false, dialogOpen: false }, () => {
        notify(t(`callback[${message}]`))
      })
    }
  }

  handleDialogClickDecline() {
    this.setState({
      dialogOpen: false,
    })
  }

  async onSubmit(formData) {
    const {
      props: { notify, t },
    } = this
    let message

    this.setState({ loading: true })

    try {
      await ReferralService.referFreelancer(formData)

      message = 'valid'
    } catch ({
      response: {
        data: { error },
      },
    }) {
      message = error
    } finally {
      this.setState({ loading: false }, () => {
        notify(t(`referral[${message}]`, formData))
      })
    }
  }

  render() {
    return (
      <Redirect to={this.state.redirectTo}>
        <Loading loading={this.state.loading}>
          <Layout
            navbarComponent={AuthenticatedNavBar}
            currentPage={PAGES.REFERRALS}
          >
            <Grid container spacing={0}>
              <Grid item xs={12} lg={4}>
                <Grid
                  container
                  flexDirection={'column'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  spacing={0}
                >
                  <Grid item lg={10} xs={10}>
                    <Box>
                      <Typography
                        variant={'h5'}
                        gutterBottom
                        className={this.props.classes.contentContainer}
                      >
                        {this.props.t('form_title')}
                      </Typography>
                      <Typography variant={'subtitle2'}>
                        {this.props.t('form_subtitle3')}
                      </Typography>
                      <Typography variant={'subtitle2'}>
                        {this.props.t('form_subtitle')}
                      </Typography>

                      <Typography variant={'subtitle2'} display='inline'>
                        {this.props.t('form_subtitle2')}
                        &nbsp;
                        <a
                          href={formData.referFreelancerPage}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {this.props.t('here')}.
                        </a>
                      </Typography>

                      <Typography variant='subtitle2'>
                        {this.props.t('referral_code')}
                        <Typography className={this.props.classes.referralCode}>
                          {this.props.profile.referralCode ||
                            this.props.t('missing_referral_code')}
                        </Typography>
                      </Typography>
                    </Box>

                    <ReferralForm
                      onSubmit={this.onSubmit}
                      classes={this.props.classes}
                    />

                    <Box mt={12}>
                      <Typography
                        variant={'h5'}
                        gutterBottom
                        className={this.props.classes.contentContainer}
                      >
                        {this.props.t('project')}
                      </Typography>
                      <Typography variant={'subtitle2'} gutterBottom>
                        {this.props.t('project_subtitle')}
                      </Typography>

                      <Typography variant={'subtitle2'} gutterBottom>
                        {this.props.t('project_subtitle2')}
                        &nbsp;
                        <a
                          href={formData.referProjectPage}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {this.props.t('here')}.
                        </a>
                      </Typography>

                      <Box color='primary.main'>
                        <FormControlLabel
                          control={
                            <a
                              href={`mailto:${formData.contactEmail}?subject=${formData.contactEmailSubject}`}
                              target='_top'
                              rel='noopener noreferrer'
                            >
                              <IconButton color='primary' size='large'>
                                <EmailOutline />
                              </IconButton>
                            </a>
                          }
                          label={formData.contactEmail}
                          labelPlacement='end'
                        />
                      </Box>
                      {/* 
                      <Box color='primary.main'>
                        <FormControlLabel
                          control={
                            <a
                              href='#'
                              target='_top'
                              rel='noopener noreferrer'
                              onClick={this.handleDialogOpen}
                            >
                              <IconButton color='primary' size='large'>
                                <PhoneIcon />
                              </IconButton>
                            </a>
                          }
                          label={this.props.t('request_callback')}
                          labelPlacement='end'
                        />
                      </Box> */}

                      <DialogBox
                        open={this.state.dialogOpen}
                        handleClickAccept={this.handleDialogClickAccept}
                        handleClickDecline={this.handleDialogClickDecline}
                        title={this.props.t('dialogBox_title')}
                        description={this.props.t('dialogBox_description')}
                        acceptBtn={this.props.t('dialogBox_acceptBtn')}
                        declineBtn={this.props.t('dialogBox_declineBtn')}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item lg={1}>
                <Hidden mdDown>
                  <Grid
                    container
                    justifyContent={'center'}
                    className={this.props.classes.fullHeight}
                  >
                    <Divider
                      variant={'middle'}
                      orientation={'vertical'}
                      className={this.props.classes.divider}
                    />
                  </Grid>
                </Hidden>
              </Grid>

              <Grid item lg={6}>
                <Hidden mdDown>
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    flexDirection='column'
                  >
                    <Box className={this.props.classes.successImg}></Box>

                    <Typography align='center' variant={'h5'} gutterBottom>
                      {this.props.t('pic_title')}
                    </Typography>
                  </Box>
                </Hidden>
              </Grid>
            </Grid>
          </Layout>
        </Loading>
      </Redirect>
    )
  }
}

const Referral = flow(
  withTranslation('referral'),
  withStyles(styles),
  withProfile,
  withNotifications
)(ReferralClass)

export { Referral }
