import { bool, node, string } from 'prop-types'
import React, { useMemo } from 'react'
import { CircularProgress, Dialog } from '@mui/material'

import withStyle from './style'

export const Container = ({ className, loading, children, ...props }) => {
  const classes = useMemo(() => ({ root: className }), [className])

  return (
    <Dialog classes={classes} open {...props}>
      {loading ? <CircularProgress size='100%' /> : children}
    </Dialog>
  )
}

Container.propTypes = {
  className: string.isRequired,
  loading: bool,
  children: node,
}

Container.defaultProps = {
  loading: false,
}

export default withStyle(Container)
