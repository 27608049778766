/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { capitalize } from 'common/utils'
import { LanguageService } from 'sdk/language/Service'
import { Autosuggest } from 'components/Autosuggest'

const buildLabel = (language) => `${capitalize(language.name)}`

class LanguageAutosuggest extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.object,
    languageIds: PropTypes.array.isRequired,
  }

  onSelect = (language) => {
    this.props.onChange(language)
    return buildLabel(language)
  }

  async getLanguages(inp) {
    const res = await LanguageService.QueryLanguages({ name: inp })
    if (!res.data.data) return []
    return res.data.data.map((x) => ({
      label: buildLabel(x),
      value: x,
    }))
  }

  getOptionDisabled = ({ value: { id } }) => this.props.languageIds.includes(id)

  render() {
    const { error } = this.props
    return (
      <Autosuggest
        id='language'
        async
        error={error}
        service={this.getLanguages}
        getOptionDisabled={this.getOptionDisabled}
        defaultValue={buildLabel(this.props.value)}
        onSelect={this.onSelect}
        {...this.props}
      />
    )
  }
}

export { LanguageAutosuggest }
