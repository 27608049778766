import { Box } from '@mui/material'

import {
  useSaveScore,
  useScorecardDispatch,
  useTabScores,
} from 'apps/scorecard-v2/hooks'
import { postQuestionFeedback } from 'apps/scorecard-v2/slices/interview-slice'

import { QuestionList } from './question-list'
import { TabHeader } from './tab-header'

const CommonTab = ({
  items,
  accessToken,
  interview,
  tabTitle,
  toggleTabKeyDisable,
}) => {
  const dispatch = useScorecardDispatch()

  const sectionTitles = items.reduce((acc, curr) => {
    acc[curr.id] = curr.title
    return acc
  }, {})

  const { tabScores } = useTabScores(items)

  const handleSave = useSaveScore(accessToken, interview?.id, tabTitle)

  const handleSaveFeedback = (questionId, payload) => {
    dispatch(
      postQuestionFeedback({
        accessToken,
        questionId,
        payload: { ...payload, interviewerId: interview.interviewer.id },
      })
    )
  }

  let index = 0
  const subDimQuestionCount = {}
  const allQuestions = items.reduce((acc, curr, currIndex) => {
    subDimQuestionCount[currIndex] = curr.nodes.length
    curr.nodes.forEach((question) => {
      acc.push({
        ...question,
        parentId: curr.id,
        index,
        parentTitle: curr.title,
      })
      index += 1
    })
    return acc
  }, [])

  return (
    <>
      <Box position='relative'>
        <TabHeader sectionTitles={sectionTitles} tabScores={tabScores} />
        <Box
          sx={{
            height: 'calc(100vh - 265px)',
            paddingTop: '24px',
            overflowY: 'scroll',
          }}
        >
          {items.map(({ id, title }, index) => (
            <QuestionList
              key={id}
              id={id}
              title={title}
              handleSave={handleSave}
              handleSaveFeedback={handleSaveFeedback}
              interviewId={interview.id}
              tabTitle={tabTitle}
              allQuestions={allQuestions}
              sectionScores={tabScores[id]}
              subDimQuestionCount={subDimQuestionCount}
              toggleTabKeyDisable={toggleTabKeyDisable}
              sectionIndex={index}
            />
          ))}
        </Box>
      </Box>
    </>
  )
}

export default CommonTab
