import { useState } from 'react'

import { FORM_FIELDS } from 'common/constants'
import {
  getBulkConfigWithTranslation,
  useFormBuilder,
} from 'hooks/form_builder'
import { AccountsService } from 'sdk/account/Service'

/**
 * This is a wrapper that injects a configuration object to
 * the useFormBuilder hook. It accepts a localization function
 * for displaying labels, messages, etc
 */
const useForm = ({ translate, onSuccessCallback }) => {
  const [status, setStatus] = useState(null)

  const { TEXTFIELD_EMAIL, TEXTFIELD_PASSWORD, SWITCH_REMEMBER_ME } =
    FORM_FIELDS

  const getConfigs = getBulkConfigWithTranslation(translate)

  const form = useFormBuilder({
    fields: getConfigs([
      TEXTFIELD_EMAIL,
      TEXTFIELD_PASSWORD,
      SWITCH_REMEMBER_ME,
    ]),

    onSubmit: async (authData) => {
      try {
        const { data } = await AccountsService.authenticate(authData)

        setStatus(null)

        onSuccessCallback({
          data,
          setStatus,
        })
      } catch (err) {
        setStatus(translate('invalid_credentials'))
      }
    },
  })

  return {
    ...form,
    status,
  }
}

export { useForm }
