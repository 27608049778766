import { shape, string } from 'prop-types'

export const propTypes = {
  skill: shape({
    id: string.isRequired,
    name: string.isRequired,
  }).isRequired,
  id: string.isRequired,
}

export const defaultProps = {}
