import { Box, Typography } from '@mui/material'
import styled from 'styled-components'

export const StyledWrapper = styled(Box)`
  margin-bottom: 25px;
`

export const StyledTitle = styled(Box)`
  display: flex;
  justify-content: space-between;

  & p {
    font-weight: 800;
    margin: 0 8px 16px 8px;
  }
`

export const StyledKeyText = styled(Typography)`
  font-size: 0.8rem;
  border: 1px solid #fff;
  padding: 0 4px;
  border-radius: 8px;
  margin-right: 4px;
`
