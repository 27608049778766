import { PLACE_STATUS } from 'apps/portal/common/constants'

const placeStatusApproved = (status) =>
  ![
    PLACE_STATUS.STATUS_UNDER_REVIEW,
    PLACE_STATUS.STATUS_PENDING,
    PLACE_STATUS.STATUS_REJECTED,
  ].includes(status)

export { placeStatusApproved }
