import { arrayOf, bool, node, shape, string } from 'prop-types'
import React, { forwardRef, useCallback, useMemo } from 'react'
import { List } from '@mui/material'

import Item from './item'
import withStyle from './style'

export const Navigation = forwardRef(
  ({ className, items, tooltip, tracking, interviewer }, ref) => {
    const classes = useMemo(() => ({ root: className }), [className])
    const renderItem = useCallback(
      (item) => (
        <Item
          key={item.url}
          tooltip={tooltip}
          onClick={() => {
            tracking.event({
              category: `Interviewer: ${interviewer.firstName} ${interviewer.lastName}`,
              action: `Question pill nav button used`,
              label: `${item.text}`,
            })
          }}
          {...item}
        />
      ),
      [tooltip, tracking, interviewer]
    )

    return (
      <List ref={ref} classes={classes}>
        {items.map(renderItem)}
      </List>
    )
  }
)

Navigation.propTypes = {
  className: string.isRequired,
  items: arrayOf(
    shape({ label: node.isRequired, url: string.isRequired, external: bool })
  ),
}

Navigation.defaultProps = {
  items: [],
}

export default withStyle(Navigation)
