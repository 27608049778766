import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import flow from 'lodash/flow'
import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'

import { Layout } from 'components'
import { withTracker } from 'components/GoogleAnalytics'

import { PAGES } from '../../common/constants'
import { AuthenticatedNavBar } from '../../components'

import contents from './content.json'
import playIcon from './playButton.svg'

const { items } = contents

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  cards: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(3),
    maxWidth: `calc(50% - ${theme.spacing(15)})`,
    '&:nth-child(2n)': {
      marginRight: '0',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    maxHeight: theme.spacing(3),
  },
  authorsContainer: {
    display: 'flex',
    marginLeft: '10px',
    span: {
      marginTop: theme.spacing(-1),
    },
  },
  dateContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
  },
  author: {
    height: theme.spacing(8),
    width: theme.spacing(8),
    borderRadius: '50%',
    marginLeft: '-10px',
  },
  secondaryColor: {
    color: 'grey',
  },
  blue: {
    color: '#3C26DE',
  },
  vod: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    marginRight: theme.spacing(1),
    span: {
      marginTop: '-10px',
    },
  },
})

const EventsBase = ({ classes, t, gEvent }) => {
  const [onlyDisplayVod, setOnlyDisplayVod] = useState(false)

  const handleVodSelect = () => {
    setOnlyDisplayVod(!onlyDisplayVod)
  }

  const shouldDisplayVodIcon = (
    <div className={classes.dateContainer}>
      <img src={playIcon} className={classes.vod} alt='playIcon' />
      <Typography>
        <span className={classes.blue}>Recorded</span>
      </Typography>
    </div>
  )

  const handleOnClick = (url, title) => {
    typeof window !== 'undefined' && window.open(url)
    gEvent({
      category: 'Events',
      action: 'Click through to event page',
      label: title,
    })
  }

  const renderItem = (item) => {
    const { title, description, authors, date, duration, language, vod, url } =
      item
    return (
      <Card key={title} className={classes.card}>
        <CardActionArea onClick={() => handleOnClick(url, title)}>
          <CardContent className={classes.dateContainer}>
            <Typography variant='h6'>
              {date},
<span className={classes.secondaryColor}>{duration}</span>
            </Typography>
            {vod ? shouldDisplayVodIcon : null}
          </CardContent>
          <CardContent>
            <div className={classes.authorsContainer}>
              {authors.map((a) => renderAuthors(a))}
            </div>
          </CardContent>
          <CardContent>
            <Typography variant='h6' gutterBottom>
              <span className={classes.blue}>{language}</span> {title}
            </Typography>
            <Typography variant='body2' component='p'>
              {description}
            </Typography>
          </CardContent>
          <CardContent className={classes.actions}>
            <Button size='large'>
              <span className={classes.blue}>{t('view')}</span>
            </Button>
          </CardContent>
        </CardActionArea>
      </Card>
    )
  }

  const renderAuthors = ({ name, photo }) => (
    <CardMedia
      component='img'
      alt={name}
      image={photo}
      title={name}
      className={classes.author}
    />
  )

  return (
    <Layout
      navbarComponent={AuthenticatedNavBar}
      currentPage={PAGES.SERVICES}
      className={classes.root}
    >
      <Grid container component='main'>
        <Grid item xs={12} sm={12} md={10} lg={10}>
          <Typography variant='h5' className={classes.title}>
            <strong>{t('title')}</strong>
          </Typography>
          <div className={classes.cards}>
            {onlyDisplayVod
              ? items
                  .filter((item) => !!item.vod)
                  .map((item) => renderItem(item))
              : items.map((item) => renderItem(item))}
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <Typography variant='h5' className={classes.title}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={onlyDisplayVod}
                  onChange={handleVodSelect}
                  value={onlyDisplayVod}
                  color='primary'
                />
              }
              label={t('vod_only_label')}
            />
          </Typography>
        </Grid>
      </Grid>
    </Layout>
  )
}

const Events = flow(
  withTranslation('events'),
  withStyles(styles),
  withTracker
)(EventsBase)

export { Events }
