import ReactGA from 'react-ga'

const dev = process.env.NODE_ENV !== 'production'

!dev && ReactGA.initialize(process.env.REACT_APP_TRACKING_ID)

const Tracking = () => {
  const track = (page, opts = {}) => {
    ReactGA.set({
      page,
      ...opts,
    })
    !dev && ReactGA.pageview(page)
  }

  const event = (args) => {
    if (!args || !args.category || !args.action) {
      throw new Error(
        'Arguments of event must have (category, action) ' +
          'attributes, (label, value) attributes are optional'
      )
    }
    !dev && ReactGA.event(args)
  }

  return {
    track,
    event,
  }
}

export default Tracking
