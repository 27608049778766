import update from 'immutability-helper'

import { UNITS } from './constants'

export const format = (value) => {
  const distribute = ({ input, output }, { amount, type }) => {
    const total = Math.floor(input / amount)

    return {
      output: update(output, { [type]: { $set: total } }),
      input: input - total * amount,
    }
  }

  return UNITS.reduce(distribute, { input: value, output: {} }).output
}
