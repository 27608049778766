import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import CircleIcon from '@mui/icons-material/Circle'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Typography } from '@mui/material'
import { useEffect, useRef } from 'react'

import { getScoreColor } from 'apps/scorecard-v2/helpers'
import { useKeyPress, useTracking } from 'apps/scorecard/hooks'

import {
  StyledWrapper,
  StyledButton,
  StyledContainer,
  StyledScore,
  StyledText,
} from './style'

const Question = ({ question, index, setCurrentQuestionIndex, focusIndex }) => {
  const buttonRef = useRef(null)
  const { text, difficulty, score, index: questionListIndex } = question

  useEffect(() => {
    if (questionListIndex === focusIndex) {
      buttonRef.current.focus()
    }
  }, [focusIndex])

  useKeyPress(
    'ArrowRight',
    () => {
      focusIndex === questionListIndex &&
        setCurrentQuestionIndex(questionListIndex)
    },
    { prevent: true }
  )

  const handleClick = () => {
    setCurrentQuestionIndex(() => questionListIndex)
  }

  const tracking = useTracking()
  const eventTracking = (label, action = 'Click') => {
    tracking.event({
      category: 'Scorecard-v2',
      action,
      label: `${action}_${label}`,
    })
  }

  return (
    <>
      <StyledWrapper>
        <StyledButton disableRipple onClick={handleClick} ref={buttonRef}>
          <StyledContainer scored={!!score} difficulty={difficulty}>
            <CircleIcon />
            <Typography color='#a9a8b9' fontWeight='600'>
              {index + 1}
            </Typography>
            <StyledText>{text}</StyledText>
          </StyledContainer>
          {!score && (
            <KeyboardArrowRightIcon
              sx={{ fontSize: '1.5rem' }}
              onClick={() => eventTracking('Language_Question')}
            />
          )}
          {!!score && (
            <StyledScore>
              <Typography color={getScoreColor(score)} fontWeight='600'>
                {score}
              </Typography>
              <CheckOutlinedIcon htmlColor='#cccbd5' />
            </StyledScore>
          )}
        </StyledButton>
      </StyledWrapper>
    </>
  )
}

export default Question
