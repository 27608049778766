import React, { forwardRef } from 'react'
import { node, string } from 'prop-types'
import { ListItemText, ListItem } from '@mui/material'
import { NavLink } from 'react-router-dom'

import withStyle from './style'

const Item = forwardRef(
  ({ url: to, label: children, className, onClick }, ref) => (
    <ListItem ref={ref} className={className}>
      <ListItemText
        primary={
          <NavLink onClick={onClick} to={to} exact>
            {children}
          </NavLink>
        }
      />
    </ListItem>
  )
)

Item.propTypes = {
  className: string.isRequired,
  label: node.isRequired,
  url: string.isRequired,
}

export default withStyle(Item)
