import { Box, CircularProgress } from '@mui/material'

import { StyledLoadingIndicator } from './modals/interview/style'

const LoadingIndicator = () => (
  <StyledLoadingIndicator>
    <CircularProgress />
  </StyledLoadingIndicator>
)

export default LoadingIndicator
