import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'

class PasswordInputComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showPassword: false,
    }
    this.onPasswordVisibility = this.onPasswordVisibility.bind(this)
  }

  onPasswordVisibility = (e) => {
    this.setState((state) => ({ showPassword: !state.showPassword }))
  }

  render() {
    const { showPassword } = this.state
    const { id, name, error, value, touched, isSubmitting, handleChange } =
      this.props
    return (
      <FormControl
        variant='outlined'
        fullWidth
        margin='normal'
        error={error && touched}
        disabled={isSubmitting}
      >
        <InputLabel htmlFor={name}>{this.props.t(name)}</InputLabel>
        <OutlinedInput
          id={id}
          type={showPassword ? 'text' : 'password'}
          value={value}
          onChange={handleChange}
          label={this.props.t(name)}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={this.onPasswordVisibility}
                size='large'
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        {this.props.children}
      </FormControl>
    )
  }
}

const PasswordInput = withTranslation('registration_form')(
  PasswordInputComponent
)
export { PasswordInput }
