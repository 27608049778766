import { useCallback } from 'react'

import { useExpertlead } from 'apps/scorecard/hooks/clients'

import { format } from './helpers'

export default () => {
  const { get } = useExpertlead()
  const API = useCallback(
    ({
      keywords = '',
      page = 0,
      perPage = 50,
      difficulty,
      dimension,
      interview,
      skill,
      sortBy,
    }) => {
      const text = keywords.trim()

      return get('/question', {
        params: {
          ...(!!difficulty && { difficulty }),
          ...(!!sortBy && { sortBy }),
          ...(!!text && { text }),
          interviewId: interview,
          skillId: skill,
          subDimensionId: dimension,
          page: page + 1,
          perPage,
        },
      }).then(format)
    },
    [get]
  )

  return API
}
