import Box from '@mui/material/Box'
import { grey } from '@mui/material/colors'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import React from 'react'
import { withTranslation } from 'react-i18next'

import { Certification } from './Certification'

const styles = (theme) => ({
  companyLogo: {
    width: '94px',
    height: '94px',
  },
  duration: {
    color: grey[430],
  },
  paddingTopZero: {
    paddingTop: `0 !important`,
  },
  dateColor: {
    color: theme.palette.gray,
  },
})

export class CertificationsComponent extends React.Component {
  render() {
    const { certifications = [], classes } = this.props
    return (
      <Box paddingTop={2}>
        <Grid container spacing={4}>
          {certifications.length > 0 ? (
            certifications.map((certification) => (
              <Grid
                key={JSON.stringify(certification)}
                item
                container
                spacing={2}
              >
                <Certification
                  classes={classes}
                  {...certification}
                  {...this.props}
                />
              </Grid>
            ))
          ) : (
            <Typography variant='subtitle1'>
              {this.props.t('profile_education_empty_educations_message')}
            </Typography>
          )}
        </Grid>
      </Box>
    )
  }
}

const Certifications = withTranslation('profile_view')(
  withStyles(styles)(CertificationsComponent)
)

export { Certifications }
