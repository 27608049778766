import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  FormHelperText,
  DialogActions,
  Box,
} from '@mui/material'
import moment from 'moment'
import React, { useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  NOT_INTERESTED_OPTIONS,
  DASHBOARD_DATES_FORMAT,
  JOB_APPROACH_RESPONSE,
} from 'apps/portal/common/constants'
import { useViewport } from 'common/view-port-hook'
import { DatePicker } from 'components'

const NotInterestedForm = ({
  setOpenSecond,
  setOpenFirst,
  profileId: candidateId,
  jobId,
  JobsService,
  showFailedNotification,
}) => {
  const { isDeviceMobile } = useViewport()
  const [selectedReason, setSelectedReason] = useState('')

  const { t } = useTranslation('jobPage')
  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      date: null,
      otherReason: '',
      selectReason: null,
    },
  })

  const transformData = (data) => {
    const { date, otherReason, selectReason } = data

    return {
      response: JOB_APPROACH_RESPONSE.APPROACH_RESPONSE_NOT_INTERESTED,
      nextAvailabilityDate: date,
      comment: otherReason || selectReason,
      candidateId,
      jobId,
    }
  }

  const submitCandidateResponse = useCallback(
    async (data) => {
      const transformedData = transformData(data)
      try {
        await JobsService.updateCandidateApproachResponse(
          {
            ...transformedData,
          },
          { path: { jobId } }
        )
        setOpenFirst(false)
        setOpenSecond(true)
      } catch (err) {
        setOpenFirst(false)
        showFailedNotification()
        console.error(err)
      }
    },
    [jobId]
  )

  const onSubmit = (data) => {
    submitCandidateResponse(data)
  }

  const handleChange = (event, name) => {
    if (event.target?.value !== '') {
      clearErrors(name)
      setValue(name, event.target?.value || event)
    }
    if (name === 'selectReason') {
      setSelectedReason(event.target.value)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl fullWidth error={errors?.selectReason}>
        <InputLabel id='selectReasonLabel'>Select reason</InputLabel>
        <Select
          labelId='selectReasonLabel'
          id='response'
          label={t('selectReason')}
          value={selectedReason}
          onChange={(e) => handleChange(e, 'selectReason')}
          {...register('selectReason', {
            required: true,
          })}
          error={errors && errors.selectReason}
          MenuProps={{
            PaperProps: {
              style: {
                maxWidth: isDeviceMobile ? '300px' : '384px',
              },
            },
          }}
        >
          {NOT_INTERESTED_OPTIONS.map((option, index) => (
            <MenuItem
              key={index}
              value={option}
              style={{ whiteSpace: 'normal' }}
            >
              {option}
            </MenuItem>
          ))}
        </Select>
        {errors.selectReason && (
          <FormHelperText sx={{ color: 'red' }}>
            {`${t('selectReason')} is required`}
          </FormHelperText>
        )}
      </FormControl>

      {selectedReason.trim() === 'Other' && (
        <FormControl fullWidth sx={{ mt: 2 }}>
          <TextField
            {...register('otherReason', { required: t('otherReason') })}
            aria-label='minimum height'
            multiline
            rows={4}
            onChange={(e) => handleChange(e, 'otherReason')}
            maxRows={4}
            label={t('otherReason')}
            placeholder={t('otherReason')}
            error={errors && errors.otherReason}
          />
          {errors.otherReason && (
            <FormHelperText sx={{ color: 'red' }}>
              {`${t('otherReason')} is required`}
            </FormHelperText>
          )}
        </FormControl>
      )}

      <FormControl fullWidth sx={{ mt: 2 }}>
        <DatePicker
          {...register('date')}
          disablePast
          label={t('availableAgain')}
          value={watch('date')}
          format={DASHBOARD_DATES_FORMAT}
          PopperProps={{ placement: 'top-start' }}
          onChange={(date) => {
            if (date) {
              setValue('date', date.toISOString())
              clearErrors('date')
            } else {
              setValue('date', null)
            }
          }}
        />
      </FormControl>

      <DialogActions sx={{ padding: 0 }}>
        <Box
          display='flex'
          sx={{ mt: 4, justifyContent: 'space-between', width: '100%' }}
        >
          <Button variant='outlined' onClick={() => setOpenFirst(false)}>
            {t('cancel')}
          </Button>
          <Button variant='contained' type='submit' color='primary'>
            {t('send')}
          </Button>
        </Box>
      </DialogActions>
    </form>
  )
}

export default NotInterestedForm
