/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import flow from 'lodash/flow'

import { withTranslation } from 'react-i18next'
import withStyles from '@mui/styles/withStyles';
import { Experience } from './Experience'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { grey } from '@mui/material/colors';

const styles = (theme) => ({
  companyLogo: {
    width: 'auto',
    height: '50px',
  },
  duration: {
    color: grey[430],
  },
  description: {
    fontFamily: theme.typography.fontFamily,
  },
})

export class ExperiencesView extends React.Component {
  static propTypes = {
    experiences: PropTypes.arrayOf(PropTypes.object),
  }

  render() {
    const { experiences = [], classes, t } = this.props

    return (
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={6}
      >
        {experiences.length ? (
          experiences.map((exp) => (
            <Grid key={JSON.stringify(exp)} item xs={12}>
              <Experience classes={classes} {...exp} />
            </Grid>
          ))
        ) : (
          <Typography variant="subtitle1">{t('not_found')}</Typography>
        )}
      </Grid>
    );
  }
}

const Experiences = flow(
  withTranslation('experiences'),
  withStyles(styles)
)(ExperiencesView)

export { Experiences }
