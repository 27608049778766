import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import withStyles from '@mui/styles/withStyles'
import { Formik, Form } from 'formik'
import flow from 'lodash/flow'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { LocationAutosuggest } from 'apps/portal/components/LocationAutosuggest'
import { SaveTab } from 'apps/portal/components/SaveTab'
import { buildImageURL } from 'common/image'
import { TextField } from 'components'
import { FormWarnOnLeave } from 'components/FormWarnOnLeave'
import { withNotifications } from 'hocs'

import { EditAvatar } from '../components/EditAvatar'

import { onCancel, onSubmit } from './common'
import { SpokenLanguages } from './SpokenLanguages'

const styles = () => ({
  form: {
    width: '100%',
  },
  clickableIcons: {
    cursor: 'pointer',
  },
})

class AboutMeFormComponent extends React.Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      clickCancel: false,
    }
    this.onCancel = onCancel.bind(this)
    this.onSubmit = onSubmit.bind(this)
  }

  getInitialValues = () => ({
    firstName: this.props.profile.firstName,
    lastName: this.props.profile.lastName,
    bio: this.props.profile.bio,
    photo: this.props.profile.photo,
    headline: this.props.profile.headline,
    location: this.props.profile.location,
    spokenLanguages: this.props.profile.spokenLanguages,
  })

  validationSchema = () => {
    const { t } = this.props

    return Yup.object({
      firstName: Yup.string().required(t('profile_firstname_error')),
      lastName: Yup.string().required(t('profile_lastname_error')),
      headline: Yup.string().required(t('profile_title_error')),
      bio: Yup.string().required(t('profile_bio_error')),
      location: Yup.object({
        id: Yup.string().required(t('profile_location_error')),
        name: Yup.string(),
      }).required(t('profile_location_error')),
      spokenLanguages: Yup.array().of(
        Yup.object({
          language: Yup.object({
            id: Yup.string().required(t('profile_invalid_language')),
          }),
          proficiency: Yup.string().required(t('profile_invalid_proficiency')),
        })
      ),
    })
  }

  render() {
    const { t, classes, fail } = this.props

    const formikProps = {
      initialValues: this.getInitialValues(),
      validationSchema: this.validationSchema,
      onSubmit: (formData, { resetForm }) =>
        this.onSubmit(formData, { resetForm }, fail, t),
      validateOnChange: false,
      validateOnBlur: false,
    }
    return (
      <Formik {...formikProps}>
        {({
          values,
          errors,
          dirty,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          submitForm,
          setFieldValue,
        }) => {
          // The type of error needed for location is string but as yup defines the schema as object, in case
          // of absence of value we get an empty object which causes panics, so hackishly replacing it with string
          if (errors.location && errors.location.id) {
            errors.location = errors.location.id
          }
          return (
            <Grid
              container
              direction='row'
              justifyContent='flex-start'
              alignItems='flex-start'
              spacing={3}
            >
              <Grid item xs={7}>
                <Grid
                  container
                  direction='column'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                  spacing={5}
                >
                  <Form className={classes.form}>
                    <FormWarnOnLeave
                      shouldWarn={dirty && !this.state.clickCancel}
                    />
                    <Grid item>
                      <Grid container spacing={4}>
                        <Grid item xs={6}>
                          <TextField
                            error={!!errors.firstName}
                            helperText={errors.firstName || ''}
                            name='firstName'
                            label={t('firstName_helper')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.firstName}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            error={!!errors.lastName}
                            name='lastName'
                            label={t('lastName_helper')}
                            helperText={errors.lastName || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.lastName}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <TextField
                        name='headline'
                        label={t('title_helper')}
                        error={!!errors.headline}
                        helperText={errors.headline || ''}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.headline}
                        inputProps={{ maxLength: 60 }}
                        maxLength={60}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={!!errors.bio}
                        helperText={errors.bio || ''}
                        name='bio'
                        label={t('bio_helper')}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.bio}
                        maxRows={5}
                        rows={3}
                        inputProps={{ maxLength: 800 }}
                        maxLength={800}
                        multiline
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <LocationAutosuggest
                        onChange={(value) =>
                          setFieldValue('location', value, false)
                        }
                        value={values.location}
                        label={t('location_helper')}
                        error={errors.location || ''}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SpokenLanguages
                        onChange={setFieldValue}
                        languages={values.spokenLanguages}
                        errors={errors.spokenLanguages}
                      />
                    </Grid>
                    <SaveTab disableSave={!dirty} onCancel={this.onCancel} />
                  </Form>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Grid
                  container
                  direction='row'
                  justifyContent='flex-end'
                  alignItems='flex-start'
                >
                  <Container>
                    <EditAvatar
                      photo={{ url: buildImageURL(values.photo) }}
                      onChange={(value) => setFieldValue('photo', value, false)}
                    />
                  </Container>
                </Grid>
              </Grid>
            </Grid>
          )
        }}
      </Formik>
    )
  }
}

export const AboutMeForm = flow(
  withStyles(styles),
  withTranslation('profileEdit'),
  withNotifications
)(AboutMeFormComponent)
