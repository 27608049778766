import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Typography,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  Box,
  IconButton,
} from '@mui/material'
import { useState } from 'react'

import { DIFFICULTY_MAP } from 'apps/scorecard/constants'
import { ToolTip } from 'components'

import { StyledCircle } from '../interview/tabs/common/question-detail/style'

import { StyledAccordion } from './style'

const SubDimensionQuestion = ({
  question,
  isSelected,
  handleChange,
  hasScore,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isChecked, setIsChecked] = useState(isSelected)

  const { hint, text, difficulty, id } = question

  const handleClick = (event) => {
    event.stopPropagation()
    setIsExpanded(!isExpanded)
  }

  const handleSelect = () => {
    if (!hasScore) {
      setIsChecked(!isChecked)
      handleChange(question.id, isSelected)
    }
  }

  return (
    <StyledAccordion expanded={isExpanded}>
      <AccordionSummary
        onClick={handleSelect}
        expandIcon={
          <IconButton onClick={handleClick} sx={{ padding: '2px' }}>
            <ExpandMoreIcon />
          </IconButton>
        }
      >
        <Box display='flex' alignItems='center' gap={2}>
          <Checkbox
            checked={isChecked}
            onChange={handleSelect}
            onClick={(event) => event.stopPropagation()}
            value={id}
            sx={{ padding: '3px' }}
            color='secondary'
            disabled={hasScore}
          />
          <ToolTip title={DIFFICULTY_MAP[difficulty]} arrow>
            <StyledCircle difficulty={difficulty} />
          </ToolTip>
          <Typography>{text}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails />
      <AccordionDetails dangerouslySetInnerHTML={{ __html: hint }} />
    </StyledAccordion>
  )
}

export default SubDimensionQuestion
