import styled from 'styled-components'

export default (component) => styled(component)`
  .MuiSwitch-thumb,
  .MuiSwitch-track {
    background-color: #000;
  }

  .Mui-checked {
    & + .MuiSwitch-track {
      background-color: #6200ee;
    }

    .MuiSwitch-thumb {
      background-color: #5e3ee9;
    }
  }
`
