import { useState } from 'react'

import { LIVE_CODING } from 'apps/scorecard-v2/constants'
import { useSaveScore, useTabScores } from 'apps/scorecard-v2/hooks'

import Questions from './questions'
import { StyledContainer } from './style'
import TaskInfo from './task-info'

const LiveCoding = ({
  items,
  accessToken,
  interview,
  task,
  toggleTabKeyDisable,
}) => {
  const handleSave = useSaveScore(accessToken, interview?.id, LIVE_CODING)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null)

  const { tabScores } = useTabScores(items)

  if (!items) {
    return null
  }

  return (
    <StyledContainer>
      <TaskInfo task={task} skill={interview.skill} />
      {items.map(({ title, id, nodes }) => (
        <Questions
          title={title}
          questions={nodes}
          key={id}
          handleSave={handleSave}
          id={id}
          sectionScores={tabScores[id]}
          currentQuestionIndex={currentQuestionIndex}
          setCurrentQuestionIndex={setCurrentQuestionIndex}
          toggleTabKeyDisable={toggleTabKeyDisable}
        />
      ))}
    </StyledContainer>
  )
}

export default LiveCoding
