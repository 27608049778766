import { Factory } from '../client/Factory'

const LocationServiceNamespace = 'location'

const LocationServiceConfigs = {
  GetAll: Factory.createRequestConfig(Factory.MethodGet, '/country/me'),
  QueryCities: Factory.createRequestConfig(Factory.MethodGet, '/city'),
  QueryCountries: Factory.createRequestConfig(Factory.MethodGet, '/country'),
  QueryLocations: Factory.createRequestConfig(Factory.MethodGet, '/locations'),
}

/**
 * @type {{getAll: (*|(function(string, Object): Promise<any>))}}
 * @type {{QueryCities: (*|(function(string, Object): Promise<any>))}}
 * @type {{QueryCountries: (*|(function(string, Object): Promise<any>))}}
 */
const LocationService = {
  GetAll: Factory.makeClientCall(LocationServiceConfigs.GetAll),
  QueryCities: Factory.makeAuthenticatedClientCall(
    LocationServiceConfigs.QueryCities
  ),
  QueryCountries: Factory.makeAuthenticatedClientCall(
    LocationServiceConfigs.QueryCountries
  ),
  QueryLocations: Factory.makeAuthenticatedClientCall(
    LocationServiceConfigs.QueryLocations
  ),
  QueryCitiesPublic: Factory.makeClientCall(LocationServiceConfigs.QueryCities),
  QueryCountriesPublic: Factory.makeClientCall(
    LocationServiceConfigs.QueryCountries
  ),
}

export { LocationServiceNamespace, LocationServiceConfigs, LocationService }
