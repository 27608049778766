/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import withStyles from '@mui/styles/withStyles'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import { grey as Grey } from '@mui/material/colors'

const styles = (theme) => ({
  displayableText: {
    '&:after': {
      content: '"..."',
    },
    '& p': {
      '&:after': {
        content: '"..."',
      },
    },
    '& ul': {
      '&  li:last-of-type': {
        '&:after': {
          content: '"..."',
        },
      },
    },
  },
  displayableContainer: {
    lineHeight: '1.5',
  },
  typographyContainer: {
    wordBreak: 'break-word',
  },
  readMoreLink: {
    color: Grey[500],
    cursor: 'pointer',
    textDecoration: 'underline',
    marginLeft: theme.spacing(1),
  },
})

class ParagraphComponent extends React.Component {
  static propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    limit: PropTypes.number,
    variant: PropTypes.string,
    fontWeight: PropTypes.string,
    renderAsMarkdown: PropTypes.bool,
  }

  state = {
    readMore: false,
  }

  toggleReadMore = (e) => {
    e.preventDefault()
    this.setState({
      readMore: !this.state.readMore,
    })
  }

  render() {
    const {
      text,
      limit,
      variant,
      fontWeight,
      classes,
      renderAsMarkdown,
      component,
    } = this.props
    const textToRender = renderAsMarkdown ? (
      <ReactMarkdown>{text}</ReactMarkdown>
    ) : (
      text
    )

    const isTextShrinkable = text ? limit < text.length : 0
    const displayableText = isTextShrinkable ? (
      <ReactMarkdown>{text.substring(0, limit)}</ReactMarkdown>
    ) : (
      textToRender
    )
    return (
      <Box fontWeight={fontWeight} className={classes.displayableContainer}>
        <Typography
          variant={variant}
          className={classes.typographyContainer}
          component={component}
        >
          <span
            className={
              this.state.readMore ? undefined : classes.displayableText
            }
          >
            {this.state.readMore ? textToRender : displayableText}
          </span>

          {!!isTextShrinkable && (
            <Link
              onClick={this.toggleReadMore}
              className={classes.readMoreLink}
              underline='hover'
            >
              {this.state.readMore ? 'Read less' : 'Read more'}
            </Link>
          )}
        </Typography>
      </Box>
    )
  }
}

export const Paragraph = withStyles(styles)(ParagraphComponent)
