import property from 'lodash/property'
import styled from 'styled-components'

import Score from './score'

export default (component) => styled(component)`
  legend {
    display: none;
  }

  [aria-roledescription='score'] {
    display: flex;
    flex-direction: row;
    margin-top: ${({ theme: { spacing } }) => spacing(3)};

    ${Score} {
      height: 61px;

      &:first-child {
        min-width: calc(100% / 6);

        strong {
          padding: 0 ${({ theme: { spacing } }) => spacing(1)};
        }
      }

      &:not(:first-child) {
        min-width: calc(100% / 12);

        &:nth-child(odd) {
          label {
            em {
              display: none;
            }
          }
        }

        strong {
          width: 24px;
        }
      }
    }
  }

  [aria-roledescription='comments'] {
    margin-top: ${({ theme: { spacing } }) => spacing(3)};

    .MuiFormLabel-root {
      &.MuiFormLabel-filled,
      &.Mui-focused {
        background-color: ${property('theme.palette.common.white')};
        padding: ${({ theme: { spacing } }) => `0 ${spacing(1)}`};
        transform: translate(14px, -12px) scale(0.75);
      }
    }

    .MuiTextField-root {
      margin: ${({ theme: { spacing } }) =>
        `${spacing(2)} ${spacing(2)} 0px ${spacing(2)}`};
      width: calc(100% - ${({ theme: { spacing } }) => spacing(4)});
    }

    .small {
      color: rgb(107, 119, 140);
      display: block;
      white-space: nowrap;
      font-size: 13px;
      line-height: 1.33333;
      margin: ${({ theme: { spacing } }) => `${spacing(1)} ${spacing(2)}`};
      font-style: inherit;
    }

    .key {
      background-color: var(--ds-background-neutral, #dfe1e6);
      color: var(--ds-text, #42526e);
      display: inline-block;
      box-sizing: border-box;
      max-width: 100%;
      padding: 2px 4px 3px;
      border-radius: 3px;
      font-size: 11px;
      font-weight: 700;
      line-height: 1;
      text-transform: uppercase;
      vertical-align: baseline;
    }
  }
`
