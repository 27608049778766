import CloseIcon from '@mui/icons-material/Close'
import { Button, Box, DialogActions, IconButton } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'

import { StyledContainedButton, StyledDialog, StyledDialogTitle } from './style'

const InterviewConfirmation = ({
  open,
  handleClose,
  onConfirm,
  onReject,
  onArchiveConfirm,
}) => (
  <StyledDialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
    <StyledDialogTitle>
      Are you sure you want to finish the interview?
      <IconButton onClick={onReject} size='large'>
        <CloseIcon />
      </IconButton>
    </StyledDialogTitle>
    <DialogContent>
      <Typography color='#a2a1b4'>
        Please make sure you added your final feedback.
      </Typography>
    </DialogContent>
    <DialogActions
      sx={{ justifyContent: 'space-between', m: '20px 15px 10px' }}
    >
      <Button
        variant='outlined'
        sx={{
          width: '100px',
          textTransform: 'none',
          color: '#3c26de',
        }}
        onClick={onReject}
      >
        Cancel
      </Button>
      <Box display='flex' gap={1}>
        <Button
          sx={{ textTransform: 'none', color: '#3c26de' }}
          variant='outlined'
          onClick={onArchiveConfirm}
        >
          Archive Interview
        </Button>
        <StyledContainedButton variant='contained' onClick={onConfirm}>
          Yes, Finish Interview
        </StyledContainedButton>
      </Box>
    </DialogActions>
  </StyledDialog>
)

export default InterviewConfirmation
