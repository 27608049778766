import { configureStore } from '@reduxjs/toolkit'

import interviewReducer from './slices/interview-slice'
import modalReducer from './slices/modal-slice'
import questionsReducer from './slices/questions-slice'
import reportReducer from './slices/report-slice'

const store = configureStore({
  reducer: {
    interview: interviewReducer,
    modal: modalReducer,
    questions: questionsReducer,
    report: reportReducer,
  },
})

export default store
