import isBoolean from 'lodash/isBoolean'

export const cast = (value) => {
  switch (true) {
    case isBoolean(value):
      return value ? 'yes' : 'no'
    default:
      return value
  }
}

export const format = ([key, value]) => `${key}=${cast(value)}`

export const stringify = (settings) =>
  Object.entries(settings).map(format).join(',')

export const isFirefox = () => !!navigator.userAgent.match(/firefox/i)
