import React from 'react'

const RadarChartLabelText = (props) => {
  const lines = []
  const label = props.children
  const labelLength = label?.length
  const { characterAllowance } = props
  const { charsBeforeBreak } = props
  if (labelLength > charsBeforeBreak + characterAllowance) {
    const chars = label.split('')
    for (
      let i = 0, j = charsBeforeBreak;
      i <= chars.length;
      i += charsBeforeBreak, j += charsBeforeBreak
    ) {
      const line = chars.slice(i, j)
      if (line.length === 1 || line.length === 2) {
        const previousLine = lines[lines.length - 1]
        lines[lines.length - 1] = `${previousLine}${line.join('')}`
      } else lines.push(line.join(''))
    }
  } else {
    lines.push(label)
  }
  const { x, y } = props
  const lineHeight = 1
  let linesLength = lines.length
  if (y > 385 && y < 399)
    return (
      <text {...props}>
        {lines.map((line, index) => (
          <tspan x={x} y={y} dy={`${index * lineHeight + 0.5}em`}>
            {line}
          </tspan>
        ))}
      </text>
    )
  if (y > 60 && y < 63) {
    return (
      <text {...props}>
        {lines.map((line, index) => {
          linesLength--
          return (
            <tspan x={x} y={y} dy={`-${linesLength + 0.5}em`}>
              {line}
            </tspan>
          )
        })}
      </text>
    )
  }
  return (
    <text {...props}>
      {lines.map((line, index) => (
        <tspan x={x} y={y} dy={`${index * lineHeight}em`}>
          {line}
        </tspan>
      ))}
    </text>
  )
}
export default RadarChartLabelText
