import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

const getIdentifier = (index) => `__KEYWORD_INDEX_${index}__`
const splitterRegex = /(__KEYWORD_INDEX_[0-9]+__)/g

const HighlightedText = ({ text, keywords, highlightClassName }) => {
  const tokens = useMemo(() => {
    let transformedString = text
    const savedWords = []

    keywords.forEach((keyword, index) => {
      const regexp = new RegExp(keyword, 'gi')
      transformedString = transformedString.replace(regexp, (match) => {
        // Save match in savedWords for later injection into JSX
        savedWords[index] = (savedWords[index] || []).concat(match)
        return getIdentifier(index)
      })
    })

    return transformedString.split(splitterRegex).map((slice) => {
      if (splitterRegex.test(slice)) {
        const idx = parseInt(slice.match(/[\d]+/)[0], 10)
        const savedWord = savedWords[idx].shift()
        return <span className={highlightClassName}>{savedWord}</span>
      }

      return slice
    })
  }, [text, keywords, highlightClassName])

  return <>{tokens}</>
}

HighlightedText.defaultProps = {
  text: '',
  keywords: [],
}

HighlightedText.propTypes = {
  text: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  highlightClassName: PropTypes.string.isRequired,
}

export default HighlightedText
