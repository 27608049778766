/* eslint-disable*/
import React from 'react'
import { IconButton, Typography, Tooltip } from '@mui/material'
import { Divider } from '@mui/material'
import Grid from '@mui/material/Grid'
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import '@jamiri/filter-map'
import { withTranslation } from 'react-i18next'
import { flow } from 'lodash'
import { PUBLIC_PROFILE_MINIMUM_RADAR_CHART_PLOT_COORDINATES } from 'common/constants'
import {
  useStyles,
  useStylesForLegend,
  useDynamicStyleForLegend,
} from './styles'
import { RadarChartHelper } from './helper'
import InfoIcon from '@mui/icons-material/Info'
import RadarChartLabelText from './RadarChartLabelText'
const RadarChartComponent = ({ data, classes, t }) => {
  const radarChartHelper = new RadarChartHelper(data)
  const radarChartData = radarChartHelper
    .parsePublishedWSCReport()
    .transform()
    .setDimensions()
    .setDimensionsWithColors()
    .addOtherDimensions()
    .closeGaps()
    .renameDuplicateSubdimensions().report
  const dimensionsWithColors = radarChartHelper.dimensionsWithColors
  const renderCustomLabel = ({ payload, ...otherProps }) => {
    const originalValue = payload.value.trim()
    const charsBeforeBreak = calculateCharsBeforeBreak()
    console.log({ charsBeforeBreak })
    return (
      <RadarChartLabelText
        {...otherProps}
        charsBeforeBreak={charsBeforeBreak}
        characterAllowance={2}
      >
        {originalValue}
      </RadarChartLabelText>
    )
  }
  const calculateCharsBeforeBreak = () => {
    const width = window.innerWidth
    if (width > 1500) return 21
    else if (width > 1400 && width < 1500) return 19
    else if (width > 1300 && width < 1400) return 15
    else if (width > 1200 && width < 1300) return 12
  }
  const renderLegend = ({ payload }) => {
    const legendStyleClasses = useStylesForLegend()
    const legendJSX = (
      <div className={legendStyleClasses.legendOuterDiv}>
        {payload.map((entry, index) => {
          const dynamicCSSClasses = useDynamicStyleForLegend({
            color: entry.color,
          })
          return (
            <div
              className={legendStyleClasses.legendInnerDiv}
              key={`legend-item-${index}`}
            >
              <span className={dynamicCSSClasses.legendLi}>&#x2022;</span>
              <span className={legendStyleClasses.legendSpan}>
                {entry.value.trim()}
              </span>
            </div>
          )
        })}
      </div>
    )
    return (
      <>
        <Divider />
        {legendJSX}
      </>
    )
  }
  return (
    <>
      {radarChartData.length >=
        PUBLIC_PROFILE_MINIMUM_RADAR_CHART_PLOT_COORDINATES && (
        <div className={classes.profileWSCAssessmentWrapper}>
          <Grid
            item
            container
            xs={12}
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            className={classes.titleContainer}
          >
            <Grid item className={classes.chartsLabelStyle}>
              <Typography variant='h6'>{t('skillsMatch')}</Typography>
            </Grid>
            <Grid item className={classes.titleStyle}>
              <Tooltip arrow title={t('skillToolTipHelpText')}>
                <IconButton>
                  <InfoIcon className={classes.skillsHelpIcon} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Divider />
          <Grid item container xs={true}>
            <Grid
              container
              item
              alignItems='center'
              justifyContent='center'
              id='radarChart'
              height={500}
            >
              <ResponsiveContainer>
                <RadarChart
                  cx='38%'
                  cy='46%'
                  outerRadius={160}
                  data={radarChartData}
                  className={classes.radarChartStyle}
                >
                  <PolarGrid gridType='circle' />
                  <PolarAngleAxis
                    dataKey='subDimension'
                    tick={renderCustomLabel}
                  />
                  <PolarRadiusAxis
                    tick={false}
                    axisLine={false}
                    tickCount={11}
                    domain={[0, 10]}
                  />
                  {dimensionsWithColors.map((datum, index) => {
                    return (
                      <Radar
                        key={index}
                        dataKey={datum.dimension}
                        stroke={datum.color}
                        fill={datum.color}
                        fillOpacity={0.4}
                        dot={true}
                      />
                    )
                  })}
                  <Legend content={renderLegend} />
                </RadarChart>
              </ResponsiveContainer>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}
const RadarChartWrapped = flow(withTranslation('publicProfile'))(
  RadarChartComponent
)
function ProfileWSCAssessment(props) {
  const { report } = props
  const classes = useStyles()
  return <>{report && <RadarChartWrapped data={report} classes={classes} />}</>
}

export default ProfileWSCAssessment
