/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { DEBOUNCE_DELAY } from 'common/constants'

class Autosuggest extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    service: PropTypes.func,
    async: PropTypes.bool,
    error: PropTypes.string,
    defaultValue: PropTypes.string,
    delay: PropTypes.number,
    onInputClear: PropTypes.func,
    onSelect: PropTypes.func.isRequired,
    clearAfterSelection: PropTypes.bool,
    id: PropTypes.string,
    // validator(props, propName, componentName) {
    //   if (props.async && !props.service) {
    //     return new Error(
    //       `You need to specify "service" prop when defining the component as async.`
    //     )
    //   }
    //   return
    // },
    invokeSelectWhenEmpty: PropTypes.bool,
  }

  static defaultProps = {
    clearAfterSelection: false,
    invokeSelectWhenEmpty: false,
    onInputClear: () => {},
    id: 'autoSuggest',
    disableClearable: false,
  }

  constructor(props) {
    super(props)
    const { defaultValue } = props
    const shrink = !!defaultValue

    this.state = {
      options: this.props.options || [],
      value: props.defaultValue,
      shrink,
    }

    this.onInputChange = this.onInputChange.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onSelect = this.props.onSelect.bind(this)
    this.delay = this.props.delay || DEBOUNCE_DELAY
    this.timerValue = null
  }

  componentWillUnmount() {
    clearTimeout(this.timerValue)
  }

  componentDidMount() {
    if (this.props.invokeSelectWhenEmpty) {
      this.loadData('')
    }
  }

  async loadData(value) {
    const options = await this.props.service(value)
    this.setState({
      options,
      loading: false,
    })
  }

  async onInputChange(event) {
    if (!event) return
    if (event.type !== 'change') return
    try {
      const { value } = event.currentTarget
      this.setState({ value, shrink: true })

      if (value === '') {
        this.props.onSelect({})
      }

      if (this.props.invokeSelectWhenEmpty && value.length === 0) {
        this.props.onSelect(null)
      }

      if (!this.props.async) return

      clearTimeout(this.timerValue)
      this.timerValue = setTimeout(async () => {
        this.loadData(value)
      }, this.delay)
    } catch (err) {
      console.error(err)
    }
  }

  onChange(event, option) {
    if (option === null) {
      this.props.onInputClear(this.props.id)
    }
    const selected = !!option && this.onSelect(option.value)
    const value = selected && !this.props.clearAfterSelection ? selected : ''

    this.setState({ value, loading: false })
  }

  render() {
    const { loading } = this.state

    return (
      <Autocomplete
        disabled={this.props.disabled}
        id={this.props.id}
        onClose={() => {
          if (this.props.clearAfterSelection) {
            this.setState({ value: '' })
            this.loadData('')
          }
        }}
        onChange={this.onChange}
        getOptionLabel={(option) => option.label}
        options={this.state.options}
        inputValue={this.state.value || ''}
        onInputChange={this.onInputChange}
        getOptionDisabled={this.props.getOptionDisabled}
        disableClearable={this.props.disableClearable}
        renderOption={(props, option) => (
          <li {...props} key={option.value?.id || props.key}>
            {option.label}
          </li>
        )}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label={this.props.label}
            fullWidth
            helperText={this.props.error}
            error={!!this.props.error}
            margin='normal'
            variant={this.props.variant || 'outlined'}
            InputLabelProps={{ shrink: this.state.shrink }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    )
  }
}

export { Autosuggest }
