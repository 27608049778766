import { Typography } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import React from 'react'
import { Link } from 'react-router-dom'

const styles = (theme) => ({
  container: {
    position: 'fixed',
    left: '0px',
    bottom: '0px',
    padding: '3px 6px',
    background: 'white',
    border: '1px solid lightGrey',
    borderRadius: '3px',
    zIndex: 100000,
  },
})

const LicensesOverlayBase = ({ classes }) => (
  <div className={classes.container}>
    <Link to='/third-party' target='_blank'>
      <Typography variant='caption' data-automator='third-party-licenses'>
        Third Party Licences
      </Typography>
    </Link>
  </div>
)

const LicensesOverlay = withStyles(styles)(LicensesOverlayBase)

export { LicensesOverlay }
