import { Box, Button, Typography } from '@mui/material'
import styled, { css } from 'styled-components'

import { getDifficultyColor } from 'apps/scorecard-v2/helpers'

const FlexJustify = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledWrapper = styled(Box)`
  & .MuiPaper-root {
    max-width: 100% !important;
  }
  margin-bottom: 6px;
`

export const StyledButton = styled(Button)`
  ${FlexJustify};
  width: 100%;
  padding: 4px;
  text-transform: none;
  margin-bottom: 5px;

  & p {
    text-align: left;
  }

  &:focus {
    background: rgba(48, 63, 159, 0.04);
  }
`

export const StyledContainer = styled(Box)`
  opacity: ${({ scored }) => scored && '0.4'};
  ${FlexJustify};
  gap: 7px;

  & .MuiSvgIcon-root {
    font-size: 0.7rem;
    color: ${({ difficulty }) => getDifficultyColor(difficulty)};
  }
`
export const StyledScore = styled(Box)`
  ${FlexJustify};
  gap: 10px;
`

export const StyledText = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 550px;
  color: #555172;
`
