import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import withStyles from '@mui/styles/withStyles'
import flow from 'lodash/flow'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter, NavLink } from 'react-router-dom'

import Layout from 'components/Layout'
import { Loading } from 'components/Loading'
import { withProfile } from 'hocs/withProfile'
import { ProfileService } from 'sdk/profile/Service'

import { PAGES } from '../../common/constants'
import { AuthenticatedNavBar } from '../../components'

import { ProfileSections } from './sections'

const styles = (theme) => {
  const activeLinkStyles = {
    textDecoration: 'none',
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  }

  const baseLinkStyles = {
    color: theme.palette.secondary.dark,
    fontSize: theme.typography.fontSize,
    textDecoration: 'none',
    '&:hover': activeLinkStyles,
  }

  return {
    menuLink: baseLinkStyles,
    activeLink: activeLinkStyles,
    sidebar: {
      left: '24px',
      position: 'fixed',
      top: '120px',
      maxWidth: theme.spacing(18),
    },
    profileWrapper: {
      marginLeft: theme.spacing(18),
    },
  }
}

class ProfileComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
    }

    this.sections = [
      {
        title: this.props.t('profile_about_me_section_title'),
        link: `/${PAGES.PROFILE_ABOUT_ME_SECTION}`,
        routeParam: 'about-me',
      },
      {
        title: this.props.t('profile_skills_section_title'),
        link: `/${PAGES.PROFILE_SKILLS_SECTION}`,
        routeParam: 'skills',
      },
      {
        title: this.props.t('profile_experience_section_title'),
        link: `/${PAGES.PROFILE_EXPERIENCE_SECTION}`,
        routeParam: 'experience',
      },
      {
        title: this.props.t('profile_education_section_title'),
        link: `/${PAGES.PROFILE_EDUCATION_SECTION}`,
        routeParam: 'education',
      },
      {
        title: this.props.t('profile_certification_section_title'),
        link: `/${PAGES.PROFILE_CERTIFICATION_SECTION}`,
        routeParam: 'certification',
      },
    ]
  }

  static getDerivedStateFromProps(props, state) {
    if (!isEmpty(props.profile)) {
      return {
        loading: false,
      }
    }
    return null
  }

  async componentDidMount() {
    const { data } = await ProfileService.getProfile()
    this.props.dispatchers.loadProfile(data)
  }

  getSectionFromURL = (pathname, index) => {
    const resources = pathname.split('/')
    return resources.length > 2 ? resources.slice(index)[0] : 'about-me'
  }

  getSection = (pathname) => {
    const isEditMode = pathname.includes('edit')
    const sectionIndex = isEditMode ? -2 : -1
    const currentSectionName = this.getSectionFromURL(pathname, sectionIndex)
    const filteredSections = this.sections.filter((s) =>
      s.link.includes(currentSectionName)
    )
    const currentTitle = get(filteredSections, '[0].title', '')
    return {
      isEditMode,
      currentSectionName,
      currentTitle,
    }
  }

  render() {
    const { classes, profile } = this.props
    const { currentSectionName, currentTitle, isEditMode } = this.getSection(
      this.props.location.pathname
    )

    return (
      <Loading loading={this.state.loading}>
        <Layout
          navbarComponent={AuthenticatedNavBar}
          currentPage={PAGES.PROFILE}
        >
          <Grid
            container
            spacing={4}
            direction='row'
            justifyContent='space-evenly'
            alignItems='flex-start'
          >
            <Grid item xs={2} className={classes.sidebar}>
              <List component='nav' aria-label='Profile sections'>
                {this.sections.map(({ routeParam, title, link }) => (
                  <ListItem key={title}>
                    {routeParam.includes(currentSectionName) ? (
                      <ListItemText
                        primary={title}
                        className={classes.activeLink}
                      />
                    ) : (
                      <NavLink to={link} className={classes.menuLink}>
                        <ListItemText primary={title} />
                      </NavLink>
                    )}
                  </ListItem>
                ))}
              </List>
            </Grid>

            <Grid item xs={10} className={classes.profileWrapper}>
              <ProfileSections
                profile={profile}
                currentTitle={currentTitle}
                isEditMode={isEditMode}
              />
            </Grid>
          </Grid>
        </Layout>
      </Loading>
    )
  }
}

export const Profile = flow(
  withTranslation('profile_view'),
  withRouter,
  withStyles(styles),
  withProfile
)(ProfileComponent)
