/* eslint-disable*/
import React from 'react'
import ReactGA from 'react-ga'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

const dev = process.env.NODE_ENV !== 'production'

!dev &&
  ReactGA.initialize(process.env.REACT_APP_TRACKING_ID, {
    debug: process.env.NODE_ENV === 'DEBUG',
  })

const mapStateToProps = (state, ownProps) => {
  return {
    userId: state?.auth ? state.auth.id : null,
  }
}

const withTracker = (WrappedComponent, options = {}) => {
  const track = (page, opts = {}) => {
    ReactGA.set({
      page,
      ...opts,
    })

    ReactGA.pageview(page)
  }

  const event = (args) => {
    if (!args.category || !args.action) {
      throw new Error(
        'Arguments of event must have (category, action) ' +
          'attributes, (label, value) attributes are optional'
      )
    }
    !dev && ReactGA.event(args)
  }

  const timing = (args) => {
    if (!args.category || !args.variable || !args.value) {
      throw new Error(
        'Arguments of timing must have (category, variable, value) ' +
          'attributes, (label) attribute is optional'
      )
    }
    !dev && ReactGA.timing(args)
  }

  class GAHoC extends React.Component {
    componentDidMount() {
      const { location } = this.props
      !dev && track(location.pathname, this.withUser())
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      const { location } = this.props
      if (location.pathname !== prevProps.location.pathname && !dev) {
        track(location.pathname, this.withUser())
      }
    }

    withUser = () => {
      const { userId } = this.props
      if (userId) {
        return { userId }
      }

      return {}
    }

    render() {
      // Filter out props to avoid leaking to WrappedComponent
      const { location, match, history, userId, ...restProps } = this.props

      return (
        <WrappedComponent
          gEvent={event}
          gTiming={timing}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...restProps}
        />
      )
    }
  }

  return connect(mapStateToProps)(withRouter(GAHoC))
}

export { withTracker }
