import { elementType, any } from 'prop-types'
import React, { createElement, useEffect } from 'react'
import { hotjar } from 'react-hotjar'

const HOTJAR_ID_VERSION = 6

const Renderer = ({ component, props }) => {
  useEffect(() => {
    hotjar.initialize(props.hotjarId, HOTJAR_ID_VERSION)
  }, [props.hotjarId])
  return createElement(component, props)
}

Renderer.propTypes = {
  component: elementType,
  props: any,
}

export const withHeatMap = (hotjarId) => (component) => (props) =>
  <Renderer component={component} props={{ ...props, hotjarId }} />
