import { bool, string, node } from 'prop-types'
import React, { Children, forwardRef, useCallback } from 'react'
import {
  Button as RegularButton,
  CircularProgress,
  Fab,
  IconButton,
  Tooltip,
} from '@mui/material'

import { isIcon } from 'apps/scorecard/helpers/render'

import withStyle from './style'

export const Button = forwardRef(
  ({ children, disabled, floating, loading, tooltip, ...props }, ref) => {
    const Assistance = useCallback(
      (props) =>
        !disabled ? (
          <Tooltip title={tooltip} placement='top' arrow {...props} />
        ) : (
          props.children
        ),
      [disabled, tooltip]
    )
    const Icon = useCallback(floating ? Fab : IconButton, [floating])
    const renderChild = useCallback(
      (child) =>
        isIcon(child) ? (
          <Assistance>
            <Icon ref={ref} disabled={disabled} {...props}>
              {child}
              {!!loading && <CircularProgress size='100%' />}
            </Icon>
          </Assistance>
        ) : (
          <RegularButton ref={ref} disabled={disabled} {...props}>
            {child}
          </RegularButton>
        ),
      [ref, loading, disabled, props]
    )

    return Children.map(children, renderChild)
  }
)

Button.propTypes = {
  children: node,
  className: string.isRequired,
  disabled: bool,
  floating: bool,
  loading: bool,
  tooltip: node,
}

Button.defaultProps = {
  disabled: false,
  floating: false,
  loading: false,
  tooltip: '',
}

export default withStyle(Button)
