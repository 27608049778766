import Axios from 'axios'
import qStr from 'query-string'

import { MESSAGE_TOPIC_NAMES } from 'common/constants'
import { store } from 'common/store'

const config = {
  baseURL: process.env.REACT_APP_API_HOST,
}

let pubSubInstance = null
const setPubSubInstance = (instance) => {
  pubSubInstance = instance
}

const getToken = () => {
  const state = store.getState()
  return state.auth && state.auth.accessToken
}

const Factory = {
  MethodPut: 'PUT',
  MethodPost: 'POST',
  MethodPatch: 'PATCH',
  MethodDelete: 'DELETE',
  MethodGet: 'GET',
  /**
   * Generate an object with the method and the uri
   *
   * @param method
   * @param uri
   * @param params
   * @returns {{method: string, uri: string, params: string[]}}
   */
  createRequestConfig: (method, uri, params = [], timeout = 15000) => ({
    method,
    uri,
    params,
    timeout,
  }),

  /**
   *
   * @param call
   * @returns {function(Object, Object): Promise<any>}
   */
  makeClientCall:
    (call, authenticated = false) =>
    (data, callOpts = {}) => {
      const conf = {
        url: call.uri,
        method: call.method,
        baseURL: config.baseURL,
        timeout: call.timeout,
        validateStatus: (status) => status >= 200 && status < 400,
        params: {},
      }

      if (
        call.method === Factory.MethodGet ||
        call.method === Factory.MethodDelete
      ) {
        if (callOpts.arrayFormat) {
          // https://www.npmjs.com/package/query-string#arrayformat-1
          conf.url += `?${qStr.stringify(data, {
            arrayFormat: callOpts.arrayFormat,
          })}`
        } else {
          conf.params = data
        }
      } else {
        conf.data = data
      }

      if (callOpts.path !== undefined) {
        Object.keys(callOpts.path).forEach((k) => {
          conf.url = conf.url.replace(`:${k}`, callOpts.path[k])
        })
      }

      if (callOpts.params) {
        conf.params = {
          ...conf.params,
          ...callOpts.params,
        }
      }

      return new Promise((resolve, reject) => {
        const publishErrors = ({ err, pubsub }) => {
          try {
            const { UNAUTHORIZED, ERROR } = MESSAGE_TOPIC_NAMES

            const {
              config: { url, method },
              message,
              response: { status },
            } = err

            if (status >= 400 && status <= 599) {
              let topicName = ERROR

              if (status === 401) {
                topicName = UNAUTHORIZED
              }

              pubsub.publish(topicName, {
                url,
                method: method.toLowerCase(),
                message,
              })
            }
          } catch (e) {
            console.error(e)
          }
        }

        conf.headers = authenticated
          ? {
              Authorization: getToken(),
              'Content-Type': 'application/json',
              ...call.headers,
            }
          : {
              'Content-Type': 'application/json',
              ...call.headers,
            }

        Axios.request(conf)
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            if (pubSubInstance) {
              publishErrors({ err, pubsub: pubSubInstance })
            }

            reject(err)
          })
      })
    },

  makeAuthenticatedClientCall: (call) => Factory.makeClientCall(call, true),

  /**
   *
   * @param call
   * @returns {function(Object, Object): Promise<any>}
   */
  makeUploadCall:
    (call) =>
    (data, callOpts = {}) => {
      const conf = {
        url: data.url,
        method: call.method,
        timeout: 120000,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        validateStatus(status) {
          return status >= 200 && status < 500
        },
        onUploadProgress: data.progress,
      }

      if (call.method === Factory.MethodGet) {
        conf.params = data.formData
      } else {
        conf.data = data.formData
      }

      return new Promise((resolve, reject) => {
        Axios.request(conf)
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
}

export { setPubSubInstance, Factory }
