import { createDispatchHook } from 'react-redux'

import scorecardContext from '../context'

const useScorecardDispatch = () => {
  const useDispatch = createDispatchHook(scorecardContext)
  const dispatch = useDispatch()

  return dispatch
}

export default useScorecardDispatch
