import { Factory } from '../client/Factory'

const JobsServiceNamespace = 'jobs'

const JobsServiceConfigs = {
  getJobs: Factory.createRequestConfig(Factory.MethodGet, '/job'),
  getJobDetails: Factory.createRequestConfig(Factory.MethodGet, '/job/:id'),
  getPublicJobDetails: Factory.createRequestConfig(
    Factory.MethodGet,
    '/job/public/:id'
  ),

  getCandidates: Factory.createRequestConfig(
    Factory.MethodGet,
    '/job/:id/candidates'
  ),
  updateCandidateStage: Factory.createRequestConfig(
    Factory.MethodPatch,
    'job/:jobId/candidate/:candidateId/stage/:stage'
  ),
  refreshMatches: Factory.createRequestConfig(
    Factory.MethodPut,
    'job/:jobId/candidates/refresh'
  ),
  bulkUpdateStage: Factory.createRequestConfig(
    Factory.MethodPut,
    '/job/:jobId/candidates/stage'
  ),
  referAFriend: Factory.createRequestConfig(
    Factory.MethodPost,
    '/referral/:id'
  ),
  updateCandidateApproachResponse: Factory.createRequestConfig(
    Factory.MethodPost,
    '/job/public/:jobId/candidates/approach/response'
  ),
}

const JobsService = {
  getJobs: (data) =>
    Factory.makeAuthenticatedClientCall(JobsServiceConfigs.getJobs)(data, {
      arrayFormat: 'none',
    }),
  getJobDetails: Factory.makeAuthenticatedClientCall(
    JobsServiceConfigs.getJobDetails
  ),
  getCandidates: (data, callOpts) =>
    Factory.makeAuthenticatedClientCall(JobsServiceConfigs.getCandidates)(
      data,
      {
        arrayFormat: 'none',
        ...callOpts,
      }
    ),
  updateCandidateStage: Factory.makeAuthenticatedClientCall(
    JobsServiceConfigs.updateCandidateStage
  ),
  refreshMatches: Factory.makeAuthenticatedClientCall(
    JobsServiceConfigs.refreshMatches
  ),
  bulkUpdateStage: Factory.makeAuthenticatedClientCall(
    JobsServiceConfigs.bulkUpdateStage
  ),
  getPublicJobDetails: Factory.makeClientCall(
    JobsServiceConfigs.getPublicJobDetails
  ),
  referAFriend: Factory.makeClientCall(JobsServiceConfigs.referAFriend),
  updateCandidateApproachResponse: Factory.makeClientCall(
    JobsServiceConfigs.updateCandidateApproachResponse
  ),
}

export { JobsServiceNamespace, JobsServiceConfigs, JobsService }
