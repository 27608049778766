/* eslint-disable */
import React, { useCallback, useState, useEffect } from 'react'
import { flow } from 'lodash'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import MuiTextField from '@mui/material/TextField'
import withStyles from '@mui/styles/withStyles'
import { maxString } from 'common/utils'
import Chip from '@mui/material/Chip'
import { Box } from '@mui/material'

const styles = (theme) => ({
  container: {
    display: 'inline-flex',
    width: '100%',
  },
  extra: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: `0 ${theme.spacing(2)}`,
  },
  chips: {
    margin: theme.spacing(0.5, 0, -1.5, 0),
    '& > div': {
      margin: theme.spacing(1, 1, 1, 0),
    },
  },
})

const TextFieldBase = ({
  value,
  onChange,
  onBlur,
  classes,
  helperText,
  maxLength,
  error,
  disabled,
  adornment,
  inputProps,
  hasChips,
  ...props
}) => {
  const [keyWord, setKeyWord] = useState('')
  const [keyWords, setKeyWords] = useState(value || [])
  const [inputValue, setInputValue] = useState(maxString(value, maxLength))

  const extra = !!error || !!helperText || !!maxLength

  const handleOnChange = useCallback(
    (e) => {
      const { value } = e.target
      const trimmedValue = maxString(value, maxLength)
      setInputValue(trimmedValue)
      !hasChips && onChange(trimmedValue)
      hasChips && setKeyWord(trimmedValue)
    },
    [maxLength, onChange, hasChips]
  )

  const handleDelete = (chipToDelete) => () => {
    const updatedKeyWords = keyWords.filter((chip) => chip !== chipToDelete)
    setKeyWords(updatedKeyWords)
    onChange(updatedKeyWords)
  }

  const addKeyWord = () => {
    const updatedKeyWords = keyWords.concat(keyWord)
    setKeyWords(updatedKeyWords)
    onChange(updatedKeyWords)
    setKeyWord('')
  }

  const renderChips = useCallback(
    hasChips &&
      keyWords.map((keyword, index) => (
        <Chip label={keyword} key={keyword} onDelete={handleDelete(keyword)} />
      )),
    [keyWords]
  )

  const catchReturn = (ev) => {
    setInputValue(ev.target.value)
    if (hasChips && ev.key === 'Enter' && ev.target.value !== '') {
      addKeyWord()
      ev.preventDefault()
      setInputValue('')
    }
  }

  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <FormControl className={classes.container} error={!!error}>
      <MuiTextField
        {...props}
        error={!!error}
        helperText={error || helperText}
        value={hasChips ? keyWord : inputValue}
        onChange={handleOnChange}
        onBlur={() => (!!onBlur ? onBlur(inputValue) : null)}
        disabled={disabled}
        inputProps={inputProps}
        onKeyPress={catchReturn}
      />
      {extra && (
        <div className={classes.extra}>
          <FormHelperText>{helperText}</FormHelperText>
          {maxLength && (
            <FormHelperText>
              {inputValue.length}/{maxLength}
            </FormHelperText>
          )}
        </div>
      )}
      {hasChips && (
        <Box display='flex' flexWrap='wrap' className={classes.chips}>
          {renderChips}
        </Box>
      )}
    </FormControl>
  )
}

TextFieldBase.defaultProps = {
  value: '',
  type: 'text',
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
  hasChips: false,
  onBlur: () => {},
}

const TextField = flow(withStyles(styles))(TextFieldBase)

export { TextField }
