import { object, string, array } from 'yup'

import { TESTED_SKILLS_LIMIT } from 'common/constants'

const getTestedSkillsValidation = ({ skillLimitValidationErrorMessage }) =>
  array()
    .of(
      object({
        id: string(),
      })
    )
    .max(TESTED_SKILLS_LIMIT, skillLimitValidationErrorMessage)

export { getTestedSkillsValidation }
