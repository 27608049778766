import { blueGrey, red, indigo } from '@mui/material/colors'
import { gridClasses } from '@mui/material/Grid'
import { inputBaseClasses } from '@mui/material/InputBase'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import { createTheme, adaptV4Theme } from '@mui/material/styles'

export const theme = createTheme({
  ...adaptV4Theme({
    direction: 'ltr',
    shape: {
      borderRadius: 5,
    },
    palette: {
      white: '#fff',
      type: 'light',
      black: '#000',
      black1: '#1E2338',
      gray: '#666',
      newGray: '#EEEDF2',
      darkGray: '#808080',
      lightGray: '#333',
      lightGray2: '#828282',
      lightGray3: '#A9A7BE',
      lightGray4: '#F2F2F2',
      lightGray5: '#C4C4C4',
      lightGray6: '#E8E8E8',
      lightGray7: '#F5F5F5',
      darkBlue: '#1C173C',
      lightPurple: '#5A5288',
      lightPurple2: '#7E799D',
      darkMarine: '#1E2338',
      backgroundColor: '#F9FAFB',
      default: {
        main: '#F2F2F2',
      },
      primary: {
        blue: '#3C26DE',
        light: indigo[500],
        main: indigo[700],
        dark: indigo[900],
      },
      secondary: {
        main: blueGrey[700],
        blue: '#2B254F',
        gray: '#F1F3F6',
      },
      error: {
        light: red[300],
        main: red[500],
        dark: red[700],
      },
      radioGroupButton: {
        main: '#2B254F',
        contrastText: '#FFA',
        hover: '#7E799D',
        border: 'rgba(0, 0, 0, 0.12) !important',
      },
      contrastThreshold: 3,
      background: {
        default: '#F2F2F2',
        light: '#FFFFFF',
        topSkills: '#F9D223',
        publicProfile: '#F3F2EF',
      },
      dark: '#1E1C2F',
      green: '#52BC77',
      green1: '#02B875',
      orange: '#FFA800',
      red: '#FF0000',
      yellow: '#F9D223',
      yellow1: '#FFA94D',
      purple: '#3C26DE',
      employmentType: {
        unknown: {
          backgroundColor: '#F1F3F6',
          textColor: '#323232',
        },
        fullTime: {
          backgroundColor: '#3C26DE',
          textColor: '#FFFFFF',
        },
        freelancer: {
          backgroundColor: '#F9D223',
          textColor: '#000000',
        },
      },
      matchingScore: {
        level1: '#DF1023', // score between 0-10,
        level2: '#EA3D16', // score between 10-20,
        level3: '#F76C13', // score between 20-30,
        level4: '#FE8C04', // score between 30-40,
        level5: '#FFB104', // score between 40-50,
        level6: '#F3CF00', // score between 50-60,
        level7: '#C3CA04', // score between 60-70,
        level8: '#90C51F', // score between 70-80,
        level9: '#50C040', // score between 80-90,
        level10: '#00BC5E', // score between 90-100.
      },
    },
    overrides: {
      MuiAutocomplete: {
        popper: { zIndex: 10000 },
      },
      MuiPopover: {
        root: {
          zIndex: '9001 !important',
        },
      },
    },
    typography: {
      // In Japanese the characters are usually larger.
      fontSizeSmall: 12,
      fontSize: 14,
      fontSizeTitle: 20,
      header: {
        fontSize: 16,
      },
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      fontWeight: {
        normal: 400,
        strong: 500,
        bold: 600,
        bolder: 700,
        stronger: 900,
      },
      fontWeightBold: 600,
      subSectionHeader: {
        fontSize: 18,
      },
      fontStyle: {
        italic: 'italic',
      },
    },
    activityChart: {
      colorLevel0: '#F1F3F6',
      colorLevel1: '#D1CFE0',
      colorLevel2: '#9A96BB',
      colorLevel3: '#5A5288',
      colorLevel4: '#2B254F',
    },
  }),
  /* eslint-disable no-shadow */
  /* Style overrides for MUI components after v4 -> v5 migration */
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 0,
          border: 'none',
          backgroundColor: 'rgba(0, 0, 0, 0.12)',
          height: 1,
        },
        middle: ({ theme }) => ({
          marginLeft: theme.spacing(2),
          marginRight: theme.spacing(2),
        }),
        vertical: ({ theme }) => ({
          width: theme.spacing(1 / 8),
          height: '100%',
        }),
      },
    },

    MuiGrid: {
      styleOverrides: {
        container: ({ ownerState, theme }) => ({
          ...(ownerState.spacing === 2 && {
            '& > .MuiGrid-item': {
              padding: theme.spacing(1),
            },
          }),
        }),

        'spacing-xs-3': {
          margin: -12,

          [`& > .${gridClasses.item}`]: {
            padding: 12,
          },
        },

        'spacing-xs-1': {
          margin: -4,

          [`& > .${gridClasses.item}`]: {
            padding: 4,
          },
        },

        'spacing-xs-2': {
          margin: -8,
        },

        'spacing-xs-5': {
          margin: -20,
        },

        'spacing-xs-6': {
          margin: -24,

          [`& > .${gridClasses.item}`]: {
            padding: 24,
          },
        },

        'spacing-xs-4': {
          margin: -16,

          [`& > .${gridClasses.item}`]: {
            padding: 16,
          },
        },

        'grid-md-3': {
          maxWidth: '25%',
          flexBasis: '25%',
        },

        'direction-xs-column': {
          [`& > .${gridClasses.item}`]: {
            // maxWidth: '83.33%',

            [`&.${gridClasses['grid-xs-4']}`]: {
              maxWidth: '33.33%',
            },

            [`&.${gridClasses['grid-xs-2']}`]: {
              maxWidth: '16.66%',
            },
          },
        },

        'direction-xs-column-reverse': {
          [`& > .${gridClasses.item}`]: {
            maxWidth: '83.33%',
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          [`& .${outlinedInputClasses.input}`]: {
            padding: theme.spacing(2.25, 1.75),

            [`&.${inputBaseClasses.inputAdornedEnd}`]: {
              paddingRight: 0,
            },

            [`&.${inputBaseClasses.inputMultiline}`]: {
              padding: 0,
            },
          },
        }),

        multiline: ({ theme }) => ({
          padding: theme.spacing(2.25, 1.75),
        }),
      },
    },

    MuiInputBase: {
      styleOverrides: {
        input: ({ theme }) => ({
          height: '1.1876em',
        }),

        inputMultiline: {
          height: 'auto',
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.87)',
        },
        containedPrimary: {
          color: '#fff',
          backgroundColor: '#303f9f',
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: ({ theme }) => ({
          marginTop: theme.spacing(-1),
        }),
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(1.5),
          marginRight: 0,
        }),

        sizeSmall: {
          padding: 3,
          fontSize: '1.125rem',
        },
      },
    },

    MuiSlider: {
      styleOverrides: {
        root: {
          height: 2,
        },
        track: {
          border: 'none',
        },
      },
    },

    MuiIcon: {
      styleOverrides: {
        root: {
          display: 'inline',
        },
      },
    },
  },
  /* eslint-enable no-shadow */
})

export default theme
