export const LIVE_CODING = 'Live Coding'
export const GENERIC_QUESTIONS = 'Generic Questions'
export const LANGUAGES_AND_FRAMEWORKS = 'Languages and Frameworks'
export const FEEDBACK = 'Feedback'

export const TAB_MAP = {
  0: LIVE_CODING,
  1: GENERIC_QUESTIONS,
  2: LANGUAGES_AND_FRAMEWORKS,
}

export const TAB_NAME_MAP = {
  [LIVE_CODING]: 0,
  [GENERIC_QUESTIONS]: 1,
  [LANGUAGES_AND_FRAMEWORKS]: 2,
}

export const SCORE_HINT = {
  0: 'Not scored',
  1: 'The candidate did not give an answer at all',
  2: 'The candidate tried to answer but it was wrong',
  3: "The candidate had a general idea of the question, but didn't have an answer",
  4: 'The candidate had a general idea of the question, and had only a little answer',
  5: 'The candidate had an idea of the question, and could give a short answer about it, some things were not correct',
  6: 'The candidate had an idea of the question, and could give a short answer about it, everything said was correct',
  7: 'The candidate could give a decent answer to the question',
  8: 'The candidate could give a decent answer to the question and make some examples',
  9: 'The candidate could give a great answer to the question and make some examples',
  10: 'The candidate could give a profound answer to the question, make some examples, and deeply know everything',
}

export const DIFFICULTY = {
  hard: 'DifficultyHard',
  medium: 'DifficultyMedium',
  easy: 'DifficultyEasy',
}

export const DATE_TIME_FORMAT = 'dd-MMM-yy HH:mm'
export const HOURS_TO_SUBMIT = 4

export const STATUS = { idle: 0, pending: 1, fulfilled: 2, rejected: 3 }

export const TWO_SECS = 2000
export const ONE_SEC = 1000

export const DIFFICULTY_MAP = {
  DifficultyEasy: 'Easy',
  DifficultyMedium: 'Medium',
  DifficultyHard: 'Hard',
}

export const INTERVIEW_STATUS_FINISHED = 'INTERVIEW_STATUS_FINISHED'
export const INTERVIEW_STATUS_STARTED = 'INTERVIEW_STATUS_STARTED'

export const INTERVIEW_TYPE_FP = 'InterviewTypeFP'

export const COMPETENCY_LEVELS = [
  {
    title: 'Expert',
    minScore: 9,
    maxScore: 10,
    info: 'High level of expertise and knowledge. Sees overall picture and alternative approaches, has vision of what may be possible and achieves excellence with ease. Strongly recommended.',
  },
  {
    title: 'Proficient',
    minScore: 7,
    maxScore: 8.9,
    info: 'Has big knowledge and systems thinking developed. Can solve complex problems and can achieve a high standard routinely. Recommended.',
  },
  {
    title: 'Competent',
    minScore: 5,
    maxScore: 6.9,
    info: 'Has conceptual understanding, can follow the guidelines and solve most tasks. Sees actions at least partly in terms of longer-term goals.',
  },
  {
    title: 'Advanced Beginner',
    minScore: 3,
    maxScore: 4.9,
    info: 'Has shown basic knowledge but is missing the bigger picture. Solving complex problems would need close supervision.',
  },
  {
    title: 'Novice',
    minScore: 1,
    maxScore: 2.9,
    info: "Candidate didn't satisfy this job's requirements. Do not pursue.",
  },
]

export const FEEDBACK_SECTIONS = [
  {
    name: 'feedback1',
    label: 'Overall',
    question:
      'How did the candidate perform in regards to his/her or their professional experience?',
    hint: "Please reflect on the overall impression of the candidate's progress in comparison to their professional experience on the market / profile",
    required: true,
  },
  {
    name: 'feedback2',
    label: 'Q&A Strengths',
    session: 'Q&A Session: ',
    question:
      'What are <b>the strengths</b> of the candidate you observed during the Q&A session?',
    hint: 'Please reflect here on the specific areas (among covered sub-dimensions) during the Q&A session',
    required: true,
  },
  {
    name: 'feedback3',
    label: 'Q&A Weaknesses',
    session: 'Q&A Session: ',
    question:
      'What are <b>the weaknesses</b> of the candidate you observed during the Q&A session?',
    hint: 'Please reflect here on the specific areas (among covered sub-dimensions) during the Q&A session',
    required: true,
  },
  {
    name: 'feedback4',
    label: 'Live Coding Strengths & Weaknesses',
    session: 'Live Coding Session (if applicable): ',
    question:
      'What are <b>the strengths and weaknesses</b> of the candidate you observed during the LC part?',
    hint: 'Please reflect here on the specific aspects of the Live Coding Session that are worth mentioning to reflect strong & weak points',
  },
  {
    name: 'feedback5',
    label: 'Additional Comments',
    question:
      'Do you have any additional comments about the tech assessment of the candidate?',
    hint: 'Please reflect on any additional aspects that are worth mentioning to the client/hiring manager of  this candidate, e.g. spoken language proficiency',
  },
]

export const LEVEL_DESCRIPTORS = [
  {
    id: '0',
    title: 'Expert',
    minScore: 9,
    maxScore: 10,
  },
  {
    id: '1',
    title: 'Proficient',
    minScore: 7,
    maxScore: 8.9,
  },
  {
    id: '2',
    title: 'Competent',
    minScore: 5,
    maxScore: 6.9,
  },
  {
    id: '3',
    title: 'Advanced Beginner',
    minScore: 3,
    maxScore: 4.9,
  },
  {
    id: '4',
    title: 'Novice',
    minScore: 1,
    maxScore: 2.9,
  },
]

export const NON_TECHNICAL_VERTICALS = [
  'Product Manager',
  'Product Owner',
  'Project Manager',
]

export const CASE_STUDY = 'Case Study'
