import { Button, Dialog, DialogTitle } from '@mui/material'
import styled from 'styled-components'

export const StyledContainedButton = styled(Button)`
  text-transform: none;
  background: #3c26de;
  &:hover {
    background: #5a47ea;
  }
`
export const StyledDialog = styled(Dialog)`
  padding: 15px;
  * {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }
`

export const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
`
