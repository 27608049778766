/* eslint-disable*/
import React, { memo } from 'react'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { placeStatusApproved } from 'apps/portal/common/place-status'
import { Divider } from '@mui/material'
import { buildImageURL } from 'common/image'
import Grid from '@mui/material/Grid'
import defaultImage from 'assets/img/Experience.svg'
import { humanizeDuration } from 'common/dates'
import Box from '@mui/material/Box'
import { ParagraphByWord } from 'components/ParagraphByWord'
import { useViewport } from 'common/view-port-hook'

const useStyles = makeStyles((theme) => {
  const experiencesWrapper = {
    backgroundColor: theme.palette.white,
    position: 'relative',
    borderRadius: theme.spacing(1),
    boxShadow: `${theme.spacing(0)} ${theme.spacing(0.786)} ${theme.spacing(
      0.786
    )} rgba(0, 0, 0, 0.1)`,
  }
  const img = {
    boxShadow: `${theme.spacing(0)} ${theme.spacing(0.5)} ${theme.spacing(
      0.5
    )} rgba(0, 0, 0, 0.25)`,
    borderRadius: theme.spacing(2),
    objectFit: 'contain',
    maxWidth: theme.spacing(10),
    maxheight: theme.spacing(10),
    minWidth: theme.spacing(10),
    minHeight: theme.spacing(10),
  }
  const title = {
    fontWeight: theme.typography.fontWeight.strong,
  }
  const duration = {
    color: theme.palette.lightGray2,
  }
  const timeSpan = {
    fontWeight: theme.typography.fontWeight.strong,
  }
  return {
    experienceLabel: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      experiencesWrapper: {
        ...experiencesWrapper,
        paddingBottom: theme.spacing(1),
        marginRight: theme.spacing(1.5),
        '& > h6': {
          color: theme.palette.lightGray,
        },
      },
      experience: {
        padding: theme.spacing(2, 2, 0),
        margin: theme.spacing(2, 0),
        position: 'relative',
        '& img': {
          ...img,
        },
        '& div:nth-child(2)': {
          paddingLeft: theme.spacing(2),
        },

        '& ul': {
          marginLeft: theme.spacing(-1),
        },

        '& span': {
          '&:after': {
            content: "'' !important",
          },
        },

        '& div': {
          position: 'relative',
          padding: 0,
          margin: 0,

          '& p': {
            marginLeft: theme.spacing(2),
          },
          '& .ParagraphComponent-displayableText-30': {
            '&:after': {
              content: "'' !important",
            },
          },
          '& a ': {
            position: 'absolute',
            bottom: theme.spacing(-3.5),
            left: theme.spacing(-1),
            textDecoration: 'none',
            paddingLeft: theme.spacing(2),
            '&:after': {
              content: '...',
            },
          },
        },
        '& hr': {
          marginLeft: '-20%',
          marginTop: theme.spacing(5),
        },
      },
      title: {
        ...title,
      },
      duration: {
        ...duration,
      },
      timeSpan: {
        ...timeSpan,
        marginLeft: theme.spacing(0.25),
      },
    },
    [theme.breakpoints.down('md')]: {
      dividerContainer: {
        '& hr': {
          marginTop: theme.spacing(2),
        },
      },
      experiencesWrapper: {
        ...experiencesWrapper,
        '& .MuiTypography-body2': {
          color: theme.palette.lightGray2,
          fontWeight: theme.typography.fontWeight.strong,
        },
      },
      experience: {
        padding: theme.spacing(1.625, 2, 1.25, 2),
        position: 'relative',
        '& .MuiTypography-subtitle1': {
          color: '#333333',
          fontWeight: theme.typography.fontWeight.normal,
        },
        '& img': {
          ...img,
        },
        '& span': {
          '&:after': {
            content: "'' !important",
          },
        },
        '& div': {
          position: 'relative',
          padding: 0,
          margin: 0,
          '& .ParagraphComponent-displayableText-30': {
            '&:after': {
              content: "'' !important",
            },
          },
          '& a ': {
            position: 'absolute',
            bottom: theme.spacing(-3.5),
            left: theme.spacing(-1),
            textDecoration: 'none',
            '&:after': {
              content: '...',
            },
          },
        },
      },
      title: {
        ...title,
        marginTop: theme.spacing(2),
      },
      duration: {
        ...duration,
      },
      timeSpan: {
        ...timeSpan,
      },
    },
  }
})
const addDefaultImage = (e) => {
  e.target.src = defaultImage
}

const Experience = memo(
  ({ experience, idx, classes, noOfExps, gEvent, slugId }) => {
    const { year, duration } = humanizeDuration(
      experience.startDate,
      experience.endDate
    )
    return (
      <Grid
        item
        container
        className={classes.experience}
        justifyContent='flex-end'
        xs={12}
        key={idx}
        id={`public-profile-experience-${idx}`}
      >
        <Grid
          container
          item
          xs={12}
          lg={1.7}
          md={1.7}
          alignItems='baseline'
          justifyContent='flex-start'
          id={`exp_${idx}`}
        >
          {experience.company.logo.bucket ? (
            <img
              src={buildImageURL(experience.company.logo)}
              alt={experience.company.name}
              onError={addDefaultImage}
            />
          ) : (
            <img src={`${defaultImage}`} alt={experience.company.name} />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          lg={10.3}
          md={10.3}
          className={classes.jobTitleContainer}
        >
          <Typography variant='h6' id='title' className={classes.title}>
            {experience.jobTitle}
          </Typography>
          <Typography variant='subtitle1' id='companyName'>
            {experience.company.name}
          </Typography>
          <Typography variant='subtitle1'>
            <Box
              fontWeight='fontWeightRegular'
              className={classes.duration}
              id='duration'
            >
              {year}
              {duration && (
                <span className={classes.timeSpan}> | {duration}</span>
              )}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12} md={10.3} lg={10.3} id='description'>
          <ParagraphByWord
            text={experience.description}
            limit={25}
            variant='body1'
            fontWeight='fontWeightRegular'
            component='div'
            renderAsMarkdown
            type={`experience - ${experience.jobTitle}`}
            gEvent={gEvent}
            slugId={slugId}
          />
        </Grid>
        <Grid item xs={12} md={10} lg={10} className={classes.dividerContainer}>
          {idx === noOfExps - 1 ? '' : <Divider />}
        </Grid>
      </Grid>
    )
  }
)

function Experiences(props) {
  const { experiences, translate, gEvent, slugId } = props
  const classes = useStyles()
  const activeExperiences =
    experiences &&
    experiences.filter((experience) =>
      placeStatusApproved(experience.company.status)
    )
  const noOfExps = (activeExperiences && activeExperiences.length) || 0
  const { isDeviceMobile } = useViewport()
  const ExperienceHeaderLabel = () =>
    isDeviceMobile ? (
      <ExperienceHeaderLabelForMobile />
    ) : (
      <ExperienceHeaderLabelForDesktop />
    )
  const ExperienceHeaderLabelForMobile = () => (
    <Typography variant='body2'>{translate('experience')}</Typography>
  )
  const ExperienceHeaderLabelForDesktop = () => (
    <Typography variant='h6'>{translate('experience')}</Typography>
  )

  return (
    <>
      {activeExperiences && activeExperiences.length > 0 && (
        <Grid
          item
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          xs={11}
          md={11.8}
          lg={11.8}
          className={classes.experiencesWrapper}
        >
          <Grid item xs={11} className={classes.experienceLabel}>
            <ExperienceHeaderLabel />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Divider />
          </Grid>
          {activeExperiences &&
            activeExperiences.map((experience, idx) => (
              <Experience
                experience={experience}
                idx={idx}
                classes={classes}
                noOfExps={noOfExps}
                key={`${experience.jobTitle}_${idx}`}
                gEvent={gEvent}
                slugId={slugId}
              />
            ))}
        </Grid>
      )}
    </>
  )
}

export default Experiences
