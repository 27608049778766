/* eslint-disable */
import React from 'react'
import FormControl from '@mui/material/FormControl'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  label: {
    marginTop: theme.spacing(-1),
  },
}))

const CheckboxBase = ({
  value,
  onChange,
  error,
  label,
  labelPlacement,
  disabled,
}) => {
  const classes = useStyles()
  return (
    <FormControl error={!!error}>
      <FormControlLabel
        control={
          <Checkbox
            checked={!!value}
            color='primary'
            onChange={(e) => onChange(e.target.checked)}
            disabled={disabled}
          />
        }
        label={label}
        classes={classes}
        labelPlacement={labelPlacement}
      />
    </FormControl>
  )
}

CheckboxBase.defaultProps = {
  labelPlacement: 'end',
}

export { CheckboxBase as Checkbox }
