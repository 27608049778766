import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        application: {
          account_addaccount_error: 'Unable to create a new account',
          account_authenticate_error: 'Unable to login',
          account_updateprofile_error: 'Unable to update profile',
          account_changepassword_error: 'Unable to change password',
          account_forgotpassword_error:
            'Unable to send password reset instructions',
          account_resetpassword_error: 'Unable to reset password',
          account_querymanagers_error: 'Unable to fetch managers',
          account_queryowners_error: 'Unable to fetch managers',
          account_getuser_error: 'Unable to fetch user record',
          account_getuserprofile_error: 'Unable to fetch own user record',
          category_getallcategories_error: 'Unable to fetch skills',
          category_querycategories_error: 'Unable to fetch skills',
          invitation_getinvitation_error: 'Unable to fetch invitation',
          invitation_sendinvitation_error: 'Unable to send invitation',
          invitation_getlatestinvitation_error: 'No invitation found',
          profile_getuploadurl_error: 'Unable to fetch upload URL',
          profile_uploadfile_error: 'Unable to upload file',
          profile_getprofilebyid_error: 'Unable to fetch profile',
          profile_getprofile_error: 'Unable to fetch profile',
          profile_getpublicprofile_error: 'Unable to fetch public profile',
          profile_updateprofile_error: 'Unable to update profile',
          profile_getfocusrolelist_error: 'Unable to fetch focus roles',
          profile_queryprofiles_error: 'Unable to fetch profiles',
          profile_getprofilechangelog_error: 'Unable to fetch changelogs',
          profile_deleteprofile_error: 'Unable to delete profile',
          profile_createprofile_error: 'Unable to create profile',
          profile_updateconnectprofile_error: 'Unable to update profile',
          profile_getindustrytags_error: 'Unable to fetch industries',
          profile_gettravelpreferences_error:
            'Unable to fetch travel preferences',
          profile_contactexpertlead_error:
            'Unable to send profile details request',
          referral_referproject_error: 'Unable to send project referral',
          referral_referfreelancer_error: 'Unable to send freelancer referral',
        },
        registration: {
          registration_form_sub_title:
            'Your creativity is needed! Please come up with a strong password.',
          registration_form_title: 'Register',
          registration_title: 'Welcome',
          registration_sub_title:
            'Great to see you again! You have chosen an excellent moment to join us ' +
            'as we just started building our Portal and look forward to incorporating your feedback. ' +
            'Just turn to your freelancer manager whenever you have feedback!',
          'provided data is duplicate':
            'The credentials are already on our system.',
          users: {
            'token-expired':
              'Token expired. You need to request another token.',
          },
        },
        registration_form: {
          name: 'Name',
          email: 'Email Address',
          password: 'Password',
          password_confirmation: 'Repeat Password',
          password_need_to_match:
            'Password confirmation needs to match the password',
          submit_button: 'Create Account',
          password_tooltip: 'Password must be at least 6 characters long',
        },
        account_form: {
          tax_location: 'Tax Location',
          tax_location_required: 'Tax Location is required',
          tax_id: 'Tax ID',
          tax_id_required: 'Tax ID is required',
          hourly_rate: 'Hourly Rate',
          hourly_rate_helper:
            'Please get in touch with your Freelancer Manager to update us of your current hourly rate',
          email: 'E-mail',
          email_required: 'E-mail is invalid',
          mobile: 'Mobile phone',
          mobile_is_invalid: 'Mobile phone number is invalid',
          website: 'Personal website',
          website_invalid: 'Personal Website is invalid',
          toggle_password_visibility: 'toggle password visibility',
          password_security_title: 'Password security',
          change_password: 'CHANGE PASSWORD',
          password_security:
            'To keep your password well protected, please make sure your new password ' +
            'includes numbers, symbols as well as capital letters.',
          password_change_sub_title:
            'Please, enter your old and new passwords. ' +
            "We are sure you've created a good one!",
          password_change_title: 'Change Password',
          password_need_to_match:
            'Password confirmation needs to match the password',
          current_password: 'Current password',
          new_password: 'New password',
          new_password_confirmation: 'Repeat new password',
          submit_button: 'SAVE',
          cancel_button: 'CANCEL',
          current_password_validation: 'Current password should be provided',
          new_password_validation: 'New password should be provided',
          new_password_validation_char:
            'Password must contain at least 6 character',
          new_password_confirmation_validation:
            'Password confirmation needs to match the new password',
          new_password_confirmation_validation_provide:
            'Password confirmation should be provided',
          'change-password[valid]':
            'Your password has been changed successfully.',
          'change-password[users|password-invalid]':
            "There was an error: your current password doesn't match.",
          change_email_info_text:
            'Please get in touch with your Freelancer Manager if you need to update your email address',
        },
        login: {
          email: 'Email Address',
          email_helper: 'Need to be a valid and an active email address',
          password: 'Password',
          password_helper: "At least 8 characters, make sure it's safe",
          login_sub_title: 'Hey, nice to see you again!',
          forgot_password_valid:
            'The link to your password recovery page has been successfully sent to you by email.',
          forgot_password_error:
            'Invalid email. Please use the same login that was originally used in portal registration.',
          'set-new-password[valid]': 'Password reset was successful!',
          'set-new-password[password-recovery|token-invalid]':
            'There was an error: token is invalid.',
          emailUpdatedSuccess:
            'Successfully updated the email address. Please use new email for future login',
          emailUpdatedFail:
            'Failed to update email address! Please try again later',
        },
        profile_view: {
          profile_my_cv: 'My CV',
          profile_about_me_section_title: 'About Me',
          profile_skills_section_title: 'Skills',
          profile_experience_section_title: 'Experience',
          profile_education_section_title: 'Education',
          profile_education_empty_educations_message: 'You have no educations',
          profile_edit_button: 'Edit',
          profile_view_button: 'View',
          profile_certification_section_title: 'Licenses & Certifications',
        },
        profileEdit: {
          firstName_helper: 'First name',
          lastName_helper: 'Last name',
          bio_helper: 'Biography',
          title_helper: 'Title',
          location_helper: 'Location',
          language_name_helper: 'Language',
          language_proficiency_helper: 'Proficiency',
          profile_location_error: 'Need to select a location',
          profile_language_error: 'Need to select language',
          profile_firstname_error: 'Need to select a first name',
          profile_lastname_error: 'Need to select a last name',
          profile_title_error: 'Headline is required',
          profile_bio_error: 'Bio is required',
          profile_proficiency_error: 'Need to select proficiency',
          profile_invalid_language: 'Invalid language',
          profile_invalid_proficiency: 'Invalid proficiency',
          submit_helper: 'submit',
          cancel_helper: 'cancel',
          vatError:
            'Please check for invalid Tax ID under My Account >> Payments section',
        },
        profile_view_skills: {
          top_skills: 'Top skills',
          no_top_skills: 'No top skills were retrieved',
          other_skills: 'Other skills',
          no_other_skills: 'No skills were retrieved',
        },
        profileEditSkills: {
          skills: 'Skills',
          topSkills: 'Top skills',
          topSkillsLabel: 'Top {{limit}} skills',
          otherSkillsLabel: 'Other skills',
          topSkillNumber: 'Top skill {{index}}',
          upToSkills:
            'Add up to {{number}} other skills and use drag and drop to rearrange if required',
          from: 'From (mm/dd/yyy)',
          startedAt: 'Started At',
          no_skills: 'You have no skills! Haha!',
          dateRequiredLabel: 'Date is required',
          dateFutureErrorLabel: 'Date cannot be later than today',
          dateInvalidLabel: 'Invalid date',
          skillRequiredLabel: 'You need to select a skill',
          profile_edit_skills_duplicate: 'Skills cannot be duplicated',
          skillsLimit: 'You can only add a total of {{number}} skills',
          vatError:
            'Please check for invalid Tax ID under My Account >> Payments section',
        },
        profileEditEducation: {
          profile_education_school_label: 'School',
          profile_education_invalid_school_error_message:
            'You must choose a school',

          profile_education_location_label: 'Location',
          profile_education_invalid_location_error_message:
            'You must choose a location',

          profile_education_degree_label: 'Degree',
          profile_education_invalid_degree_error_message:
            'You must specify a degree',

          profile_education_start_date_label: 'From (dd/mm/yyyy)',
          profile_education_invalid_start_date_label_error_message:
            'Invalid date input',
          profile_education_start_date_after_end_date_error_message:
            'From date should not be later than to date',

          profile_education_end_date_label: 'To (dd/mm/yyyy)',
          profile_education_invalid_end_date_label_error_message:
            'Invalid date input',
          profile_education_end_date_before_start_date_error_message:
            'To date should not be before from date',

          profile_education_description_label: 'Description',
          profile_education_invalid_description_error_message:
            'The description must be no more than 500 characters',
          profile_education_choose_date_text: 'Please choose a date',
          vatError:
            'Please check for invalid Tax ID under My Account >> Payments section',
          add_new_school: 'Add new school',
        },
        profileExperience: {
          profile_current_position: 'Current',
          place_under_review: 'Under Review',
          place_rejected: 'rejected',
        },
        profileEditCertification: {
          authority_label: 'Issuing Authority',
          invalid_authority_error_message: 'You must choose a authority',
          certification_label: 'Licenses & Certifications',
          invalid_certification_error_message:
            'You must specify a certification',

          invalid_issue_date_label_error_message: 'Invalid date input',
          issue_date_after_exp_date_error_message:
            'From date should not be later than to date',

          invalid_exp_date_label_error_message: 'Invalid date input',
          exp_date_before_issue_date_error_message:
            'Expiration date should not be before issue date',
          issue_date_label: 'Issue Date',
          exp_date_label: 'Expiration Date',
          choose_date_text: 'Please choose a date',
          add_new_authority: 'Add new authority',
          exp_helper_text:
            'Leave the "Expiration Date" field empty if the License or Certification does not expire',
        },
        profileEducation: {
          profile_current_position: 'Current',
          place_under_review: 'Under Review',
          place_rejected: 'rejected',
        },
        profileCertification: {
          profile_current_position: 'Current',
          place_under_review: 'Under Review',
          place_rejected: 'rejected',
          issuedOn: 'Issued on',
          validTill: 'Valid until',
          noExpirationDate: 'No expiration date',
        },
        profileEditExperience: {
          profile_experience_empty:
            'Please save your profile with at least 1 complete work experience in the <strong>My CV / Experience</strong> section of your profile',
          profile_experience_company_label: 'Company',
          profile_experience_invalid_company_error_message:
            'You must choose a company',

          profile_experience_location_label: 'Location',
          profile_experience_invalid_location_error_message:
            'You must choose a location',

          profile_experience_job_title_label: 'Job Title',
          profile_experience_invalid_job_title_error_message:
            'You must specify a job title',

          profile_experience_start_date_label: 'From (dd/mm/yyyy)',
          profile_experience_invalid_start_date_label_error_message:
            'Invalid date input',
          profile_experience_start_date_after_end_date_error_message:
            'From date should not be later than to date',

          profile_experience_end_date_label: 'To (dd/mm/yyyy)',
          profile_experience_invalid_end_date_label_error_message:
            'Invalid date input',
          profile_experience_end_date_before_start_date_error_message:
            'To date should not be before from date',
          profile_experience_responsibility_label: 'Responsibility',
          profile_experience_invalid_description_error_message:
            'The description must be more than 0 and no more than 500 characters',
          profile_experience_choose_date_text: 'Please choose a date',
          vatError:
            'Please check for invalid Tax ID under My Account >> Payments section',
          add_new_company: 'Add new company',
        },
        login_form: {
          email: 'E-mail',
          password: 'Password',
          submit_button: 'Let me in',
          forgot_password: 'Forgot password?',
          rememberMe: 'Remember Me',
          login_title: 'Login',
          login_sub_title: 'Hey, nice to see you again!',
          invalid_credentials: 'Invalid username or password',
        },
        forgot_password: {
          title: 'Password recovery',
          sub_title:
            'Please, enter your email below and we will send you a link to the password recovery page in a minute.',
          email: 'E-mail',
          cancel: 'CANCEL',
          send: 'SEND',
        },
        reset_password: {
          title: 'Create new password',
          sub_title:
            'Please, enter your new password and make sure you will not forget this one!;)',
          new_password: 'New password',
          new_password_confirmation: 'Repeat new password',
          new_password_validation: 'New password should be provided',
          new_password_validation_char:
            'Password must contain at least 6 character',
          new_password_confirmation_validation:
            'Password confirmation needs to match the new password',
          new_password_confirmation_validation_provide:
            'Password confirmation should be provided',
          submit_button: 'SAVE',
          password_tooltip: 'Password must be at least 6 characters long',
        },
        referral: {
          firstName: 'First Name',
          lastName: 'Last Name',
          email: 'E-mail',
          submit: 'Send invite',
          dialogBox_title: 'Request a call',
          dialogBox_description:
            'We will get back to you within one hour after you submit your request online, from Monday to Friday from 08 AM - 10 PM (CET)',
          dialogBox_declineBtn: 'Decline',
          dialogBox_acceptBtn: 'Request a callback',
          form_title: 'REFER A FREELANCER',
          form_subtitle3: 'First project starting & 1000€ for you!',
          form_subtitle:
            'You have tech freelancers in your network that might be interested in joining expertlead?',
          form_subtitle2:
            'Refer them to us and earn on every successful referral! First project starting & 1000€ for you!. For more details on  rewards and processes, please click',
          here: 'here',
          project: 'Refer a project',
          project_subtitle:
            'You heard about a project, but cannot deliver it yourself?',
          project_subtitle2:
            'Refer it to us and earn up to 5.000€. For more details on the reward and process, please click',
          request_callback: 'Request a callback',
          pic_title: "Let's build the freelance community together",
          referral_code: 'Your referral code is: ',
          missing_referral_code: '--',
          'referral[valid]':
            'Thanks for referring one of your peers to join our community, we have sent an email to {{firstName}} {{lastName}}.',
          'referral[referralcode-invalid]':
            'There was an error: your referral code is invalid.',
          'callback[valid]':
            'Thanks for requesting a callback - we will be in touch shortly.',
          'callback[referralcode-invalid]':
            'There was an error: your referral code is invalid.',
        },
        dashboard: {
          welcome_heading: 'Meet your personal manager!',
          welcome_text:
            'We have a personal manager for you! They are here to answer your questions and support you at every step of your freelancing journey. Do not hesitate to get in touch!',
          form_welcome_text: 'Hey, are you open for new challenges?',
          form_availability_label: 'Are you open for new projects?',
          form_availability_fulltime_label: 'Full-Time',
          form_availability_parttime_label: 'Part-Time',
          form_availability_unavailable_label: 'Unavailable',
          form_availability_form_label: 'Availability',
          work_preferences_label: 'Work Preferences',
          work_setups__label: 'Work Setups',
          available_for_travel: 'Available for travelling',
          form_accessibility_label: 'Accessibility',
          form_location_label: 'Location',
          formLocationRequired: 'Location is required',
          form_location_onsite_label: 'Willing to travel',
          form_location_remote_label: 'Remote only',
          form_location_city_label: 'City',
          form_careerinterests_label: 'Career Interests',
          form_careerinterests_jobtitle_label: 'Job Title',
          form_button_save_label: 'Save',
          form_button_cancel_label: 'Cancel',
          dateRequiredLabel: 'Date is required',
          no_data: 'No data to display',
          form_availability_hours_label: 'Hours per week:',
          form_availability_open_from: 'Will be open from',
          form_availability_open_until: 'Will be open until',
          form_availability_tooltip_yes:
            'Please input the best estimated date until which you will be available. In case of open availability, leave it blank',
          form_availability_tooltip_no:
            'Please input the best estimated date of your next availability. If you are unsure of a specific date, leave it blank',
          form_availability_date_invalid: 'Invalid date input',
          dialogBox_Tax_Id_title: 'Warning!',
          dialogBox_Tax_Id_description:
            'Please proceed to the "Payments" page and enter the correct information in the "Tax Location" and "Tax ID" fields before updating any other section in the profile. Without updating these fields we cannot save your progress during the completion of the profile.',
          dialogBox_Tax_Id_acceptBtn: 'Payments page ',
          dialogBox_Tax_Id_declineBtn: 'Close ',
          dialogBox_Tax_Id_note:
            'Note: If the Tax ID information is unavailable, please leave the field blank',
          vatError:
            'Please check for invalid Tax ID under My Account >> Payments section',
        },
        services: {
          title: 'Services & Benefits',
          description:
            'At Expertlead we thrive on supporting you throughout your career as a freelancer. Beyond finding exciting projects that suit your skills and interests, we want to provide you with an ecosystem of services & benefits that facilitate your career. We are constantly looking for relevant services for you. If you have any suggestions you would want us to look into, please let us know by getting in touch with our team at <a href="mailto:community@expertlead.de">community@expertlead.de</a>',
          find_more: 'Find more',
          select_all: 'All categories',
        },
        events: {
          title: 'Past Events',
          view: 'View Event',
          filters: 'Filters',
          vod_only_label: 'Recorded Webinars Only',
        },
        formOnWarn: {
          message_confirmation:
            'You have unsaved changes, are you sure you want to leave?',
        },
        navbar: {
          home: 'Home',
          about_us: 'About us',
          why_expertlead: 'Why expertlead',
          profile: 'My CV',
          referrals: 'Referrals',
          services: 'Benefits',
          faq: 'FAQ',
          interviewer: 'Interviewer',
          account: 'My Account',
          logout: 'Logout',
          phone: '+49 174 335 8205',
          preferences: 'Preferences',
          payment: 'Payment',
          communication: 'Communication',
          events: 'Events',
          invalidTaxId:
            'This is not a valid tax ID for the selected tax location',
          invalidTaxIdWarning:
            'Please check for invalid Tax ID on Payment page',
        },
        loading: {
          loading_subtitle:
            'Please wait a sec, we are setting things up for you!',
        },
        misc: {
          read_more: 'Read more.',
          read_less: 'Read less',
        },
        about_me: {
          locations: 'Locations',
          languages: 'Languages',
          biography: 'Biography',
        },
        experiences: {
          not_found: 'No experiences to show so far.',
        },
        faq: {
          faq: 'Frequently Asked Questions',
          faq_personal: 'Personal and Profile Information',
          faq_project: 'Project Related Information',
          faq_community: 'Community Efforts',
        },
        coming_soon: {
          label: 'Coming soon...',
        },
        publicProfile: {
          about: 'About',
          skills: 'Skills',
          experience: 'Experience',
          education: 'Education',
          allRightsReserved: 'All rights reserved',
          privacyPolicy: 'Privacy Policy',
          imprint: 'Imprint',
          currentPosition: 'Current',
          URLCopy: 'URL has been copied to your clipboard',
          place_under_review: 'Under Review',
          freelancer: 'Freelancer',
          fullTime: 'Permanent Employee',
          contact_expertlead: 'contact expertlead',
          contact_success:
            'Request for this profile has been sent to expertlead',
          contact_fail: 'Failed to send request to expertlead',
          certifications: 'Certifications',
          certificationHeading: 'Licenses & Certifications',
          issuedOn: 'Issued on',
          validTill: 'Valid until',
          noExpirationDate: 'No expiration date',
          p2pInterviewer: 'P2P Interviewer',
          projectsCompleted: 'Projects with EL',
          projectCompleted: 'Project with EL',
          p2pInterviewerTooltip:
            'Acts as a technical interviewer for new candidates',
          projectsCompletedTooltip:
            'Number of projects completed with Expertlead.',
          achievement: 'Achievement',
          testedLiveCodingTooltip:
            'Successfully passed a live coding technical assignment',
          testedLiveCoding: 'Tested Live Coding',
          testedQATooltip: 'Successfully passed a technical skills interview',
          testedQA: 'Tested Q&A',
          completedCaseStudyTooltip:
            'Successfully completed a technical case study',
          completedCaseStudy: 'Completed Case Study',
          eventsHostTooltip: 'Hosted one or more Expertlead events',
          eventsHost: 'Webinar Host',
          teaserForNewPublicProfilePartOne: 'Stay tuned for the',
          teaserForNewPublicProfilePartTwo:
            'new improved look of our candidate profiles!',

          contactUs: 'Contact us',
          copyToClipboard: 'Copy email address',
          copyToClipboardMessage:
            'The email address has been copied to your clipboard',
          copyToClipboardErrorMessage:
            'The email address could not be copied to your clipboard',
          buttonLabelForExpertlead: 'Send email to expertlead',
          buttonLabelForFuturePath: 'Send email to Futurepath',
          skillsMatch: 'Assessment Summary',
          skillToolTipHelpText: `This chart is a visualization of the candidate’s in depth Expertlead technical assessment results.
The colour coding denotes the assessed technical sub-topic, listed in the key below. Each line represents one unit of the score between 1-10.`,
        },
        edit_avatar: {
          title: 'Select the part of your photo that you like best:',
          cancel: 'Cancel',
          confirm: 'Use selected area',
          invalid_dimensions:
            'Invalid image dimensions. The minimum size required is {{dimensions}} pixels',
        },
        jobPage: {
          jobHeading:
            'Please review the job details and let us know if you are interested.',
          requiredTechSkillsLabel: 'Required Technologies',
          goodToHaveTechSkillsLabel: 'Good to know',
          aboutTheClientLabel: 'About the client',
          jobDescriptionLabel: 'Job Description',
          industryLabel: 'Industry',
          workType: 'Contract Type',
          availableFrom: 'Start Date / Duration',
          preferredWorkSetup: 'Work Setup',
          Traveling: 'Traveling',
          companyType: 'Company type/size',
          offering: 'Company Culture',
          languagesRequired: 'Languages Required',
          rate: 'Rate/Salary',
          projectDomainLabel: 'Project Domain / Topic (optional)',
          moreInfo: 'For more check {{address}}',
          firstName: 'First Name',
          lastName: 'Last Name',
          theirEmail: 'Their Email',
          whatMakesFit: 'What makes your friend a good fit for this role?',
          socialMediaLabel: 'Social Media Links',
          optional: 'Optional',
          addLink: 'Social Media Link',
          cancel: 'Cancel',
          refer: 'Refer',
          scheduleCall: 'Schedule a Call',
          callNow: 'Call me Right Away',
          interestedTitle: 'Thank You for your Interest!',
          interestedDescription:
            'Thank you for expressing your interest, We are thrilled to hear that you are considering our company for your needs.',
          interestedSecondTitle: 'We Will Be Calling You',
          interestedSecondDescription:
            'Thank you for your patience, and we will be in touch with you shortly.',
          notInterestedTitle: 'Please let us know why you’re not interested',
          notInterestedDescription:
            'Thank you for expressing your interest, We are thrilled to hear that you are considering our company for your needs.',
          selectReason: 'Select reason',
          otherReason: 'Other reason',
          send: 'Send',
          availableAgain: 'Available Again On',
          notInterestedSecondTitle: 'We Value your Feedback',
          notInterestedSecondDescription:
            'Thanks for letting us know the reason why you’re not interested',
          referAFriendTitle: 'Refer a friend for this position',
          referAFriendSecondTitle: 'Thanks for Referring Talent',
          referAFriendSecondDescription:
            "We'll reach out to a referred contact and keep you posted about the progress",
          failedMessage: 'Something went wrong, please try again!',
        },
      },
    },
  })

i18n.changeLanguage('en')

export default i18n
