import withStyles from '@mui/styles/withStyles'
import flow from 'lodash/flow'
import PropTypes from 'prop-types'
import React from 'react'

import { RichTextEditorField } from 'components/v2'

const styles = (theme) => ({
  form: {
    width: '100%',
  },
  actions: {
    paddingTop: theme.spacing(2),
  },
})

const FIELD_NAME = 'description'

const ResponsibilitiesBase = ({
  responsibilities,
  onChange,
  setIsResetButtonClicked,
  isResetButtonClicked,
  ...props
}) => {
  const dataAutomator = props['data-automator'] || 'rte-responsibilities'
  const handleResponsibilities = (data) => {
    onChange(FIELD_NAME, data)
  }

  return (
    <RichTextEditorField
      value={responsibilities}
      onChange={handleResponsibilities}
      label='Description'
      error
      setIsResetButtonClicked={setIsResetButtonClicked}
      isResetButtonClicked={isResetButtonClicked}
      data-automator={dataAutomator}
    />
  )
}

ResponsibilitiesBase.propTypes = {
  responsibilities: PropTypes.string,
  onChange: PropTypes.func,
}

ResponsibilitiesBase.defaultProps = {
  responsibilities: '',
}

const Responsibilities = flow(withStyles(styles))(ResponsibilitiesBase)

export { Responsibilities }
