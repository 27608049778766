import isEmpty from 'lodash/isEmpty'

import { IMAGE_HOST } from './constants'

const buildImageURL = (photo) => {
  if (photo && photo.bucket && (photo.id || photo.name))
    return `${IMAGE_HOST}/${photo.bucket}/${photo.id || photo.name}`
  return !photo || isEmpty(photo) ? null : photo
}

export { buildImageURL }
