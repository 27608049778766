import { yupResolver } from '@hookform/resolvers/yup'
import { get } from 'lodash'
import React, { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'

const useFormBuilder = (config) => {
  const { initialValues, schema } = config.fields.reduce(
    (stack, { name, value, validation }) => ({
      initialValues: { ...stack.initialValues, [name]: value },
      schema: { ...stack.schema, [name]: validation },
    }),
    { initialValues: {}, schema: {} }
  )

  const validationSchema = Yup.object().shape(schema)

  const form = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onSubmit',
  })

  const {
    control,
    reset,
    handleSubmit,
    getValues,
    watch,
    setValue,
    formState: { isSubmitting, isSubmitSuccessful, isDirty: dirty },
    errors,
    clearErrors,
  } = form

  const fields = config.fields.reduce(
    (stack, { props, name, component: Component, onBlur }) => {
      const errorTmp = get(errors, `[${name}]`)
      let error

      if (Array.isArray(errorTmp)) {
        error = errorTmp
      } else {
        error = get(errorTmp, 'message')

        if (!error) {
          error = get(errorTmp, `id.message`)
        }
      }

      const field = (
        <Controller
          control={control}
          name={name}
          render={({ onChange }) => (
            <Component
              value={(() => {
                if (dirty) {
                  return getValues(name)
                }

                return initialValues[name]
              })()}
              onChange={onChange}
              onBlur={onBlur}
              disabled={isSubmitting}
              error={error}
              clearErrors={clearErrors}
              name={name}
              {...props}
            />
          )}
        />
      )

      return {
        ...stack,
        [name]: field,
      }
    },
    {}
  )
  const resetForm = useCallback(
    () => reset(initialValues),
    [initialValues, reset]
  )

  const submitHandler = handleSubmit(config.onSubmit, config.onError)

  return {
    fields,
    resetForm,
    dirty,
    handleSubmit: submitHandler,
    isSubmitting,
    isSubmitSuccessful,
    errors,
    values: getValues(),
    getValues,
    watch,
    setValue,
    clearErrors,
  }
}

export { useFormBuilder }
