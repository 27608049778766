import { Factory } from '../client/Factory'

const InterviewServiceNamespace = 'interview'

const InterviewServiceConfigs = {
  getCompletedInterviews: Factory.createRequestConfig(
    Factory.MethodGet,
    '/interviews'
  ),
  getInterviewers: Factory.createRequestConfig(
    Factory.MethodGet,
    '/interviewers'
  ),
  getCandidates: Factory.createRequestConfig(Factory.MethodGet, '/candidates'),
  getPublicInterviewReport: Factory.createRequestConfig(
    Factory.MethodPost,
    '/interviews/:interviewId/report-aggregated/v3'
  ),
}

const InterviewService = {
  getCompletedInterviews: Factory.makeAuthenticatedClientCall(
    InterviewServiceConfigs.getCompletedInterviews
  ),
  getInterviewers: Factory.makeAuthenticatedClientCall(
    InterviewServiceConfigs.getInterviewers
  ),
  getCandidates: Factory.makeAuthenticatedClientCall(
    InterviewServiceConfigs.getCandidates
  ),
  getPublicInterviewReport: Factory.makeClientCall(
    InterviewServiceConfigs.getPublicInterviewReport
  ),
}

export { InterviewServiceNamespace, InterviewServiceConfigs, InterviewService }
