import withStyles from '@mui/styles/withStyles'
import flow from 'lodash/flow'
import { shape, string } from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'

import Scorecard from 'apps/scorecard'
import { Layout, Loading } from 'components'

import { PAGES } from '../../common/constants'
import { AuthenticatedNavBar } from '../../components'

import { useInterviewer } from './hooks'
import styles from './style'

const InterviewerComponent = ({ classes, ...props }) => {
  const { loading, routes } = useInterviewer(props)

  return (
    <Loading loading={loading}>
      <Layout
        navbarComponent={AuthenticatedNavBar}
        currentPage={PAGES.INTERVIEWER}
        className={classes.container}
      >
        <Scorecard routes={routes} />
      </Layout>
    </Loading>
  )
}

InterviewerComponent.propTypes = {
  classes: shape({
    container: string.isRequired,
  }).isRequired,
}

InterviewerComponent.defaultProps = {}

export const Interviewer = flow(
  withRouter,
  withStyles(styles)
)(InterviewerComponent)
