import { string } from 'prop-types'
import React, { Fragment, forwardRef, useCallback } from 'react'
import Crop from 'react-image-crop'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'

import { useModal } from 'apps/scorecard/hooks'
import { Button } from 'apps/scorecard/components/widgets'
import { Highlighted } from 'apps/scorecard/components/widgets/buttons'

import { useUpload } from './hooks'
import Controls from './controls'
import Error from './error'
import withStyle from './style'

export const Upload = forwardRef(({ className, ...props }, input) => {
  const {
    components: { Container: Modal, Title, Content, Actions },
    ...modal
  } = useModal()
  const {
    canvas,
    close,
    crop,
    error,
    height,
    onCanvasLoad,
    onCropChange,
    onCropComplete,
    onInputChange,
    pick,
    source,
    upload,
    uploading,
    width,
  } = useUpload({ modal, ...props })
  const renderComponentContent = useCallback(
    (controls) => (
      <>
        <Controls {...controls} />
        <TransformComponent>
          <img
            alt='Select the part you like best'
            className='ReactCrop__image'
            onLoad={onCanvasLoad}
            ref={canvas}
            src={source}
          />
        </TransformComponent>
      </>
    ),
    [canvas, onCanvasLoad, source]
  )

  return (
    <>
      <div className={className}>
        <input
          accept='image/*'
          type='file'
          onChange={onInputChange}
          ref={input}
        />
      </div>
      <Modal>
        <Title>Select the part of your photo that you like best:</Title>
        <Content>
          {error ? (
            <Error retry={pick} {...error} />
          ) : (
            <Crop
              renderComponent={
                <TransformWrapper>{renderComponentContent}</TransformWrapper>
              }
              crop={crop}
              minHeight={height}
              minWidth={width}
              onChange={onCropChange}
              onComplete={onCropComplete}
              src={source}
              ruleOfThirds
            />
          )}
        </Content>
        <Actions>
          <Button onClick={close}>Cancel</Button>
          <Button disabled={uploading.loading} onClick={pick}>
            Browse files
          </Button>
          {!error && (
            <Highlighted disabled={uploading.loading} onClick={upload}>
              {uploading.loading ? 'Saving' : 'Use selected area'}
            </Highlighted>
          )}
        </Actions>
      </Modal>
    </>
  )
})

Upload.propTypes = {
  className: string.isRequired,
}

Upload.defaultProps = {}

export default withStyle(Upload)
