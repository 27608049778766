import { DIFFICULTY, NON_TECHNICAL_VERTICALS } from '../constants'

export const getScoreColor = (score) => {
  if (!score) {
    return '#e6e8ef'
  }

  if (score >= 1 && score < 3) {
    return '#E76262'
  }

  if (score >= 3 && score < 5) {
    return '#FFC702'
  }

  if (score >= 5 && score < 7) {
    return '#FDA225'
  }

  if (score >= 7 && score < 9) {
    return '#418AFC'
  }

  if (score >= 9) {
    return '#3ECE80'
  }
}

export const getDifficultyColor = (difficulty) => {
  if (difficulty === DIFFICULTY.hard) {
    return '#f21412'
  }

  if (difficulty === DIFFICULTY.medium) {
    return '#ffb800'
  }

  if (difficulty === DIFFICULTY.easy) {
    return '#52bc77'
  }

  return 'eee'
}

export const getDifficultyLabel = (difficulty) => {
  if (difficulty === DIFFICULTY.hard) {
    return 'Hard'
  }

  if (difficulty === DIFFICULTY.medium) {
    return 'Medium'
  }

  if (difficulty === DIFFICULTY.easy) {
    return 'Easy'
  }

  return 'n/a'
}

export const isFirefox = () => !!navigator.userAgent.match(/firefox/i)

export const getIsNonTechnical = (verticalName) =>
  NON_TECHNICAL_VERTICALS.includes(verticalName)
