import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import withStyles from '@mui/styles/withStyles'
import update from 'immutability-helper'
import flow from 'lodash/flow'
import get from 'lodash/get'
import React, { Fragment, useRef, useEffect } from 'react'

import { TIME_TO_SCROLL_MS } from 'common/constants'
import { generateKey } from 'common/key-generator'

import { Certification } from './Certification'

const styles = () => ({
  add: {
    textAlign: 'center',
  },
})

const CertificationsBase = ({
  classes,
  value: certifications = [],
  onChange,
  error,
  placeAutosuggest,
  licensesCertificationTextField,
  datePicker,
  label,
  clearErrors,
  name,
}) => {
  const updatedCertificates = useRef(null)

  useEffect(() => {
    updatedCertificates.current = certifications
  }, [certifications])

  const editCertification = (setter, index) => (field, value) => {
    const editedCertifications = update(updatedCertificates.current, {
      [index]: { $merge: { [field]: value } },
    })

    setter(editedCertifications)
  }

  const addCertification = (setter, certifications) => (e) => {
    setter([
      ...certifications,
      {
        id: generateKey(),
        authority: {},
        name: '',
        issueDate: '',
        expiryDate: null,
        createdAt: new Date().getTime(),
      },
    ])

    setTimeout(
      () =>
        typeof window !== 'undefined' &&
        window.scrollTo(0, document.body.clientHeight),
      TIME_TO_SCROLL_MS
    )
  }

  const removeCertification =
    (setter, certifications, index) => (certification) => (c) => {
      const certificationsArray = [...certifications]
      certificationsArray.splice(index, 1)
      clearErrors(name)
      setter(certificationsArray)
    }

  return (
    <Grid
      container
      direction='column'
      justifyContent='flex-start'
      alignItems='stretch'
    >
      {certifications.map((certification, index) => (
        <Fragment key={certification.id || index}>
          <Grid item>
            <Certification
              value={certification}
              onChange={editCertification(onChange, index)}
              index={index}
              label={label}
              outerIndex={index}
              error={get(error, `${index}`, {})}
              removeCertification={removeCertification(
                onChange,
                certifications,
                index
              )}
              placeAutosuggestProps={placeAutosuggest}
              licensesCertificationTextFieldProps={
                licensesCertificationTextField
              }
              datePickerProps={datePicker}
            />
          </Grid>
        </Fragment>
      ))}
      <Box component='div' className={classes.add}>
        <IconButton
          onClick={addCertification(onChange, certifications)}
          id='add-certification'
          size='large'
        >
          <AddIcon />
        </IconButton>
      </Box>
    </Grid>
  )
}

const Certifications = flow(withStyles(styles))(CertificationsBase)

export { Certifications }
