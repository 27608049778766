import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import StopCircleIcon from '@mui/icons-material/StopCircle'
import { Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'

import { ONE_SEC } from 'apps/scorecard-v2/constants'
import { useTracking } from 'apps/scorecard/hooks'

import { StyledIconButton, TimerWrapper } from './style'

const LiveCodingTime = () => {
  const [time, setTime] = useState(0)
  const [isStarted, setIsStarted] = useState(false)
  const startTimeRef = useRef(null)
  const previousTimeRef = useRef(0)

  useEffect(() => {
    if (isStarted) {
      startTimeRef.current = Date.now() - previousTimeRef.current
      const interval = setInterval(() => {
        const currentTime = Date.now()
        setTime(currentTime - startTimeRef.current)
        previousTimeRef.current = currentTime - startTimeRef.current
      }, ONE_SEC)
      return () => clearInterval(interval)
    }
  }, [isStarted])

  const tracking = useTracking()
  const eventTracking = (label, action = 'Click') => {
    tracking.event({
      category: 'Scorecard-v2',
      action,
      label: `${action}_${label}`,
    })
  }

  const onToggleStart = () => {
    setIsStarted(!isStarted)
    eventTracking('Live_coding_time')
  }

  const secondsElapsed = time / ONE_SEC
  const seconds = Math.floor(secondsElapsed % 60)
  const minutes = Math.floor((secondsElapsed / 60) % 60)
  const hours = Math.floor((secondsElapsed / (60 * 60)) % 24)

  const formatTime = (time) => (time < 10 ? `0${time}` : time)

  return (
    <TimerWrapper>
      <Typography>Live coding: </Typography>
      <Typography width='50px'>
        {`${formatTime(hours)}:${formatTime(minutes)}:${formatTime(seconds)} `}
      </Typography>
      <StyledIconButton onClick={onToggleStart}>
        {isStarted ? (
          <StopCircleIcon htmlColor='#f40103' />
        ) : (
          <PlayCircleIcon htmlColor='#7abf04' />
        )}
      </StyledIconButton>
    </TimerWrapper>
  )
}

export default LiveCodingTime
