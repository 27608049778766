/* eslint-disable */
import update from 'immutability-helper'
import { theme } from '../../../themes/Styles'
import { Actions } from '../containers/login/Actions'
import { Actions as LogoutActions } from '../containers/logout/Actions'
import { Actions as ProfileActions } from '../containers/profile/Actions'
import { persistItem, getPersistedItem } from 'common/localStorage'
import { AUTH_TOKEN_KEY } from 'common/constants'

export const initialState = {
  theme: theme,
  appName: '',
  searchTarget: '',
  menu_items: [],
  language: '',
  layout: 'center_content',
  auth: getPersistedItem(AUTH_TOKEN_KEY),
  profile: {},
  isTaxIdDialogueShowed: false,
}

export const Reducers = (state = initialState, { type, value }) => {
  switch (type) {
    case Actions.AUTH_USER:
      persistItem(AUTH_TOKEN_KEY, value)

      return update(state, { auth: { $set: value } })
    case LogoutActions.LOGOUT_USER:
      persistItem(AUTH_TOKEN_KEY, {})

      return update(state, {
        isTaxIdDialogueShowed: { $set: false },
        auth: { $set: {} },
      })
    case ProfileActions.LOAD_PROFILE:
      return update(state, { profile: { $set: value } })
    case ProfileActions.SET_TAX_ID_DIALOGUE_SHOWED:
      return update(state, {
        isTaxIdDialogueShowed: { $set: true },
      })
    case ProfileActions.LOAD_ROLES:
      persistItem(
        AUTH_TOKEN_KEY,
        update(getPersistedItem(AUTH_TOKEN_KEY), {
          roles: { $set: value },
        })
      )

      return update(state, { auth: { roles: { $set: value } } })
    default:
      return state
  }
}
