import property from 'lodash/property'
import styled from 'styled-components'

import { Button } from 'apps/scorecard/components/widgets'

export default (component) => styled(component)`
  justify-content: space-between;

  &.startsToday {
    height: fit-content;
  }

  .MuiCardContent-root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    dl {
      ${property('theme.typography.body2')};

      &:not(:first-child) {
        margin-top: ${({ theme: { spacing } }) => spacing(1)};
      }

      &[aria-roledescription='name'] {
        color: ${property('theme.palette.common.black')};
        ${property('theme.typography.h6')};
      }

      &[aria-roledescription='headline'] {
        ${property('theme.typography.subtitle1')};
      }

      &[aria-roledescription='stack'] {
        display: flex;

        dt {
          display: block;
          font-weight: bold;
        }

        dd {
          font-weight: ${property('theme.typography.fontWeightMedium')};
          margin-left: ${({ theme: { spacing } }) => spacing(1)};
        }
      }
    }

    dt {
      display: none;
    }
  }

  .MuiCardActions-root {
    ${Button} {
      color: ${property('theme.palette.primary.main')};
    }
  }
`
