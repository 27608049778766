import get from 'lodash/get'
import { arrayOf, number, oneOfType, shape } from 'prop-types'
import React, { useMemo, useState } from 'react'
import { Badge, CardContent, CardHeader } from '@mui/material'

import { propTypes } from 'apps/scorecard/prop-types/skill'
import { Card, Markup } from 'apps/scorecard/components/widgets'

import { useQuestion } from './hooks'
import { Header, Footer } from './placeholders'
import Skill from './skill'
import withStyle from './style'

export const renderSkill = (skill) => {
  const key = get(skill, 'skill.id', skill.id)

  return (
    <li key={key}>
      <Skill {...skill} />
    </li>
  )
}

export const Question = ({
  difficulty: difficultyCode,
  answer,
  className,
  hint,
  limit,
  isRequired,
  text,
  ...props
}) => {
  const [expandedRow, setExpandedRow] = useState(false)
  const { findBy, skills } = useQuestion(props)
  const { footer, header } = useMemo(
    () => ({
      footer: findBy({ type: Footer }),
      header: findBy({ type: Header }),
    }),
    [findBy]
  )
  const difficulty = useMemo(
    () =>
      ({
        DifficultyEasy: 'Easy',
        DifficultyMedium: 'Medium',
        DifficultyHard: 'Hard',
      }[difficultyCode]),
    [difficultyCode]
  )

  const questionText = useMemo(
    () => (isRequired ? `${text} *` : text),
    [isRequired, text]
  )

  return (
    <>
      <Card className={className}>
        <CardHeader
          subheader={<Badge variant='dot'>{difficulty}</Badge>}
          action={header}
        />
        <CardContent>
          <dl aria-roledescription='content'>
            {!!text && (
              <dt>
                <Markup source={questionText} />
              </dt>
            )}
            {!!hint && (
              <dd>
                <Markup
                  limit={limit}
                  source={hint}
                  expanded={expandedRow}
                  setExpanded={setExpandedRow}
                />
              </dd>
            )}
          </dl>
          {!!skills.length && (
            <dl aria-roledescription='skills'>
              <dt>Skills</dt>
              <dd>
                <ul>{skills.map(renderSkill)}</ul>
              </dd>
            </dl>
          )}
          {!!footer.length && <div aria-roledescription='footer'>{footer}</div>}
        </CardContent>
      </Card>
    </>
  )
}

Question.propTypes = {
  limit: number,
  skills: oneOfType([
    arrayOf(shape(propTypes).isRequired).isRequired,
    shape(propTypes).isRequired,
  ]),
}

Question.defaultProps = {
  limit: 200,
  skills: [],
}

export default withStyle(Question)
