import abbreviate from 'abbreviate'

import {
  PUBLIC_PROFILE_MINIMUM_RADAR_CHART_THRESHOLD_VALUE,
  PUBLIC_PROFILE_MAXIMUM_RADAR_CHART_AXIS_VALUE,
} from 'common/constants'

import { colors } from './styles'

export class RadarChartHelper {
  constructor(radarChartData) {
    this.radarChartData = radarChartData
  }

  parsePublishedWSCReport() {
    const report = []
    this.radarChartData.dimensions.forEach((dimension) => {
      dimension.subDimensions.forEach((subdimension) => {
        report.push({
          dimension: dimension.label,
          dimensionScore: dimension.score,
          subDimension: subdimension.label,
          subDimensionScore: subdimension.score,
        })
      })
    })
    this.report = report
    return this
  }

  transform() {
    this.report = this.report.filterMap(
      (datum) =>
        datum.subDimensionScore >=
        PUBLIC_PROFILE_MINIMUM_RADAR_CHART_THRESHOLD_VALUE,
      (datum) => ({
        subDimension: datum.subDimension,
        [datum.dimension]: datum.subDimensionScore,
        dimension: datum.dimension,
        fullMark: PUBLIC_PROFILE_MAXIMUM_RADAR_CHART_AXIS_VALUE,
      })
    )
    return this
  }

  setDimensions() {
    const dimensions = this.report.map((datum) => datum.dimension)
    const dimensionsSet = Array.from(new Set(dimensions))
    this.dimensions = dimensionsSet
    return this
  }

  setDimensionsWithColors() {
    const dimensionsWithColors = this.dimensions.map((datum, index) => ({
      dimension: datum,
      color: colors[index],
    }))
    this.dimensionsWithColors = dimensionsWithColors
    return this
  }

  addOtherDimensions() {
    this.report = this.report.map((datum) => {
      const newDatum = { ...datum }
      this.dimensions.forEach((dimension) => {
        if (datum.dimension !== dimension) {
          newDatum[dimension] = 0
        }
      })
      return newDatum
    })
    return this
  }

  closeGaps() {
    let tempDimension = this.report[this.report.length - 1]
    this.report = this.report.map((datum) => {
      let newDatum = { ...datum }
      if (tempDimension.dimension !== datum.dimension) {
        newDatum = {
          ...datum,
          [tempDimension.dimension]: datum[datum.dimension],
        }
        tempDimension = datum
      }
      return newDatum
    })
    return this
  }

  renameDuplicateSubdimensions() {
    this.report = this.report.map((datum) => {
      const newDatum = { ...datum }
      const filteredValues = this.report.filter(
        (filterDatum) => filterDatum.subDimension === datum.subDimension
      )
      if (filteredValues.length > 1) {
        const abbreviationOfDimension = abbreviate(newDatum.dimension, {
          length: 2,
        })
        newDatum.subDimension = `${newDatum.subDimension}-(${abbreviationOfDimension})`
      }
      return newDatum
    })
    return this
  }
}
