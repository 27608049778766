import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import withStyles from '@mui/styles/withStyles'
import update from 'immutability-helper'
import flow from 'lodash/flow'
import get from 'lodash/get'
import React, { Fragment, useRef, useEffect } from 'react'

import { TIME_TO_SCROLL_MS } from 'common/constants'
import { generateKey } from 'common/key-generator'

import { Education } from './Education'

const styles = () => ({
  add: {
    textAlign: 'center',
  },
})

const EducationsBase = ({
  classes,
  value: educations = [],
  onChange,
  error,
  placeAutosuggest,
  locationAutosuggest,
  degreeTextField,
  datePicker,
  label,
  clearErrors,
  name,
}) => {
  const updatedEducation = useRef(null)

  useEffect(() => {
    updatedEducation.current = educations
  }, [educations])
  const editEducation = (setter, index) => (field, value) => {
    const editedEducations = update(updatedEducation.current, {
      [index]: { $merge: { [field]: value } },
    })

    setter(editedEducations)
  }

  const addEducation = (setter, educations) => (e) => {
    setter([
      ...educations,
      {
        id: generateKey(),
        school: {},
        location: {},
        degree: '',
        startDate: '',
        endDate: null,
      },
    ])

    setTimeout(
      () =>
        typeof window !== 'undefined' &&
        window.scrollTo(0, document.body.clientHeight),
      TIME_TO_SCROLL_MS
    )
  }

  const removeEducation = (setter, educations, index) => (exp) => (e) => {
    const educationsArray = [...educations]
    educationsArray.splice(index, 1)
    clearErrors(name)
    setter(educationsArray)
  }

  return (
    <Grid
      container
      direction='column'
      justifyContent='flex-start'
      alignItems='stretch'
    >
      {educations.map((edu, index) => (
        <Fragment key={edu.id || index}>
          <Grid item>
            <Education
              value={edu}
              onChange={editEducation(onChange, index)}
              index={index}
              label={label}
              outerIndex={index}
              error={get(error, `${index}`, {})}
              removeEducation={removeEducation(onChange, educations, index)}
              placeAutosuggestProps={placeAutosuggest}
              locationAutosuggestProps={locationAutosuggest}
              degreeTextFieldProps={degreeTextField}
              datePickerProps={datePicker}
            />
          </Grid>
        </Fragment>
      ))}
      <Box component='div' className={classes.add}>
        <IconButton
          onClick={addEducation(onChange, educations)}
          id='add-education'
          size='large'
        >
          <AddIcon />
        </IconButton>
      </Box>
    </Grid>
  )
}

const Educations = flow(withStyles(styles))(EducationsBase)

export { Educations }
