import PropTypes from 'prop-types'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

const InvertedRoute = ({ disabledPath, children, component }) => (
  <Switch>
    <Route path={disabledPath} />
    <Route component={component}>{children}</Route>
  </Switch>
)

InvertedRoute.propTypes = {
  disabledPath: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]).isRequired,
  children: PropTypes.node,
  component: PropTypes.elementType,
}

InvertedRoute.defaultProps = {
  children: null,
  component: null,
}

export default InvertedRoute
