/* eslint-disable react/prop-types */
import HelpIcon from '@mui/icons-material/Help'
import FormControl from '@mui/material/FormControl'
import Tooltip from '@mui/material/Tooltip'
import withStyles from '@mui/styles/withStyles'
import { flow } from 'lodash'
import React from 'react'

import { DEFAULT_DATE_FORMAT } from 'common/constants'
import { DatePicker as DatePickerBase } from 'components'

const styles = (theme) => ({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  toolTip: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    cursor: 'pointer',
  },
})

const AvailabilityDatePickerBase = ({
  value,
  onChange,
  classes,
  helperText,
  maxLength,
  error,
  disabled,
  hasTooltip,
  label,
  tooltipLabel,
  id,
  ...props
}) => (
  <FormControl className={classes.container}>
    {/* TODO (Jirffey): Move datepicker base inside v2 directory */}
    {/* TODO (Jirffey): Implement minimum date logic */}
    <DatePickerBase
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      name={id}
      label={label}
      error={!!error}
      helperText={error || helperText}
      value={value}
      format={DEFAULT_DATE_FORMAT}
      onChange={(date) => onChange(date)}
    />

    {!!hasTooltip && (
      <Tooltip className={classes.toolTip} title={tooltipLabel}>
        <HelpIcon />
      </Tooltip>
    )}
  </FormControl>
)

const DatePicker = flow(withStyles(styles))(AvailabilityDatePickerBase)

DatePicker.defaultProps = {
  value: null,
}

export { DatePicker }
