import { HeadsetMic as HeadsetMicIcon } from '@mui/icons-material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Hidden from '@mui/material/Hidden'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import flow from 'lodash/flow'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'

import { PAGES } from 'apps/portal/common/constants'
import { PUBLIC_WEBSITE_URL } from 'common/constants'
import { NavBar } from 'components'
import { withConfig } from 'hocs/whitelabel'

const styles = (theme) => {
  const activeLinkStyles = {
    textDecoration: 'none',
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  }

  return {
    gridRoot: {
      justifyContent: 'space-between',
    },
    navLinksContainer: {
      position: 'relative',
      top: theme.spacing(1.43),
    },
    navLink: {
      color: theme.palette.secondary.dark,
      fontSize: theme.typography.fontSize,
      '&:hover': activeLinkStyles,
      margin: theme.spacing(0, 3),
      padding: theme.spacing(0, 0, 2.7),
      borderBottom: `${theme.spacing(0.4)} solid ${
        theme.palette.background.light
      }`,
    },
    navLinkActive: activeLinkStyles,
    phone: {
      display: 'flex',
      position: 'relative',
      '&:before': {
        backgroundColor: theme.palette.grey[300],
        content: '""',
        display: 'block',
        height: theme.spacing(9),
        position: 'absolute',
        right: `calc(100% + ${theme.spacing(4)})`,
        top: '50%',
        transform: 'translateY(-50%)',
        width: '1px',
      },
    },
    phoneNumber: {
      margin: `0 ${theme.spacing(3)} 0 0`,
      color: theme.palette.secondary.dark,
      paddingTop: theme.spacing(1),
    },
    phoneIcon: {
      width: theme.spacing(3),
      marginRight: theme.spacing(1),
      color: theme.palette.secondary.dark,
    },
    active: activeLinkStyles,
  }
}

const { LOGIN } = PAGES

class NonAuthenticatedNavBarClass extends React.Component {
  static propTypes = {
    currentPage: PropTypes.string.isRequired,
    classes: PropTypes.object,
    t: PropTypes.func,
    config: PropTypes.object,
  }

  render() {
    const {
      classes,
      t,
      config: {
        navigation: {
          logo,
          links: { login, aboutUs, whyExpertlead },
          contactNumber,
        },
      },
      currentPage,
    } = this.props

    const shouldRenderDrawer = login && aboutUs && whyExpertlead

    const navbarProps = {
      logo,
      shouldRenderDrawer,
      classes: { gridRoot: classes.gridRoot },
    }

    return (
      <NavBar {...navbarProps}>
        <>
          {/* navbar content for small screens down */}
          <Hidden mdUp>
            <Box>
              <List component='nav' area-label='main pages'>
                {!!aboutUs && (
                  <ListItem
                    button
                    component='a'
                    href={`${PUBLIC_WEBSITE_URL}/about`}
                  >
                    <ListItemText primary={t('about_us')} />
                  </ListItem>
                )}

                {!!whyExpertlead && (
                  <ListItem
                    button
                    component='a'
                    href={`${PUBLIC_WEBSITE_URL}/why-expertlead`}
                  >
                    <ListItemText primary={t('why_expertlead')} />
                  </ListItem>
                )}
              </List>

              <Divider />

              {!!contactNumber && (
                <List component='nav' area-label='account actions'>
                  <ListItem>
                    <ListItemText primary={t('phone')} />
                  </ListItem>
                </List>
              )}
            </Box>
          </Hidden>

          {/* navbar content for medium screens up */}
          <Hidden mdDown>
            <Grid item md={8} lg={9}>
              <Box
                display='flex'
                justifyContent='center'
                className={classes.navLinksContainer}
              >
                {!!login && currentPage.toLowerCase() === LOGIN.toLowerCase() && (
                  <Link
                    href='#'
                    className={`${classes.navLink} ${classes.active}`}
                    underline='hover'
                  >
                    <Typography variant='body1'>{currentPage}</Typography>
                  </Link>
                )}

                {!!aboutUs && (
                  <Link
                    href={`${PUBLIC_WEBSITE_URL}/about`}
                    className={classes.navLink}
                    target='_blank'
                    rel='noopener'
                    underline='hover'
                  >
                    <Typography variant='body1'>{t('about_us')}</Typography>
                  </Link>
                )}

                {!!whyExpertlead && (
                  <Link
                    href={`${PUBLIC_WEBSITE_URL}/join-our-freelance-community`}
                    className={classes.navLink}
                    target='_blank'
                    rel='noopener'
                    underline='hover'
                  >
                    <Typography variant='body1'>
                      {t('why_expertlead')}
                    </Typography>
                  </Link>
                )}
              </Box>
            </Grid>

            {!!contactNumber && (
              <Grid item>
                <Box className={classes.phone}>
                  <HeadsetMicIcon
                    className={classes.phoneIcon}
                    fontSize='large'
                  />
                  <Typography variant='body2' className={classes.phoneNumber}>
                    {t('phone')}
                  </Typography>
                </Box>
              </Grid>
            )}
          </Hidden>
        </>
      </NavBar>
    )
  }
}

const component = flow(
  withConfig,
  withTranslation('navbar'),
  withStyles(styles)
)(NonAuthenticatedNavBarClass)
export { component as NonAuthenticatedNavBar }
