import withStyles from '@mui/styles/withStyles'
import flow from 'lodash/flow'
import { SnackbarProvider } from 'notistack'
import { node } from 'prop-types'
import React, { Component } from 'react'

const styles = (theme) => ({
  containerAnchorOriginBottomRight: {
    marginBottom: theme.spacing(5),
  },
})

class Notifications extends Component {
  static propTypes = {
    children: node.isRequired,
  }

  render() {
    const { props } = this

    return <SnackbarProvider hideIconVariant {...props} />
  }
}

const enhance = flow(withStyles(styles))

export default enhance(Notifications)
