import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import Redirect from 'components/Redirect'

import { logoutUser } from './Actions'

class Logout extends React.Component {
  static propTypes = {
    onLogout: PropTypes.func.isRequired,
  }

  render() {
    this.props.onLogout()
    return <Redirect to={this.props.onSuccessRedirectUri} />
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLogout: () => {
    dispatch(logoutUser())
  },
})

const clogout = connect(null, mapDispatchToProps)(Logout)
export { clogout as Logout }
