import property from 'lodash/property'
import styled from 'styled-components'

export default (component) => styled(component)`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: ${({ theme }) => theme.spacing(1)};
  top: ${({ theme }) => theme.spacing(1)};
  z-index: 1;

  & {
    .MuiIconButton-root {
      background-color: ${property('theme.palette.common.white')};

      &:not(:first-child) {
        margin-top: ${({ theme }) => theme.spacing(1)};
      }
    }
  }
`
