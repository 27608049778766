import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import withStyles from '@mui/styles/withStyles'
import { flow, noop } from 'lodash'
import React, { useEffect, useState } from 'react'
import RichTextEditor from 'react-rte/lib/RichTextEditor'

import boldImg from 'assets/img/button_bold.svg'
import listImg from 'assets/img/button_list.svg'
import { escapeHeaderAndBlockquote } from 'common/markdown'
import { OutlinedBox } from 'components/OutlinedBox'

const getCharacterLength = (value = '') =>
  value.replaceAll('\n', '').replaceAll('​', '').length

const styles = ({ palette, spacing, typography }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column-reverse',
    fontFamily: typography.fontFamily,

    '& > div:first-child': {
      margin: 0,
      backgroundColor: palette.background.default,
      padding: spacing(1, 1, 1.5),
      display: 'flex',
    },

    '& > div:nth-child(2) ': {
      overflow: 'scroll',
      padding: spacing(2),
      fontFamily: typography.fontFamily,

      '& .public-DraftEditor-content': {
        minHeight: spacing(10),
      },
    },

    '& button': {
      padding: 0,
      border: 'none',
      font: 'inherit',
      color: 'inherit',
      cursor: 'pointer',
      width: spacing(3),
      height: spacing(3),
      marginRight: spacing(1),
    },
  },
  bold: {
    background: `url(${boldImg}) no-repeat`,
  },
  bullet: {
    background: `url(${listImg}) no-repeat`,
  },
  extra: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: `0 ${spacing(2)}`,
  },
})

const RichTextEditorBase = ({
  value,
  onChange,
  label,
  maxLength,
  error,
  classes,
  setIsResetButtonClicked = noop,
  isResetButtonClicked,
  ...props
}) => {
  const dataAutomator = props['data-automator'] || 'rich-text-editor'
  const [currentValue, setCurrentValue] = useState(
    RichTextEditor.createValueFromString(value, 'markdown')
  )
  const toolbarConfig = {
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD', className: classes.bold },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'UL', style: 'unordered-list-item', className: classes.bullet },
    ],
  }
  useEffect(() => {
    if (value !== currentValue._cache.markdown && isResetButtonClicked) {
      setCurrentValue(RichTextEditor.createValueFromString(value, 'markdown'))
    }
  }, [value, currentValue._cache.markdown, isResetButtonClicked])
  const handleOnChange = (data) => {
    setCurrentValue(data)
    setIsResetButtonClicked(false)
    const formattedText = escapeHeaderAndBlockquote(data.toString('markdown'))
    const characterLength = getCharacterLength(formattedText)

    if (!maxLength || characterLength <= maxLength) {
      onChange(formattedText)
    } else {
      setCurrentValue(RichTextEditor.createValueFromString(value, 'markdown'))
    }
  }
  return (
    <FormControl error={!!error} fullWidth data-automator={dataAutomator}>
      <OutlinedBox label={label}>
        <RichTextEditor
          toolbarConfig={toolbarConfig}
          value={currentValue}
          onChange={handleOnChange}
          className={classes.root}
          data-automator={dataAutomator}
        />
      </OutlinedBox>
      {maxLength && (
        <div className={classes.extra}>
          <FormHelperText>
            {getCharacterLength(value)}/
{maxLength}
          </FormHelperText>
        </div>
      )}
    </FormControl>
  )
}

const RichTextEditorField = flow(withStyles(styles))(RichTextEditorBase)

export { RichTextEditorField }
