import { string } from 'prop-types'
import React from 'react'

import { Error as Container } from 'apps/scorecard/components/widgets'

import withStyle from './style'

export const Error = (props) => <Container {...props} />

Error.propTypes = {
  className: string.isRequired,
}

Error.defaultProps = {}

export default withStyle(Error)
