import { Tab } from '@mui/material'
import { useState } from 'react'

import { useTracking } from 'apps/scorecard/hooks'

import QuestionFeedback from './question-feedback'
import { StyledHint, StyledTabs } from './style'

const DetailsTab = ({
  hint,
  handleSaveFeedback,
  questionId,
  setIsCommentFocus,
}) => {
  const [activeTab, setActiveTab] = useState(0)

  const handleTabChange = (_, value) => {
    setActiveTab(value)
  }

  const tracking = useTracking()
  const eventTracking = (label, action = 'Click') => {
    tracking.event({
      category: 'Scorecard-v2',
      action,
      label: `${action}_${label}`,
    })
  }

  return (
    <>
      <StyledTabs value={activeTab} onChange={handleTabChange} marginTop='0'>
        <Tab
          label='Question Hint'
          onClick={() => eventTracking('Question_Hint_Tab')}
        />
        <Tab
          label='Provide Feedback on Question'
          onClick={() => eventTracking('Provide_Feedback_on_Question_Tab')}
        />
      </StyledTabs>
      {activeTab === 0 && (
        <StyledHint dangerouslySetInnerHTML={{ __html: hint }} />
      )}
      {activeTab === 1 && (
        <StyledHint>
          <QuestionFeedback
            questionId={questionId}
            handleSaveFeedback={handleSaveFeedback}
            setIsCommentFocus={setIsCommentFocus}
          />
        </StyledHint>
      )}
    </>
  )
}

export default DetailsTab
