import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'
import withStyles from '@mui/styles/withStyles'
import PropTypes from 'prop-types'
import React from 'react'

import { capitalize } from 'common/utils'

const proficiencies = ['BEGINNER', 'INTERMEDIATE', 'ADVANCED', 'NATIVE']

const styles = (theme) => ({
  form: {
    width: '100%',
  },
  clickableIcons: {
    cursor: 'pointer',
  },
  formControl: {
    minWidth: 120,
    width: '100%',

    [`& > .${outlinedInputClasses.root} > .${outlinedInputClasses.input}`]: {
      padding: '14.5px 14px',
    },
  },
})

class ProficiencyComponent extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
  }

  onChange = (event) => {
    const { value } = event.target
    this.props.onChange(value)
  }

  render() {
    const { classes, error } = this.props
    return (
      <FormControl
        variant='outlined'
        margin='normal'
        className={classes.formControl}
        error={!!error}
      >
        <InputLabel>{this.props.label}</InputLabel>
        <Select
          id='proficiency'
          label={this.props.label}
          fullWidth
          value={this.props.value}
          onChange={this.onChange}
        >
          {proficiencies.map((proficiency, index) => (
            <MenuItem key={proficiency} value={proficiency}>
              {capitalize(proficiency)}
            </MenuItem>
          ))}
        </Select>
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    )
  }
}

const Proficiency = withStyles(styles)(ProficiencyComponent)
export { Proficiency }
