import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import PropTypes from 'prop-types'
import React from 'react'

const styles = (theme) => ({
  media: {
    height: '180px',
  },
})

class CardView extends React.Component {
  static defaultProps = {}

  static propTypes = {
    thumbnail: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }

  render() {
    return (
      <Card>
        {this.props.thumbnail && (
          <CardMedia
            className={this.props.classes.media}
            image={this.props.thumbnail}
            title={this.props.title}
          />
        )}
        <CardContent>
          <Typography gutterBottom variant='h5' component='h2'>
            {this.props.title}
          </Typography>
          <Typography variant='body2' color='textSecondary' component='p'>
            {this.props.description}
          </Typography>
        </CardContent>
        {/* <CardActions> */}
        {/* TODO: ============== */}
        {/*    <Button size="small" color="primary"> */}
        {/*        Share */}
        {/*    </Button> */}
        {/*    <Button size="small" color="primary"> */}
        {/*        Learn More */}
        {/*    </Button> */}
        {/* </CardActions> */}
      </Card>
    )
  }
}

export default withStyles(styles)(CardView)
