import { node, string } from 'prop-types'
import React from 'react'

import withStyle from './style'

export const NotFound = ({ className, children }) => (
  <p className={className}>{children}</p>
)

NotFound.propTypes = {
  className: string.isRequired,
  children: node,
}

NotFound.defaultProps = {}

export default withStyle(NotFound)
