import { get, reduce } from 'lodash'

/**
 * This function accepts a configsCollection object which has the following
 * format:
 *
 * {
 *   serviceNamespace1: serviceConfig1,
 *   serviceNamespace2: serviceConfig2
 *   ...
 * }
 *
 * where serviceConfig is an object created by calling `Factory.createRequestConfig(...)`.
 *
 * It then returns an object that has the following format:
 *
 * {
 *   endpoint1: {
 *     get: {
 *       success: 'servicenamespace1_serviceconfig1_success',
 *       error: 'servicenamespace1_serviceconfig1_error',
 *     }
 *     post: { ... },
 *     ...
 *   },
 *   endpoint2: {
 *     ...
 *   }
 * }
 */
const createEndpointMethodsMap = (configsCollection) =>
  reduce(
    configsCollection,
    (acc, configs, outerKey) => {
      // eslint-disable-next-line
      acc = reduce(
        configs,
        (innerAcc, item, innerKey) => {
          let endpointNamespaceObj = get(innerAcc, item.uri, {})
          const keyPrefix = `${outerKey}_${innerKey}`.toLowerCase()

          endpointNamespaceObj = {
            ...endpointNamespaceObj,
            [`${item.method.toLowerCase()}`]: {
              success: `${keyPrefix}_success`,
              error: `${keyPrefix}_error`,
            },
          }

          return {
            ...innerAcc,
            [item.uri]: endpointNamespaceObj,
          }
        },
        acc
      )

      return acc
    },
    {}
  )

/**
 * extracts the object from a map (same format as `createEndpointMethodsMap` return value
 * the extraction logic is based on url pattern matching
 */
const getObjectFromMap = ({ url, method, matcher, map }) => {
  const urlToMatch = url.split('?')[0] // remove query string
  const matches = reduce(
    map,
    (acc, item, key) => {
      const match = matcher(urlToMatch, {
        path: key,
        exact: true,
        strict: true,
      })

      if (!match) return acc

      return {
        ...acc,
        ...item,
      }
    },
    {}
  )

  const targetKey = matches[method]

  if (!targetKey) {
    throw new Error(`Could not find message for ${url}`)
  }

  return targetKey
}

export { createEndpointMethodsMap, getObjectFromMap }
