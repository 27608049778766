import StyledTooltip from 'apps/scorecard-v2/components/StyledTooltip'
import { SCORE_HINT } from 'apps/scorecard-v2/constants'

import { MarksWrapper, MarkButton } from './style'

const Marks = ({ score, setItemScore, handleItemSave }) => {
  const handleClick = ({ target }) => {
    setItemScore(Number(target.value))
    handleItemSave(_, Number(target.value))
  }

  return (
    <MarksWrapper>
      <StyledTooltip arrow title={SCORE_HINT[0]}>
        <MarkButton value={0} onClick={handleClick}>
          x
        </MarkButton>
      </StyledTooltip>

      <StyledTooltip arrow title={SCORE_HINT[1]}>
        <MarkButton active={score == 1 || ''} value={1} onClick={handleClick}>
          1
        </MarkButton>
      </StyledTooltip>

      <StyledTooltip arrow title={SCORE_HINT[2]}>
        <MarkButton active={score == 2 || ''} value={2} onClick={handleClick}>
          2
        </MarkButton>
      </StyledTooltip>

      <StyledTooltip arrow title={SCORE_HINT[3]}>
        <MarkButton active={score == 3 || ''} value={3} onClick={handleClick}>
          3
        </MarkButton>
      </StyledTooltip>

      <StyledTooltip arrow title={SCORE_HINT[4]}>
        <MarkButton active={score == 4 || ''} value={4} onClick={handleClick}>
          4
        </MarkButton>
      </StyledTooltip>

      <StyledTooltip arrow title={SCORE_HINT[5]}>
        <MarkButton active={score == 5 || ''} value={5} onClick={handleClick}>
          5
        </MarkButton>
      </StyledTooltip>

      <StyledTooltip arrow title={SCORE_HINT[6]}>
        <MarkButton active={score == 6 || ''} value={6} onClick={handleClick}>
          6
        </MarkButton>
      </StyledTooltip>

      <StyledTooltip arrow title={SCORE_HINT[7]}>
        <MarkButton active={score == 7 || ''} value={7} onClick={handleClick}>
          7
        </MarkButton>
      </StyledTooltip>

      <StyledTooltip arrow title={SCORE_HINT[8]}>
        <MarkButton active={score == 8 || ''} value={8} onClick={handleClick}>
          8
        </MarkButton>
      </StyledTooltip>

      <StyledTooltip arrow title={SCORE_HINT[9]}>
        <MarkButton active={score == 9 || ''} value={9} onClick={handleClick}>
          9
        </MarkButton>
      </StyledTooltip>

      <StyledTooltip arrow title={SCORE_HINT[10]}>
        <MarkButton active={score == 10 || ''} value={10} onClick={handleClick}>
          10
        </MarkButton>
      </StyledTooltip>
    </MarksWrapper>
  )
}

export default Marks
