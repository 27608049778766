import { useMemo } from 'react'

import { format } from './helpers'

export const useCounter = ({ total, value }) => {
  const formatted = useMemo(
    () => ({ total: format(total), value: format(value) }),
    [total, value]
  )

  return formatted
}
