import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React from 'react'
import { withTranslation } from 'react-i18next'

import { CERTIFICATION_DATE_FORMAT } from 'apps/portal/common/constants'
import defaultImage from 'assets/img/certification.svg'
import { formatDateISO } from 'common/dates'
import { buildImageURL } from 'common/image'

import { placeStatusApproved } from '../../../common/place-status'

class CertificationComponent extends React.Component {
  getAuthorityName = (status, name) =>
    placeStatusApproved(status)
      ? name
      : `${name} (${this.props.t('place_under_review')})`

  addDefaultImage = (e) => {
    e.target.src = defaultImage
  }

  render() {
    const { classes, name, authority, issueDate, expiryDate, t } = this.props

    return (
      <>
        <Grid item>
          <Box pr={1}>
            {authority?.logo?.bucket ? (
              <img
                className={classes.companyLogo}
                src={buildImageURL(authority.logo)}
                alt={authority.name}
                onError={this.addDefaultImage}
              />
            ) : (
              <img
                className={classes.companyLogo}
                src={`${defaultImage}`}
                alt={authority.name}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm container>
          <Grid
            item
            xs
            container
            direction='column'
            spacing={2}
            justifyContent='center'
          >
            <Grid item className={classes.paddingTopZero}>
              <Typography variant='h6' component='div'>
                {name}
              </Typography>
              <Typography variant='subtitle2' gutterBottom component='div'>
                {this.getAuthorityName(authority.status, authority.name)}
              </Typography>
              <Typography variant='body2' className={classes.dateColor}>
                {`${t('issuedOn')}  ${formatDateISO(
                  issueDate,
                  CERTIFICATION_DATE_FORMAT
                )}`}
              </Typography>
              <Typography variant='body2' className={classes.dateColor}>
                {expiryDate
                  ? `${t('validTill')}  ${formatDateISO(
                      expiryDate,
                      CERTIFICATION_DATE_FORMAT
                    )}`
                  : t('noExpirationDate')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }
}

const Certification = withTranslation('profileCertification')(
  CertificationComponent
)

export { Certification }
