import { node } from 'prop-types'
import { forwardRef } from 'react'

export const Items = forwardRef(({ children }, ref) => children)

Items.propTypes = {
  children: node,
}

Items.defaultProps = {}

export default Items
