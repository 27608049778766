import { Tooltip } from '@mui/material'
import withStyles from '@mui/styles/withStyles'

const CustomizedTooltip = withStyles(({ palette, spacing }) => ({
  tooltip: {
    backgroundColor: palette.secondary.blue,
    padding: spacing(1),
    fontSize: spacing(1.2),
  },
  arrow: {
    color: palette.secondary.blue,
  },
}))(Tooltip)

export { CustomizedTooltip }
