import styled from 'styled-components'

export default (component) => styled(component)`
  display: flex;

  dt {
    display: none;
  }

  dl[aria-roledescription='total'] {
    color: #b7b7b7;

    dd {
      &:before {
        content: '/';
        display: inline-block;
        font: inherit;
        margin: 0 ${({ theme: { spacing } }) => spacing(0.5)};
      }
    }
  }
`
