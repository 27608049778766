import { Box, IconButton, Slider, TextField, Typography } from '@mui/material'
import styled from 'styled-components'

import { getScoreColor } from 'apps/scorecard-v2/helpers'

export const StyledContainer = styled(Box)`
  padding: 0 16px;
  height: calc(100vh - 148px);
  overflow-y: scroll;
`

export const StyledTaskWrapper = styled(Box)`
  background: #f1f3f6;
  margin: 10px auto 18px auto;
  border-radius: 5px;
  color: #2b254f;

  &::before {
    height: 0;
  }

  & .MuiAccordionSummary-root {
    background-color: #f1f3f6;
  }

  & .MuiPaper-root {
    max-width: 100% !important;
  }
`

export const StyledTaskTitle = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;

  & a {
    display: flex;
    align-item: center;
  }
`

export const StyledBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledSlider = styled(Slider)`
  color: ${({ value }) => getScoreColor(value)};
  height: 9px;

  & .MuiSlider-mark {
    height: 4px;
    background: #e1e3e9;
  }

  & .MuiSlider-valueLabel {
    min-width: 170px;
    white-space: normal;
    background-color: #2b244f;
    padding: 10px;
    line-height: 20px;
    border-radius: 5px;

    & .MuiTooltip-arrow {
      color: '#2b244f';
    }
  }
`

export const QuestionWrapper = styled(StyledBox)`
  padding: 0 2px;
  color: #2b254f;
  margin-bottom: 4px;
  width: 100%;
  box-sizing: border-box;
`

export const ItemContainer = styled(Box)`
  margin-bottom: 5px;
  border-radius: 5px;
  padding: 6px 30px 0 15px;

  &:hover {
    background: #f1f3f6;
  }
`
export const CommentButton = styled(IconButton)`
  margin-right: 12px;
  padding: 8px;
`

export const StyledTextField = styled(TextField)`
  display: flex;

  & .MuiOutlinedInput-input.MuiInputBase-inputMultiline {
    padding: ${({ show }) => (show ? '18px 14px' : '0')};
  }

  & .MuiInputBase-fullWidth {
    padding: 0 0.8%;
  }

  & .MuiInputBase-inputMultiline {
    height: ${({ show }) => (show ? 'auto' : '0 !important')};
    background: #fff;
    transition: height 0.1s ease-in-out;
    color: #8f8ea3;
    border-radius: 5px;
    border: ${({ show }) => (show ? '1px solid #e2e1e6' : 'none')};
  }

  & fieldset {
    border: none;
  }

  & .MuiFormHelperText-root {
    padding: 0;
    text-align: right;
    margin: 0;
    opacity: ${({ show }) => (show ? '1' : '0')};
    padding-right: 2%;
  }
`
const activeButton = `
    align-self: flex-end;
    margin-top: 5px;
    min-width: fit-content;
    padding: 4px;
    font-size: 0.875rem;
    height: fit-content;
    width: fit-content;
`

export const StyledComment = styled(Box)`
  height: ${({ show }) => (show ? 'fit-content' : '0')};
  display: flex;
  flex-direction: column;

  & button {
    align-self: flex-end;
    margin-top: 5px;
    min-width: fit-content;
    padding: 0;
    text-transform: none;
    height: 0;
    font-size: 0;
    ${({ show }) => show && activeButton};
    transition: all 0.1s ease-in-out;
  }
`

export const StyledTaskHint = styled(Typography)`
  color: #534e70;
  max-height: 300px;
  overflow-y: scroll;

  & code {
    display: flex;
    border-radius: 5px;
    width: 100%;
    background: #e6e6e6;
    padding: 6px;
    white-space: break-spaces;
    margin: 5px 0;
  }
`

export const StyledHintTitle = styled(Typography)`
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom: 8px;
  color: #534e70;
`
