import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Typography,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from '@mui/material'
import { useState } from 'react'

import { STATUS } from 'apps/scorecard-v2/constants'
import { useTracking } from 'apps/scorecard/hooks'

import FeedbackHint from './feedback-hint'
import FeedbackStatus from './feedback-status'
import { StyledFeedbackSection, StyledAccordion, StyledText } from './style'

const FeedbackSection = ({
  formik,
  handleSaveFeedback,
  status,
  setTyping,
  toggleTabKeyDisable,
}) => {
  const { errors, values, handleChange } = formik
  const [currentFeedback, setCurrentFeedback] = useState('')

  const tracking = useTracking()
  const eventTracking = (label, action = 'Click') => {
    tracking.event({
      category: 'Scorecard-v2',
      action,
      label: `${action}_${label}`,
    })
  }

  const onChange = (event) => {
    const { name, value } = event.target
    setTyping(STATUS.pending)
    setCurrentFeedback(name)
    handleChange(event)
    handleSaveFeedback({ ...formik.values, [name]: value })
  }

  const handleFocus = () => {
    toggleTabKeyDisable(true)
  }

  const handleBlur = () => {
    toggleTabKeyDisable(false)
  }

  return (
    <StyledFeedbackSection>
      <Typography fontWeight={700} fontSize='1.15rem' mb={1}>
        Please provide us your final feedback
      </Typography>
      <StyledAccordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          onClick={() => eventTracking('Feedback_Comment_Expand')}
        >
          <StyledText>
            How did the candidate perform in regards to his/her or their
            professional experience?*
          </StyledText>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            placeholder='Add a Comment'
            variant='outlined'
            multiline
            minRows={2}
            name='feedback1'
            value={values.feedback1}
            onChange={onChange}
            error={!!errors.feedback1}
            onBlur={handleBlur}
            onFocus={handleFocus}
            helperText={
              <FeedbackStatus
                typing={currentFeedback === 'feedback1'}
                status={status}
                error={errors.feedback1 && `Min ${values.feedback1.length}/150`}
              />
            }
            onClick={() => eventTracking('Add_Comment')}
          />
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel2a-content'
          id='panel2a-header'
          onClick={() => eventTracking('Feedback_Comment_Expand')}
        >
          <StyledText>
            Q&A section: What are the <b>strengths</b>
{' '}
of the candidate you
            observed during the Q&A part?*
</StyledText>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            placeholder='Add a Comment'
            variant='outlined'
            multiline
            name='feedback2'
            value={values.feedback2}
            onChange={onChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            helperText={
              <FeedbackStatus
                typing={currentFeedback === 'feedback2'}
                status={status}
                error={errors.feedback2 && `Min ${values.feedback2.length}/150`}
              />
            }
            error={!!errors.feedback2}
            minRows={2}
            onClick={() => eventTracking('Add_Comment')}
          />
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel2a-content'
          id='panel2a-header'
          onClick={() => eventTracking('Feedback_Comment_Expand')}
        >
          <StyledText>
            Q&A section: What are the <b>weaknesses</b>
{' '}
of the candidate you
            observed during the Q&A part?*
</StyledText>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            placeholder='Add a Comment'
            variant='outlined'
            multiline
            name='feedback3'
            value={values.feedback3}
            onChange={onChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            helperText={
              <FeedbackStatus
                typing={currentFeedback === 'feedback3'}
                status={status}
                error={errors.feedback3 && `Min ${values.feedback3.length}/150`}
              />
            }
            error={!!errors.feedback3}
            minRows={2}
            onClick={() => eventTracking('Add_Comment')}
          />
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel2a-content'
          id='panel2a-header'
          onClick={() => eventTracking('Feedback_Comment_Expand')}
        >
          <StyledText>
            LC section (if applicable): What are the{' '}
            <b>strengths and weaknesses</b> of the candidate you observed during
            the LC part?
          </StyledText>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            placeholder='Add a Comment'
            variant='outlined'
            multiline
            name='feedback4'
            value={values.feedback4}
            onChange={onChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            helperText={
              <FeedbackStatus
                typing={currentFeedback === 'feedback4'}
                status={status}
                error={errors.feedback4 && `Min ${values.feedback4.length}/150`}
              />
            }
            minRows={2}
            onClick={() => eventTracking('Add_Comment')}
          />
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel2a-content'
          id='panel2a-header'
          onClick={() => eventTracking('Feedback_Comment_Expand')}
        >
          <StyledText>
            Do you have any additional comments about the tech assessment of the
            candidate?
          </StyledText>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            placeholder='Add a Comment'
            variant='outlined'
            multiline
            name='feedback5'
            value={values.feedback5}
            onChange={onChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            helperText={
              <FeedbackStatus
                typing={currentFeedback === 'feedback5'}
                status={status}
                error={errors.feedback5 && `Min ${values.feedback5.length}/150`}
              />
            }
            minRows={2}
            onClick={() => eventTracking('Add_Comment')}
          />
        </AccordionDetails>
      </StyledAccordion>
      <FeedbackHint />
    </StyledFeedbackSection>
  )
}

export default FeedbackSection
