import get from 'lodash/get'
import update from 'immutability-helper'

export const getInitialState = (props) => ({
  crop: {
    height: get(props, 'height', 0),
    width: get(props, 'width', 0),
    aspect: 1,
    unit: 'px',
    x: 0,
    y: 0,
  },
  distortion: { x: 0, y: 0 },
  scale: 1,
  source: null,
  error: null,
})

export const setCrop = (crop) => (state) =>
  update(state, { crop: { $set: crop } })

export const setDistortion = (distortion) => (state) =>
  update(state, { distortion: { $set: distortion } })

export const setError = (error) => (state) =>
  update(state, { error: { $set: error } })

export const setSource = (source) => (state) =>
  update(state, {
    crop: { x: { $set: 0 }, y: { $set: 0 } },
    source: { $set: source },
    error: { $set: null },
  })
