import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  chip: {
    marginRight: theme.spacing(1),
  },
  chipsContainer: {
    marginTop: theme.spacing(1),
  },
}))

export default useStyles
