import { DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'

import { StyledContainedButton } from './style'

const Popup = ({ message, title, open, onConfirm }) => (
  <Dialog
    open={open}
    aria-labelledby='alert-dialog-title'
    aria-describedby='alert-dialog-description'
    maxWidth='xs'
    fullWidth
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <Typography>{message}</Typography>
    </DialogContent>
    <DialogActions>
      <StyledContainedButton
        style={{ height: '40px' }}
        variant='contained'
        onClick={onConfirm}
      >
        Okay
      </StyledContainedButton>
    </DialogActions>
  </Dialog>
)

export default Popup
