class SimplePubSub {
  constructor() {
    this.queue = {}
    this.on = this.subscribe
  }

  subscribe(topic, callback) {
    this.queue[topic] = this.queue[topic] || []
    this.queue[topic] = [...this.queue[topic], callback]
  }

  publish(topic, payload) {
    ;(this.queue[topic] || []).forEach((x) => x(payload))
  }
}

export { SimplePubSub }
