import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'
import withStyles from '@mui/styles/withStyles'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { SPOKEN_LANGUAGE_PROFICIENCIES } from 'common/constants'
import { capitalize } from 'common/utils'

const styles = (theme) => ({
  form: {
    width: '100%',
  },
  clickableIcons: {
    cursor: 'pointer',
  },
  formControl: {
    minWidth: 120,
    width: '100%',

    [`& > .${outlinedInputClasses.root} > .${outlinedInputClasses.input}`]: {
      padding: '14.5px 14px',
    },
  },
})

const ProficiencyBase = ({
  label,
  onChange,
  value,
  classes,
  error,
  ...props
}) => {
  const outerIndex = props.outerIndex || 0
  const onChangeHandler = useCallback(
    (event) => {
      const { value } = event.target
      onChange(value)
    },
    [onChange]
  )

  return (
    <FormControl
      variant='outlined'
      margin='normal'
      className={classes.formControl}
      error={!!error}
    >
      <InputLabel data-automator={`language-proficiency-label-${outerIndex}`}>
        {label}
      </InputLabel>
      <Select
        label={label}
        fullWidth
        value={value}
        onChange={onChangeHandler}
        data-automator={`language-proficiency-level-${outerIndex}`}
      >
        {SPOKEN_LANGUAGE_PROFICIENCIES.map((proficiency, index) => (
          <MenuItem
            key={proficiency}
            value={proficiency}
            data-automator={`language-proficiency-${proficiency}-${outerIndex}`}
          >
            {capitalize(proficiency)}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

ProficiencyBase.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}

const Proficiency = withStyles(styles)(ProficiencyBase)
export { Proficiency }
