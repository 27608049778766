import React from "react"
import { Typography, CardContent } from '@mui/material'

const Cco1 = () => (
  <CardContent>
    <section>
      <Typography gutterBottom><strong>No Copyright</strong></Typography>

      <Typography gutterBottom>
        The person who associated a work with this deed has <strong>dedicated</strong> the work to the public domain by
        waiving all of
        his or her rights to the work worldwide under copyright law, including all related and neighboring rights, to
        the extent allowed by law.
      </Typography>

      <Typography gutterBottom>
        You can copy, modify, distribute and perform the work, even for commercial purposes, all without asking
        permission. See <strong>Other Information</strong> below.
      </Typography>
    </section>

    <section>
      <Typography gutterBottom><strong>Other Information</strong></Typography>

      <ul>
        <li>
          <Typography gutterBottom>
            In no way are the patent or trademark rights of any person affected by CC0, nor are the rights that other
          persons may have in the work or in how the work is used, such as <strong>publicity or privacy</strong> rights.
          </Typography>
        </li>

        <li>
          <Typography gutterBottom>
            Unless expressly stated otherwise, the person who associated a work with this deed makes no warranties about
            the work, and disclaims liability for all uses of the work, to the fullest extent permitted by applicable law.
          </Typography>
        </li>

        <li>
          <Typography gutterBottom>
            When using or citing the work, you should not imply endorsement by the author or the affirmer.
          </Typography>
        </li>
      </ul>
    </section>
  </CardContent>
)

export { Cco1 }
