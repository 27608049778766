import withStyles from '@mui/styles/withStyles'
import { flow } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'

import {
  ORGANIZATION_IDS,
  PROFILE_TYPES,
  PUBLIC_PROFILE_CONTACT_US,
} from 'common/constants'

import { ContactUs } from './ContactUs'

const styles = (theme) => {
  const buttonForContactUs = {
    borderRadius: theme.spacing(4),
    color: 'white',
    textTransform: 'none',
  }
  return {
    buttonForEL: {
      ...buttonForContactUs,
      background: theme.palette.primary.blue,
      '&:hover': {
        backgroundColor: theme.palette.primary.blue,
      },
    },
    buttonForFP: {
      ...buttonForContactUs,
      background: theme.palette.green,
      '&:hover': {
        backgroundColor: theme.palette.green,
      },
    },
  }
}
const ContactUsAsPerOrgBase = ({
  organizationId,
  classes,
  t,
  gEvent,
  slugId,
  profileType,
}) => {
  const emailToExpertleadAddr =
    profileType === PROFILE_TYPES.FREELANCER
      ? PUBLIC_PROFILE_CONTACT_US.emailToExpertlead
      : PUBLIC_PROFILE_CONTACT_US.emailToExpertleadForPerm

  if (organizationId === ORGANIZATION_IDS.EXPERTLEAD)
    return (
      <ContactUs
        buttonClass={classes.buttonForEL}
        emailTo={emailToExpertleadAddr}
        buttonLabel={t('buttonLabelForExpertlead')}
        gEvent={gEvent}
        slugId={slugId}
      />
    )
  if (organizationId === ORGANIZATION_IDS.JV)
    return (
      <ContactUs
        buttonClass={classes.buttonForFP}
        emailTo={PUBLIC_PROFILE_CONTACT_US.emailToFuturepath}
        buttonLabel={t('buttonLabelForFuturePath')}
        gEvent={gEvent}
        slugId={slugId}
      />
    )
  return <></>
}
const ContactUsAsPerOrg = flow(
  withTranslation('publicProfile'),
  withStyles(styles)
)(ContactUsAsPerOrgBase)

ContactUsAsPerOrg.propTypes = {
  organizationId: PropTypes.string,
}
export { ContactUsAsPerOrg }
