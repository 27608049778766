import { bool } from 'prop-types'
import React, { cloneElement } from 'react'
import { Redirect, Route } from 'react-router-dom'

import { COMMON_PAGES } from 'common/constants'

const params = window && new URLSearchParams(window.location.search)

const AuthGuard = ({
  authToken,
  isCloneRequired = false,
  children,
  ...props
}) => {
  const pathname = props?.location?.pathname

  const childComponent = isCloneRequired
    ? cloneElement(children, props)
    : children

  const component = authToken ? (
    childComponent
  ) : (
    <Redirect
      to={{
        pathname: `/${COMMON_PAGES.LOGIN}`,
        search: `?${params}`,
        state: {
          from: { pathname: pathname ? `${pathname}?${params}` : null },
        },
      }}
    />
  )
  return <Route {...props}>{component}</Route>
}

AuthGuard.propTypes = {
  authToken: bool.isRequired,
}

export { AuthGuard }
