import AddCommentIcon from '@mui/icons-material/AddComment'
import CommentIcon from '@mui/icons-material/Comment'
import InfoIcon from '@mui/icons-material/Info'
import { Typography } from '@mui/material'
import { debounce } from 'lodash'
import { useState, useCallback } from 'react'

import StyledTooltip from 'apps/scorecard-v2/components/StyledTooltip'
import { SCORE_HINT, STATUS } from 'apps/scorecard-v2/constants'
import { getScoreColor } from 'apps/scorecard-v2/helpers'
import { useTracking } from 'apps/scorecard/hooks'

import {
  StyledBox,
  StyledSlider,
  QuestionWrapper,
  CommentButton,
  ItemContainer,
  StyledTextField,
  StyledComment,
} from './style'

const QuestionItem = ({ question, handleSave, toggleTabKeyDisable }) => {
  const { text, hint, score, note, id } = question
  const [showTextField, setShowTextField] = useState(0)
  const [hasFocus, setHasFocus] = useState(false)
  const [itemScore, setItemScore] = useState(score)
  const [itemNote, setItemNote] = useState(note || '')
  const [typingStatus, setTypingStatus] = useState(STATUS.idle)

  const tracking = useTracking()
  const eventTracking = (label, action = 'Click') => {
    tracking.event({
      category: 'Scorecard-v2',
      action,
      label: `${action}_${label}`,
    })
  }

  const handleCommentClick = (eventName) => {
    setShowTextField(showTextField ? 0 : 1)
    setHasFocus(() => !hasFocus)
    eventTracking(eventName)
  }

  const handleScoreChange = (_, value) => {
    setItemScore(value)
    eventTracking('Rating')
  }

  const handleNoteChange = (event) => {
    const { value } = event.target
    setItemNote(value)
    setTypingStatus(STATUS.pending)
    debouncedSaveComment(value, itemScore, id)
  }

  const handleItemSave = () => {
    if (itemScore === score) {
      return
    }

    const payload = {
      questionAnswer: {
        answer: { score: itemScore, note: itemNote },
      },
    }
    handleSave(id, payload)
  }

  const debouncedSaveComment = useCallback(
    debounce((note, score, id) => {
      const payload = {
        questionAnswer: {
          answer: { score, note },
        },
      }
      handleSave(id, payload, true)
      setTypingStatus(STATUS.fulfilled)
    }, 700),
    []
  )

  const getHelperText = () => {
    if (typingStatus === STATUS.pending) {
      return 'Typing...'
    }

    if (typingStatus === STATUS.fulfilled) {
      return 'Saved'
    }

    return ' '
  }

  const handleKeyDown = (event) => {
    if (
      event.keyCode === 37 ||
      event.keyCode === 38 ||
      event.keyCode === 39 ||
      event.keyCode === 40
    ) {
      event.preventDefault()
    }
  }

  const handleBlur = () => {
    toggleTabKeyDisable(false)
    setHasFocus(() => false)
  }

  const handleFocus = () => {
    setHasFocus(() => true)
    toggleTabKeyDisable(true)
  }

  const questionText =
    text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()

  return (
    <ItemContainer>
      <QuestionWrapper>
        <Typography display='flex' alignItems='center'>
          {questionText} &nbsp;&nbsp;
          <StyledTooltip arrow title={hint}>
            <InfoIcon
              sx={{ color: '#c5c4cf' }}
              onMouseEnter={() => eventTracking('List_Item_Info', 'Hover')}
            />
          </StyledTooltip>
        </Typography>
        <StyledBox width='40%'>
          {note?.length ? (
            <CommentButton onClick={() => handleCommentClick('Message_Icon')}>
              <CommentIcon htmlColor='#aaa8b9' />
            </CommentButton>
          ) : (
            <CommentButton onClick={() => handleCommentClick('Plus_Icon')}>
              <AddCommentIcon htmlColor='#3c26de' />
            </CommentButton>
          )}

          <StyledSlider
            value={itemScore || 0}
            valueLabelDisplay='auto'
            step={1}
            marks
            min={0}
            max={10}
            onChange={handleScoreChange}
            onChangeCommitted={handleItemSave}
            valueLabelFormat={(score) => <p>{SCORE_HINT[score]}</p>}
            onKeyDown={handleKeyDown}
          />
          <Typography ml={3} color={getScoreColor(itemScore)}>
            {itemScore || 'n/a'}
          </Typography>
        </StyledBox>
      </QuestionWrapper>
      <StyledComment show={showTextField}>
        <StyledTextField
          fullWidth
          variant='outlined'
          placeholder='Add a comment'
          show={showTextField}
          value={itemNote}
          onChange={handleNoteChange}
          multiline
          helperText={getHelperText()}
          onBlur={handleBlur}
          onFocus={handleFocus}
          inputRef={(input) => {
            hasFocus && input && input.focus()
          }}
        />
      </StyledComment>
    </ItemContainer>
  )
}

export default QuestionItem
