import isEqual from 'lodash/isEqual'
import update from 'immutability-helper'
import { useMemo } from 'react'

export const useScore = ({ setScore, setComments, ...props }) => {
  const scores = useMemo(
    () =>
      props.scores.map((score) =>
        update(score, {
          onChange: {
            $set: ({ target: { checked } }) => checked && setScore(score.value),
          },
          checked: { $set: isEqual(score.value, props.score) },
        })
      ),
    [props.scores, setScore, props.score]
  )
  const comments = useMemo(
    () => ({
      onChange: ({ target: { value } }) => setComments(value),
      value: props.note || '',
    }),
    [props, setComments]
  )

  return { ...(props.score && { comments }), scores }
}
