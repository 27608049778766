import { useEffect, useState } from 'react'

const getTabScores = (items = []) =>
  items.reduce((acc, curr) => {
    acc[curr.id] = {}
    curr.nodes.forEach((question) => {
      acc[curr.id][question.id] = question.score || 0
    })

    return acc
  }, {})

const useTabScores = (items) => {
  const initialTabScores = getTabScores(items)

  const [tabScores, setTabScores] = useState(initialTabScores)

  useEffect(() => {
    const tabScores = getTabScores(items)
    setTabScores(tabScores)
  }, [items])

  return { tabScores }
}

export default useTabScores
