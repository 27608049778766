import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import MuiSwitch from '@mui/material/Switch'
import React from 'react'

const Switch = ({
  value,
  onChange,
  error,
  label,
  labelPlacement,
  disabled,
}) => (
  <FormControl error={!!error}>
    <FormControlLabel
      control={
        <MuiSwitch
          checked={!!value}
          color='primary'
          onChange={(e) => onChange(e.target.checked)}
          disabled={disabled}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
    />
  </FormControl>
)

Switch.defaultProps = {
  labelPlacement: 'start',
}

export { Switch }
