import isUndefined from 'lodash/isUndefined'
import { bool, node, number, string } from 'prop-types'
import React, { forwardRef, useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import { ListItem, ListItemText, Tooltip, Typography } from '@mui/material'

import { Markup } from 'apps/scorecard/components/widgets'

import withStyle from './style'

export const Item = forwardRef(
  (
    {
      url: to,
      label: children,
      active,
      className,
      external,
      id,
      invalid,
      limit,
      tooltip,
      onClick,
    },
    ref
  ) => {
    const isActive = useCallback(() => active, [active])
    const link = {
      ...(!isUndefined(active) && { isActive }),
      exact: true,
      to,
      children,
    }
    const Wrapper = useCallback(
      ({ title, ...props }) =>
        tooltip ? (
          <Tooltip
            title={
              <Typography component='span' variant='body1'>
                <Markup limit={limit} source={title} />
              </Typography>
            }
            arrow
            onClick={onClick}
          >
            {props.children}
          </Tooltip>
        ) : (
          props.children
        ),
      [limit, tooltip, onClick]
    )

    return (
      <ListItem
        {...(!!id && { 'aria-roledescription': id })}
        {...(!!invalid && { 'aria-invalid': invalid })}
        className={className}
        ref={ref}
      >
        <Wrapper title={children}>
          <ListItemText
            primary={
              !external ? (
                <NavLink {...link} />
              ) : (
                <a href={to} rel='noreferrer noopener' target='_blank'>
                  {children}
                </a>
              )
            }
          />
        </Wrapper>
      </ListItem>
    )
  }
)

Item.propTypes = {
  active: bool,
  className: string.isRequired,
  external: bool,
  invalid: bool,
  label: node.isRequired,
  limit: number,
  tooltip: bool,
  url: string.isRequired,
}

Item.defaultProps = {
  invalid: false,
  limit: 200,
  tooltip: false,
}

export default withStyle(Item)
