import React from 'react'
import 'date-fns'
import flow from 'lodash/flow'
import get from 'lodash/get'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'

import { DatePicker } from 'components'

import withStyles from '@mui/styles/withStyles'

import { Autosuggest, TextField } from 'components/v2'

import Typography from '@mui/material/Typography'

import { Industries } from './Industries'
import { Responsibilities } from './Responsibilities'

const styles = (theme) => ({
  controls: {
    alignItems: 'center',
  },
  control: {
    display: 'flex',
    justifyContent: 'center',
  },
})

const ExperienceBase = ({
  classes,
  value: experience,
  onChange,
  error,
  outerIndex,
  placeAutosuggestProps,
  locationAutosuggestProps,
  jobTitleTextFieldProps,
  datePickerProps,
  responsibilityTextFieldProps,
  removeExperience,
  label,
  industryLabelProps,
  setIsResetButtonClicked,
  isResetButtonClicked,
}) => (
  <Grid
    container
    direction='row'
    justifyContent='flex-start'
    alignItems='flex-start'
  >
    <Grid item xs={10}>
      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        className={classes.labelWrapper}
        spacing={2}
      >
        <Grid item>
          <Typography variant='h6'>{`${label} #${outerIndex + 1}`}</Typography>
        </Grid>
        <Grid item>
          <IconButton
            onClick={removeExperience}
            p={1.5}
            color='primary'
            id='delete-experience'
            size='large'
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>

    <Grid item xs={10}>
      <Autosuggest
        {...placeAutosuggestProps}
        onChange={(value) => onChange('company', value)}
        onInputChange={(value) => onChange('companyFreeText', value)}
        error={
          get(error, 'company.id.message', '') ||
          get(error, 'company.message', '')
        }
        value={experience.company}
        data-automator={`experience-company-${outerIndex}`}
      />
    </Grid>

    <Grid item xs={10}>
      <Autosuggest
        {...locationAutosuggestProps}
        onChange={(value) => onChange('location', value)}
        onInputChange={(value) => onChange('locationFreeText', value)}
        error={get(error, 'location.id.message', '')}
        value={experience.location}
        data-automator={`experience-location-${outerIndex}`}
      />
    </Grid>

    <Grid item xs={10}>
      <TextField
        {...jobTitleTextFieldProps}
        value={get(experience, 'jobTitle', '')}
        onChange={(value) => onChange('jobTitle', value)}
        error={!!error.jobTitle}
        helperText={get(error, 'jobTitle.message', '')}
        data-automator={`experience-jobTitle-${outerIndex}`}
        inputProps={{ maxLength: 100 }}
        maxLength={100}
      />
    </Grid>

    <Grid container spacing={2}>
      <Grid item>
        <DatePicker
          id={`from${experience.id}`}
          disableFuture
          error={!!error.startDate}
          helperText={get(error, 'startDate.message', '')}
          label={datePickerProps.fromLabel}
          value={experience.startDate || null}
          onChange={(date) => onChange('startDate', date)}
          KeyboardButtonProps={{
            'aria-label': datePickerProps.chooseDateLabel,
          }}
          data-automator={`experience-fromDate-${outerIndex}`}
        />
      </Grid>
      <Grid item>
        <DatePicker
          id={`to${experience.id}`}
          error={!!error.endDate}
          helperText={get(error, 'endDate.message', '')}
          label={datePickerProps.toLabel}
          value={experience.endDate || null}
          onChange={(date) => onChange('endDate', date)}
          KeyboardButtonProps={{
            'aria-label': datePickerProps.chooseDateLabel,
          }}
          minDate={experience.startDate}
          data-automator={`experience-endDate-${outerIndex}`}
        />
      </Grid>
    </Grid>

    <Grid item xs={10}>
      <Industries
        industryLabelProps={industryLabelProps}
        value={experience.industry}
        onChange={onChange}
        error={get(error, 'industry.message', '')}
        data-automator={`experience-industries-${outerIndex}`}
      />
    </Grid>
    <Grid item xs={10}>
      <Responsibilities
        responsibilities={experience.description || ''}
        onChange={onChange}
        error={!!error.description}
        setIsResetButtonClicked={setIsResetButtonClicked}
        isResetButtonClicked={isResetButtonClicked}
        data-automator={`experience-responsibilities-${outerIndex}`}
      />
    </Grid>
  </Grid>
)

export const Experience = flow(withStyles(styles))(ExperienceBase)
