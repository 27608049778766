/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { get, flow, find } from 'lodash'
import withStyles from '@mui/styles/withStyles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Button from '@mui/material/Button'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import { withTranslation } from 'react-i18next'
import { Formik, Field } from 'formik'
import { FormWarnOnLeave } from 'components/FormWarnOnLeave'
import * as Yup from 'yup'
import { generateKey } from 'common/key-generator'
import { LocationAutosuggest } from 'apps/portal/components/LocationAutosuggest'
import Switch from '@mui/material/Switch'
import {
  DASHBOARD_DATES_FORMAT,
  AVAILABILITY_OPTIONS,
} from 'apps/portal/common/constants'
import Slider from '@mui/material/Slider'
import { DatePicker, DropdownSelect } from 'components'
import 'date-fns'
import Tooltip from '@mui/material/Tooltip'
import HelpIcon from '@mui/icons-material/Help'
import { makeEnum } from 'common/enum'
import getTravelPreferenceOptions from 'apps/connect/common/getTravelPreferenceOptions'

import { capitalize } from 'common/utils'
const styles = (theme) => ({
  formControlLabel: {
    fontWeight: theme.typography.fontWeightBold,
    color: `${theme.palette.grey[900]} !important`,
    marginBottom: theme.spacing(1),
  },
  formControl: {
    marginBottom: theme.spacing(5),
  },
  formControlFocusRoles: {
    marginTop: theme.spacing(1),
  },
  availabilityHours: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  isOpenForNewProject: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 0,
  },
  dataPickerSection: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  toolTip: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    cursor: 'pointer',
  },
  jobTitleLabel: {
    zIndex: 0,
  },
})

const OPTIONS = makeEnum(
  'AVAILABILITY_UNDEFINED AVAILABILITY_AVAILABLE AVAILABILITY_NOT_AVAILABLE'
)

class DashboardFormClass extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    profile: PropTypes.object,
    classes: PropTypes.object,
    focusRoles: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.inputLabelFocusRoles = React.createRef()
    this.hoursSteps = Array.from(
      Array(AVAILABILITY_OPTIONS.HOURS_LENGTH),
      (x, i) => ({
        label: (i + 1) * AVAILABILITY_OPTIONS.HOURS_STEP,
        value: (i + 1) * AVAILABILITY_OPTIONS.HOURS_STEP,
      })
    )
    this.state = {
      accessiblityOptions: {
        workPreferences: null,
        workSetups: null,
      },
      accessiblitySelected: {
        workPreferences: this.getKeyValue(this.props.profile.workPreferences),
        workSetups: this.getKeyValue(this.props.profile.workSetups),
      },
    }
  }

  getKeyValue = (data) => {
    return (
      data?.map((item) => {
        return {
          id: typeof item === 'object' ? item.id : item,
          name: capitalize(
            (typeof item === 'object' ? item.name : item).split('_').join(' ')
          ),
        }
      }) || []
    )
  }

  async componentDidMount() {
    const { workPreference: workPreferences, workSetups } =
      await getTravelPreferenceOptions()

    this.setState({
      accessiblityOptions: { workPreferences, workSetups },
    })
  }

  onChangeAvailability = (handleChange) => (setter) => (e, value) => {
    handleChange(e)
    setter(
      'availability.available',
      value
        ? OPTIONS.AVAILABILITY_AVAILABLE
        : OPTIONS.AVAILABILITY_NOT_AVAILABLE
    )
    setter('availability.date', null)
  }

  onChangeFocusRole = (setter) => (e) => {
    const { focusRoles: focusRoleOptions } = this.props

    // find the target object from the focusCategories data
    const focusRoleObj = find(
      get(focusRoleOptions, '[0].roles', []),
      ({ id }) => id === e.target.value
    )

    setter('focusRole', focusRoleObj)
  }

  validationSchema = () => {
    const { t } = this.props
    const { object, string, date, number } = Yup

    return object({
      location: object({
        id: string().required(t('formLocationRequired')),
      }),
      availability: object().shape({
        available: string().required(),
        date: date().typeError(t('form_availability_date_invalid')).nullable(),
        hours: number().required(),
      }),
    })
  }

  renderFocusRoles = (roles) => (
    <React.Fragment>
      {roles.map(({ id, name }) => (
        <option value={id} key={id}>
          {name}
        </option>
      ))}
    </React.Fragment>
  )

  handleDeselect = (setter) => (e, fieldName) => {
    const { value } = e.target
    const { accessiblitySelected } = this.state
    const itemToBeRemoved = Object.values(
      value?.reduce((c, v) => {
        let k = v.name + '-' + v.name
        c[k] = c[k] || []
        c[k].push(v)
        return c
      }, {})
    ).reduce((c, v) => (v.length > 1 ? c.concat(v) : c), [])[0]
    if (itemToBeRemoved) {
      let fieldToBeUpdated = accessiblitySelected[fieldName]?.filter(
        (item) => item.name !== itemToBeRemoved?.name
      )
      this.setState({
        accessiblitySelected: {
          ...accessiblitySelected,
          [fieldName]: fieldToBeUpdated,
        },
      })
      setter(fieldName, fieldToBeUpdated)
    } else {
      this.setState({
        accessiblitySelected: {
          ...accessiblitySelected,
          [fieldName]: e.target.value,
        },
      })
      setter(fieldName, e.target.value)
    }
  }

  handleReset = (cb) => {
    cb()
    this.setState({
      accessiblitySelected: {
        workPreferences: this.getKeyValue(this.props.profile.workPreferences),
        workSetups: this.getKeyValue(this.props.profile.workSetups),
      },
    })
  }
  render() {
    const { classes, profile, t, focusRoles } = this.props
    const checker =
      profile.availability.available === OPTIONS.AVAILABILITY_NOT_AVAILABLE
        ? false
        : true

    return (
      <Formik
        validationSchema={this.validationSchema}
        initialValues={{
          isRemoteOnly: !!profile.isRemoteOnly,
          checker,
          ...profile,
          availability: {
            ...profile.availability,
            date: profile.availability.date || null,
            hours: profile.availability.hours || AVAILABILITY_OPTIONS.HOURS_MAX,
          },
        }}
        onSubmit={this.props.handleSubmit}
      >
        {({
          values,
          dirty,
          errors,
          touched,
          handleChange,
          handleReset,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box mb={5}>
              <Typography variant='body1'>{t('form_welcome_text')}</Typography>
            </Box>
            <FormWarnOnLeave shouldWarn={dirty} />

            {/* availability */}
            <FormControl
              className={`${classes.formControl} ${classes.isOpenForNewProject}`}
            >
              <FormLabel className={classes.formControlLabel}>
                {t('form_availability_label')}
              </FormLabel>
              <Switch
                name='checker'
                checked={values.checker}
                color='primary'
                value={values.checker}
                touched={touched.state}
                onChange={this.onChangeAvailability(handleChange)(
                  setFieldValue
                )}
              />
            </FormControl>
            <FormControl
              className={`${classes.formControl} ${classes.dataPickerSection}`}
            >
              <DatePicker
                name='availability.date'
                label={this.props.t(
                  `form_availability_open_${values.checker ? 'until' : 'from'}`
                )}
                error={!!get(errors, 'availability.date')}
                helperText={get(errors, 'availability.date', '')}
                value={values.availability.date || null}
                format={DASHBOARD_DATES_FORMAT}
                onChange={(date) => setFieldValue('availability.date', date)}
                disablePast={true}
              />
              <Tooltip
                className={classes.toolTip}
                title={
                  values.checker
                    ? this.props.t('form_availability_tooltip_yes')
                    : this.props.t('form_availability_tooltip_no')
                }
              >
                <HelpIcon />
              </Tooltip>
            </FormControl>

            {(values.checker || values.availability.date) && (
              <FormControl fullWidth className={classes.formControl}>
                <FormLabel
                  className={`${classes.formControlLabel} ${classes.availabilityHours}`}
                >
                  {this.props.t('form_availability_hours_label')}
                  <Typography variant='body1'>
                    &nbsp;
                    {values.availability.hours ||
                      AVAILABILITY_OPTIONS.HOURS_MAX}
                  </Typography>
                </FormLabel>
                <Slider
                  name='availability.hours'
                  value={values.availability.hours}
                  defaultValue={
                    values.availability.hours || AVAILABILITY_OPTIONS.HOURS_MAX
                  }
                  getAriaValueText={(value) => value}
                  aria-labelledby='discrete-slider'
                  valueLabelDisplay='off'
                  step={AVAILABILITY_OPTIONS.HOURS_STEP}
                  marks={this.hoursSteps}
                  min={AVAILABILITY_OPTIONS.HOURS_STEP}
                  max={AVAILABILITY_OPTIONS.HOURS_MAX}
                  onChange={(_, value) =>
                    setFieldValue('availability.hours', value)
                  }
                />
              </FormControl>
            )}

            <p>
              <FormLabel className={classes.formControlLabel}>
                {t('form_availability_form_label')}
              </FormLabel>
            </p>

            <FormControl fullWidth variant='outlined'>
              <DropdownSelect
                name='workPreferences'
                isMultiSelect={true}
                formControlVariant='outlined'
                formControlFullWidth={true}
                formControlClassName={classes.formControl}
                inputLabel={t('work_preferences_label')}
                selectOnChange={handleChange}
                handleDeselect={this.handleDeselect(setFieldValue)}
                selectValue={this.state.accessiblitySelected.workPreferences}
                isConnect={false}
                selectOptions={this.state.accessiblityOptions?.workPreferences}
              />
            </FormControl>

            <FormControl
              fullWidth
              variant='outlined'
              style={{ marginTop: '12px' }}
            >
              <DropdownSelect
                name='workSetups'
                isMultiSelect={true}
                formControlVariant='outlined'
                formControlFullWidth={true}
                formControlClassName={classes.formControl}
                inputLabel={t('work_setups__label')}
                selectOnChange={handleChange}
                handleDeselect={this.handleDeselect(setFieldValue)}
                selectValue={this.state.accessiblitySelected.workSetups}
                isConnect={false}
                selectOptions={this.state.accessiblityOptions?.workSetups}
              />
            </FormControl>

            <FormControl fullWidth className={classes.formControl} mt={1}>
              <LocationAutosuggest
                id='city'
                label={t('form_location_city_label')}
                value={values.location}
                onChange={(value) => setFieldValue('location', value, false)}
                error={get(errors, 'location.id', '')}
              />
            </FormControl>

            {/* focus roles */}
            <FormControl fullWidth className={classes.formControl}>
              <FormControl
                variant='outlined'
                htmlFor='form-focus-roles'
                fullWidth
                className={classes.formControlFocusRoles}
              >
                <InputLabel
                  className={classes.jobTitleLabel}
                  ref={this.inputLabelFocusRoles}
                >
                  {t('form_careerinterests_jobtitle_label')}
                </InputLabel>

                <Select
                  label={t('form_careerinterests_jobtitle_label')}
                  native
                  value={values.focusRole.id}
                  inputProps={{
                    name: 'focusRole',
                    id: 'form-focus-roles',
                  }}
                  onChange={this.onChangeFocusRole(setFieldValue)}
                >
                  {focusRoles.map((focusRole) => (
                    <optgroup key={generateKey()} label={focusRole.category}>
                      {this.renderFocusRoles(focusRole.roles)}
                    </optgroup>
                  ))}
                </Select>
              </FormControl>
            </FormControl>

            <FormControl fullWidth className={classes.formControl} mt={1}>
              <p>
                <Field
                  type='checkbox'
                  name='availableForTravel'
                  checked={values.availableForTravel}
                  onChange={handleChange}
                />
                {this.props.t('available_for_travel')}
              </p>
            </FormControl>

            <Box textAlign='right'>
              <Button
                variant='contained'
                type='button'
                disabled={!dirty || isSubmitting}
                onClick={() => this.handleReset(handleReset)}
              >
                {this.props.t('form_button_cancel_label')}
              </Button>
              &nbsp;&nbsp;
              <Button
                variant='contained'
                color='primary'
                type='submit'
                disabled={!dirty || isSubmitting}
              >
                {this.props.t('form_button_save_label')}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    )
  }
}

const DashboardForm = flow(
  withTranslation('dashboard'),
  withStyles(styles)
)(DashboardFormClass)

export { DashboardForm }
