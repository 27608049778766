import {
  Avatar,
  Box,
  Button,
  Chip,
  Container,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
  Link,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import flow from 'lodash/flow'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useParams, withRouter } from 'react-router-dom'

import logo from 'apps/onboarding/assets/el_logo.png'
import available from 'apps/portal/assets/available.svg'
import companyType from 'apps/portal/assets/companyType.svg'
import languagesRequired from 'apps/portal/assets/languagesRequired.svg'
import rate from 'apps/portal/assets/rate.svg'
import workSetupIcon from 'apps/portal/assets/workSetup.svg'
import workType from 'apps/portal/assets/workType.svg'
import { capitalize } from 'common/utils'
import { useViewport } from 'common/view-port-hook'
import { Loading } from 'components'
import { JobsService } from 'sdk/jobs/Service'

import JobDialogs from './components/JobDialogs'
import useStyles from './style'

const CONTRACT_TYPE = {
  Fulltime: 'Part Time',
  parttime: 'Full Time',
  consultant: 'Consultant',
}

const DIALOG_TYPE = {
  REFER_A_FRIEND: 'REFER_A_FRIEND',
  INTERESTED: 'INTERESTED',
  NOT_INTERESTED: 'NOT_INTERESTED',
}

const JobPageBase = (props) => {
  const { t } = props
  const theme = useTheme()
  const classes = useStyles()
  const [jobData, setJobData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const { isDeviceMobile } = useViewport()
  const { jobId, profileId } = useParams()

  const [openFirst, setOpenFirst] = useState(false)
  const [openSecond, setOpenSecond] = useState(false)
  const [currentDialogType, setCurrentDialogType] = useState('')
  const handleFirstOpen = (dialogType) => {
    setOpenFirst(true)
    setCurrentDialogType(dialogType)
  }

  const getJobDetails = async () => {
    try {
      const data = await JobsService.getPublicJobDetails(
        {},
        { path: { id: jobId } }
      )
      setJobData(data.data)
      setIsLoading(false)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getJobDetails()
  }, [])

  const jobDetails = ({
    actualProjectEndDate,
    actualProjectStartDate,
    contractType,
    workSetup,
    languages,
    clientRate,
    annualSalary,
    company: { type },
  }) => {
    const checkMonthsBetweenDates = () => {
      const months = moment(actualProjectEndDate).diff(
        moment(actualProjectStartDate),
        'months'
      )
      if (months === 0) {
        const days = moment(actualProjectEndDate).diff(
          moment(actualProjectStartDate),
          'days'
        )
        return `${days} days`
      }
      return `${months} months`
    }

    return [
      {
        name: t('workType'),
        value: CONTRACT_TYPE[contractType] || contractType,
        logo: workType,
      },
      {
        name: t('availableFrom'),
        value: `${moment(actualProjectStartDate).format(
          'DD.MM.YYYY'
        )} / ${checkMonthsBetweenDates()}`,
        logo: available,
      },
      {
        name: t('preferredWorkSetup'),
        value: workSetup,
        logo: workSetupIcon,
      },
      { name: t('companyType'), value: type, logo: companyType },
      {
        name: t('languagesRequired'),
        value: languages?.join(', '),
        logo: languagesRequired,
      },
      {
        name: t('rate'),
        value: (clientRate || annualSalary) && (
          <>
            €{clientRate || annualSalary}
{' '}
            <span style={{ fontWeight: 300 }}>/hr</span>
          </>
        ),
        logo: rate,
      },
    ]
  }

  const renderSkills = (skills, isPrimary) => (
    <Stack direction='row' flexWrap='wrap' spacing={1}>
      {skills?.map((skill) => (
        <Chip
          label={skill}
          key={skill.toString()}
          color={isPrimary ? 'primary' : 'default'}
          variant={!isPrimary ? 'outlined' : undefined}
          sx={{
            bgcolor: isPrimary
              ? theme.palette.primary.blue
              : theme.palette.newGray,
            fontWeight: 500,
          }}
        />
      ))}
    </Stack>
  )

  const renderClientDetails = ({
    company: { about, corporateCultures, industries },
    domains,
  }) => (
    <>
      <Typography
        variant='h6'
        fontWeight='bold'
        sx={{ mt: { xs: 1, md: 3 }, fontSize: 24, fontWeight: 700 }}
      >
        {t('aboutTheClientLabel')}
      </Typography>
      <Typography component='p' sx={{ mt: 1, opacity: '80%', fontWeight: 500 }}>
        {about}
      </Typography>

      <br />
      {corporateCultures.items?.length > 0 && (
        <>
          <Typography variant='h4' className={classes.subTitle}>
            {t('offering')}
          </Typography>
          <Typography component='p' sx={{ mt: 1, opacity: '80%' }}>
            {corporateCultures.items?.map((item) => item.name).join(', ')}
          </Typography>
          <br />
        </>
      )}
      {industries.items?.length > 0 && (
        <>
          <Typography variant='h4' className={classes.subTitle}>
            {t('industryLabel')}
          </Typography>
          <Typography component='p' sx={{ mt: 1, opacity: '80%' }}>
            {industries.items?.map((item) => item.name).join(', ')}
          </Typography>
          <br />
        </>
      )}

      {domains.items?.length > 0 && (
        <>
          <Typography variant='h4' className={classes.subTitle}>
            {t('projectDomainLabel')}
          </Typography>
          <Typography component='p' sx={{ mt: 1, opacity: '80%' }}>
            {domains.items?.map((item) => item.name).join(', ')}
          </Typography>
        </>
      )}
    </>
  )
  const renderJobDetails = (data) =>
    jobDetails(data)?.map((item) => {
      if (!item.value) {
        return null
      }

      return (
        <ListItem key={item.name.toString()}>
          <ListItemAvatar>
            <Avatar alt={item.name} src={item.logo} variant='square' />
          </ListItemAvatar>
          <ListItemText
            primary={item.name}
            secondary={
              <Typography
                component='span'
                variant='body2'
                sx={{ fontSize: 16 }}
              >
                {item.value}
              </Typography>
            }
          />
        </ListItem>
      )
    })

  const renderMoreInfo = () => (
    <Typography component='span' variant='body1'>
      {t('moreInfo')}
      <Link
        target='_blank'
        color={theme.palette.primary.blue}
        href='https://www.expertlead.com/'
      >
        www.expertlead.com
      </Link>
    </Typography>
  )

  const renderNotInterestedBtn = () => (
    <Button
      variant='outlined'
      className={classes.notInterestedBtn}
      onClick={() => handleFirstOpen(DIALOG_TYPE.NOT_INTERESTED)}
    >
      Not Interested
    </Button>
  )

  return (
    <Loading loading={isLoading}>
      {jobData && (
        <Container maxWidth='lg' sx={{ p: { xs: 0 } }}>
          {!!profileId && (
            <Box>
              <header className={classes.header}>
                <img src={logo} alt='logo' className={classes.elLogo} />
                <Typography
                  variant='h6'
                  sx={{
                    fontSize: 16,
                  }}
                >
                  {t('jobHeading')}
                </Typography>
              </header>
            </Box>
          )}
          <main className={classes.main}>
            <Box
              sx={{
                ...(!profileId && {
                  borderRadius: `16px !important`,
                }),
              }}
              className={classes.wrapper}
            >
              <Box className={classes.container}>
                <Box className={classes.title}>
                  <Typography
                    variant='h4'
                    sx={{
                      fontSize: { xs: 24, md: 32 },
                      fontWeight: 700,
                      lineHeight: { xs: 1.18, md: 1.4 },
                      padding: { xs: 2, md: 0 },
                    }}
                  >
                    {jobData.title}
                  </Typography>

                  <Box
                    sx={{
                      padding: { xs: 2, md: 0 },
                      marginTop: { xs: 0, md: 2 },
                    }}
                  >
                    <Typography
                      variant='h4'
                      className={classes.subTitle}
                      sx={{
                        lineHeight: { xs: 2.25 },
                      }}
                    >
                      {t('requiredTechSkillsLabel')}
                    </Typography>
                    {renderSkills(jobData.mustHaveSkills, true)}
                    <br />
                    <Typography
                      variant='h4'
                      className={classes.subTitle}
                      sx={{
                        lineHeight: { xs: 2.25 },
                      }}
                    >
                      {t('goodToHaveTechSkillsLabel')}
                    </Typography>
                    {renderSkills(jobData.goodToHaveSkills, false)}
                  </Box>
                </Box>
                {isDeviceMobile && (
                  <Box className={classes.sideSection}>
                    {renderJobDetails(jobData)}
                  </Box>
                )}
                <Box className={classes.details}>
                  {renderClientDetails(jobData)}
                  <Typography
                    variant='h6'
                    fontWeight='bold'
                    sx={{ mt: { xs: 1, md: 3 }, fontSize: 24, fontWeight: 700 }}
                  >
                    {t('jobDescriptionLabel')}
                  </Typography>
                  {jobData.description?.split('\n')?.map((text, index) => (
                    <Typography
                      key={index.toString()}
                      component='p'
                      sx={{ mt: 1, opacity: '80%', fontWeight: 500 }}
                    >
                      {text}
                    </Typography>
                  ))}
                </Box>
              </Box>
              {!isDeviceMobile && (
                <Box className={classes.sidebar}>
                  <Box className={classes.sideSection}>
                    {renderJobDetails(jobData)}
                  </Box>
                </Box>
              )}
            </Box>
            {!!profileId && (
              <Box
                className={classes.footerAction}
                sx={{
                  position: 'relative',
                  p: { xs: 3, md: 6 },
                  pr: { md: 0 },
                }}
              >
                {!isDeviceMobile && renderNotInterestedBtn()}
                <Box className={classes.sideButtons}>
                  <Box>
                    {isDeviceMobile && (
                      <>
                        {renderNotInterestedBtn()}
                        <Box mt={5} mb={5}>
                          {renderMoreInfo()}
                        </Box>
                      </>
                    )}
                  </Box>
                  <Box className={classes.sideButtonsDown}>
                    <Button
                      variant='outlined'
                      className={classes.referAFriend}
                      onClick={() =>
                        handleFirstOpen(DIALOG_TYPE.REFER_A_FRIEND)
                      }
                    >
                      {isDeviceMobile
                        ? 'Refer a Friend'
                        : 'Refer a Friend (earn up to $1000)'}
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      style={{
                        textTransform: 'none',
                        backgroundColor: theme.palette.primary.blue,
                      }}
                      onClick={() => handleFirstOpen(DIALOG_TYPE.INTERESTED)}
                    >
                      I'm Interested
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
          </main>
          {!isDeviceMobile && !!profileId && (
            <Box mt={4}>{renderMoreInfo()}</Box>
          )}
          <JobDialogs
            profileId={profileId}
            jobId={jobId}
            currentDialogType={currentDialogType}
            openFirst={openFirst}
            openSecond={openSecond}
            setOpenFirst={setOpenFirst}
            setOpenSecond={setOpenSecond}
          />
        </Container>
      )}
    </Loading>
  )
}

export const JobPage = flow(withTranslation('jobPage'), withRouter)(JobPageBase)
