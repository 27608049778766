import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  profileWSCAssessmentWrapper: {
    backgroundColor: theme.palette.white,
    position: 'relative',
    borderRadius: theme.spacing(1),
    boxShadow: '0px 6.28771px 6.28771px rgba(0, 0, 0, 0.1)',
    marginRight: theme.spacing(1.5),
    marginTop: theme.spacing(-2),
    '& > h6': {
      color: theme.palette.lightGray,
      margin: theme.spacing(2),
      padding: theme.spacing(2, 2, 0),
    },

    '& > div:last-child': {
      paddingBottom: theme.spacing(4),
      marginBottom: theme.spacing(3),
    },
    '& #radarChart svg': {
      transform: `translateX(11%)`,
      overflow: `overlay`,
    },
  },
  radarChartStyle: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeight.normal,
    fontSize: theme.spacing(1.1),
    color: theme.palette.black,
  },
  duration: {
    color: theme.palette.lightGray2,
  },
  spam: {
    fontWeight: theme.typography.fontWeight.strong,
    marginLeft: theme.spacing(0.25),
  },
  toolTip: {
    position: 'absolute',
    display: 'none',
    background: 'grey',
    border: '1px solid white',
    borderRadius: theme.spacing(0.625),
    padding: theme.spacing(0.625),
    color: theme.palette.white,
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeight.normal,
    fontSize: theme.spacing(1.375),
  },
  titleContainer: {
    marginTop: theme.spacing(2),
  },
  chartsLabelStyle: {
    padding: theme.spacing(2),
  },
  titleStyle: {
    margin: theme.spacing(1),
  },
  skillsHelpIcon: {
    color: theme.palette.lightGray6,
  },
}))
const useStylesForLegend = makeStyles((theme) => ({
  legendOuterDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  legendInnerDiv: {
    display: 'inline',
  },
  legendSpan: {
    fontSize: theme.spacing(1.375),
    color: theme.palette.black,
    fontFamily: theme.typography.fontFamily,
    marginRight: theme.spacing(1),
    position: 'relative',
    bottom: theme.spacing(0.375),
  },
}))
const useDynamicStyleForLegend = makeStyles((theme) => ({
  legendLi: {
    color: ({ color }) => color,
    borderRadius: '50%',
    fontSize: theme.spacing(3),
  },
}))

const colors = [
  '#3C26DE',
  '#C22296',
  '#426EE0',
  '#C22248',
  '#28CBEF',
  '#F9D223',
  '#58F0DE',
  '#42E052',
  '#00E2BA',
  '#8F42E0',
  '#52BC77',
  '#FFBF3A',
  '#FF7D3C',
  '#F58C57',
  '#EC5353',
  '#F34F80',
]
export { useStyles, useStylesForLegend, useDynamicStyleForLegend, colors }
