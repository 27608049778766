export const Actions = {
  LOAD_PROFILE: 'LOAD_PROFILE',
  LOAD_ROLES: 'LOAD_ROLES',
  SET_TAX_ID_DIALOGUE_SHOWED: 'SET_TAX_ID_DIALOGUE_SHOWED',
}

export function loadProfile(value) {
  return {
    type: Actions.LOAD_PROFILE,
    value,
  }
}

export function loadRoles(value) {
  return {
    type: Actions.LOAD_ROLES,
    value,
  }
}

export function setIsTaxIdDialogueShowed(value) {
  return {
    type: Actions.SET_TAX_ID_DIALOGUE_SHOWED,
    value,
  }
}
