const makeEnum = (text) => {
  const items = text
    .split(' ')
    .reduce((acc, item) => ({ [item]: item, ...acc }), {})
  return Object.freeze(items)
}

const makeEnumFromArray = (arr) => makeEnum(arr.join(' '))

export { makeEnum, makeEnumFromArray }
