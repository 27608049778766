import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { TextField } from '../TextField'

import useStyles from './styles'

const ChipInput = ({
  id,
  label,
  textFieldProps,
  chipSelectorPrefix,
  value: values = [],
  onChange,
}) => {
  const [input, setInput] = useState('')
  const classes = useStyles()

  return (
    <>
      <TextField
        id={id}
        label={label}
        value={input}
        onChange={(val) => setInput(val)}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter') {
            ev.preventDefault()
            const text = input.trim()
            if (text && !values.includes(text)) {
              onChange([...values, text])
            }
            setInput('')
          }
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...textFieldProps}
      />
      {values.length > 0 && (
        <Box className={classes.chipsContainer}>
          <Grid container direction='row'>
            {values.map((value, index) => (
              <Chip
                className={classes.chip}
                key={value}
                label={value}
                onDelete={() => onChange(values.filter((val) => val !== value))}
                data-automator={`${chipSelectorPrefix}-chips-${index}`}
              />
            ))}
          </Grid>
        </Box>
      )}
    </>
  )
}

ChipInput.defaultProps = {
  chipSelectorPrefix: '',
}

const { string, func, arrayOf, object } = PropTypes

ChipInput.propTypes = {
  id: string.isRequired,
  onChange: func.isRequired,
  chipSelectorPrefix: string,
  label: string.isRequired,
  value: arrayOf(string).isRequired,
  /* eslint-disable react/forbid-prop-types */
  textFieldProps: object.isRequired,
}

export default ChipInput
