import moment from 'moment'
import { array, object, string, date, ref, lazy } from 'yup'

const getCertificationsValidation = ({
  authorityErrorMessage,
  certificationErrorMessage,
  issueDateInvalidErrorMessage,
  issueDateAfterExpDateErrorMessage,
  expDateInvalidErrorMessage,
  expDateBeforeIssueDateErrorMessage,
}) =>
  array().of(
    object({
      authority: lazy((value) => {
        switch (typeof value) {
          case 'object':
            return object()
              .shape({
                id: string().required(authorityErrorMessage),
              })
              .required(authorityErrorMessage)
          default:
            return string().required(authorityErrorMessage)
        }
      }),
      name: string().required(certificationErrorMessage),
      issueDate: date()
        .required(issueDateInvalidErrorMessage)
        .typeError(issueDateInvalidErrorMessage)
        .when('expiryDate', (expiryDate, schema) => {
          if (expiryDate) {
            const expDateParsed = moment(expiryDate)
            if (expDateParsed.isValid() && expDateParsed.isBefore(new Date())) {
              return schema.max(
                ref('expiryDate'),
                issueDateAfterExpDateErrorMessage
              )
            }
          }

          return schema.max(new Date(), issueDateInvalidErrorMessage)
        }),
      expiryDate: date()
        .nullable()
        .min(ref('issueDate'), expDateBeforeIssueDateErrorMessage)
        .typeError(expDateInvalidErrorMessage)
        .default(null),
    })
  )

export { getCertificationsValidation }
