/* eslint-disable*/
import React from 'react'
import { withStyles } from '@mui/styles'
import { withRouter, Redirect } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import isEmpty from 'lodash/isEmpty'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import { withTranslation } from 'react-i18next'
import { withProfile } from 'hocs/withProfile'
import Box from '@mui/material/Box'
import Layout from 'components/Layout'
import { capitalize } from 'common/utils'
import { CommunicationForm } from './components/CommunicationForm'
import { PaymentForm } from './components/PaymentForm'
import { Preferences } from './components/Preferences'
import { PAGES } from 'apps/portal/common/constants'
import { AuthenticatedNavBar } from '../../components'
import { ProfileService } from 'sdk/profile/Service'
import { Loading } from 'components'
import flow from 'lodash/flow'
import { Switch, Route, NavLink } from 'react-router-dom'
import { withNotifications } from 'hocs'
import { red } from '@mui/material/colors'

const styles = (theme) => ({
  title: {
    marginBottom: theme.spacing(3),
  },
  link: {
    textDecoration: 'none',
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
  },
  activeLink: {
    color: '#3C26DE',
  },
  error: {
    color: red[500],
  },
  container: {
    width: '90%',
  },
})

const communicationSections = {
  email: 'TYPE_EMAIL',
  mobile: 'TYPE_MOBILE',
  website: 'TYPE_WEBSITE',
}

class AccountComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      error: null,
      loading: true,
    }
  }

  async componentDidMount() {
    const { data } = await ProfileService.getProfile()
    const communications = ['email', 'website', 'mobile'].reduce(
      (obj, key) => ({
        ...obj,
        [key]: getCommunicationSection(key, data).value,
      }),
      {}
    )

    this.props.dispatchers.loadProfile({
      ...data,
      ...communications,
      taxLocation: data.locationVat.name,
    })
  }

  static getDerivedStateFromProps(props, state) {
    if (!isEmpty(props.profile)) {
      return {
        loading: false,
      }
    }
    return null
  }

  onSubmit = async (values, { setSubmitting, setErrors, resetForm }) => {
    const callOpts = {
      path: {
        id: values.id,
      },
    }
    let response = {}
    const {
      fail,
      location: { pathname },
      t,
    } = this.props
    try {
      response = await ProfileService.updateProfile(values, callOpts)
      this.props.dispatchers.loadProfile(response.data)
      resetForm({ values: response.data })
    } catch (err) {
      setErrors({ vat: this.props.t('invalidTaxId') })
      if (pathname.split('/')[2] !== 'payment') {
        fail(t('invalidTaxIdWarning'))
      }
      console.error(err)
    } finally {
      this.setState({ loading: false })
      setSubmitting(false)
    }
  }

  getSection = (path) => path.substring(path.lastIndexOf('/') + 1)

  render() {
    const { props, state } = this
    const { classes, t, location, profile } = props
    const currentSection = this.getSection(location.pathname)

    return (
      <Loading loading={state.loading}>
        <Layout
          navbarComponent={AuthenticatedNavBar}
          currentPage={PAGES.ACCOUNT}
        >
          <Grid
            container
            spacing={4}
            direction='row'
            justifyContent='space-evenly'
            alignItems='flex-start'
          >
            <Grid item xs={12} sm={3} md={3} lg={2} xl={2}>
              <List component='nav' aria-label='Account sections'>
                <ListItem>
                  <NavLink
                    className={classes.link}
                    activeClassName={classes.activeLink}
                    to={`/${PAGES.ACCOUNT_PAYMENT}`}
                  >
                    <ListItemText primary={t('payment')} />
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink
                    className={classes.link}
                    activeClassName={classes.activeLink}
                    to={`/${PAGES.ACCOUNT_COMMUNICATION}`}
                  >
                    <ListItemText primary={t('communication')} />
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink
                    className={classes.link}
                    activeClassName={classes.activeLink}
                    to={`/${PAGES.ACCOUNT_PREFERENCES}`}
                  >
                    <ListItemText primary={t('preferences')} />
                  </NavLink>
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={9}>
              <Box p={3}>
                <Grid
                  container
                  direction='column'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                  spacing={10}
                >
                  <Grid item xs={12} className={classes.container}>
                    <Typography variant='h5' m={1} className={classes.title}>
                      <strong>{t('account')} / </strong>{' '}
                      {capitalize(currentSection)}
                    </Typography>
                    {state.error && (
                      <Typography className={classes.error}>
                        Error: {state.error}
                      </Typography>
                    )}
                    <Switch>
                      <Redirect
                        from={`/${PAGES.ACCOUNT}`}
                        exact
                        to={`/${PAGES.ACCOUNT_PAYMENT}`}
                      />
                      <Route path={`/${PAGES.ACCOUNT_PAYMENT}`}>
                        <PaymentForm
                          profile={profile}
                          onSubmit={this.onSubmit}
                        />
                      </Route>
                      <Route path={`/${PAGES.ACCOUNT_COMMUNICATION}`}>
                        <CommunicationForm
                          profile={profile}
                          onSubmit={this.onSubmit}
                        />
                      </Route>
                      <Route path={`/${PAGES.ACCOUNT_PREFERENCES}`}>
                        <Preferences />
                      </Route>
                    </Switch>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Layout>
      </Loading>
    )
  }
}

function getCommunicationSection(sectionName, profileData) {
  return (
    (Array.isArray(profileData.communications) &&
      profileData.communications.find(
        (x) => x.type === communicationSections[sectionName]
      )) || { value: '' }
  )
}

const Account = flow(
  withTranslation('navbar'),
  withStyles(styles),
  withNotifications,
  withRouter,
  withProfile
)(AccountComponent)

export { Account }
