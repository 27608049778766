/* eslint-disable */
import React, { Fragment } from 'react'
import { capitalize } from 'common/utils'
import { TopSkills } from './TopSkills'
import { RegularSkills } from './RegularSkills'
import FormLabel from '@mui/material/FormLabel'
import { TOP_SKILLS_LIMIT } from 'apps/portal/common/constants'
import { uniqueId, isEmpty, get } from 'lodash'
import update from 'immutability-helper'
import Grid from '@mui/material/Grid'

const Skills = ({
  value: skills = [],
  onChange,
  skillsAutosuggest,
  skillsDatePicker,
  topSkillsLabel,
  regularSkillsLabel,
  error,
  isOnlyRegularSkills,
  regularSkillsWidth,
}) => {
  const topSkillsLimit = isOnlyRegularSkills ? 0 : TOP_SKILLS_LIMIT
  const addSkill = (setter) => (skills) => (index) => (skill) => {
    if (isEmpty(skill)) return
    const topSkills = skills.slice(0, topSkillsLimit)
    const emptyTopSkillIndex = topSkills.findIndex(
      (o) => o.skill && !Object.keys(o.skill).length
    )
    const actualIndex = isEmpty(skills[0]) ? 0 : index
    const currentIndex =
      emptyTopSkillIndex === -1 ? actualIndex : emptyTopSkillIndex
    const addedSkill = {
      id: `regularSkill_${uniqueId()}`,
      skill,
      order: currentIndex,
    }

    const updatedSkills = update(skills, {
      [currentIndex]: { $set: addedSkill },
    })

    setter(updatedSkills)
  }

  const addTopSkill = (setter) => (skills) => () => {
    const blankSkill = {
      skill: {},
      id: `topSkill_${uniqueId()}`,
      order: skills.length + 1,
    }

    setter([...skills, blankSkill])
  }

  const editSkill = (setter) => (skills) => (index) => (field) => (value) => {
    const editedSkills = update(skills, {
      [index]: { $merge: { [field]: value } },
    })
    setter(editedSkills)
  }

  const removeSkill = (setter) => (skills) => (index) => (e) => {
    const filteredSkills = update(skills, {
      $splice: [[index, 1]],
    })

    setter(filteredSkills)
  }

  const skillIds = (skills = []) =>
    skills.map((skill) => get(skill, 'skill.id') || get(skill, 'skill.dbId'))
  const topSkills = skills.slice(0, topSkillsLimit)
  const regularSkills = skills.slice(topSkillsLimit)
  const setter = (skills) => onChange(skills)
  const onFind = (find) => find(skills)
  const onSort = (sort) => setter(sort(skills))
  const skillProps = {
    skillIds: skillIds(skills),
    onFind,
    onSort,
    error,
    topSkills,
    skills,
    regularSkills,
    setter,
    addSkill: addSkill(setter)(skills),
    addTopSkill: addTopSkill(setter)(skills),
    editSkill: editSkill(setter)(skills),
    removeSkill: removeSkill(setter)(skills),
    onChange,
    skillsAutosuggestProps: skillsAutosuggest,
    skillsDatePickerProps: skillsDatePicker,
    topSkillsLabel,
    regularSkillsLabel,
    isOnlyRegularSkills,
  }

  return (
    <Fragment>
      {!isOnlyRegularSkills && <TopSkills {...skillProps} />}
      <Grid md={regularSkillsWidth || 12}>
        <RegularSkills {...skillProps} />
      </Grid>
      {!!error && typeof error === 'string' && (
        <FormLabel error={true}>{capitalize(error)}</FormLabel>
      )}
    </Fragment>
  )
}

export { Skills }
