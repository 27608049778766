import moment from 'moment'
import tzLookup from 'tz-lookup'

import {
  SERVICES_DATES_FORMAT,
  SERVICES_TIMES_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
} from 'apps/portal/common/constants'
import { pluralize } from 'common/utils'

const isValid = (value) => /^\d{2}[./-]\d{2}[./-]\d{4}$/.test(value)
const format = (date) => {
  if (date) {
    return moment(date).format(SERVICES_DATES_FORMAT) + SERVICES_TIMES_FORMAT
  }
  return null
}

const formatDate = ({ date, format = DATE_FORMAT }) =>
  moment(date).format(format)

const formatDateISO = (date, format = 'YYYY-MM-DD') => {
  const str = moment(date)
  return str.utc().format(format)
}

const formatFromNow = (date) => moment.unix(date).fromNow()

const formatUnixDateTime = (date, format = DATETIME_FORMAT) =>
  moment.unix(date).format(format)

function humanizeDuration(startDate, endDate = new Date()) {
  const data = moment.duration(
    Date.parse(startDate) - Date.parse(endDate)
  )._data
  const result = []
  const years = Math.abs(data.years)
  const months = Math.abs(data.months)
  const startYear = moment(startDate).format('YYYY')
  const endYear = moment().isSame(moment(endDate), 'day')
    ? 'Present'
    : moment(endDate).format('YYYY')

  if (years > 0) {
    result.push(`${years} ${pluralize('year')(years)}`)
  }
  if (months > 0) {
    result.push(`${months} ${pluralize('month')(months)}`)
  }
  return {
    year: `${startYear} - ${endYear}`,
    duration: result.join(' '),
  }
}

function durationInYears(startDate, endDate = new Date()) {
  const data = moment.duration(
    Date.parse(startDate) - Date.parse(endDate)
  )._data
  const years = Math.abs(data.years)
  const months = Math.abs(data.months)

  return {
    years,
    months,
  }
}

const getTimeZoneByLatLong = (lat, long) => tzLookup(lat, long)

export {
  format,
  isValid,
  humanizeDuration,
  formatFromNow,
  formatUnixDateTime,
  formatDate,
  formatDateISO,
  getTimeZoneByLatLong,
  durationInYears,
}
