import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { flow } from 'lodash'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { withTranslation } from 'react-i18next'

import UploadIcon from 'assets/img/upload_icon.svg'
import { setFormData } from 'common/upload'
import { ProfileService } from 'sdk/profile/Service'

const CvUploadBase = ({ onChange, t }) => {
  const [fileDisplayName, setFileDisplayName] = useState('')

  const handleFileFieldChange = async (event) => {
    const file = event.target.files[0]
    const { name: fileNameTemp } = event.target.files[0]

    const bucket = process.env.REACT_APP_PROFILES_CV_BUCKET_NAME
    try {
      const {
        data: { url, name, html },
      } = await ProfileService.getUploadURL({ mimeType: file.type, bucket })
      const formData = setFormData({ ...html, file })

      await ProfileService.uploadFile({ url, formData })
      onChange({ bucket, name })
      setFileDisplayName(fileNameTemp)
    } catch (error) {
      setFileDisplayName('')
    }
  }

  return (
    <>
      <Box
        sx={{
          flexBasis: '50%',
          border: 0,
          borderBottom: 1,
          borderColor: 'secondary.blue',
          pb: 0.5,
        }}
      >
        <Button
          variant='text'
          component='label'
          sx={{
            textTransform: 'capitalize',
            width: '100%',
            pl: 0,
            justifyContent: 'flex-start',
          }}
          style={{
            display: 'flex',
            alignItem: 'baseline',
            justifyContent: 'space-between',
          }}
        >
          Upload CV
          <input
            style={{ width: '1px', opacity: 0 }}
            accept='application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            type='file'
            onChange={handleFileFieldChange}
          />
          <div>
            <Typography variant='caption' sx={{ mx: 2, opacity: 0.6 }}>
              {t('cvUploadText')}
            </Typography>
            <img src={UploadIcon} alt='Upload' height='22px' width='22px' />
          </div>
        </Button>
      </Box>
      {fileDisplayName && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {fileDisplayName}
        </Box>
      )}
    </>
  )
}

CvUploadBase.propTypes = {
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export const CvUpload = flow(withTranslation('createProfile'))(CvUploadBase)
