import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import withStyles from '@mui/styles/withStyles'
import update from 'immutability-helper'
import flow from 'lodash/flow'
import get from 'lodash/get'
import uniqueId from 'lodash/uniqueId'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'

import { TIME_TO_SCROLL_MS } from 'apps/portal/common/constants'

import { Proficiency } from '../components/Proficiency'

import { LanguageAutosuggest } from './LanguageAutosuggest'

const styles = (theme) => ({
  form: {
    width: '100%',
  },
  actions: {
    paddingTop: theme.spacing(2),
    textAlign: 'right',
  },
  notLastItem: {
    marginRight: theme.spacing(6),
  },
})

class SpokenLanguagesComponent extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    handleChange: PropTypes.func,
    errors: PropTypes.array,
  }

  editLanguage = (setter, languages, index) => (field) => (value) => {
    const editedLanguages = update(languages, {
      [index]: { $merge: { [field]: value } },
    })

    setter('spokenLanguages', editedLanguages)
  }

  removeLanguage = (setter, index, languages) => () => {
    const filteredLanguages = languages.filter(
      (_, innerIndex) => index !== innerIndex
    )

    setter('spokenLanguages', filteredLanguages)
  }

  addNewLanguage = (setter, languages) => () => {
    setter('spokenLanguages', [
      ...languages,
      {
        proficiency: '',
        language: {
          name: '',
        },
        id: `id_${uniqueId()}`,
      },
    ])

    setTimeout(() => {
      typeof window !== 'undefined' &&
        window.scrollTo(0, document.body.clientHeight)
    }, TIME_TO_SCROLL_MS)
  }

  renderAddButton(onChange, languages) {
    return (
      <IconButton
        id='add-language'
        onClick={this.addNewLanguage(onChange, languages)}
        size='large'
      >
        <AddIcon />
      </IconButton>
    )
  }

  render() {
    const { languages = [], classes, onChange, t, errors } = this.props
    const addButton = this.renderAddButton(onChange, languages)
    const languageIds = languages.map((spokenLang) =>
      get(spokenLang, 'language.id')
    )

    return (
      <>
        {languages.map((lang, index) => {
          const editField = this.editLanguage(onChange, languages, index)
          const isLast = index === languages.length - 1

          return (
            <React.Fragment key={lang.id}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={5}>
                  <LanguageAutosuggest
                    label={t('language_name_helper')}
                    onChange={editField('language')}
                    languageIds={languageIds}
                    value={lang.language}
                    error={get(errors, `[${index}].language.id`, '')}
                  />
                </Grid>
                <Grid item xs={10} sm={8} md={4} lg={4}>
                  <Proficiency
                    label={t('language_proficiency_helper')}
                    onChange={editField('proficiency')}
                    value={lang.proficiency}
                    error={get(errors, `[${index}].proficiency`, '')}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={4} lg={3}>
                  <Box component='div' className={classes.actions}>
                    <IconButton
                      {...(!isLast && { className: classes.notLastItem })}
                      onClick={this.removeLanguage(onChange, index, languages)}
                      size='large'
                    >
                      <DeleteIcon />
                    </IconButton>
                    {isLast && addButton}
                  </Box>
                </Grid>
              </Grid>
            </React.Fragment>
          )
        })}
        {languages.length === 0 && (
          <Box
            className={classes.actions}
            component='div'
            display='flex'
            justifyContent='flex-end'
          >
            {addButton}
          </Box>
        )}
      </>
    )
  }
}

const SpokenLanguages = flow(
  withStyles(styles),
  withTranslation('profileEdit')
)(SpokenLanguagesComponent)

export { SpokenLanguages }
