import {
  arrayOf,
  node,
  number,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types'
import React, { useCallback } from 'react'
import { TextField, Box } from '@mui/material'

import { useScore } from './hooks'
import Score from './score'
import withStyle from './style'

export const Feedback = ({ className, ...props }) => {
  const { form, scores, comments } = useScore(props)
  const renderScore = useCallback(
    (score) => <Score key={score.value} {...score} />,
    []
  )

  return (
    <form className={className} {...form}>
      <fieldset>
        <legend>
          Choose an option that describes candidate&apos;s knowledge:
        </legend>
        <div aria-roledescription='score'>{scores.map(renderScore)}</div>
        {!!comments && (
          <div aria-roledescription='comments'>
            <TextField
              label='Comment'
              variant='outlined'
              rows={5}
              multiline
              inputProps={{
                ref: props.textref,
              }}
              {...comments}
            />
            <Box component='div' display='inline' className='small'>
              press{' '}
              <span className='key'>
                <code>Tab</code>
              </span>{' '}
              to comment /{' '}
              <span className='key'>
                <code>0-9</code>
              </span>{' '}
              to score /{' '}
              <span className='key'>
                <code>Enter</code>
              </span>{' '}
              to save & next
            </Box>
          </div>
        )}
      </fieldset>
    </form>
  )
}

Feedback.propTypes = {
  className: string.isRequired,
  scores: arrayOf(
    shape({
      label: node.isRequired,
      value: oneOfType([oneOf([null]).isRequired, number.isRequired]),
      tooltip: string.isRequired,
    }).isRequired
  ),
}

Feedback.defaultProps = {
  scores: [
    { label: 'Off', value: null, tooltip: '' },
    {
      label: 'Wrong',
      value: 1,
      tooltip: 'The candidate did not give an answer at all',
    },
    {
      label: 'Wrong',
      value: 2,
      tooltip: 'The candidate tried to answer but it was wrong',
    },
    {
      label: 'Poor',
      value: 3,
      tooltip:
        'The candidate had a general idea of the question, but didn’t have an answer',
    },
    {
      label: 'Poor',
      value: 4,
      tooltip:
        'The candidate had a general idea of the question, and had only a little answer',
    },
    {
      label: 'Ok',
      value: 5,
      tooltip:
        'The candidate had an idea of the question, and could give a short answer about it, some things were not correct',
    },
    {
      label: 'Ok',
      value: 6,
      tooltip:
        'The candidate had an idea of the question, and could give a short answer about it, everything said was correct',
    },
    {
      label: 'Good',
      value: 7,
      tooltip: 'The candidate could give a decent answer to the question',
    },
    {
      label: 'Good',
      value: 8,
      tooltip:
        'The candidate could give a decent answer to the question and make some examples',
    },
    {
      label: 'Great',
      value: 9,
      tooltip:
        'The candidate could give a great answer to the question and make some examples',
    },
    {
      label: 'Great',
      value: 10,
      tooltip:
        'The candidate could give a profound answer to the question, make some examples, and deeply know everything',
    },
  ],
}

export default withStyle(Feedback)
