/* eslint-disable*/
import React, { memo } from 'react'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { placeStatusApproved } from 'apps/portal/common/place-status'
import { Divider } from '@mui/material'
import { buildImageURL } from 'common/image'
import Grid from '@mui/material/Grid'
import defaultImage from 'assets/img/Experience.svg'
import { humanizeDuration } from 'common/dates'
import Box from '@mui/material/Box'
import { useViewport } from 'common/view-port-hook'

const useStyles = makeStyles((theme) => {
  const educationsWrapper = {
    backgroundColor: theme.palette.white,
    position: 'relative',
    borderRadius: theme.spacing(1),
    boxShadow: `${theme.spacing(0)} ${theme.spacing(0.786)} ${theme.spacing(
      0.786
    )} rgba(0, 0, 0, 0.1)`,
  }
  const img = {
    boxShadow: `${theme.spacing(0)} ${theme.spacing(0.5)} ${theme.spacing(
      0.5
    )} rgba(0, 0, 0, 0.25)`,
    borderRadius: theme.spacing(2),
    objectFit: 'contain',
    maxWidth: theme.spacing(10),
    maxheight: theme.spacing(10),
    minWidth: theme.spacing(10),
    minHeight: theme.spacing(10),
  }
  const title = {
    fontWeight: theme.typography.fontWeight.strong,
  }
  return {
    educationLabel: {
      padding: theme.spacing(2),
    },
    duration: {
      color: theme.palette.lightGray2,
    },
    timeSpan: {
      fontWeight: theme.typography.fontWeight.strong,
      marginLeft: theme.spacing(0.25),
    },
    [theme.breakpoints.up('md')]: {
      educationsWrapper: {
        ...educationsWrapper,
        marginTop: theme.spacing(3),
        marginRight: theme.spacing(1.5),

        '& > h6': {
          color: theme.palette.lightGray,
          margin: theme.spacing(2),
          padding: theme.spacing(2, 2, 0),
        },

        '& > div:last-child': {
          paddingBottom: theme.spacing(4),
        },
      },
      education: {
        padding: theme.spacing(2, 2, 0),
        margin: theme.spacing(2, 0),
        position: 'relative',
        '& img': {
          ...img,
        },

        '& div:nth-child(2)': {
          paddingLeft: theme.spacing(2),
        },

        '& ul': {
          marginLeft: theme.spacing(-1),
        },

        '& span': {
          '&:after': {
            content: "'' !important",
          },
        },

        '& p': {
          position: 'relative',
          padding: 0,
          margin: 0,
          '& .ParagraphComponent-displayableText-28': {
            '& p': {
              marginLeft: theme.spacing(2),
            },

            '&:after': {
              content: "'' !important",
            },
          },
          '& a ': {
            position: 'absolute',
            bottom: '0px',
            right: '0px',
            textDecoration: 'none',
            backgroundColor: theme.palette.white,
            paddingLeft: theme.spacing(2),
            '&:after': {
              content: '...',
            },
          },
        },
        '& hr': {
          marginTop: theme.spacing(2),
        },
      },
      title: {
        ...title,
      },
    },
    [theme.breakpoints.down('md')]: {
      educationsWrapper: {
        ...educationsWrapper,
        '& .MuiTypography-body2': {
          color: theme.palette.lightGray2,
          fontWeight: theme.typography.fontWeight.strong,
        },
        '& > div:last-child': {
          paddingBottom: theme.spacing(1),
        },
      },
      education: {
        padding: theme.spacing(2, 2, 0),
        position: 'relative',
        '& .MuiTypography-subtitle1': {
          color: theme.palette.lightGray2,
          fontWeight: theme.typography.fontWeight.normal,
        },
        '& img': {
          ...img,
        },

        '& span': {
          '&:after': {
            content: "'' !important",
          },
        },

        '& p': {
          position: 'relative',
          padding: 0,
          margin: 0,
          '& .ParagraphComponent-displayableText-28': {
            '& p': {
              marginLeft: theme.spacing(2),
            },

            '&:after': {
              content: "'' !important",
            },
          },
          '& a ': {
            position: 'absolute',
            bottom: '0px',
            right: '0px',
            textDecoration: 'none',
            backgroundColor: theme.palette.white,
            paddingLeft: theme.spacing(2),
            '&:after': {
              content: '...',
            },
          },
        },
      },
      title: {
        marginTop: theme.spacing(2),
        ...title,
      },
      dividerContainer: {
        '& hr': {
          marginTop: theme.spacing(2),
        },
      },
    },
  }
})

const addDefaultImage = (e) => {
  e.target.src = defaultImage
}

const Education = memo(({ education, idx, classes, noOfEdus }) => {
  const { year, duration } = humanizeDuration(
    education.startDate,
    education.endDate
  )
  return (
    <Grid
      item
      container
      justifyContent='flex-end'
      className={classes.education}
      xs={12}
      key={idx}
      id={`public-profile-education-${idx}`}
    >
      <Grid
        container
        item
        xs={12}
        lg={1.7}
        md={1.7}
        alignItems='baseline'
        justifyContent='flex-start'
        id={`edu_${idx}`}
      >
        {education.school.logo.bucket ? (
          <img
            src={buildImageURL(education.school.logo)}
            alt={education.school.name}
            onError={addDefaultImage}
          />
        ) : (
          <img src={`${defaultImage}`} alt={education.school.name} />
        )}
      </Grid>
      <Grid item xs={12} md={10.3}>
        <Typography variant='h6' id='title' className={classes.title}>
          {education.degree}
        </Typography>
        <Typography variant='subtitle1' id='companyName'>
          {education.school.name}
        </Typography>
        <Typography variant='subtitle1'>
          <Box
            fontWeight='fontWeightRegular'
            className={classes.duration}
            id='duration'
          >
            {year}
            {duration && (
              <span className={classes.timeSpan}> | {duration}</span>
            )}
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} lg={12} className={classes.dividerContainer}>
        {idx === noOfEdus - 1 ? '' : <Divider />}
      </Grid>
    </Grid>
  )
})

function Educations(props) {
  const { educations, translate } = props
  const classes = useStyles()
  const activeEducations =
    educations &&
    educations.filter((education) =>
      placeStatusApproved(education.school.status)
    )
  const noOfEdus = (activeEducations && activeEducations.length) || 0
  const { isDeviceMobile } = useViewport()
  const EducationHeaderLabel = () =>
    isDeviceMobile ? (
      <EducationHeaderLabelForMobile />
    ) : (
      <EducationHeaderLabelForDesktop />
    )
  const EducationHeaderLabelForMobile = () => (
    <Typography variant='body2'>{translate('education')}</Typography>
  )
  const EducationHeaderLabelForDesktop = () => (
    <Typography variant='h6'>{translate('education')}</Typography>
  )
  return (
    <>
      {activeEducations && activeEducations.length > 0 && (
        <Grid
          item
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          xs={11}
          md={11.8}
          lg={11.8}
          className={classes.educationsWrapper}
        >
          <Grid item xs={11} className={classes.educationLabel}>
            <EducationHeaderLabel />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {activeEducations.map((education, idx) => (
            <Education
              education={education}
              classes={classes}
              idx={idx}
              noOfEdus={noOfEdus}
              key={`${education.degree}_${idx}`}
            />
          ))}
        </Grid>
      )}
    </>
  )
}

export default Educations
