import { useCallback } from 'react'

import { useFilesystem } from 'apps/scorecard/hooks/clients'

export default () => {
  const { post } = useFilesystem()
  const API = useCallback(
    ({ data: { file, ...data }, url }) => post(url, { ...data, file }),
    [post]
  )

  return API
}
