import { Tooltip } from '@mui/material'

const StyledTooltip = (props) => (
  <Tooltip
    componentsProps={{
      tooltip: {
        sx: {
          backgroundColor: '#2b244f',
          padding: '10px',
          lineHeight: '18px',

          '& .MuiTooltip-arrow': {
            color: '#2b244f',
          },
        },
      },
    }}
    {...props}
  />
)

export default StyledTooltip
