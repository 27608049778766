import { Box, Typography } from '@mui/material'

import { getScoreColor } from 'apps/scorecard-v2/helpers'

import QuestionItem from './question-item'
import { StyledBox } from './style'

const Questions = ({
  title,
  questions,
  handleSave,
  id,
  sectionScores,
  toggleTabKeyDisable,
}) => {
  let sectionAnsweredCount = 0
  const totalScore = Object.values(sectionScores).reduce((acc, curr) => {
    acc += curr

    if (curr > 0) {
      sectionAnsweredCount += 1
    }

    return acc
  }, 0)

  const sectionTotalScore = totalScore
    ? (totalScore / sectionAnsweredCount).toFixed(1)
    : totalScore

  return (
    <Box mb={2} mt={3}>
      <StyledBox mb={2} padding='1px 20px 0 8px'>
        <Typography fontWeight='800' paddingX={1}>
          {title}
        </Typography>
        <Typography
          paddingRight={1}
          color={getScoreColor(sectionTotalScore)}
          fontWeight='bold'
        >
          {sectionTotalScore || 'n/a'}
        </Typography>
      </StyledBox>
      {questions.map((question) => (
        <QuestionItem
          key={question.id}
          question={question}
          handleSave={handleSave}
          parentId={id}
          toggleTabKeyDisable={toggleTabKeyDisable}
        />
      ))}
    </Box>
  )
}

export default Questions
