import update from 'immutability-helper'

export const swap = (collection, { from, to }) => {
  const clone = [...collection]
  const temp = clone[to]

  clone[to] = clone[from]
  clone[from] = temp

  return clone
}

export const insertAndShift = (collection, { from, to }) => {
  const tmpFrom = collection[from]

  return update(collection, {
    $splice: [
      [from, 1],
      [to, 0, tmpFrom],
    ],
  })
}
