import { TextField } from '@mui/material'
import { useController } from 'react-hook-form'

export const renderTextField = ({
  name,
  label,
  control,
  defaultValue,
  rules,
  errors,
  style,
  ...rest
}) => {
  const {
    field: { ref, onChange, value, onBlur },
  } = useController({ name, control, defaultValue, rules })

  return (
    <TextField
      fullWidth
      sx={style}
      label={label}
      margin='dense'
      inputRef={ref}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={errors && errors[name]}
      helperText={errors && errors[name]?.message}
      {...rest}
    />
  )
}
