import AddBoxIcon from '@mui/icons-material/AddBox'
import ClearIcon from '@mui/icons-material/Clear'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import { useEffect } from 'react'
import { useFieldArray, Controller } from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
  addBtn: {
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: 'none!important',
    textDecoration: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    '& p': {
      marginLeft: theme.spacing(0.5),
    },
  },
}))

const DynamicTextField = ({
  variant,
  control,
  placeholder,
  name,
  buttonLabel,
  maxFields = 1,
}) => {
  const classes = useStyles()
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  })

  const handleListAdd = () => {
    if (fields.length <= maxFields) append({ value: '' })
  }

  const handleRemoveItem = (index) => {
    if (index !== 0) {
      remove(index)
    }
  }
  useEffect(() => {
    fields.push({ value: '' })
  }, [])
  return (
    <>
      {fields.map((field, index) => (
        <Controller
          control={control}
          name={`${name}[${index}].value`}
          defaultValue={field.value}
          key={field.id}
          as={
            <TextField
              sx={{ my: 2 }}
              placeholder={placeholder}
              variant={variant}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      edge='end'
                      color='primary'
                      onClick={() => handleRemoveItem(index)}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          }
        />
      ))}
      <button type='button' className={classes.addBtn} onClick={handleListAdd}>
        <AddBoxIcon color='primary' />
        <Typography variant='body1' color='primary.blue'>
          {buttonLabel}
        </Typography>
        &nbsp;
      </button>
    </>
  )
}

export default DynamicTextField
