import castArray from 'lodash/castArray'
import update from 'immutability-helper'

export const append = (stack, [name, entry]) => {
  stack.append(name, ...castArray(entry))

  return stack
}

export const intercept = (settings) =>
  update(settings, { data: { $apply: serialize } })

export const serialize = (current) =>
  Object.entries(current).reduce(append, new FormData())
