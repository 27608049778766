import { string } from 'prop-types'
import React from 'react'
import { CropOriginal as Reset, ZoomIn, ZoomOut } from '@mui/icons-material'

import { Button } from 'apps/scorecard/components/widgets'

import withStyle from './style'

export const Controls = ({ className, resetTransform, zoomIn, zoomOut }) => (
  <div className={className}>
    <Button onClick={zoomOut}>
      <ZoomOut />
    </Button>
    <Button onClick={resetTransform}>
      <Reset />
    </Button>
    <Button onClick={zoomIn}>
      <ZoomIn />
    </Button>
  </div>
)

Controls.propTypes = {
  className: string.isRequired,
}

Controls.defaultProps = {}

export default withStyle(Controls)
