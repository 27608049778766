import { useCallback } from 'react'

import { useExpertlead } from 'apps/scorecard/hooks/clients'

export default () => {
  const { post } = useExpertlead()
  const API = useCallback(
    (payload) => post(`/question/${payload.questionId}/feedback`, payload),
    [post]
  )

  return API
}
