/* eslint-disable */
import React from 'react'
import { withTranslation } from 'react-i18next'
import { buildImageURL } from 'common/image'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { humanizeDuration } from 'common/dates'
import defaultImage from 'assets/img/Experience.svg'
import { placeStatusApproved } from '../../../common/place-status'
import ReactMarkdown from 'react-markdown'

class ExperienceItem extends React.Component {
  getCompanyName = (status, name) =>
    placeStatusApproved(status)
      ? name
      : `${name} (${this.props.t('place_under_review')})`

  addDefaultImage = (e) => {
    e.target.src = defaultImage
  }

  render() {
    const { classes, jobTitle, company, startDate, endDate, description } =
      this.props
    const { year, duration } = humanizeDuration(startDate, endDate)
    return (
      <Grid
        container
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
      >
        <Grid item>
          <Grid item>
            {company.logo.bucket ? (
              <img
                className={classes.companyLogo}
                src={buildImageURL(company.logo)}
                alt={company.name}
                onError={this.addDefaultImage}
              />
            ) : (
              <img
                className={classes.companyLogo}
                src={`${defaultImage}`}
                alt={company.name}
              />
            )}
          </Grid>
          <Grid item>
            <Typography variant='body1'>
              {this.getCompanyName(company.status, company.name)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='h6'>{jobTitle}</Typography>
          </Grid>
          <Grid item>
            <Typography variant='subtitle2'>
              <Box fontWeight='fontWeightLight' className={classes.duration}>
                {year} | {duration}
              </Box>
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={7}>
          <ReactMarkdown className={classes.description}>
            {description}
          </ReactMarkdown>
        </Grid>
      </Grid>
    )
  }
}

const Experience = withTranslation('profileExperience')(ExperienceItem)
export { Experience }
