import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoIcon from '@mui/icons-material/Info'
import {
  Typography,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
} from '@mui/material'

import { useTracking } from 'apps/scorecard/hooks'

import { StyledAccordion } from './style'

const tracking = useTracking()
const eventTracking = (label, action = 'Click') => {
  tracking.event({
    category: 'Scorecard-v2',
    action,
    label: `${action}_${label}`,
  })
}

const FeedbackHint = () => (
  <StyledAccordion>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls='panel2a-content'
      id='panel2a-header'
      sx={{
        background: '#f1f3f6',
        borderRadius: '5px',
        minHeight: '30px',
        height: '40px',
        '& p': {
          padding: '5px',
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
        },
        '& span': {
          fontWeight: '500',
        },
        '&.Mui-expanded': { minHeight: '40px', borderRadius: '5px 5px 0  0' },
        mt: '20px',
      }}
      onClick={() => eventTracking('Hint_Info')}
    >
      <p>
        <InfoIcon />
        Struggling with writing a feedback?
        <span>Check these hints</span>
      </p>
    </AccordionSummary>
    <AccordionDetails
      sx={{
        backgroundColor: '#f1f3f6',
        '& > p': {
          padding: '9px',
        },
      }}
    >
      <Typography>
        This might help interviewers that struggle with giving feedback, but
        please feel free to use your own words - your personal feedback is at
        most importance for us
      </Typography>
      <Typography fontWeight='700'>Q&A Strengths:</Typography>
      <List>
        <ListItem>
          The candidate has very strong analytical and problem solving skills
        </ListItem>
        <ListItem>
          The candidate could prove [his/her] strong understanding of (language)
          and demonstrated [his/her] expertise in (XXX).
        </ListItem>
        <ListItem>
          The candidate has demonstrated a strong knowledge of XXX.
        </ListItem>
        <ListItem>
          The candidate did not demonstrate any obvious strengths in the Q&A
          session of the [BE, FE. etc] interview and lack knowledge in most
          relevant topics for this position.{' '}
        </ListItem>
      </List>
      <Typography fontWeight='700'>Q&A Weaknesses:</Typography>
      <List>
        <ListItem>There is room for improvement in XXX.</ListItem>
        <ListItem>
          Especially in questions about (XXX) and (XXX) [he/she] performed
          exceptionally poorly.
        </ListItem>
        <ListItem>
          The candidate did not demonstrate any obvious weaknesses in the Q&A
          session of the [BE, FE. etc] interview and holds all the relevant
          knowledge for this position.
        </ListItem>
      </List>
      <Typography fontWeight='700'>
        Live Coding Strengths & Weaknesses:{' '}
      </Typography>
      <Typography>
        Please feel free to use below mentioned questions as a guideline to
        write your feedback about the Live Coding part.
      </Typography>
      <List>
        <ListItem>
          Does the candidate understand the problem? How was his/her
          communication?
        </ListItem>
        <ListItem>
          Did he/she need many hints? Use of proper terminology?
        </ListItem>
        <ListItem>Appropriate amount of test coverage?</ListItem>
        <ListItem>
          How was the provided code? (well structured and clean or neither clean
          nor concise)
        </ListItem>
        <ListItem>Was it finished on time? Did the solution work?</ListItem>
      </List>
      <Typography fontWeight='700'>Additional comments</Typography>
      <List>
        <ListItem>
          The candidate could be a good fit for a [junior, mid, senior] level
          position
        </ListItem>
        <ListItem>
          The candidate could be a good fit for another position with [Node.js,
          etc.] programming language as its main focus.
        </ListItem>
      </List>
    </AccordionDetails>
  </StyledAccordion>
)

export default FeedbackHint
