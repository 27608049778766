import { FLOATING_PATTERN } from './constants'

export const getCSSTransformValuesFrom = ({ style: { transform } }) =>
  transform.match(FLOATING_PATTERN).map(Number)

export const promisify = (canvas) =>
  new Promise((resolve) =>
    canvas.toBlob(
      (blob) => resolve(Object.assign(blob, { lastModifiedDate: new Date() })),
      'image/png',
      1
    )
  )
