import { arrayOf, bool, elementType, oneOfType, string } from 'prop-types'
import React, { createElement, useCallback } from 'react'
import { Route as DOMRoute } from 'react-router-dom'

export const Route = ({ path, component, exact, ...proxy }) => {
  const render = useCallback(
    (props) => createElement(component, { ...props, ...proxy }),
    [component, proxy]
  )

  return <DOMRoute path={path} render={render} exact={exact} />
}

Route.propTypes = {
  path: oneOfType([string.isRequired, arrayOf(string.isRequired).isRequired])
    .isRequired,
  component: elementType.isRequired,
  exact: bool,
}

Route.defaultProps = {
  exact: false,
}

export default Route
