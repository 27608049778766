import CallOutlinedIcon from '@mui/icons-material/CallOutlined'
import DateRangeIcon from '@mui/icons-material/DateRange'
import React, { useState } from 'react'
import { PopupModal, useCalendlyEventListener } from 'react-calendly'
import { useTranslation } from 'react-i18next'

import interestedCall from 'apps/portal/assets/interestedCall.svg'
import interestedJob from 'apps/portal/assets/interestedJob.svg'
import {
  CALENDLY_LINK,
  JOB_APPROACH_RESPONSE,
} from 'apps/portal/common/constants'
import NotInterestedForm from 'apps/portal/containers/jobPage/components/NotInterestedForm'
import ReferralForm from 'apps/portal/containers/jobPage/components/ReferralForm'
import { DialogBox } from 'components/DialogBox'
import { useNotifications } from 'hooks'
import { JobsService } from 'sdk/jobs/Service'

import useStyles from '../style'

const JobDialogs = ({
  openFirst,
  openSecond,
  currentDialogType,
  setOpenSecond,
  setOpenFirst,
  profileId,
  jobId,
}) => {
  const { t } = useTranslation('jobPage')
  const classes = useStyles()
  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false)
  const { showSnackbar, VARIANTS } = useNotifications()

  const handleDialogClose = () => {
    setOpenFirst(false)
    setOpenSecond(false)
  }

  useCalendlyEventListener({
    onEventScheduled: (e) => updateCandidateInterestResponse(false),
  })

  const showFailedNotification = () =>
    showSnackbar(t('failedMessage'), VARIANTS.ERROR)
  const updateCandidateInterestResponse = async (isCallMeNowSelected) => {
    try {
      await JobsService.updateCandidateApproachResponse(
        {
          response: JOB_APPROACH_RESPONSE.APPROACH_RESPONSE_INTERESTED,
          candidateId: profileId,
          callMeNow: isCallMeNowSelected,
          jobId,
        },
        { path: { jobId } }
      )
      if (isCallMeNowSelected) {
        setOpenFirst(false)
        setOpenSecond(true)
      } else {
        setOpenSecond(false)
      }
    } catch (err) {
      setOpenFirst(false)
      showFailedNotification()
      console.error(err)
    }
  }
  const dialogContent = {
    INTERESTED: {
      FIRST_DIALOG: {
        icon: interestedJob,
        title: t('interestedTitle'),
        description: t('interestedDescription'),
        leftBtnLabel: null,
        // TODO : Removing the schedule Call option until we decide on the requirements for this.
        // (
        //   <>
        //     // {/* <DateRangeIcon sx={{ width: 18, height: 18, mr: 0.5 }} />
        //     // {t('scheduleCall')} */}
        //   </>
        // ),
        rightBtnLabel: (
          <>
            <CallOutlinedIcon sx={{ width: 18, mr: 0.5 }} /> {t('callNow')}
          </>
        ),
        leftBtnVariant: 'contained',
        rightBtnVariant: 'outlined',
        handleLeftBtn: () => {
          setIsCalendlyOpen(true)
          setOpenFirst(false)
        },
        handleRightBtn: (reason) => {
          if (reason && reason === 'backdropClick') {
            setOpenFirst(false)
          } else {
            updateCandidateInterestResponse(true)
          }
        },
        classWrapper: classes.dialogWrapper,
        classActions: classes.classActions,
      },
      SECOND_DIALOG: {
        icon: interestedCall,
        title: t('interestedSecondTitle'),
        description: t('interestedSecondDescription'),
        classWrapper: classes.dialogWrapper,
        classActions: classes.classActions,
        hideActionButtons: true,
      },
    },
    NOT_INTERESTED: {
      FIRST_DIALOG: {
        title: t('notInterestedTitle'),
        description: (
          <NotInterestedForm
            setOpenFirst={setOpenFirst}
            setOpenSecond={setOpenSecond}
            profileId={profileId}
            jobId={jobId}
            JobsService={JobsService}
            showFailedNotification={showFailedNotification}
          />
        ),
        classWrapper: classes.dialogWrapperNotInterested,
        classActions: classes.dialogActionNotInterested,
        hideActionButtons: true,
      },
      SECOND_DIALOG: {
        icon: interestedJob,
        title: t('notInterestedSecondTitle'),
        description: t('notInterestedSecondDescription'),
        classWrapper: classes.dialogWrapper,
        classActions: classes.classActions,
        hideActionButtons: true,
      },
    },
    REFER_A_FRIEND: {
      FIRST_DIALOG: {
        title: t('referAFriendTitle'),
        description: (
          <ReferralForm
            setOpenFirst={setOpenFirst}
            setOpenSecond={setOpenSecond}
            profileId={profileId}
            jobId={jobId}
            JobsService={JobsService}
            showFailedNotification={showFailedNotification}
          />
        ),
        classWrapper: classes.dialogWrapperReferAFriend,
        classActions: classes.dialogActionNotInterested,
        hideActionButtons: true,
      },
      SECOND_DIALOG: {
        icon: interestedJob,
        title: t('referAFriendSecondTitle'),
        description: t('referAFriendSecondDescription'),
        classWrapper: classes.dialogWrapper,
        classActions: classes.classActions,
        hideActionButtons: true,
      },
    },
  }

  const currentDialogContent =
    currentDialogType && dialogContent[currentDialogType]
  return (
    <div>
      {currentDialogType && (
        <>
          <DialogBox
            dialogTitleStyle={classes.dialogTitleStyle}
            classWrapper={currentDialogContent.FIRST_DIALOG.classWrapper}
            classAction={currentDialogContent.FIRST_DIALOG.classActions}
            open={openFirst}
            hideActionButtons={
              currentDialogContent.FIRST_DIALOG.hideActionButtons
            }
            icon={currentDialogContent.FIRST_DIALOG.icon}
            title={currentDialogContent.FIRST_DIALOG.title}
            description={currentDialogContent.FIRST_DIALOG.description}
            acceptBtn={currentDialogContent.FIRST_DIALOG.leftBtnLabel}
            declineBtn={currentDialogContent.FIRST_DIALOG.rightBtnLabel}
            handleClickAccept={currentDialogContent.FIRST_DIALOG.handleLeftBtn}
            handleClickDecline={
              currentDialogContent.FIRST_DIALOG.handleRightBtn
            }
            leftBtnVariant={currentDialogContent.FIRST_DIALOG.leftBtnVariant}
            rightBtnVariant={currentDialogContent.FIRST_DIALOG.rightBtnVariant}
          />
          <DialogBox
            dialogTitleStyle={classes.secondDialogTitleStyle}
            handleDialogClose={handleDialogClose}
            classWrapper={currentDialogContent.SECOND_DIALOG.classWrapper}
            classAction={currentDialogContent.SECOND_DIALOG.classActions}
            open={openSecond}
            icon={currentDialogContent.SECOND_DIALOG.icon}
            title={currentDialogContent.SECOND_DIALOG.title}
            description={currentDialogContent.SECOND_DIALOG.description}
            acceptBtn={currentDialogContent.SECOND_DIALOG.leftBtnLabel}
            declineBtn={currentDialogContent.SECOND_DIALOG.rightBtnLabel}
            handleClickAccept={() => setOpenSecond(false)}
            handleClickDecline={() => setOpenSecond(false)}
            hideActionButtons={
              currentDialogContent.SECOND_DIALOG.hideActionButtons
            }
          />
          <PopupModal
            url={CALENDLY_LINK}
            onModalClose={() => setIsCalendlyOpen(false)}
            open={isCalendlyOpen}
            rootElement={document.getElementById('root')}
          />
        </>
      )}
    </div>
  )
}

export default JobDialogs
