import { bool, number, string } from 'prop-types'
import React, { forwardRef, useMemo } from 'react'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Button } from 'apps/scorecard/components/widgets'
import { useMarkup } from './hooks'
import withStyle from './style'
import QuestionFeedback from '../question-feedback'

export const Markup = forwardRef(
  ({ children, className, questionId, ...props }, ref) => {
    const {
      dangerouslySetInnerHTML,
      expandable,
      expanded,
      toggle,
      setFeedback,
    } = useMarkup(props)

    const action = useMemo(
      () => `Show ${expanded ? 'less' : 'more'}`,
      [expanded]
    )

    return (
      <div className={className} ref={ref}>
        <div
          aria-roledescription='markup'
          dangerouslySetInnerHTML={dangerouslySetInnerHTML}
        />
        <>
          {expanded && children}
          {expandable && (
            <nav>
              <Button
                endIcon={expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                onClick={toggle}
              >
                {action}
              </Button>
            </nav>
          )}
        </>

        {expanded && questionId && (
          <QuestionFeedback setFeedback={setFeedback} questionId={questionId} />
        )}
      </div>
    )
  }
)

Markup.propTypes = {
  className: string.isRequired,
  expandable: bool,
  limit: number,
  source: string.isRequired,
}

Markup.defaultProps = {
  expandable: false,
  limit: 0,
}

export default withStyle(Markup)
