import { ORGANIZATION_IDS } from 'common/constants'
import { getConfigDirectoryNameFromHostname } from 'hocs/whitelabel'

import { config as expertleadConfig } from './expertlead/config'
import { config as futurePathConfig } from './futurepath/config'

export const getConfigFromHostname = (hostname) => async () => {
  const dirName = getConfigDirectoryNameFromHostname(hostname)
  const { config } = await import(`apps/portal/config/${dirName}/config.js`)
  return config
}

export const getConfigByOrganizationId = (orgId) =>
  ({
    [ORGANIZATION_IDS.EXPERTLEAD]: expertleadConfig,
    [ORGANIZATION_IDS.JV]: futurePathConfig,
  }[orgId] || expertleadConfig)
