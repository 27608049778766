import { string } from 'prop-types'
import React from 'react'

import { useParagraphs } from './hooks'

export const renderParagraph = (paragraph, key) => <p key={key}>{paragraph}</p>

export const Paragraphs = ({ children }) => {
  const paragraphs = useParagraphs(children)

  return paragraphs.map(renderParagraph)
}

Paragraphs.propTypes = {
  children: string.isRequired,
}

Paragraphs.defaultProps = {}

export default Paragraphs
