import styled from 'styled-components'

export default (component) => styled(component)`
  .MuiCircularProgress-root {
    animation: none;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`
