import { object, string, array, lazy } from 'yup'

const getSpokenLanguagesValidation = ({
  spokenLanguageNameError,
  spokenLanguageProficiencyError,
}) =>
  array().of(
    object().shape(
      {
        language: lazy((_, { path }) => {
          const group = path.match(/\[(\d+)\]/)[1]
          const index = Number(group)

          if (index === 0) {
            return object().when('proficiency', {
              is: (proficiency) => proficiency && proficiency !== '',
              then: object().shape({
                id: string().required(spokenLanguageNameError),
                name: string().required(spokenLanguageNameError),
              }),
            })
          }
          return object().shape({
            id: string().required(spokenLanguageNameError),
            name: string().required(spokenLanguageNameError),
          })
        }),
        proficiency: lazy((_, { path }) => {
          const group = path.match(/\[(\d+)\]/)[1]
          const index = Number(group)

          if (index === 0) {
            return string().when('language', {
              is: (language) => language && language?.name !== '',
              then: string().required(spokenLanguageProficiencyError),
            })
          }
          return string().required(spokenLanguageProficiencyError)
        }),
      },
      ['language', 'proficiency']
    )
  )

export { getSpokenLanguagesValidation }
