import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  align-items: center;
  margin-top: 10px;
  .MuiButtonBase-root.MuiIconButton-root {
    margin-top: 0;
    padding: 2px;
  }

  .MuiDivider-vertical {
    height: 20px;
    margin: auto 0 auto 8px;
  }
`

export const FeedbackIcons = styled.div`
  gap: 10px;
  margin-left: 5px;
  display: flex;
`
