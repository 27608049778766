import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import flow from 'lodash/flow'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { Route, Switch, Redirect } from 'react-router-dom'

import { PAGES } from 'apps/portal/common/constants'

import { QUESTIONS } from './questions'

const styles = (theme) => ({
  question: {
    fontWeight: theme.typography.fontWeight.strong,
    fontSize: theme.typography.subSectionHeader.fontSize,
    marginBottom: theme.spacing(1),
  },
  subSection: {
    marginBottom: theme.spacing(5),
  },
  subSectionTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: theme.typography.fontWeight.bold,
  },
  questionBox: {
    marginBottom: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
})
class FaqSectionsComponent extends React.Component {
  renderSubSectionTitle(title) {
    const {
      props: { classes },
    } = this
    return (
      <Typography variant='h6' className={classes.subSectionTitle}>
        {title}
      </Typography>
    )
  }

  renderSubSectionAnswer(answer) {
    return <Typography gutterBottom>{answer}</Typography>
  }

  renderSections(subSections) {
    const {
      props: { classes },
    } = this
    return (subSections || []).map((subSection) => (
      <Box key={subSection.title} className={classes.subSection}>
        {this.renderSubSectionTitle(subSection.title)}

        {(subSection.questions || []).map((question) => (
          <Box key={question.q} className={classes.questionBox}>
            <Typography className={classes.question} variant='body1'>
              {question.q}
            </Typography>
            {this.renderSubSectionAnswer(question.a)}
          </Box>
        ))}
      </Box>
    ))
  }

  render() {
    const { t, title, classes } = this.props

    return (
      <Box p={2}>
        <Grid
          container
          direction='column'
          justifyContent='space-between'
          alignItems='stretch'
        >
          <Grid item xs={12} className={classes.title}>
            <Typography variant='h5'>
              <strong> {t('faq')} / </strong> {title}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Switch>
              <Redirect
                from={`/${PAGES.FAQ}`}
                exact
                to={`/${PAGES.FAQ_PERSONAL}`}
              />
              <Route path={`/${PAGES.FAQ_PERSONAL}`}>
                {this.renderSections(QUESTIONS[PAGES.FAQ_PERSONAL])}
              </Route>
              <Route path={`/${PAGES.FAQ_PROJECT}`}>
                {this.renderSections(QUESTIONS[PAGES.FAQ_PROJECT])}

                {this.renderSubSectionTitle(
                  'expertlead Contract & Terms and Conditions'
                )}
                {this.renderSubSectionAnswer(
                  <a
                    href='https://expertlead-general-files.s3.amazonaws.com/20200101%20AGB%20FL%20ENG.pdf'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    T&amp;Cs
                  </a>
                )}
              </Route>
              <Route path={`/${PAGES.FAQ_COMMUNITY}`}>
                {this.renderSections(QUESTIONS[PAGES.FAQ_COMMUNITY])}
              </Route>
            </Switch>
          </Grid>
        </Grid>
      </Box>
    )
  }
}

const FaqSections = flow(
  withTranslation('faq'),
  withStyles(styles)
)(FaqSectionsComponent)

export { FaqSections }
