import { customArray } from 'country-codes-list'
import { startsWith } from 'lodash'
import { string } from 'yup'

const validCountryCode = (label, errorMessage) => {
  const countryCodes = customArray(
    { name: '{countryCode}', value: '+{countryCallingCode}' },
    {}
  )
  return string().test(label, errorMessage, (inputNumber) => {
    const result = countryCodes.some(
      (countryCode) =>
        startsWith(inputNumber, countryCode.value) ||
        startsWith(countryCode.value, inputNumber)
    )
    return result
  })
}

export { validCountryCode }
