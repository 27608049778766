import { ProfileOutgoing } from 'sdk/profile/ProfileOutgoing'
import { ProfileService } from 'sdk/profile/Service'

function onCancel() {
  this.setState({ clickCancel: true }, () => {
    this.props.onCancel()
  })
}

async function onSubmit(formData, { resetForm }, fail, t) {
  const { profile, onSuccess } = this.props
  const callOpts = {
    path: {
      id: profile.id,
    },
  }

  try {
    const response = await ProfileService.updateProfile(
      ProfileOutgoing({ ...profile, ...formData }),
      callOpts
    )
    resetForm(formData)
    onSuccess(response)
  } catch (err) {
    if (err.response.data.message.includes('vat-invalid')) {
      fail(t('vatError'))
    }
    console.error(err)
  } finally {
    this.setState({
      loading: false,
    })
  }
}

export { onCancel, onSubmit }
