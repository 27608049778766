import {
  FEEDBACK,
  GENERIC_QUESTIONS,
  LANGUAGES_AND_FRAMEWORKS,
  LIVE_CODING,
} from 'apps/scorecard-v2/constants'

import { Feedback } from './tabs/feedback'
import { GenericQuestions } from './tabs/generic-questions'
import { Languages } from './tabs/languages'
import { LiveCoding } from './tabs/live-coding'

const ActiveTab = ({ currentTab, ...props }) => {
  switch (currentTab) {
    case LIVE_CODING:
      return <LiveCoding {...props} />
    case GENERIC_QUESTIONS:
      return <GenericQuestions {...props} />
    case LANGUAGES_AND_FRAMEWORKS:
      return <Languages {...props} />
    case FEEDBACK:
      return <Feedback {...props} />
    default:
      return null
  }
}

export default ActiveTab
