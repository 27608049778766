import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import update from 'immutability-helper'
import { isEmpty, get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import { Autosuggest } from 'components/v2'

import useStyles from './styles'

const MultiSuggest = ({
  id,
  value: options = [],
  onChange,
  chipSelectorPrefix,
  label,
  service,
  buildLabel,
  buildValue,
  transformResponse,
}) => {
  const classes = useStyles()
  const selectedIds = options.map((op) => get(op, 'id'))

  const getOptionDisabled = ({ value }) => selectedIds.includes(value.id)

  const onSelectOption = (option) => {
    if (isEmpty(option)) return

    onChange(
      update(options, {
        $push: [option],
      })
    )
  }

  const onRemove = (_id) => onChange(options.filter((op) => op.id !== _id))

  const renderItem = (option, index) => (
    <Chip
      className={classes.chip}
      key={option.id}
      label={buildLabel(option)}
      onDelete={() => onRemove(option.id)}
      data-automator={`${chipSelectorPrefix}-chips-${index}`}
    />
  )

  return (
    <>
      <Autosuggest
        id={id}
        onChange={onSelectOption}
        getOptionDisabled={getOptionDisabled}
        buildLabel={buildLabel}
        label={label}
        service={async (input) => {
          if (!input) {
            return []
          }
          const response = await service(input)
          const formattedList = response.map((x) => ({
            label: buildLabel(x),
            value: buildValue ? buildValue(x) : x,
          }))

          return transformResponse
            ? transformResponse(formattedList)
            : formattedList
        }}
        clearOnBlur
        clearAfterSelection
        async
        disableClearable
      />
      {options.length > 0 && (
        <Box className={classes.chipsContainer}>
          <Grid container direction='row'>
            {options.map(renderItem)}
          </Grid>
        </Box>
      )}
    </>
  )
}

MultiSuggest.defaultProps = {
  chipSelectorPrefix: '',
  buildValue: null,
  transformResponse: null,
}

const { string, func, array } = PropTypes

MultiSuggest.propTypes = {
  id: string.isRequired,
  onChange: func.isRequired,
  chipSelectorPrefix: string,
  label: string.isRequired,
  service: func.isRequired,
  buildLabel: func.isRequired,
  buildValue: func,
  transformResponse: func,
  /* eslint-disable react/forbid-prop-types */
  value: array.isRequired,
}

export default MultiSuggest
