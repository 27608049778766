import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { Prompt } from 'react-router'

const initBeforeUnLoad = (shouldWarn) => {
  if (shouldWarn) {
    window.onbeforeunload = (event) => {
      const e = event || window.event
      e.preventDefault()
      if (e) {
        e.returnValue = ''
      }
      return ''
    }
  } else {
    window.onbeforeunload = null
  }
}
class FormWarnOnLeaveComponent extends React.Component {
  static propTypes = {
    shouldWarn: PropTypes.bool.isRequired,
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      initBeforeUnLoad(this.props.shouldWarn)
    }
  }

  componentDidUpdate() {
    if (typeof window !== 'undefined') {
      initBeforeUnLoad(this.props.shouldWarn)
    }
  }

  componentWillUnmount = () => {
    if (typeof window !== 'undefined') window.onbeforeunload = null
  }

  render() {
    return (
      <Prompt
        when={this.props.shouldWarn}
        message={this.props.t('message_confirmation')}
      />
    )
  }
}

const FormWarnOnLeave = withTranslation('formOnWarn')(FormWarnOnLeaveComponent)
export { FormWarnOnLeave }
