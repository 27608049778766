import React from 'react'

import { Button } from 'apps/scorecard/components/widgets'

export const Outlined = (props) => (
  <Button color='primary' variant='outlined' {...props} />
)

Outlined.propTypes = {}

Outlined.defaultProps = {}

export default Outlined
