/* eslint-disable */
import React from 'react'
import flow from 'lodash/flow'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'

import withDnD from 'hocs/DnD'
import withStyles from '@mui/styles/withStyles'
import { SKILLS_LIMIT, TOP_SKILLS_LIMIT } from 'common/constants'
import uniqueId from 'lodash/uniqueId'
import { Autosuggest } from 'components/v2'

const styles = (theme) => ({
  skillChip: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    cursor: 'move',
  },
})

const RegularSkillsBase = ({
  skills,
  regularSkills,
  addSkill,
  removeSkill,
  skillIds,
  onFind,
  onSort,
  createDnD,
  regularSkillsLabel,
  skillsAutosuggestProps,
  classes,
  isOnlyRegularSkills,
}) => {
  const renderItem = (profileSkill, index) => {
    return (
      <Chip
        key={profileSkill.id}
        className={classes.skillChip}
        label={profileSkill.skill.name}
        id={`regular-skill-${index}`}
        onDelete={removeSkill(
          isOnlyRegularSkills ? index : TOP_SKILLS_LIMIT + index
        )}
        data-automator={`regular-skills-chips-${index}`}
      />
    )
  }

  const { ref, connect } = createDnD({ onFind, onSort })
  const { buildRegularSkillsLabel } = skillsAutosuggestProps
  const getOptionDisabled = ({ value: { id } }) => skillIds.includes(id)

  return (
    <>
      {!isOnlyRegularSkills && (
        <Typography variant='h6'>{regularSkillsLabel}</Typography>
      )}

      <Autosuggest
        {...skillsAutosuggestProps}
        label={buildRegularSkillsLabel(
          SKILLS_LIMIT - (isOnlyRegularSkills ? 0 : TOP_SKILLS_LIMIT)
        )}
        onChange={addSkill(skills.length)}
        skillIds={skillIds}
        getOptionDisabled={getOptionDisabled}
        clearOnBlur={true}
        id='regular-skill'
        data-automator={`regular-skills-auto-suggest`}
        disableClearable
      />

      <Box m={1} ref={ref}>
        <Grid container direction='row'>
          {regularSkills.map(connect(renderItem))}
        </Grid>
      </Box>
    </>
  )
}

export const RegularSkills = flow(
  withStyles(styles),
  withDnD({ type: 'regularSkills' })
)(RegularSkillsBase)
