import React from 'react'
import { Typography, CardContent } from '@mui/material'

const Bsd2 = () => (
  <CardContent>
    <ul>
      <li>
        <Typography gutterBottom>
          Redistribution and use in source and binary forms, with or without modification, are permitted provided that the
          following conditions are met:
        </Typography>
      </li>

      <li>
        <Typography gutterBottom>
          Redistributions of source code must retain the above copyright notice, this list of conditions and the following
          disclaimer.
        </Typography>
      </li>

      <li>
        <Typography gutterBottom>
          Redistributions in binary form must reproduce the above copyright notice, this list of conditions and the
          following disclaimer in the documentation and/or other materials provided with the distribution.
        </Typography>
      </li>
    </ul>

    <Typography>
      THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS "AS IS" AND ANY EXPRESS OR IMPLIED WARRANTIES,
      INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE ARE
      DISCLAIMED. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR CONTRIBUTORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
      SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR
      SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY,
      WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE
      USE OF THIS SOFTWARE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
    </Typography>
  </CardContent>
)

export { Bsd2 }
