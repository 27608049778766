import React from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider as Theming } from 'styled-components'
import { SnackbarProvider as Notifications } from 'notistack'

import { Global } from './contexts'
import theme from './theme'
import { App, Tracking } from './components'
import store from '../scorecard-v2/store'
import scorecardContext from '../scorecard-v2/context'

const Scorecard = (props) => (
  <Global.Provider value={props}>
    <Tracking>
      <Theming theme={theme}>
        <Notifications>
          <Provider store={store} context={scorecardContext}>
            <App />
          </Provider>
        </Notifications>
      </Theming>
    </Tracking>
  </Global.Provider>
)

Scorecard.propTypes = {}

Scorecard.defaultProps = {}

export default Scorecard
