import 'date-fns'
import DeleteIcon from '@mui/icons-material/Delete'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import flow from 'lodash/flow'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React from 'react'

import { Autosuggest } from '../Autosuggest'
import { DatePicker } from '../DatePicker'
import { TextField } from '../TextField'

const styles = (theme) => ({
  wrapper: {
    marginBottom: theme.spacing(3),
  },
  control: {
    display: 'flex',
    justifyContent: 'center',
  },
  labelWrapper: {
    marginBottom: theme.spacing(-3),
  },
})

const EducationBase = ({
  classes,
  value: education = {},
  error,
  onChange,
  placeAutosuggestProps,
  locationAutosuggestProps,
  degreeTextFieldProps,
  datePickerProps,
  removeEducation,
  label,
  outerIndex,
}) => (
  <Grid
    container
    direction='row'
    justifyContent='flex-start'
    alignItems='flex-start'
    className={classes.wrapper}
  >
    <Grid item xs={12}>
      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        className={classes.labelWrapper}
        spacing={2}
      >
        <Grid item>
          <Typography variant='h6'>{`${label} #${outerIndex + 1}`}</Typography>
        </Grid>
        <Grid item>
          <IconButton
            onClick={removeEducation(education)}
            p={1.5}
            color='primary'
            id='delete-education'
            size='large'
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Autosuggest
            {...placeAutosuggestProps}
            onChange={(value) => onChange('school', value)}
            onInputChange={(value) => onChange('schoolFreeText', value)}
            error={get(error, 'school.id.message', '')}
            value={education.school}
            data-automator={`education-school-${outerIndex}`}
          />
        </Grid>
        <Grid item xs={6}>
          <Autosuggest
            {...locationAutosuggestProps}
            onChange={(value) => onChange('location', value)}
            onInputChange={(value) => onChange('locationFreeText', value)}
            error={get(error, 'location.id.message', '')}
            value={education.location}
            data-automator={`education-location-${outerIndex}`}
          />
        </Grid>
      </Grid>
      <Grid item xs={8}>
        <TextField
          {...degreeTextFieldProps}
          value={get(education, 'degree', '')}
          onChange={(value) => onChange('degree', value)}
          error={!!error.degree}
          helperText={get(error, 'degree.message', '')}
          data-automator={`education-degree-${outerIndex}`}
          inputProps={{ maxLength: 100 }}
          maxLength={100}
        />
      </Grid>
      <Grid container spacing={2}>
        <Grid item>
          <DatePicker
            id={`from${education.id}`}
            disableFuture
            error={get(error, 'startDate.message', '')}
            helperText={get(error, 'startDate.message', '')}
            label={datePickerProps.fromLabel}
            value={education.startDate || null}
            onChange={(date) => onChange('startDate', date)}
            KeyboardButtonProps={{
              'aria-label': datePickerProps.chooseDateLabel,
            }}
            data-automator={`education-startDate-${outerIndex}`}
          />
        </Grid>
        <Grid item>
          <DatePicker
            id={`to${education.id}`}
            error={!!error.endDate}
            helperText={get(error, 'endDate.message', '')}
            label={datePickerProps.toLabel}
            value={education.endDate || null}
            onChange={(date) => onChange('endDate', date)}
            KeyboardButtonProps={{
              'aria-label': datePickerProps.chooseDateLabel,
            }}
            minDate={education.startDate}
            data-automator={`education-endDate-${outerIndex}`}
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
)

EducationBase.propTypes = {
  value: PropTypes.object,
  classes: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  outerIndex: PropTypes.number,
  placeAutosuggestProps: PropTypes.object,
  locationAutosuggestProps: PropTypes.object,
  degreeTextFieldProps: PropTypes.object,
  datePickerProps: PropTypes.object,
  removeEducation: PropTypes.func,
  label: PropTypes.string,
}

export const Education = flow(withStyles(styles))(EducationBase)
