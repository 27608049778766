import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Box } from '@mui/material'

import { StyledHeader, StyledText } from './style'

const TabHeader = ({ tabScores, sectionTitles }) => {
  const answeredCount = Object.entries(tabScores).reduce(
    (acc, [id, answers], index) => {
      const section = Object.values(answers)
      const count = section.reduce((totalCount, score) => {
        if (score > 0) {
          totalCount += 1
        }
        return totalCount
      }, 0)

      acc[index] = {
        count,
        sectionLength: section.length,
        title: sectionTitles[id],
      }
      return acc
    },
    {}
  )

  return (
    <StyledHeader px={3} py={1}>
      {Object.values(answeredCount).map(
        ({ count, sectionLength, title }, index) => {
          const isComplete = sectionLength === count
          return (
            <Box position='relative' key={title}>
              <StyledText key={index}>
                {isComplete && (
                  <CheckCircleIcon
                    style={{
                      fontSize: '0.9rem',
                    }}
                    htmlColor='#7abf04'
                  />
                )}
                {title}
                &nbsp;
                <span>
                  {count}/
{sectionLength}
                </span>
              </StyledText>
            </Box>
          )
        }
      )}
    </StyledHeader>
  )
}

export default TabHeader
