export const format = ({
  questions = [],
  pagination: { page = 1, perPage = 50, total = 0 },
}) => ({
  pagination: {
    page: Number(page) - 1,
    perPage: Number(perPage),
    total: Number(total),
  },
  questions,
})
