import HelpIcon from '@mui/icons-material/Help'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { FormHelperText, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Tooltip from '@mui/material/Tooltip'
import withStyles from '@mui/styles/withStyles'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import * as Yup from 'yup'

const styles = (theme) => ({
  body: {
    width: '73%',
  },
  form: {
    width: '78%',
    marginTop: theme.spacing(2),
  },
  actionArea: {
    marginTop: theme.spacing(2),
  },
  toolTip: {
    display: 'absolute',
    float: 'right',
    marginRight: theme.spacing(-7),
    cursor: 'pointer',
    paddingLeft: theme.spacing(4),
  },
})

class ResetPasswordForm extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      showNewPassword: false,
      showNewPasswordConfirmation: false,
    }
  }

  onPasswordVisibilityClick(part) {
    const key = `show${part}`
    this.setState({ [key]: !this.state[key] })
  }

  validationShema() {
    const { t } = this.props
    return Yup.object().shape({
      newPassword: Yup.string()
        .required(t('new_password_validation'))
        .min(6, t('new_password_validation_char')),
      newPasswordConfirmation: Yup.string()
        .required(t('new_password_confirmation_validation_provide'))
        .oneOf(
          [Yup.ref('newPassword'), null],
          t('new_password_confirmation_validation')
        ),
    })
  }

  render() {
    const { t, classes } = this.props
    const { token } = this.props.match.params
    return (
      <>
        <Typography variant='h5' gutterBottom>
          {t('title')}
        </Typography>
        <Typography variant='body1' gutterBottom className={classes.body}>
          {t('sub_title')}
        </Typography>
        <Formik
          initialValues={{
            newPassword: '',
            newPasswordConfirmation: '',
            token,
          }}
          validationSchema={this.validationShema()}
          onSubmit={this.props.onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            status,
          }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              {!!status && <FormLabel error>{status}</FormLabel>}
              <FormControl
                variant='outlined'
                fullWidth
                margin='normal'
                value={values.newPassword}
                error={!!(touched.newPassword && errors.newPassword)}
                onChange={handleChange}
                id='newPassword'
                name='newPassword'
                disabled={isSubmitting}
              >
                <InputLabel htmlFor='newPassword'>
                  {t('new_password')}
                </InputLabel>
                <OutlinedInput
                  id='newPassword'
                  type={this.state.showNewPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={this.onPasswordVisibilityClick.bind(
                          this,
                          'NewPassword'
                        )}
                        size='large'
                      >
                        {this.state.showNewPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                      <Tooltip
                        className={classes.toolTip}
                        title={this.props.t('password_tooltip')}
                      >
                        <HelpIcon />
                      </Tooltip>
                    </InputAdornment>
                  }
                  label={t('new_password')}
                />

                {touched.newPassword && errors.newPassword ? (
                  <FormHelperText error> {errors.newPassword}</FormHelperText>
                ) : null}
              </FormControl>
              <FormControl
                variant='outlined'
                fullWidth
                margin='normal'
                value={values.newPasswordConfirmation}
                onChange={handleChange}
                error={
                  !!(
                    touched.newPasswordConfirmation &&
                    errors.newPasswordConfirmation
                  )
                }
                id='newPasswordConfirmation'
                name='newPasswordConfirmation'
                disabled={isSubmitting}
              >
                <InputLabel htmlFor='newPasswordConfirmation'>
                  {t('new_password_confirmation')}
                </InputLabel>
                <OutlinedInput
                  id='newPasswordConfirmation'
                  type={
                    this.state.showNewPasswordConfirmation ? 'text' : 'password'
                  }
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={this.onPasswordVisibilityClick.bind(
                          this,
                          'NewPasswordConfirmation'
                        )}
                        size='large'
                      >
                        {this.state.showNewPasswordConfirmation ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={t('new_password_confirmation')}
                />
                {touched.newPasswordConfirmation &&
                errors.newPasswordConfirmation ? (
                  <FormHelperText error>
                    {' '}
                    {errors.newPasswordConfirmation}
                  </FormHelperText>
                ) : null}
              </FormControl>
              <Box className={classes.actionArea} textAlign='right'>
                <Button
                  type='submit'
                  disabled={isSubmitting}
                  color='primary'
                  variant='contained'
                >
                  {t('submit_button')}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </>
    )
  }
}

const tResetPasswordForm = withTranslation('reset_password')(ResetPasswordForm)
const rResetPasswordForm = withRouter(tResetPasswordForm)
const sResetPasswordForm = withStyles(styles)(rResetPasswordForm)
export { sResetPasswordForm as ResetPasswordForm }
