import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { withStyles } from '@mui/styles'
import * as React from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { PAGES } from 'apps/portal/common/constants'

const styles = (theme) => ({
  card: {
    width: '27%',
    marginTop: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  changePasswordButton: {
    textDecoration: 'none',
  },
})

class PreferencesComponent extends React.Component {
  render() {
    const { props } = this
    const { classes, t } = props

    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography className={classes.title} variant='h6'>
            {t('password_security_title')}
          </Typography>
          <Typography variant='body2'>{t('password_security')}</Typography>
        </CardContent>
        <CardActions>
          <Link
            to={`/${PAGES.ACCOUNT_CHANGE_PASSWORD}`}
            className={classes.changePasswordButton}
          >
            <Button color='primary' size='large'>
              {t('change_password')}
            </Button>
          </Link>
        </CardActions>
      </Card>
    )
  }
}

const tPreferences = withTranslation('account_form')(PreferencesComponent)
const Preferences = withStyles(styles)(tPreferences)

export { Preferences }
