import { Factory } from '../client/Factory'

const PlaceServiceNamespace = 'place'

const PlaceServiceConfigs = {
  QueryPlaces: Factory.createRequestConfig(Factory.MethodGet, '/place'),
}

/**
 * @type {{UpdateProfile: (*|(function(string, Object): Promise<any>))}}
 */
const PlaceService = {
  QueryPlaces: Factory.makeAuthenticatedClientCall(
    PlaceServiceConfigs.QueryPlaces
  ),
}

export { PlaceServiceNamespace, PlaceServiceConfigs, PlaceService }
