import { Typography, Box } from '@mui/material'

import { StyledScore } from './style'

const Score = ({ score, tabName, label, hasError }) => {
  if (!label) {
    return null
  }

  return (
    <>
      <Box display='flex' justifyContent='space-between'>
        <Typography>
          {tabName}
          <Typography
            display='inline-flex'
            ml={2}
            component='span'
            color={hasError ? '#f40405' : '#b7b6c4'}
          >
            {label} answered questions
          </Typography>
        </Typography>
        <Typography>{score}</Typography>
      </Box>
      <StyledScore score={score}>
        <div />
      </StyledScore>
    </>
  )
}

export default Score
