import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {
  getReport,
  setReport,
  setFeedback,
  postPublishReport,
  getPublicReport,
} from '../api'
import { STATUS } from '../constants'

export const getReportDetails = createAsyncThunk(
  'report/details',
  async (payload) => {
    const response = await getReport(payload)
    return response.data
  }
)

export const updateReport = createAsyncThunk(
  'report/update',
  async (payload) => {
    const response = await setReport(payload)
    return response.data
  }
)

export const updateFeedback = createAsyncThunk(
  'report/feedback',
  async (payload) => {
    await setFeedback(payload)
    return payload
  }
)

export const publishReport = createAsyncThunk(
  'interview/archive',
  async (payload) => {
    await postPublishReport(payload)
    return {}
  }
)

const reportSlice = createSlice({
  name: 'report',
  initialState: { value: {} },
  extraReducers: {
    [getReportDetails.fulfilled]: (state, { payload }) => {
      state.value = payload
      state.value.reportStatus = STATUS.fulfilled
    },
    [getReportDetails.rejected]: (state) => {
      state.value.reportStatus = STATUS.rejected
    },
    [getReportDetails.pending]: (state) => {
      state.value.reportStatus = STATUS.pending
    },
    [updateReport.fulfilled]: (state, { payload }) => {
      state.value = payload
      state.value.updateReportStatus = STATUS.fulfilled
    },
    [updateReport.rejected]: (state) => {
      state.value.updateReportStatus = STATUS.rejected
    },
    [updateReport.pending]: (state) => {
      state.value.updateReportStatus = STATUS.pending
    },
    [updateFeedback.fulfilled]: (state, { payload }) => {
      const multiFeedback = payload?.payload?.multiFeedback
      if (multiFeedback) {
        state.value.updatedFeedback = multiFeedback
        state.value.updateFeedbackStatus = STATUS.fulfilled
      }
    },
    [updateFeedback.rejected]: (state) => {
      state.value.updateFeedbackStatus = STATUS.rejected
    },
    [updateFeedback.pending]: (state) => {
      state.value.updateFeedbackStatus = STATUS.pending
    },
    [publishReport.fulfilled]: (state) => {
      state.value.publishReportStatus = STATUS.fulfilled
      return state
    },
    [publishReport.pending]: (state) => {
      state.value.publishReportStatus = STATUS.pending
      return state
    },
    [publishReport.rejected]: (state) => {
      state.value.publishReportStatus = STATUS.rejected
      return state
    },
  },
})

export default reportSlice.reducer
