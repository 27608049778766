/* eslint-disable */
import React, { Fragment, useState, useEffect } from 'react'
import flow from 'lodash/flow'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'

import withStyles from '@mui/styles/withStyles';
import { Autosuggest } from 'components/v2'
import get from 'lodash/get'
import { ProfileService } from 'sdk/profile/Service'
import { INDUSTRIES_LIMIT } from 'common/constants'

const styles = (theme) => ({
  industryChip: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    cursor: 'move',
  },
})

const FIELD_NAME = 'industry'

const IndustriesBase = ({
  value: activeIndustriesTags = [],
  industryLabelProps,
  classes,
  onChange,
  error,
  ...props
}) => {
  const [industries, setIndustries] = useState([])
  const dataAutomator = props["data-automator"] || 'auto-suggest-industries'
  useEffect(() => {
    async function fetchIndustries() {
      const response = await ProfileService.getIndustryTags()
      const industryTags = get(response, 'data.data', []).map((x) => ({
        label: x.name,
        value: {
          id: x.id,
          name: x.name,
        },
      }))
      setIndustries(industryTags)
    }
    fetchIndustries()
  }, [])

  const renderItem = (industry, index) => {
    return (
      <Chip
        className={classes.industryChip}
        key={industry.id}
        label={industry.name}
        onDelete={removeIndustry(onChange, activeIndustriesTags)(index)}
      />
    )
  }

  const addIndustry = (setter, activeIndustriesTags) => (value) => {
    if (value.name && value.id) {
      setter(FIELD_NAME, [...activeIndustriesTags, value])
    }
  }

  const removeIndustry =
    (setter, activeIndustriesTags) => (removeIndex) => () => {
      const filteredIndustries = activeIndustriesTags.filter(
        (_, index) => removeIndex !== index
      )
      setter(FIELD_NAME, filteredIndustries)
    }

  const getOptionDisabled = ({ value: { id } }) =>
    activeIndustriesTags.some((item) => item.id === id)
  const buildLabel = (industry) => get(industry, 'name', '')

  return (
    <Fragment>
      <Autosuggest
        items={
          activeIndustriesTags.length === INDUSTRIES_LIMIT ? [] : industries
        }
        buildLabel={buildLabel}
        label={industryLabelProps.label}
        placeholder={industryLabelProps.buildIndustryPlaceholder(
          INDUSTRIES_LIMIT - activeIndustriesTags.length
        )}
        onChange={(value) =>
          activeIndustriesTags.length < INDUSTRIES_LIMIT &&
          addIndustry(onChange, activeIndustriesTags)(value)
        }
        getOptionDisabled={getOptionDisabled}
        clearOnBlur={true}
        error={error}
        data-automator={dataAutomator}
      />

      <Box m={1}>
        <Grid container direction='row'>
          {activeIndustriesTags.map(renderItem)}
        </Grid>
      </Box>
    </Fragment>
  )
}

export const Industries = flow(withStyles(styles))(IndustriesBase)
