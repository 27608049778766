/* eslint-disable */
import React from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Layout from 'components/Layout'
import flow from 'lodash/flow'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import { NonAuthenticatedNavBar } from '../../components'
import { Loading } from 'components'
import { AccountsService } from 'sdk/account/Service'
import { ProfileService } from 'sdk/profile/Service'
import { authenticateUser } from './Actions'
import { LoginForm } from './forms/login'
import { ForgotPasswordForm } from './forms/ForgotPasswordForm'
import { ResetPasswordForm } from './forms/ResetPasswordForm'
import { PAGES } from '../../common/constants'
import loginImg from 'apps/portal/assets/log_in.png'
import { loadRoles } from 'apps/portal/containers/profile/Actions'
import { withNotifications } from '../../../../hocs'

const styles = (theme) => ({
  fullHeight: {
    height: '100%',
  },
  avatar: {
    width: '300px',
  },
  formContainer: {
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2),
    },
  },
})

class LoginClass extends React.Component {
  static propTypes = {
    onAuthenticate: PropTypes.func.isRequired,
    authToken: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.onLoginSuccessCallback = this.onLoginSuccessCallback.bind(this)
    this.onForgotPasswordSubmit = this.onForgotPasswordSubmit.bind(this)
    this.onResetPasswordSubmit = this.onResetPasswordSubmit.bind(this)
    this.confirmEmail = this.confirmEmail.bind(this)

    this.state = {
      showPassword: false,
    }
  }

  componentDidMount() {
    this.confirmEmail()
    const pathName =
      this.props.location.state?.from?.pathname || `/${PAGES.DASHBOARD}`
    if (this.props.authToken) {
      this.redirect(pathName)
    }
  }

  redirect(url) {
    const {
      props: { history },
    } = this
    history.push(url)
  }

  async load() {
    const [
      {
        data: { roles },
      },
    ] = await Promise.all([AccountsService.getUserProfile()])

    this.props.onLoadRoles(roles)
  }

  async onLoginSuccessCallback({ data, setStatus }) {
    try {
      this.props.onAuthenticate(data)
      this.load()
      const params = new URLSearchParams(this.props.location.search)
      const pathName =
        this.props.location.state?.from?.pathname ||
        `/${PAGES.DASHBOARD}?${params}`
      this.redirect(pathName)
    } catch (e) {
      setStatus(e.message)
    }
  }

  async onForgotPasswordSubmit(formData, { setSubmitting }) {
    const {
      props: { notify, t },
    } = this
    let message

    try {
      await AccountsService.forgotPassword(formData)

      this.redirect(`/${PAGES.LOGIN}`)

      message = 'valid'
    } catch ({
      response: {
        data: { error },
      },
    }) {
      message = 'error'
    } finally {
      setSubmitting(false)

      notify(t(`forgot_password_${message}`))
    }
  }

  async confirmEmail() {
    const {
      props: { warn, notify, t },
    } = this
    const params = new URLSearchParams(this.props.location.search)
    const confirmationCode = params.get('confirmationCode')
    const userId = params.get('userId')
    let result
    try {
      if (confirmationCode && userId) {
        const callOpts = {
          path: {
            id: userId,
          },
        }
        result = await AccountsService.confirmEmailChangeRequest(
          {
            confirmationCode,
          },
          callOpts
        )
        if (result.status !== 200 && result.status !== 401) {
          warn(t('emailUpdatedFail'))
        }
        notify(t('emailUpdatedSuccess'))
      }
    } catch (e) {
      if (result && result.status !== 401) {
        warn(t('emailUpdatedFail'))
      }
      console.error(e)
    }
  }

  async onResetPasswordSubmit(formData, { setSubmitting }) {
    const {
      props: { notify, t },
    } = this
    const data = {
      token: formData.token,
      password: formData.newPassword,
    }
    let message

    try {
      await AccountsService.resetPassword(data)

      this.redirect(`/${PAGES.LOGIN}`)

      message = 'valid'
    } catch ({
      response: {
        data: { error },
      },
    }) {
      message = error.replace(/\./gi, '|')
    } finally {
      setSubmitting(false)

      notify(t(`set-new-password[${message}]`))
    }
  }

  render() {
    const { loading } = this.state
    const { classes, t } = this.props

    return (
      <Loading loading={loading}>
        <Layout navbarComponent={NonAuthenticatedNavBar} currentPage='Login'>
          <Grid container spacing={0}>
            <Grid item lg={5}>
              <Box
                display={{
                  sm: 'none',
                  xs: 'none',
                  xl: 'block',
                  md: 'block',
                  lg: 'block',
                }}
                textAlign='center'
              >
                <img
                  src={loginImg}
                  className={classes.avatar}
                  alt={t('login_sub_title')}
                />
              </Box>
            </Grid>
            <Grid item lg={1}>
              <Box
                className={classes.fullHeight}
                display={{
                  sm: 'none',
                  xs: 'none',
                  xl: 'block',
                  lg: 'block',
                  md: 'block',
                }}
              >
                <Divider
                  sx={{ mx: 'auto' }}
                  variant='middle'
                  orientation='vertical'
                />
              </Box>
            </Grid>
            <Grid item lg={6} sm={12} md={7} xs={12}>
              <Grid
                container
                className={classes.fullHeight}
                flexDirection='column'
                alignItems={'center'}
                justifyContent={'center'}
                spacing={0}
              >
                <Grid
                  item
                  lg={9}
                  sm={12}
                  xs={12}
                  className={classes.formContainer}
                >
                  <Switch>
                    <Route exact path={`/${PAGES.LOGIN}`}>
                      <LoginForm
                        onSuccessCallback={this.onLoginSuccessCallback}
                      />
                    </Route>
                    <Route exact path={`/${PAGES.LOGIN_FORGOT_PASSWORD}`}>
                      <ForgotPasswordForm
                        onSubmit={this.onForgotPasswordSubmit}
                      />
                    </Route>
                    <Route exact path={`/${PAGES.LOGIN_RESET_PASSWORD}/:token`}>
                      <ResetPasswordForm
                        onSubmit={this.onResetPasswordSubmit}
                      />
                    </Route>
                  </Switch>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Layout>
      </Loading>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    authToken: state.auth && state.auth.accessToken,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onAuthenticate: (auth) => dispatch(authenticateUser(auth)),
    onLoadRoles: (roles) => dispatch(loadRoles(roles)),
  }
}

const Login = flow(
  withTranslation('login'),
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  withNotifications
)(LoginClass)
export { Login }
