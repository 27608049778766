import { createSlice } from '@reduxjs/toolkit'

const modalSlice = createSlice({
  name: 'modal',
  initialState: { value: {} },
  reducers: {
    setModal: (state, { payload }) => {
      state.value = payload
    },
  },
})

export const { setModal } = modalSlice.actions
export default modalSlice.reducer
