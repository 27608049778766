import React, { useCallback } from 'react'
import {
  DialogActions as Actions,
  DialogContent as Content,
  DialogTitle as Title,
} from '@mui/material'

import { Container as Dialog } from 'apps/scorecard/components/widgets/modal'
import { usePortal } from 'apps/scorecard/hooks'

export default () => {
  const { Portal, open, close } = usePortal()
  const Container = useCallback(
    (props) => (
      <Portal>
        <Dialog onClose={close} {...props} />
      </Portal>
    ),
    [close]
  )

  return {
    components: { Container, Title, Content, Actions },
    open,
    close,
  }
}
