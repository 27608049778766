/* eslint-disable*/
import React, { useCallback, memo } from 'react'
import LinkIcon from '@mui/icons-material/Link'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Link, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ProfileService } from 'sdk/profile/Service'
import { ANALYTICS_TAGS, ANALYTICS_ACTIONS } from 'apps/portal/common/constants'
import Grid from '@mui/material/Grid'
const useStyles = makeStyles((theme) => {
  const orgLogo = {
    width: theme.spacing(2.5),
  }

  return {
    backBtn: {
      cursor: 'pointer',
    },
    linkIcon: {
      color: theme.palette.black,
    },
    [theme.breakpoints.down('md')]: {
      orgLogo: {
        ...orgLogo,
      },
      headerContainer: {
        padding: theme.spacing(2),
      },
    },
    [theme.breakpoints.up('md')]: {
      orgLogo: {
        ...orgLogo,
        marginTop: theme.spacing(0.75),
      },
      '& > a': {
        margin: theme.spacing(0, 1),
      },
    },
  }
})
function Header(props) {
  const classes = useStyles()
  const {
    config: {
      navigation: {
        logo: { image, altText },
        homePageUrl,
      },
    },
    notify,
    translate,
    profileId,
    history,
    match,
    contactExpertLead,
    gEvent,
    slugId,
  } = props
  const handleCopyLink = useCallback((e) => {
    if (typeof window === 'undefined') return
    const temp = document.createElement('input')
    document.body.appendChild(temp)
    temp.value = `${window.location.href}#triggerGA`
    temp.select()
    document.execCommand('copy')
    notify(translate('URLCopy'))
    gEvent({
      ...ANALYTICS_TAGS.PUBLIC_PROFILE,
      action: `${ANALYTICS_ACTIONS.COPY_URL} - ${temp.value}`,
      label: slugId,
    })
    document.body.removeChild(temp)
  }, [])

  return (
    <Grid
      container
      className={classes.headerContainer}
      direction='row'
      justifyContent='space-between'
      alignItems='center'
    >
      {!!profileId && (
        <Grid item>
          <Link to='#' className={classes.backBtn}>
            <ArrowBackIcon onClick={() => history.goBack()} />
          </Link>
        </Grid>
      )}
      {!!image && !profileId && (
        <Grid item>
          <Link href={homePageUrl}>
            <img src={image} className={classes.orgLogo} alt={altText} />
          </Link>
        </Grid>
      )}
      {!profileId && (
        <Grid item>
          <Link href='#' onClick={handleCopyLink}>
            <LinkIcon className={classes.linkIcon} id='copy-link' />
          </Link>
        </Grid>
      )}
    </Grid>
  )
}
export default memo(Header)
