import { Factory } from '../client/Factory'

const ProfileServiceNamespace = 'profile'

/**
 * @type {{getProfile: (*|{method: string, uri: string, params: string[]})}}
 * @type {{updateProfile: (*|{method: string, uri: string, params: string[]})}}
 */
const ProfileServiceConfigs = {
  getUploadURL: Factory.createRequestConfig(Factory.MethodGet, '/file-upload'),
  uploadFile: Factory.createRequestConfig(Factory.MethodPost),
  getSignedURL: Factory.createRequestConfig(
    Factory.MethodGet,
    '/file-url/:bucket/:path/:name'
  ),
  getProfileById: Factory.createRequestConfig(
    Factory.MethodGet,
    '/admin/profile/:id'
  ),
  getProfile: Factory.createRequestConfig(
    Factory.MethodGet,
    '/expert/profile/me'
  ),
  getPublicProfile: Factory.createRequestConfig(
    Factory.MethodGet,
    '/profile/public/:slug'
  ),
  updateProfile: Factory.createRequestConfig(
    Factory.MethodPatch,
    '/expert/profile/:id'
  ),
  getFocusRoleList: Factory.createRequestConfig(
    Factory.MethodGet,
    '/focus-role'
  ),
  queryProfiles: Factory.createRequestConfig(Factory.MethodGet, '/profile'),
  getProfileChangelog: Factory.createRequestConfig(
    Factory.MethodGet,
    '/changelog/resource/:id'
  ),
  deleteProfile: Factory.createRequestConfig(
    Factory.MethodDelete,
    '/profile/:id'
  ),
  createProfile: Factory.createRequestConfig(Factory.MethodPost, 'profile'),
  createDraftProfileWithCv: Factory.createRequestConfig(
    Factory.MethodPost,
    '/admin/draft-profile'
  ),
  updateConnectProfile: Factory.createRequestConfig(
    Factory.MethodPatch,
    '/admin/profile/:id'
  ),
  getIndustryTags: Factory.createRequestConfig(Factory.MethodGet, '/industry'),
  getTravelPreferences: Factory.createRequestConfig(
    Factory.MethodGet,
    '/profile/accessibility-list'
  ),
  contactExpertLead: Factory.createRequestConfig(
    Factory.MethodPost,
    '/profile/:id/detail-request'
  ),
  validateTaxId: Factory.createRequestConfig(
    Factory.MethodPost,
    '/profile/validate-tax-id'
  ),
  saveDraftProfile: Factory.createRequestConfig(
    Factory.MethodPatch,
    '/admin/profile/:id/draft'
  ),
  createDraftProfile: Factory.createRequestConfig(
    Factory.MethodPost,
    '/draft-profile'
  ),
  getCVSignedURL: Factory.createRequestConfig(
    Factory.MethodGet,
    '/profile/:id/cv-link'
  ),
}

/**
 * @type {{getProfile: (*|(function(string, Object): Promise<any>))}}
 * @type {{updateProfile: (*|(function(string, Object): Promise<any>))}}
 */
const ProfileService = {
  getUploadURL: Factory.makeAuthenticatedClientCall(
    ProfileServiceConfigs.getUploadURL
  ),
  uploadFile: Factory.makeUploadCall(ProfileServiceConfigs.uploadFile),
  getSignedURL: Factory.makeAuthenticatedClientCall(
    ProfileServiceConfigs.getSignedURL
  ),
  getProfile: Factory.makeAuthenticatedClientCall(
    ProfileServiceConfigs.getProfile
  ),
  getPublicProfile: Factory.makeClientCall(
    ProfileServiceConfigs.getPublicProfile
  ),
  updateProfile: Factory.makeAuthenticatedClientCall(
    ProfileServiceConfigs.updateProfile
  ),
  getFocusRoleList: Factory.makeAuthenticatedClientCall(
    ProfileServiceConfigs.getFocusRoleList
  ),
  getProfileById: Factory.makeAuthenticatedClientCall(
    ProfileServiceConfigs.getProfileById
  ),
  getProfileChangelog: Factory.makeAuthenticatedClientCall(
    ProfileServiceConfigs.getProfileChangelog
  ),
  deleteProfile: Factory.makeAuthenticatedClientCall(
    ProfileServiceConfigs.deleteProfile
  ),
  // https://www.npmjs.com/package/query-string#arrayformat-1
  queryProfiles: (data) =>
    Factory.makeAuthenticatedClientCall(ProfileServiceConfigs.queryProfiles)(
      data,
      { arrayFormat: 'none' }
    ),
  createProfile: Factory.makeAuthenticatedClientCall(
    ProfileServiceConfigs.createProfile
  ),
  createDraftProfileWithCv: Factory.makeAuthenticatedClientCall(
    ProfileServiceConfigs.createDraftProfileWithCv
  ),
  updateConnectProfile: Factory.makeAuthenticatedClientCall(
    ProfileServiceConfigs.updateConnectProfile
  ),
  getIndustryTags: Factory.makeAuthenticatedClientCall(
    ProfileServiceConfigs.getIndustryTags
  ),
  getTravelPreferences: Factory.makeAuthenticatedClientCall(
    ProfileServiceConfigs.getTravelPreferences
  ),
  contactExpertLead: Factory.makeAuthenticatedClientCall(
    ProfileServiceConfigs.contactExpertLead
  ),
  validateTaxId: Factory.makeAuthenticatedClientCall(
    ProfileServiceConfigs.validateTaxId
  ),
  saveDraftProfile: Factory.makeAuthenticatedClientCall(
    ProfileServiceConfigs.saveDraftProfile
  ),
  getPublicUploadURL: Factory.makeClientCall(
    ProfileServiceConfigs.getUploadURL
  ),
  createDraftProfile: Factory.makeClientCall(
    ProfileServiceConfigs.createDraftProfile
  ),
  getPublicFocusRoleList: Factory.makeClientCall(
    ProfileServiceConfigs.getFocusRoleList
  ),
  getCVSignedURL: Factory.makeAuthenticatedClientCall(
    ProfileServiceConfigs.getCVSignedURL
  ),
  getPublicTravelPreferences: Factory.makeClientCall(
    ProfileServiceConfigs.getTravelPreferences
  ),
}

export { ProfileServiceNamespace, ProfileServiceConfigs, ProfileService }
