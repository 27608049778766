import { Children, cloneElement, useCallback, useMemo } from 'react'

import { identify } from './helpers'

export default ({ components, children }) => {
  const collection = useMemo(() => Children.toArray(children), [children])
  const findBy = useCallback(
    ({ type }) => collection.filter((item) => item.type === type),
    [collection]
  )
  const groupWith = useCallback(
    (props) => {
      const types = Object.entries(components).reduce(identify, new Map())
      const concat = (stack, child) => {
        const type = types.get(child.type)
        const { [type]: current = [] } = stack
        const next = cloneElement(child, props)

        return Object.assign(stack, { [type]: current.concat(next) })
      }

      return collection.reduce(concat, {})
    },
    [collection, components]
  )

  return { findBy, groupWith }
}
