import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import flow from 'lodash/flow'
import get from 'lodash/get'
import throttle from 'lodash/throttle'
import uniqueId from 'lodash/uniqueId'
import PropTypes from 'prop-types'
import React from 'react'

import { DEBOUNCE_DELAY, TOP_SKILLS_LIMIT } from 'apps/portal/common/constants'
import { DatePicker } from 'components'
import withDnD from 'hocs/DnD'

import { Autosuggest } from '../Autosuggest'

const styles = (theme) => ({
  skillChip: {
    marginRight: theme.spacing(1),
  },
  draggable: {
    listStyleType: 'none',
  },
  dragIcon: {
    cursor: 'move',
    paddingTop: theme.spacing(4),
    color: theme.palette.lightGray,
  },
  actions: {
    paddingTop: theme.spacing(2),
  },
  datePicker: {
    [`& > .${outlinedInputClasses.root} > .${outlinedInputClasses.input}`]: {
      padding: '16.5px 14px',
    },
  },
})

const TopSkillsBase = ({
  topSkills = [],
  classes,
  onFind,
  onSort,
  createDnD,
  topSkillsLabel,
  skillsAutosuggestProps,
  skillsDatePickerProps,
  addTopSkill,
  addSkill,
  error,
  editSkill,
  removeSkill,
  skillIds,
}) => {
  const renderAddButton = () => (
    <IconButton onClick={addTopSkill} id='add-skill' size='large'>
      <AddIcon />
    </IconButton>
  )

  const renderItem = (profileSkill, index) => {
    const isLast = index === topSkills.length - 1
    const editSkillFunc = editSkill(index)
    const addSkillFunc = addSkill(index)
    const dateErrorText = get(error, `${index}.startedAt.message`, '')
    const changeDate = throttle(
      (date) => editSkillFunc('startedAt')(date || ''),
      DEBOUNCE_DELAY
    )
    const elementId = get(
      profileSkill,
      'skill.id',
      profileSkill.id || uniqueId()
    )
    const { buildTopSkillsLabel } = skillsAutosuggestProps
    const { fromLabel } = skillsDatePickerProps
    const getOptionDisabled = ({ value: { id } }) => skillIds.includes(id)
    const onInputChange = editSkillFunc('skillFreeText')

    return (
      <li key={elementId}>
        <Grid container spacing={2}>
          <Grid item>
            <DragIndicatorIcon className={classes.dragIcon} id='drag-skill' />
          </Grid>
          <Grid item xs={10} sm={8} md={4} lg={4}>
            <Autosuggest
              {...skillsAutosuggestProps}
              label={buildTopSkillsLabel(index + 1)}
              onChange={addSkillFunc}
              onInputChange={(value) => onInputChange(value)}
              skillIds={skillIds}
              error={get(error, `${index}.skill.id`, '')}
              value={profileSkill.skill}
              getOptionDisabled={getOptionDisabled}
              id={`top-skill-${index}`}
              data-automator={`top-skills-auto-suggest-${index}`}
            />
          </Grid>
          <Grid item xs={10} sm={8} md={4} lg={4}>
            <DatePicker
              disableFuture
              id={`from_${profileSkill.id}`}
              helperText={dateErrorText}
              error={!!dateErrorText}
              label={fromLabel}
              value={profileSkill.startedAt || null}
              onChange={changeDate}
              inputClassName={classes.datePicker}
              KeyboardButtonProps={{
                'aria-label': fromLabel,
              }}
              data-automator={`top-skills-from-date-${index}`}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={3} lg={2}>
            <Box component='div' className={classes.actions}>
              <IconButton
                {...(!isLast && { className: classes.notLastItem })}
                onClick={removeSkill(index)}
                id='delete-skill'
                size='large'
              >
                <DeleteIcon />
              </IconButton>
              {isLast &&
                topSkills.length < TOP_SKILLS_LIMIT &&
                renderAddButton()}
            </Box>
          </Grid>
        </Grid>
      </li>
    )
  }

  const { ref, connect } = createDnD({ onFind, onSort })

  return (
    <>
      <Typography variant='h6'>{topSkillsLabel}</Typography>
      <ul className={classes.draggable} ref={ref}>
        {topSkills.map(connect(renderItem))}
      </ul>
      {topSkills.length === 0 && (
        <Box component='div' display='flex' justifyContent='flex-start'>
          {renderAddButton()}
        </Box>
      )}
    </>
  )
}

TopSkillsBase.propTypes = {
  handleChange: PropTypes.func,
  error: PropTypes.array,
}

const TopSkills = flow(
  withStyles(styles),
  withDnD({ type: 'topSkills' })
)(TopSkillsBase)

export { TopSkills }
