import FormLabel from '@mui/material/FormLabel'
import update from 'immutability-helper'
import get from 'lodash/get'
import React, { Fragment } from 'react'

import { capitalize } from 'common/utils'

import { Skills } from './Skills'

export const TestedSkills = ({
  value: testedSkills = [],
  onChange,
  skillsAutosuggest,
  skillsLabel,
  error,
}) => {
  const addSkill = (setter) => (skills) => (index) => (skill) => {
    if (skill.id && skill.name) {
      const updatedSkills = update(skills, {
        [index]: { $set: skill },
      })
      setter(updatedSkills)
    }
  }

  const editSkill = (setter) => (skills) => (index) => (field) => (value) => {
    const editedSkills = update(skills, {
      [index]: { $merge: { [field]: value } },
    })
    setter(editedSkills)
  }

  const removeSkill = (setter) => (skills) => (index) => (e) => {
    const filteredSkills = update(skills, {
      $splice: [[index, 1]],
    })
    setter(filteredSkills)
  }

  const skillIds = (skills = []) =>
    skills.map((profileSkill) => get(profileSkill, 'id'))
  const setter = (skills) => onChange(skills)
  const skillProps = {
    skillIds: skillIds(testedSkills),
    error,
    skills: testedSkills,
    setter,
    addSkill: addSkill(setter)(testedSkills),
    editSkill: editSkill(setter)(testedSkills),
    removeSkill: removeSkill(setter)(testedSkills),
    onChange,
    skillsAutosuggestProps: skillsAutosuggest,
    skillsLabel,
  }

  return (
    <>
      <Skills {...skillProps} />
      {!!error && typeof error === 'string' && (
        <FormLabel error>{capitalize(error)}</FormLabel>
      )}
    </>
  )
}
