import React, { useMemo } from 'react'
import isToday from 'date-fns/isToday'
import add from 'date-fns/add'
import fromUnixTime from 'date-fns/fromUnixTime'
import format from 'date-fns/format'

import { isFirefox } from 'apps/scorecard/helpers/window/open/helpers'
import { StyledBox } from './style'

import { DATE_TIME_FORMAT } from '../interview/constants'
import { HOURS_TO_SUBMIT } from './constant'

const InterviewPrompt = ({ dateTime, endingTime, status, curved }) => {
  const formattedDateTime = useMemo(
    // Firefox browser needs this date format when we use the "new Date" constructor
    // https://stackoverflow.com/a/61775999/3698747
    () => {
      const dateFormat = isFirefox() ? 'dd/MMM/yy HH:mm' : DATE_TIME_FORMAT
      return format(fromUnixTime(dateTime), dateFormat)
    },
    [dateTime]
  )

  const startsToday = isToday(new Date(formattedDateTime).getTime())

  if (endingTime || !startsToday) {
    return null
  }

  if (status === 'INTERVIEW_STATUS_ARCHIVED') {
    return null
  }

  const timeToSubmit = format(
    add(new Date(formattedDateTime), { hours: HOURS_TO_SUBMIT }),
    DATE_TIME_FORMAT
  )

  return (
    <StyledBox curved={curved}>
      <p>
        Please submit your feedback immediately after the interview, until&nbsp;
        <em>{timeToSubmit}</em>
      </p>
    </StyledBox>
  )
}

export default InterviewPrompt
