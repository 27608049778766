import CircleIcon from '@mui/icons-material/Circle'
import { Box, IconButton, Slider, Tabs, Button } from '@mui/material'
import styled, { css } from 'styled-components'

import { getDifficultyColor, getScoreColor } from 'apps/scorecard-v2/helpers'

const FlexCenter = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Active = css`
  background: #1b1246;
  color: #fff;
`

export const StyledWrapper = styled(Box)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  background: #f9fafb;
  padding-inline: 24px;
  height: calc(100vh - 148px);
  display: flex;
  flex-direction: column;
`

export const StyledTitle = styled(Box)`
  ${FlexCenter}
  justify-content: flex-start;
  gap: 5px;
  margin: 20px 0 14px 0;
`

export const StyledIconButton = styled(IconButton)`
  ${FlexCenter}
  justify-content: center;
  background: #2b254f;
  border-radius: 3px;
  color: #fdfdfe;
  width: 35px;
  height: 30px;
  padding-left: 14px;

  & svg {
    padding-left: 4px;
  }

  &:hover {
    background-color: #3b3465;
  }
`

export const StyledCircle = styled(CircleIcon)`
  font-size: 0.85rem;
  color: ${({ difficulty }) => getDifficultyColor(difficulty)};
`

export const SliderWrapper = styled(Box)`
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-basis: 100%;
  padding-block: 10px;
`

export const StyledSlider = styled(Slider)`
  position: absolute;
  color: ${({ value }) => getScoreColor(value)};
  height: 9px;
  width: 97%;
  left: 1.8%;
  top: 42px;
  margin: 0 auto;

  & .MuiSlider-mark {
    height: 9px;
    background: #e6e9ef;
    border-radius: 0;
  }

  & .MuiSlider-mark[data-index='0'],
  .MuiSlider-mark[data-index='10'] {
    display: none;
  }

  & .MuiSlider-valueLabel {
    display: none;
  }

  & .MuiSlider-markLabel {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    cursor: pointer;
    background: #fff;
    border-radius: 50%;

    width: 1.1rem;
    color: #77738e;
    font-size: 0.8rem;

    &:hover {
      background: #1b1246;
      color: #fff;
    }
  }
`

export const ScoreContainer = styled(Box)`
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: ${({ width }) => width || `18%`};
  height: 90px;
  padding: 6px 8px 0 8px;
  font-weight: ${({ bold }) => (bold ? '800' : '400')};

  & p {
    opacity: ${({ bold }) => (bold ? '1' : '0.64')};
    padding: 0;
    margin: 0;
    font-size: 0.9rem;
    text-align: center;
  }
`

export const StyledHint = styled(Box)`
  background: #fff;
  color: #555172;
  width: 106.5%;
  position: relative;
  left: -22px;
  border-block: 1px solid #e6e9ef;
  flex-grow: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  line-height: 32px;
  padding: 20px 30px;
`

export const StyledTabs = styled(Tabs)`
  margin-top: ${({ marginTop }) => marginTop || '30px'};

  & .MuiTab-root {
    text-transform: none;
  }

  & .MuiTabs-indicator {
    background-color: #3c26de;
    height: 4px;
  }

  & .MuiTab-root.Mui-selected {
    color: #3c26de;
  }
`

export const StyledFooter = styled(Box)`
  ${FlexCenter}

  padding: 15px 30px;
`

export const FeedbackContainer = styled(Box)`
  & .MuiInputBase-fullWidth {
    padding: 0;
  }

  & > p {
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 0.9rem;
  }

  & .MuiOutlinedInput-root {
    margin-bottom: 15px;
    color: #8f8ea3;
  }
`

export const StyledButton = styled(Button)`
  text-transform: none;
  align-self: flex-end;
  margin-top: 10px;
  display: ${({ show }) => (show ? 'block' : 'none')};
  background: #3c26de;
  &:hover {
    background: #5a47ea;
  }
`

export const MarksWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 15%;
  padding: 0 3%;
  width: 100%;
`

export const MarkButton = styled(Button)`
  padding: 5px;
  min-width: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  color: #1b1246;
  text-transform: none;
  font-size: 0.8rem;

  ${({ active }) => active && `${Active}`}

  &:hover {
    ${Active}
  }
`
