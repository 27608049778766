import { arrayOf, node, shape, string } from 'prop-types'
import React, { useCallback, useMemo } from 'react'
import { Grid } from '@mui/material'

import { useElements } from 'apps/scorecard/hooks'
import { Interview } from 'apps/scorecard/components/widgets'

import withStyle from './style'

export const Interviews = ({
  components: { NotFound, ...components },
  className,
  items,
  children,
  userEmail,
}) => {
  const { findBy, groupWith } = useElements({ components, children })
  const notFound = useMemo(() => findBy({ type: NotFound }), [findBy, NotFound])
  const renderItem = useCallback(
    (interview) => {
      const elements = groupWith(interview)

      return (
        <Grid key={interview.id} item xs={4}>
          <Interview elements={elements} {...interview} />
        </Grid>
      )
    },
    [groupWith]
  )

  return items.length ? (
    <Grid className={className} spacing={3} container>
      {items.map(renderItem)}
    </Grid>
  ) : (
    notFound
  )
}

Interviews.propTypes = {
  className: string.isRequired,
  items: arrayOf(shape()),
  children: node,
}

Interviews.defaultProps = {
  items: [],
}

export default withStyle(Interviews)
