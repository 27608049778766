import { object, string, lazy, date, array } from 'yup'

import { SKILLS_LIMIT, TOP_SKILLS_LIMIT } from 'common/constants'

const getSkillsValidation = ({
  skillDateValidationErrorMessage,
  skillLimitValidationErrorMessage,
  skillRequiredValidationErrorMessage,
}) =>
  array()
    .of(
      object({
        skill: object({
          id: string().required(skillRequiredValidationErrorMessage),
        }).required(skillRequiredValidationErrorMessage),
        startedAt: lazy((value, { path }) => {
          const group = path.match(/\[(\d+)\]/)[1]
          const index = Number(group)

          const dateSchema = date()
            .max(new Date(), skillDateValidationErrorMessage)
            .typeError(skillDateValidationErrorMessage)

          if (index < TOP_SKILLS_LIMIT) {
            return dateSchema.required(skillDateValidationErrorMessage)
          }

          return dateSchema.optional()
        }),
      })
    )
    .max(SKILLS_LIMIT, skillLimitValidationErrorMessage)

export { getSkillsValidation }
