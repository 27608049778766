import TimelapseIcon from '@mui/icons-material/Timelapse'
import { useEffect, useState } from 'react'

import { useTracking } from 'apps/scorecard/hooks'

import { StyledInterviewTime } from './style'

const InterviewTime = ({ startingTime }) => {
  const [computedTime, setComputedTime] = useState({
    secs: 0,
    mins: 0,
    hours: 0,
  })

  const computeTime = () => {
    if (!startingTime) {
      return
    }

    const timeDiff = Math.floor(Date.now() / 1000) - startingTime
    const secs = Math.floor(timeDiff % 60)
    const mins = Math.floor((timeDiff / 60) % 60)
    const hours = Math.floor((timeDiff / (60 * 60)) % 24)

    setComputedTime({ secs, mins, hours })
  }

  useEffect(() => {
    const interval = setInterval(() => computeTime(), 1000)

    return () => clearInterval(interval)
  }, [startingTime])

  const formatTime = (time) => (time < 10 ? `0${time}` : time)

  const { secs, mins, hours } = computedTime

  const tracking = useTracking()
  const eventTracking = (label, action = 'Click') => {
    tracking.event({
      category: 'Scorecard-v2',
      action,
      label: `${action}_${label}`,
    })
  }

  return (
    <StyledInterviewTime onClick={() => eventTracking('Interview_Time')}>
      <TimelapseIcon />
      {`${formatTime(hours)}:${formatTime(mins)}:${formatTime(secs)} `}
    </StyledInterviewTime>
  )
}

export default InterviewTime
