// TODO: Unify constants used in this file & the HOC file
// Also add rest of the notification types (warn, success, etc.)
import { useSnackbar } from 'notistack'
import { useCallback } from 'react'

const defaults = { anchorOrigin: { vertical: 'bottom', horizontal: 'right' } }

const useNotifications = () => {
  const { enqueueSnackbar } = useSnackbar()

  const notify = useCallback(
    (content) =>
      enqueueSnackbar(content || 'Unknown Error!', {
        variant: 'default',
        ...defaults,
      }),
    [enqueueSnackbar]
  )
  // variant can be used from below
  const showSnackbar = useCallback(
    (content, variant) =>
      enqueueSnackbar(content, {
        variant,
        ...defaults,
      }),
    [enqueueSnackbar]
  )
  const VARIANTS = {
    SUCCESS: 'success',
    INFO: 'info',
    ERROR: 'error',
    WARN: 'warning',
    DEFAULT: 'default',
  }
  return { notify, showSnackbar, VARIANTS }
}

export default useNotifications
