import React, { useState } from 'react'
import {
  DialogContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  IconButton,
  DialogContentText,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useForm, Controller } from 'react-hook-form'

import {
  StyledDialogTitle,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledTextField,
  StyledButton,
} from './style'

const ArchiveConfirmation = ({ open, setOpen, archive, color = 'primary' }) => {
  const {
    unregister,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm()

  const feedbackOptions = [
    'This interview was rescheduled.',
    'This interview was cancelled.',
  ]

  const [option, setOption] = useState(feedbackOptions[0])
  const [textFeedback, setTextFeedback] = useState('')
  const [feedbackOpen, setFeedbackOpen] = useState(false)

  const isOtherOption = option === 'other'

  const handleFeedbackChange = (event) => {
    const { value } = event.target
    setTextFeedback(value)
    setValue('textFeedback', value)
  }

  const handleOptionChange = (event) => {
    const { value } = event.target
    if (value !== 'other') {
      unregister('textFeedback')
    }
    setOption(value)
  }

  const handleClose = () => {
    setOpen(false)
    setFeedbackOpen(false)
  }

  const onSubmit = () => {
    const archivingReason = isOtherOption ? textFeedback : option
    archive(archivingReason)
    handleClose()
  }

  const handleFeedbackOpen = () => {
    setOpen(false)
    setFeedbackOpen(true)
  }

  return (
    <>
      <StyledDialog
        open={feedbackOpen}
        onClose={handleClose}
        maxWidth='sm'
        fullWidth
      >
        <StyledDialogTitle>
          <span>What is the reason to archive this interview?</span>
          <IconButton onClick={handleClose} size='large'>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <RadioGroup
              aria-labelledby='archive-buttons-group-label'
              name='archive-buttons-group'
              onChange={handleOptionChange}
            >
              <FormControlLabel
                value={feedbackOptions[0]}
                control={<Radio color={color} />}
                label={feedbackOptions[0]}
                checked={option === feedbackOptions[0]}
              />
              <FormControlLabel
                value={feedbackOptions[1]}
                control={<Radio color={color} />}
                label={feedbackOptions[1]}
                checked={option === feedbackOptions[1]}
              />
              <FormControlLabel
                value='other'
                control={<Radio color={color} />}
                label='Other'
                checked={isOtherOption}
              />
            </RadioGroup>
            {isOtherOption && (
              <Controller
                control={control}
                name='textFeedback'
                rules={{ required: true, minLength: 4 }}
                render={({ name, value }) => (
                  <StyledTextField
                    variant='outlined'
                    value={value}
                    name={name}
                    onChange={handleFeedbackChange}
                    size='medium'
                    color='default'
                    rows={3}
                    multiline
                    error={errors.textFeedback}
                    helperText={
                      errors.textFeedback &&
                      'Please enter a reason (min 4 characters)'
                    }
                  />
                )}
              />
            )}
          </FormControl>
        </DialogContent>
        <StyledDialogActions>
          <StyledButton onClick={handleSubmit(onSubmit)} color={color}>
            Submit
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog>

      <StyledDialog open={open} onClose={handleClose} size='sm' fullWidth>
        <StyledDialogTitle>
          <span>Do you want to archive this interview?</span>
          <IconButton onClick={handleClose} size='large'>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <StyledDialogContent>
          <DialogContentText>
            The interview would be moved to &quot;Archived interviews&quot;.
          </DialogContentText>
        </StyledDialogContent>
        <StyledDialogActions>
          <Button onClick={handleClose} color={color}>
            Cancel
          </Button>
          <StyledButton onClick={handleFeedbackOpen} color={color}>
            Archive
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog>
    </>
  )
}

export default ArchiveConfirmation
