import { LANGUAGES_AND_FRAMEWORKS } from 'apps/scorecard-v2/constants'

import CommonTab from '../common/tab'

const Languages = ({ items, accessToken, interview, toggleTabKeyDisable }) => (
  <CommonTab
    items={items}
    accessToken={accessToken}
    interview={interview}
    tabTitle={LANGUAGES_AND_FRAMEWORKS}
    toggleTabKeyDisable={toggleTabKeyDisable}
  />
)

export default Languages
