import React, { Component } from 'react'
import { DndProvider } from 'react-dnd'
import backend from 'react-dnd-html5-backend'

class DnD extends Component {
  render() {
    const { props } = this

    return <DndProvider backend={backend} {...props} />
  }
}

export default DnD
