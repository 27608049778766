import {
  Dialog,
  DialogContent,
  Tabs,
  Box,
  Button,
  Tab,
  Snackbar,
  IconButton,
} from '@mui/material'
import styled from 'styled-components'

export const StyledDialog = styled(Dialog)`
  & * {
    box-sizing: border-box;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }

  max-height: 100vh;

  & .MuiDialog-container {
    width: 100%;
    justify-content: flex-end;

    & .MuiPaper-root {
      max-height: 100vh;
      max-width: max(750px, 30vw);
      width: max(750px, 30vw);
      border-radius: 0;
      background: transparent;
      box-shadow: none;
      margin: 0;
      flex-direction: row;
    }
  }
`

export const StyledDialogContent = styled(DialogContent)`
  margin: 0;
  height: 100vh;
  width: fit-content;
  background: #fff;
  padding: 0;
  cursor: default;
  overflow-y: hidden;
`

export const StyledTabs = styled(Tabs)`
  width: fit-content;

  & .MuiTabs-indicator {
    background-color: #3c26de;
    height: 4px;
  }

  & .MuiTab-root.Mui-selected {
    color: #3c26de;
  }
`

export const CloseButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  background: #f1f3f6;
  border-radius: 4px 0px 0px 4px;
  height: 50px;
  color: #5f5f6b;
  margin-top: 2vh;
  font-weight: 600;
  padding: 5px;
  max-width: 200px;
  gap: 20px;

  & svg {
    color: #5f5f6b;
  }
`

export const StyledButton = styled(Button)`
  padding: 5px;
  min-width: fit-content;
`

export const StyledInterviewHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
`

export const StyledInterviewTime = styled(Box)`
  font-weight: 600;
  padding: 10px;
  height: fit-content;
  color: #2b254f;
  font-size: 1.15rem;
  display: flex;
  align-items: center;
  gap: 5px;
`

export const StyledTab = styled(Tab)`
  text-transform: none;
  padding-bottom: 0;
  padding: 12px 10px;
`

export const StyledLoadingIndicator = styled(Box)`
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max(750px, 30vw);
  animation: flashBg 1s ease-in-out infinite;

  @keyframes flashBg {
    0% {
      background-color: #f1f3f6;
    }
    100% {
      background-color: #fff;
    }
  }
`

export const StyledSnackbar = styled(Snackbar)`
  bottom: 70px;

  & .MuiSnackbarContent-root {
    min-width: fit-content;
  }
`

export const StyledIconButton = styled(IconButton)`
  padding: 3px;
  margin-left: 8px;
`

export const TimerWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  background: #fff;
  border-radius: 3px;
  padding: 1px 5px;
`
