import noop from 'lodash/noop'
import { useEffect, useState } from 'react'

import { decrease } from 'apps/scorecard/helpers/number'

import { format } from './helpers'

export default ({ until }) => {
  const [state, setState] = useState(
    Math.max(until - Date.now().toString().substring(0, 10), 0)
  )

  useEffect(() => {
    const enqueue = () => {
      const timeout = window.setTimeout(() => setState(decrease), 1000)

      return () => window.clearTimeout(timeout)
    }
    const effect = state ? enqueue : noop

    return effect()
  }, [state])
  return state && format(state)
}
