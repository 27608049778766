import React from 'react'

import { InterviewModal } from './interview'

export { default as InterviewConfirmation } from './interview-confirmation'
export { default as Popup } from './popup'

const Modals = () => (
  <>
    <InterviewModal />
  </>
)

export default Modals
