import CloseIcon from '@mui/icons-material/Close'
import {
  IconButton,
  DialogContentText,
  Box,
  DialogContent,
} from '@mui/material'

import {
  StyledDialogTitle,
  StyledDialog,
  StyledDialogActions,
  StyledButton,
} from './style'

const ArchiveConfirmation = ({
  open,
  handleClose,
  handleArchiveOpen,
  setShow,
  handleFinishInterview,
  interviewIsFinished,
}) => {
  const onCloseInterview = () => {
    handleClose()
    setShow(false)
  }

  const onClose = () => {
    setShow(false)
  }

  return (
    <StyledDialog open={open} onClose={onClose} size='sm' fullWidth>
      <StyledDialogTitle>
        Do you want to close the widget?
        <IconButton onClick={onClose} size='large'>
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <DialogContent>
        <DialogContentText color='#a2a1b4'>
          The interview will continue running until you finish it.
        </DialogContentText>
      </DialogContent>
      <StyledDialogActions>
        <StyledButton
          sx={{ color: '#3c26de' }}
          variant='outlined'
          onClick={handleArchiveOpen}
        >
          Archive Interview
        </StyledButton>
        <Box display='flex' gap={1}>
          {!interviewIsFinished && (
            <StyledButton
              sx={{ color: '#3c26de' }}
              variant='outlined'
              onClick={handleFinishInterview}
            >
              Complete Interview
            </StyledButton>
          )}
          <StyledButton
            sx={{ background: '#3c26de', '&:hover': { background: '#1f0f93' } }}
            variant='contained'
            onClick={onCloseInterview}
          >
            Close Widget
          </StyledButton>
        </Box>
      </StyledDialogActions>
    </StyledDialog>
  )
}

export default ArchiveConfirmation
