import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import withStyles from '@mui/styles/withStyles'
import update from 'immutability-helper'
import { flow, get } from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useRef, useEffect } from 'react'

import { TIME_TO_SCROLL_MS } from 'common/constants'
import { generateKey } from 'common/key-generator'

import { Autosuggest } from '../Autosuggest'

import { Proficiency } from './Proficiency'

const styles = (theme) => ({
  form: {
    width: '100%',
  },
  actions: {
    paddingTop: theme.spacing(2),
    textAlign: 'right',
  },
  notLastItem: {
    marginRight: theme.spacing(6),
  },
})

const SpokenLanguagesBase = ({
  value: languages = [],
  onChange,
  classes,
  languagesAutosuggest,
  proficiencyDropdown,
  error,
  ...props
}) => {
  const prevLanguages = useRef(null)

  useEffect(() => {
    prevLanguages.current = languages
  }, [languages])

  const editLanguage = useCallback(
    (index) => (field) => (value) => {
      const editedLanguages = update(prevLanguages.current, {
        [index]: { $merge: { [field]: value } },
      })

      onChange(editedLanguages)
    },
    [onChange]
  )

  const removeLanguage = useCallback(
    (index, languages) => () => {
      const filteredLanguages = languages.filter(
        (_, innerIndex) => index !== innerIndex
      )

      onChange(filteredLanguages)
    },
    [onChange]
  )

  const addNewLanguage = useCallback(
    (languages) => () => {
      onChange([
        ...languages,
        {
          proficiency: '',
          language: {
            name: '',
          },
          id: generateKey(),
        },
      ])

      setTimeout(() => {
        typeof window !== 'undefined' &&
          window.scrollTo(0, document.body.clientHeight)
      }, TIME_TO_SCROLL_MS)
    },
    [onChange]
  )

  const renderAddButton = (languages) => (
    <IconButton
      onClick={addNewLanguage(languages)}
      data-automator='spoken-languages-add-button'
      size='large'
    >
      <AddIcon />
    </IconButton>
  )
  const dataAutomator = props['data-automator'] || 'spoken-languages'
  const addButton = renderAddButton(languages)
  const languageIds = languages.map((spokenLang) =>
    get(spokenLang, 'language.id')
  )
  const getOptionDisabled = ({ value: { id } }) => languageIds.includes(id)

  return (
    <>
      {languages.length === 0 && addNewLanguage(languages)()}
      {languages.map((lang, index) => {
        const editField = editLanguage(index)
        const isLast = index === languages.length - 1

        return (
          <React.Fragment key={lang.id}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={5}>
                <Autosuggest
                  {...languagesAutosuggest}
                  onChange={editField('language')}
                  onInputChange={editField('languageFreeText')}
                  value={lang.language}
                  error={
                    error &&
                    error[index] &&
                    (error[index]?.language?.name?.message ||
                      error[index]?.language?.id?.message)
                  }
                  getOptionDisabled={getOptionDisabled}
                  data-automator={`${dataAutomator}-${index}`}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={5}>
                <Proficiency
                  label={proficiencyDropdown.label}
                  onChange={editField('proficiency')}
                  value={lang.proficiency || ''}
                  outerIndex={index}
                  error={
                    error && error[index] && error[index]?.proficiency?.message
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <Box component='div' className={classes.actions}>
                  <IconButton
                    {...(!isLast && { className: classes.notLastItem })}
                    onClick={removeLanguage(index, languages)}
                    data-automator={`spoken-languages-delete-button-${index}`}
                    size='large'
                  >
                    <DeleteIcon />
                  </IconButton>
                  {isLast && addButton}
                </Box>
              </Grid>
            </Grid>
          </React.Fragment>
        )
      })}
      {languages.length === 0 && (
        <Box
          className={classes.actions}
          component='div'
          display='flex'
          justifyContent='flex-end'
        >
          {addButton}
        </Box>
      )}
    </>
  )
}

SpokenLanguagesBase.propTypes = {
  handleChange: PropTypes.func,
  errors: PropTypes.array,
  value: PropTypes.array,
  defaultValue: PropTypes.array,
}

const SpokenLanguages = flow(withStyles(styles))(SpokenLanguagesBase)

export { SpokenLanguages }
