import {
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
  Button,
  Radio,
} from '@mui/material'
import styled from 'styled-components'

export const StyledDialog = styled(Dialog)`
  padding: 15px;

  * {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }
`

export const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
`

export const StyledDialogActions = styled(DialogActions)`
  margin: 20px 15px 10px;
  justify-content: space-between;
`

export const StyledTextField = styled(TextField)`
  margin-top: 12px;
`

export const StyledButton = styled(Button)`
  text-transform: none;
`

export const StyledRadio = styled(Radio)`
  &.Mui-checked {
    color: #3c26de;
  }
`
