import { Accordion, Box, Typography } from '@mui/material'
import styled from 'styled-components'

import { getScoreColor } from 'apps/scorecard-v2/helpers'

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 150px);
`

export const StyledFooter = styled(Box)`
  display: flex;
  justify-content: space-between;
  background: #f9fafb;
  border-top: 1px solid #e6e9ef;
  padding: 15px 24px;
`

export const StyledOverallScore = styled(Box)`
  background: #f9fafb;
  border-block: 1px solid #e6e9ef;
  padding: 28px 24px;
  display: flex;
  justify-content: space-between;
  gap: 8%;
  min-height: 12rem;
`

export const StyledScore = styled(Box)`
  height: 8px;
  background: #e9e9ed;
  position: relative;
  border-radius: 3px;
  margin-bottom: 28px;

  & div {
    display: block;
    width: ${({ score }) => `${score * 10}%`};
    background: ${({ score }) => getScoreColor(score)};
    height: 8px;
  }
`

export const StyledFeedbackSection = styled(Box)`
  flex: 1;
  padding: 18px 24px;
  overflow-y: scroll;
`
export const StyledCircleWrapper = styled(Box)`
  flex-basis: 25%;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  & .MuiCircularProgress-root {
    position: absolute;
    display: flex;
  }

  & > .score.MuiCircularProgress-root {
    color: ${({ score }) => getScoreColor(score)};
    stroke-linecap: round;
  }

  & > :first-child.MuiCircularProgress-root {
    color: #e6e8ef;
  }
`

export const StyledScoreText = styled(Box)`
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  color: #555072;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .score {
    color: ${({ score }) => getScoreColor(score)};
    text-align: center;
    font-size: 1.5rem;

    & > span {
      color: #a8a6b7;
    }
  }
`

export const StyledMessage = styled(Box)`
  background: #ffd722;

  border: 1px solid rgba(0, 0, 0, 0.12);
  border-top: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50px;
  padding: 8px;

  & em {
    font-weight: 800;
  }
`

export const StyledAccordion = styled(Accordion)`
  width: 100% !important;

  ::before {
    display: none;
  }

  & .MuiAccordionSummary-root {
    padding: 0;
    font-weight: 600;
  }

  & .MuiAccordionDetails-root {
    padding: 0 4px;
    margin-bottom: 12px;
  }

  & .MuiTextField-root {
    width: 100%;
  }

  & .MuiFormHelperText-root {
    display: flex;
    justify-content: space-between;
    margin: 0 1px;
  }

  & .MuiInputBase-inputMultiline {
    color: #555172;
    line-height: 25px;
  }
`

export const StyledText = styled(Typography)`
  color: ${({ hasError }) => (hasError ? '#f56f51' : '#555172')};
  font-weight: 400;
  font-size: 0.98rem;
`
