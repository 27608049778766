import split from 'lodash/split'
import React from 'react'

const SAVE_DELIMITER = '*'
const MARKDOWN_DELIMITERS = [SAVE_DELIMITER, '-'].map((val) => `\\${val}`)

/*
    Markdown helpers used to parse textarea with markdown to list of fields.
    Used on Experience.description => responsibilities

    Format expected:
    * List 1
    * List 2

    Or
    - List 1
    - List 2

    SAVE_DELIMITER will be the delimiter used to save this information back to the backend.
*/

const markdownTextToList = (text) =>
  split(text, '\n')
    .filter((value) =>
      new RegExp(`^${MARKDOWN_DELIMITERS.join('|')}`).test(value)
    )
    .map((value) => value.slice(2))

const markdownTextToElementList = (element) => (text) =>
  markdownTextToList(text).map((value, index) =>
    React.createElement(element, { key: value + index }, value)
  )

const listToMarkdownText = (list) =>
  list
    .filter((elem) => !!elem.trim() && elem)
    .map((value) => `${SAVE_DELIMITER} ${value}`)
    .join('\n')

const markdownTextForTooltip = (element) => (text) =>
  text
    .split('\n')
    .filter(Boolean)
    .map((value, index) => React.createElement(element, { key: index }, value))

const escapeHeaderAndBlockquote = (str) =>
  (str || '')
    .replace(/\+ |#{1,6} |>|_|/g, '')
    .split('↵*')
    .join('\n*')

export {
  markdownTextToList,
  markdownTextToElementList,
  listToMarkdownText,
  markdownTextForTooltip,
  escapeHeaderAndBlockquote,
}
