import styled from 'styled-components'
import property from 'lodash/property'

export const StyledBox = styled.section`
${property('theme.typography.body2')};
  background: #ffd722;
  margin-top: ${({ curved }) => (curved === 'false' ? '0' : '-5px;')}
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-top: none;
  border-radius: ${({ curved }) =>
    curved === 'false' ? 'none;' : '0px 0px 4px 4px;'}
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  display: flex;
  min-height: 52px;
  justify-content: center;
  align-items: center;

  em {
    font-weight: 700;
  }

  p {
    padding: 8px;
  }

  @media screen and (max-width: 725px) {
    p {
      max-width: 60%;
      text-align: center;
    }
  }
`
