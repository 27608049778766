import { node } from 'prop-types'
import React from 'react'

import { Tracking as Context } from 'apps/scorecard/contexts'

import { useTracking } from './hooks'

export const Tracking = ({ children }) => {
  const value = useTracking()

  return <Context.Provider value={value}>{children}</Context.Provider>
}

Tracking.propTypes = {
  children: node.isRequired,
}

Tracking.defaultProps = {}

export default Tracking
