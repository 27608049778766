import { Card, CardContent, Typography, Container, Table } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import withStyles from '@mui/styles/withStyles'
import { map, uniq } from 'lodash'
import React from 'react'

import logo from 'assets/img/expertlead-logo.png'

import packages from './packages.json'
import {
  Apache2,
  Bsd2,
  Bsd3,
  Cco1,
  CoreUiIconsPro,
  FontAwesomeFree,
  Mit,
} from './templates'

const styles = (theme) => ({
  background: {
    width: '100%',
    height: '100%',
    backgroundColor: 'grey',
    border: 0,
    margin: 0,
  },
  logoContainer: {
    width: '100%',
    height: 100,
    margin: '10px 0',
    background: 'linear-gradient(#3c26de, #291a97)',
    padding: '15',
    borderRadius: '3',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  licenseSection: {
    margin: '20px 0px',
  },
})

const LicensesBase = ({ classes }) => {
  const transformDataForRendering = (data) =>
    data.reduce((acc, curr) => {
      const { name, version, license, copyrights } = curr

      const copyrightsTrimmed = map(copyrights, (_, key) => key)

      const item = {
        name,
        version,
        copyrights: uniq(copyrightsTrimmed),
      }

      acc[license] = [...(acc[license] || []), item]
      return acc
    }, {})

  const renderPackagesTable = (group) => (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography>
                <strong>Component Name</strong>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                <strong>Version</strong>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                <strong>Copyright</strong>
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {map(group, (item) => {
            const { name, version, copyrights } = item

            return (
              <TableRow key={JSON.stringify(item)}>
                <TableCell>
                  <Typography>{name}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{version}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{copyrights.join(', ')}</Typography>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )

  const renderLicenseSection = ({ license, data }) => (
    <Card key={license} className={classes.licenseSection}>
      <CardContent>
        <Typography variant='h4' align='center'>
          {license}
        </Typography>

        {renderPackagesTable(data)}

        <CardContent className='license-content'>
          {renderLicenseContent(license)}
        </CardContent>
      </CardContent>
    </Card>
  )

  const renderLicenseContent = (license) => {
    const contentMap = {
      MIT: Mit,
      'BSD 2-Clause': Bsd2,
      'BSD-3-Clause': Bsd3,
      'Font Awesome Free License': FontAwesomeFree,
      'Apache-2.0': Apache2,
      'CC0-1.0': Cco1,
      'CoreUI Icons Free License': CoreUiIconsPro,
    }

    const Component = contentMap[license]

    return <Component />
  }

  const ossData = transformDataForRendering(packages)

  return (
    <Container>
      <CssBaseline />
      <Card className={classes.logoContainer}>
        <a href='/'>
          <img src={logo} alt='' width='200px' />
        </a>
      </Card>
      <Card>
        <CardContent>
          <Typography variant='h4' gutterBottom>
            Copyright notices and licenses for Open Source Software
          </Typography>

          <Typography gutterBottom>
            The internet page <strong>{window.location.origin}</strong>
{' '}
uses
            Open Source Software (OSS). Insofar as the applicable license terms
            specify an obligation to publish, the copyright notices and license
            texts referring to the OSS components used are shown below:
</Typography>
        </CardContent>
      </Card>
      {map(ossData, (data, license) => renderLicenseSection({ data, license }))}
    </Container>
  )
}

const Licenses = withStyles(styles)(LicensesBase)

export { Licenses }
