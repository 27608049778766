import { Factory } from '../client/Factory'

const OnboardingNamespace = 'onboarding'

const OnboardingServiceConfigs = {
  checkToken: Factory.createRequestConfig(
    Factory.MethodGet,
    '/onboarding/profile/:token'
  ),
  submitProfile: Factory.createRequestConfig(
    Factory.MethodPost,
    '/onboarding/profile/:token'
  ),
  resendVerificationMail: Factory.createRequestConfig(
    Factory.MethodPost,
    '/onboarding/profile/:profileId/resend-verification'
  ),
}

const OnboardingService = {
  checkToken: Factory.makeClientCall(OnboardingServiceConfigs.checkToken),
  submitProfile: Factory.makeClientCall(OnboardingServiceConfigs.submitProfile),
  resendVerificationMail: Factory.makeAuthenticatedClientCall(
    OnboardingServiceConfigs.resendVerificationMail
  ),
}

export { OnboardingNamespace, OnboardingServiceConfigs, OnboardingService }
