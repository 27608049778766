import flow from 'lodash/flow'
import noop from 'lodash/noop'
import React, { useCallback, useRef } from 'react'
import {
  Dialog,
  DialogActions as Actions,
  DialogTitle as Title,
} from '@mui/material'

import { usePortal } from 'apps/scorecard/hooks'
import {
  Confirmation as Content,
  Button,
} from 'apps/scorecard/components/widgets'
import { Highlighted } from 'apps/scorecard/components/widgets/buttons'

export const DEFAULT_SETTINGS = { onCancel: noop }

export default ({ onCancel: abort } = DEFAULT_SETTINGS) => {
  const ref = useRef(noop)
  const { Portal, open, close } = usePortal()
  const confirm = useCallback(
    (action) => {
      ref.current = action

      return open
    },
    [open]
  )
  const cancel = useCallback(flow(close, abort), [close, abort])
  const proceed = useCallback(flow(close, ref.current), [close, ref.current])
  const Container = useCallback(
    ({ children }) => (
      <Portal>
        <Dialog onClose={cancel} open>
          {children}
        </Dialog>
      </Portal>
    ),
    [cancel]
  )
  const Confirm = useCallback(
    ({ children }) => <Highlighted onClick={proceed}>{children}</Highlighted>,
    [proceed]
  )
  const Cancel = useCallback(
    ({ children }) => <Button onClick={cancel}>{children}</Button>,
    [cancel]
  )

  return {
    components: { Container, Title, Content, Actions, Confirm, Cancel },
    confirm,
    cancel,
  }
}
