import { propTypes, defaultProps } from 'apps/scorecard/prop-types/placeholder'
import { usePlaceholder } from 'apps/scorecard/hooks'

export const Footer = (props) => usePlaceholder(props)

Footer.propTypes = propTypes

Footer.defaultProps = defaultProps

export default Footer
