import React from 'react'
import FormControl from '@mui/material/FormControl'
import 'react-phone-input-2/lib/material.css'
import FormHelperText from '@mui/material/FormHelperText'
import MuiPhoneNumber from 'react-phone-input-2'

const PhoneNumberField = ({
  error,
  onChange,
  isSubmitting,
  label,
  value,
  ...props
}) => {
  const isValid = error === undefined
  return (
    <FormControl
      error={!!error}
      onChange={(e) => onChange(e.target.value)}
      disabled={isSubmitting}
      {...props}
    >
      <React.Suspense fallback={<></>}>
        <MuiPhoneNumber
          country='de'
          id={props.id}
          value={value}
          variant='outlined'
          label={label}
          countryCodeEditable={false}
          enableAreaCodes
          prefix='+'
          isValid={isValid}
        />
        {error && (
          <div>
            <FormHelperText>{error}</FormHelperText>
          </div>
        )}
      </React.Suspense>
    </FormControl>
  )
}

PhoneNumberField.defaultProps = {
  margin: 'normal',
  fullWidth: true,
  value: '',
}

export { PhoneNumberField }
