import { Typography, TextField, MenuItem } from '@mui/material'
import { useEffect, useState } from 'react'

import { StyledTextField } from '../../live-coding/style'

import { FeedbackContainer, StyledButton } from './style'

const QuestionFeedback = ({
  questionId,
  handleSaveFeedback,
  setIsCommentFocus,
}) => {
  const [feedback, setFeedback] = useState('')
  const [showComment, setShowComment] = useState(0)
  const [option, setOption] = useState('')

  useEffect(() => {
    setFeedback('')
    setShowComment(0)
    setOption('')
  }, [questionId])

  const handleFeedbackChange = ({ target }) => {
    setFeedback(target.value)
  }

  const handleOptionChange = ({ target }) => {
    setOption(target.value)
    setShowComment(1)
  }

  const handleSave = () => {
    const payload = {
      feedback: option,
      interviewerNotes: feedback,
    }

    handleSaveFeedback(questionId, payload)
    setOption('')
    setShowComment(0)
    setIsCommentFocus(false)
    setFeedback('')
  }

  return (
    <>
      <FeedbackContainer>
        <Typography>
          What do you think about this question? Here, you may provide your
          suggestions for improving it.
        </Typography>
        <TextField
          value={option}
          label='Choose an option'
          onChange={handleOptionChange}
          sx={{ width: '200px', marginBottom: '8px' }}
          select
        >
          <MenuItem value={1}>Outdate</MenuItem>
          <MenuItem value={2}>Duplicated</MenuItem>
          <MenuItem value={3}>Not Relevant</MenuItem>
          <MenuItem value={4}>Hint Improvement</MenuItem>
          <MenuItem value={5}>Good</MenuItem>
        </TextField>
      </FeedbackContainer>
      <StyledTextField
        fullWidth
        variant='outlined'
        placeholder='Please give us more details'
        value={feedback}
        onChange={handleFeedbackChange}
        multiline
        rows={3}
        onBlur={() => setIsCommentFocus(false)}
        onFocus={() => setIsCommentFocus(true)}
        show={showComment}
        sx={{ '& .MuiInputBase-fullWidth': { padding: '0 !important' } }}
      />
      <StyledButton variant='contained' onClick={handleSave} show={showComment}>
        Save Feedback
      </StyledButton>
    </>
  )
}

export default QuestionFeedback
