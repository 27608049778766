import { Factory } from '../client/Factory'

const LanguageServiceNamespace = 'language'

const LanguageServiceConfigs = {
  QueryLanguages: Factory.createRequestConfig(Factory.MethodGet, '/language'),
}

/**
 * @type {{getAll: (*|(function(string, Object): Promise<any>))}}
 * @type {{QueryCities: (*|(function(string, Object): Promise<any>))}}
 */
const LanguageService = {
  QueryLanguages: Factory.makeAuthenticatedClientCall(
    LanguageServiceConfigs.QueryLanguages
  ),
  QueryLanguagesPublic: Factory.makeClientCall(
    LanguageServiceConfigs.QueryLanguages
  ),
}

export { LanguageServiceNamespace, LanguageServiceConfigs, LanguageService }
