import { string } from 'prop-types'
import React, { forwardRef, useMemo } from 'react'
import { Switch as Container } from '@mui/material'

import withStyle from './style'

export const Switch = forwardRef(({ className, ...props }, ref) => {
  const classes = useMemo(() => ({ root: className }), [className])

  return <Container classes={classes} ref={ref} {...props} />
})

Switch.propTypes = {
  className: string.isRequired,
}

Switch.defaultProps = {}

export default withStyle(Switch)
