const persistItem = (name, data) => {
  try {
    if (typeof window !== 'undefined') {
      localStorage.setItem(name, JSON.stringify(data))
    }
  } catch (err) {
    console.error(err)
  }
}

const getPersistedItem = (name) => {
  if (typeof window === 'undefined') return
  /*eslint-disable */
  try {
    return JSON.parse(localStorage.getItem(name))
  } catch (err) {
    console.error(err)
  }
  /* eslint-enable */
}

export { persistItem, getPersistedItem }
