import TungstenOutlinedIcon from '@mui/icons-material/TungstenOutlined'
import { IconButton, Typography, List, ListItem } from '@mui/material'

import StyledTooltip from 'apps/scorecard-v2/components/StyledTooltip'

import { StyledKeyText } from './style'

const KeyboardHint = () => (
  <IconButton>
    <StyledTooltip
      arrow
      title={
        <List sx={{ minWidth: '350px' }}>
          <ListItem>
            <StyledKeyText>Q</StyledKeyText>
            <Typography fontSize='0.8rem'>
              Press Q to switch between the sections
            </Typography>
          </ListItem>

          <ListItem>
            <StyledKeyText>&uarr;</StyledKeyText>
            <Typography fontSize='0.8rem'>
              Press &uarr; to move up between questions
            </Typography>
          </ListItem>

          <ListItem>
            <StyledKeyText>&darr;</StyledKeyText>
            <Typography fontSize='0.8rem'>
              Press &darr; to move down between questions
            </Typography>
          </ListItem>
          <ListItem>
            <StyledKeyText>&rarr;</StyledKeyText>
            <Typography fontSize='0.8rem'>
              Press &rarr; to select a question
            </Typography>
          </ListItem>
          <ListItem>
            <StyledKeyText>DEL</StyledKeyText>
            <Typography fontSize='0.8rem'>
              Press DEL to exit question detail mode
            </Typography>
          </ListItem>
        </List>
      }
      placement='right'
    >
      <TungstenOutlinedIcon sx={{ transform: 'rotate(180deg)' }} />
    </StyledTooltip>
  </IconButton>
)

export default KeyboardHint
