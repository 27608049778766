import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

const DialogBox = ({
  open,
  description,
  note,
  declineBtn,
  acceptBtn,
  handleClickAccept,
  handleClickDecline = noop,
  title,
  classWrapper,
  classAction,
  icon,
  leftBtnVariant,
  rightBtnVariant,
  handleDialogClose,
  hideActionButtons = false,
  dialogTitleStyle,
}) => (
  <div>
    <Dialog
      className={classWrapper}
      open={open}
      onClose={(_, reason) => handleClickDecline(reason)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      disableEscapeKeyDown
    >
      {icon && (
        <Avatar
          sx={{ width: 64, height: 64, mb: 3, mt: 4 }}
          alt=''
          src={icon}
        />
      )}

      <DialogTitle id='alert-dialog-title' className={dialogTitleStyle || ''}>
        {title}
        {handleDialogClose && (
          <IconButton onClick={handleDialogClose}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        {typeof description === 'string' && (
          <DialogContentText id='alert-dialog-description'>
            {description}
          </DialogContentText>
        )}
        {typeof description === 'object' && <Box>{description}</Box>}
        {note && (
          <DialogContentText id='alert-dialog-note'>{note}</DialogContentText>
        )}
      </DialogContent>
      {!hideActionButtons && (
        <>
          <DialogActions className={classAction}>
            <Button
              onClick={handleClickDecline}
              variant={leftBtnVariant}
              color='primary'
            >
              {declineBtn}
            </Button>
            {!!acceptBtn && (
              <Button
                onClick={handleClickAccept}
                color='primary'
                variant={rightBtnVariant}
              >
                {acceptBtn}
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  </div>
)

DialogBox.propTypes = {
  open: PropTypes.bool,
  handleClickAccept: PropTypes.func,
  handleClickDecline: PropTypes.func,
  title: PropTypes.string,
  note: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  acceptBtn: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  declineBtn: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export { DialogBox }
