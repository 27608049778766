import Typography from '@mui/material/Typography'
import { Formik } from 'formik'
import get from 'lodash/get'
import { func, object } from 'prop-types'
import * as React from 'react'
import { withTranslation } from 'react-i18next'

import { SaveTab } from 'apps/portal/components/SaveTab'
import { isPhoneNumber, isURL } from 'common/regx-lib'
import { TextField } from 'components'
import { FormWarnOnLeave } from 'components/FormWarnOnLeave'

class CommunicationComponent extends React.Component {
  constructor(props) {
    super(props)
    this.timerTracker = null
    this.onChange = this.onChange.bind(this)
  }

  onChange = (setter) => (communications) => (evt) => {
    const val = evt.currentTarget.value
    const { name } = evt.currentTarget
    setCommunication(setter, communications, name, val)
  }

  getCommunicationSection = (profile, name) => {
    const type = `TYPE_${name.toUpperCase()}`
    const found = (profile.communications || []).find((x) => x.type === type)
    return get(found, 'value', '')
  }

  onValidation = (values) => {
    const { t } = this.props
    const email = get(
      values.communications.find((x) => x.type === 'TYPE_EMAIL'),
      'value',
      ''
    )
    const website = get(
      values.communications.find((x) => x.type === 'TYPE_WEBSITE'),
      'value',
      ''
    )
    const mobile = get(
      values.communications.find((x) => x.type === 'TYPE_MOBILE'),
      'value',
      ''
    )
    const err = {}
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      err.email = t('email_required')
    }
    if (website.length !== 0 && !isURL(website)) {
      err.website = t('website_invalid')
    }
    if (mobile.length !== 0 && !isPhoneNumber(mobile)) {
      err.mobile = t('mobile_is_invalid')
    }
    return err
  }

  render() {
    const { t, profile, onSubmit } = this.props
    return (
      <Formik
        initialValues={{ ...profile }}
        onSubmit={onSubmit}
        validate={this.onValidation}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          values,
          dirty,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          isSubmitting,
          setFieldValue,
          resetForm,
        }) => {
          const setter = (value) => setFieldValue('communications', value)
          const onChange = this.onChange(setter)(values.communications)
          return (
            <form onSubmit={handleSubmit}>
              <FormWarnOnLeave shouldWarn={dirty} />
              <TextField
                label={t('email')}
                name='email'
                onChange={onChange}
                onBlur={handleBlur}
                error={!!errors.email}
                helperText={errors.email}
                value={this.getCommunicationSection(values, 'email')}
                disabled
              />
              <Typography gutterBottom component='p' variant='caption'>
                {t('change_email_info_text')}
              </Typography>
              <TextField
                label={t('mobile')}
                name='mobile'
                onChange={onChange}
                onBlur={handleBlur}
                error={!!errors.mobile}
                helperText={errors.mobile}
                value={this.getCommunicationSection(values, 'mobile')}
                disabled={isSubmitting}
              />
              <TextField
                name='website'
                label={t('website')}
                onChange={onChange}
                onBlur={handleBlur}
                error={!!errors.website}
                helperText={errors.website}
                value={this.getCommunicationSection(values, 'website')}
                disabled={isSubmitting}
              />
              <SaveTab
                onSave={handleSubmit}
                onCancel={resetForm}
                disableSave={!dirty || isSubmitting}
                disableCancel={!dirty || isSubmitting}
              />
            </form>
          )
        }}
      </Formik>
    )
  }
}

function setCommunication(setter, communications, name, value) {
  const type = `TYPE_${name.toUpperCase()}`
  const found = communications.find((x) => x.type === type)
  let action = 'modify'

  if (value === '' && ['TYPE_MOBILE', 'TYPE_WEBSITE'].includes(type)) {
    action = 'remove'
  }

  if (found && action === 'modify') {
    setter(
      communications.map((x) => (x.type === type ? { ...found, value } : x))
    )
    return
  }

  if (found && action === 'remove') {
    setter(communications.filter((x) => x.type !== type))
  }

  if (action === 'remove') return

  setter([...communications, { type, value }])
}

CommunicationComponent.propTypes = {
  t: func.isRequired,
  profile: object.isRequired,
}

const CommunicationForm = withTranslation('account_form')(
  CommunicationComponent
)

export { CommunicationForm }
