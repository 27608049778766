import property from 'lodash/property'
import styled from 'styled-components'

export default (component) => styled(component)`
  display: flex;
  flex-direction: column;
  height: 100%;

  .MuiCardHeader-root {
    padding-bottom: 0;
  }

  .MuiCardHeader-subheader {
    ${property('theme.typography.body2')};
  }
`
