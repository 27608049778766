import { number, string } from 'prop-types'
import React from 'react'

import { useCounter } from './hooks'
import withStyle from './style'

export const Counter = ({ className, ...props }) => {
  const { value, total } = useCounter(props)

  return (
    <div className={className}>
      <dl aria-roledescription='value'>
        <dt>Value</dt>
        <dd>{value}</dd>
      </dl>
      <dl aria-roledescription='total'>
        <dt>Total</dt>
        <dd>{total}</dd>
      </dl>
    </div>
  )
}

Counter.propTypes = {
  className: string.isRequired,
  total: number,
  value: number,
}

Counter.defaultProps = {
  total: 0,
  value: 0,
}

export default withStyle(Counter)
