/* eslint-disable max-classes-per-file */
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { ThemeProvider, StyledEngineProvider } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import { store } from 'common/store'
import { LicensesOverlay } from 'components/licenses'
import { Loading } from 'components/Loading'
import { DnD, Notifications } from 'components/providers'

import { Application as Portal } from './apps/portal/Application'

const styles = (theme) => ({
  pageTitle: {
    flexGrow: 1,
  },
})

const muiCache = createCache({
  key: 'mui',
  prepend: true,
})

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      application: class extends React.Component {
        render() {
          return <Loading>{null}</Loading>
        }
      },
    }
  }

  async componentDidMount() {
    if (!process.env.REACT_APP_NAME) {
      this.setState({ application: Portal })
      return
    }
    const { Application: application } = await import(
      `./apps/${process.env.REACT_APP_NAME}/Application`
    )
    this.setState({ application })
  }

  render() {
    const {
      state: { application: Application },
    } = this

    return (
      <Suspense fallback={<div />}>
        <Provider store={store}>
          <StyledEngineProvider injectFirst>
            <CacheProvider value={muiCache}>
              <ThemeProvider theme={store.getState().theme}>
                <Router>
                  <DnD>
                    <Notifications>
                      <LicensesOverlay />
                      <Application />
                    </Notifications>
                  </DnD>
                </Router>
              </ThemeProvider>
            </CacheProvider>
          </StyledEngineProvider>
        </Provider>
      </Suspense>
    )
  }
}

export default withStyles(styles)(App)
